import {
  clearFromCache,
  getCachedObject,
  setObjectInCache,
} from '../../../core/ObjectCache';
import { HiveApi } from './HiveApi';

export class HiveService {
  hiveApi = new HiveApi();

  cacheIdentifier = 'hives';
  cacheIdentifierSingle = 'hive';
  cacheDurationMinutes = 5;

  async getHive(hiveId, forceReload) {
    if (!forceReload) {
      var cached = getCachedObject(this.cacheIdentifierSingle);
      if (cached) return cached;
    }

    let result = await this.hiveApi.getHive(hiveId);

    if (!result.isAxiosError) {
      setObjectInCache(
        this.cacheIdentifierSingle,
        result,
        this.cacheDurationMinutes
      );
    }

    return result;
  }

  async getHives(forceReload) {
    if (!forceReload) {
      var cached = getCachedObject(this.cacheIdentifier);
      if (cached) return cached;
    }

    var result = await this.hiveApi.getHives();
    if (!result.isAxiosError) {
      setObjectInCache(this.cacheIdentifier, result, this.cacheDurationMinutes);
    }

    return result;
  }

  async addHive(hiveId, hive) {
    this.clearCache();
    return await this.hiveApi.addHive(hiveId, hive);
  }

  async updateHive(hiveId, hive) {
    this.clearCache();
    return await this.hiveApi.updateHive(hiveId, hive);
  }

  async clearJsonCache(hiveId) {
    return await this.hiveApi.clearJsonCache(hiveId);
  }

  async getVisitorsPaged(
    hiveId,
    search,
    page,
    resultsPerPage,
    sortColumn,
    sortDirection
  ) {
    return await this.hiveApi.getVisitorsPaged(
      hiveId,
      search,
      page,
      resultsPerPage,
      sortColumn,
      sortDirection
    );
  }

  async getVisitorsCount(hiveId, search) {
    return await this.hiveApi.getVisitorsCount(hiveId, search);
  }

  async getVisitorEmailInfo(hiveId, email) {
    return await this.hiveApi.getVisitorEmailInfo(hiveId, email);
  }

  async getModuleActivationsHive(hiveId) {
    return await this.hiveApi.getModuleActivationsHive(hiveId);
  }

  async activateModuleForHive(hiveId, module) {
    return await this.hiveApi.activateModuleForHive(hiveId, module);
  }

  async deactivateModuleForHive(hiveId, module) {
    return await this.hiveApi.deactivateModuleForHive(hiveId, module);
  }

  async deleteHive(hiveId) {
    return await this.hiveApi.deleteHive(hiveId);
  }

  async getHiveExportJson(hiveId) {
    return await this.hiveApi.getHiveExportJson(hiveId);
  }

  async importHive(hiveId, file) {
    try {
      var result = await this.hiveApi.importHive(hiveId, file);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  clearCache() {
    clearFromCache(this.cacheIdentifier);
    clearFromCache(this.cacheIdentifierSingle);
  }
}
