import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { LoginHelper } from './LoginHelper';
import { navigateToEditProfile_Page } from './Navigation';
import { Link } from 'react-router-dom';
import getLanguage from './Language';
import { Button, Grid, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ExitToApp } from '@material-ui/icons';
import { Application_Build_Number } from './ApplicationConfig';
import UserAvatar from '../features/activity/components/UserAvatar';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "5px 10px 5px 10px",
        minWidth: "300px"
    },
    createButton: {
        margin: "10px 0 10px 0"
    },
    userInfo: {
        margin: "5px 0 0 0"
    },
    buildNumber: {
        fontSize: "8px",
        marginLeft: "10px"
    }
}));

function SettingsDropdown(props) {
    const classes = useStyles();

    const loginHelper = new LoginHelper();

    return (
        <>
            {props.isOpen && <Menu id="simple-menu"
                onClose={() => props.requestClose && props.requestClose()}
                anchorEl={props.anchorEl}
                open={props.isOpen}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <div>
                    <div className={classes.root}>
                        <Grid container justify="center">
                            <Grid item xs={9} className={classes.userInfo}>
                                <UserAvatar login={loginHelper.getCurrentLogin()} size={"40"} /><br />
                                <b>{loginHelper.getUserFullName() ? loginHelper.getUserFullName() : ("(" + getLanguage("NewUser") + ")")}</b><br />
                                {loginHelper.getUserEmail()}<br />
                            </Grid>
                            <Grid item xs={3}>
                                <Link color="inherit" onClick={(e) => { e.preventDefault(); props.requestEditProfile(); }}>
                                    <Tooltip title={getLanguage("UpdateProfile")}><EditIcon /></Tooltip>
                                </Link>{" "}
                                <Link color="inherit" to="/logout">
                                    <Tooltip title={getLanguage("Logout")}><ExitToApp /></Tooltip>
                                </Link>
                            </Grid>
                        </Grid>
                        <br />
                    </div>
                    <div className={classes.buildNumber}>Build: {Application_Build_Number}</div>
                </div>

            </Menu>
            }
        </>
    );
}


export { SettingsDropdown }