import { NewsItemApi } from './NewsItemApi';

export class NewsItemService {
  newsItemApi = new NewsItemApi();

  async getNewsItem(id) {
    return await this.newsItemApi.getNewsItem(id);
  }

  async getNewsItems(hiveId) {
    return await this.newsItemApi.getNewsItems(hiveId);
  }

  async addNewsItem(newsItem) {
    return await this.newsItemApi.addNewsItem(newsItem);
  }

  async updateNewsItem(id, newsItem) {
    return await this.newsItemApi.updateNewsItem(id, newsItem);
  }

  async deleteNewsItem(id) {
    return await this.newsItemApi.deleteNewsItem(id);
  }
}
