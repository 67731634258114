import { LoginHelper } from '../../../core/LoginHelper'
import { ResourceArchiveApi } from './ResourceArchiveAPI'

export class ResourceArchiveService {
  resourceArchiveApi = new ResourceArchiveApi()
  loginHelper = new LoginHelper()

  async deleteResourceAndRelations(resourceId) {
    try {
      var result = await this.resourceArchiveApi.deleteResourceAndRelations(
        this.loginHelper.getCurrentHive().id,
        resourceId,
      )
      return result
    } catch (error) {
      console.log(error)
    }
  }

  async getResourceRelations() {
    try {
      var result = await this.resourceArchiveApi.getResourceRelations(
        this.loginHelper.getCurrentHive().id,
      )
      return result
    } catch (error) {
      console.log(error)
    }
  }
}
