import { StorageHelper } from './StorageHelper';
import { clearAllCaches } from './ObjectCache';

export class LoginHelper {
  storageHelper = new StorageHelper();
  jwt = require('jsonwebtoken');
  isLoggedIn() {
    return this.storageHelper.getLoginObject() !== null;
  }
  setLoginObject(loginObject) {
    this.storageHelper.setLoginObject(loginObject);
  }
  getLoginObject() {
    return this.storageHelper.getLoginObject();
  }
  getCurrentLogin() {
    var decoded = this.jwt.decode(this.getLoginObject());
    return decoded;
  }
  receivedUnauthorizedAnswerFromApi() {
    this.storageHelper.clearAllStorageRelatedToUser();
    clearAllCaches();
    window.location.href = '/';
  }
  performedErrorRelatedToLoginToken() {
    this.storageHelper.clearAllStorageRelatedToUser();
    clearAllCaches();
    window.location.href = '/';
  }
  getUserId() {
    var decoded = this.jwt.decode(this.getLoginObject());
    return decoded.Id;
    //return decoded.sub
  }
  isAdmin() {
    var decoded = this.jwt.decode(this.getLoginObject());
    if (decoded === null) {
      return null;
    }
    return decoded.Role == 'Admin';
    //return decoded?.realm_access?.roles.includes('admin')
  }
  getUserFullName() {
    var decoded = this.jwt.decode(this.getLoginObject());
    if (!decoded) return null;
    return decoded.FullName;
    // var name = decoded.name
    // return name
  }
  getUserEmail() {
    var decoded = this.jwt.decode(this.getLoginObject());
    if (!decoded) return null;
    return decoded.Email;
  }
  getUserHives() {
    var decoded = this.jwt.decode(this.getLoginObject());
    if (!decoded) return null;
    return decoded.Hives;
  }
  getUserPages() {
    var decoded = this.jwt.decode(this.getLoginObject());
    if (!decoded) return null;
    return decoded.Pages_Logins;
  }
  getUserTimezone() {
    var decoded = this.jwt.decode(this.getLoginObject());
    return 'Europe/Copenhagen';
  }

  //   "resource_access": {
  //     "account": {
  //       "roles": ["manage-account", "manage-account-links", "view-profile"]
  //     }
  //   },
  getUserRoles() {
    var decoded = this.jwt.decode(this.getLoginObject());
    if (!decoded) return null;
    return decoded.RoleAssignments;
    //return decoded?.realm_access?.roles
  }

  getAuthToken() {
    return this.storageHelper.getLoginObject();
  }

  userInMultipleHives() {
    let hives = this.getUserHives();
    return hives && hives.length > 1;
  }

  getCurrentHive() {
    let hives = this.getUserHives();

    let savedHive = this.storageHelper.getCurrentHive();

    if (hives && hives.length > 0) {
      if (savedHive) {
        let hiveOwnedByUser = hives.find((t) => t.Id === savedHive.id);
        if (hiveOwnedByUser) {
          return {
            id: hiveOwnedByUser.Id,
            title: hiveOwnedByUser.Title,
            moduleActivations: hiveOwnedByUser.ModuleActivations,
          };
        }
      }
      if (!this.isAdmin()) {
        return {
          id: hives[0].Id,
          title: hives[0].Title,
          moduleActivations: hives[0].ModuleActivations,
        };
      }
    }

    if (this.isAdmin()) {
      if (savedHive) {
        return savedHive;
      }
    }

    return null;
  }

  setCurrentHive(hive) {
    clearAllCaches();
    this.storageHelper.setCurrentHive(hive);
  }

  isModuleActivatedForCurrentHive(module) {
    let currentHive = this.getCurrentHive();

    if (!currentHive) return false;

    if (!currentHive.moduleActivations) return false;

    if (
      currentHive.moduleActivations &&
      currentHive.moduleActivations.find(
        (t) => t.module === module || t.Module === module
      ) != null
    )
      return true;

    return false;
  }

  userHasRole(role) {
    if (this.isAdmin()) {
      return true;
    }

    let roles = this.getUserRoles();
    if (!roles) return false;

    let currentHive = this.getCurrentHive();
    if (!currentHive) return false;

    // Always return true, if "Administrator" role is present
    let adminRole = roles.find(
      (t) => t.HiveId === currentHive.id && t.Role === 'Administrator'
    );
    if (adminRole) return true;

    // Always return true, if "Administrator" role is present, and role tested is not UserAdministration
    if (role !== 'UserAdministration' && role !== 'Administrator') {
      let editorRole = roles.find(
        (t) => t.HiveId === currentHive.id && t.Role === 'Editor'
      );
      if (editorRole) return true;
    }

    return (
      roles.find((t) => t.HiveId === currentHive.id && t.Role === role) != null
    );
  }

  isPageOwner(pageId) {
    let pages = this.getUserPages();
    return pages.find((t) => t === pageId);
  }
}
