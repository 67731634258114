import { getApiPath } from "../../../core/ServiceRoutes"
import { LoginHelper } from "../../../core/LoginHelper"

class Api {

    loginHelper = new LoginHelper();
    
    getJson(hiveId = this.loginHelper.getCurrentHive().id) {
        if (!hiveId) throw new Error('missing hiveId')
        return fetch(`${getApiPath()}/json/${hiveId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
            }
        }).then(res => res.json())
    }
}

const instance = new Api()

export default instance
