import {
  Application_Api_Path,
  Application_WebSocket_Path,
  Application_Cdn_Path,
  Application_Chat_Path,
} from "./ApplicationConfig";

function getApiPath() {
  return Application_Api_Path;
}

function getEchoPath() {
  return Application_WebSocket_Path;
}

function getCdnPath() {
  return Application_Cdn_Path;
}

function getChatPath() {
  return Application_Chat_Path;
}

export { getApiPath, getCdnPath, getChatPath };
