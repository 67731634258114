import './ChatOverview.css';
import { useDispatch, useSelector } from 'react-redux';
import getLanguage from '../../../core/Language';
import { LoginHelper } from '../../../core/LoginHelper';
import { useState, useEffect, useRef } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  getRoomInformation,
  removeNewMessageNotification,
} from '../../../actions/Actions';
import useWindowDimensions from '../../../core/helpers/ScreenSizeHelper';
import { VisitorFieldService } from '../../visitorFields/services/VisitorFieldService';

const ChatOverview = (props) => {
  const bottomRef = useRef(null);
  const dispatch = useDispatch();
  const bufferHeight = 225;
  const loginHelper = new LoginHelper();
  const visitorFieldService = new VisitorFieldService();

  const [visitorFieldsForHive, setVisitorFieldsForHive] = useState(null);
  const [conversationHistory, setConversationHistory] = useState({});
  const [roomId, setRoomId] = useState('');
  const [isChatRoomOpen, setIsChatRoomOpen] = useState(false);
  const [chatRoomName, setChatRoomName] = useState('');

  const { height } = useWindowDimensions();

  const useMatrixClient = useSelector((state) => state.matrixClient);
  const useRoomInformation = useSelector((state) => state.roomInformation);
  const useIsChatListUpdateNeeded = useSelector(
    (state) => state.isUpdateNeeded
  );
  const useNewMessageNotification = useSelector(
    (state) => state.newMessageNotification
  );
  const useActiveGuestRoomId = useSelector((state) => state.activeUserRoomId);
  const [message, setMessage] = useState('');
  let chatRooms = useMatrixClient.getRooms();

  useEffect(() => {
    prepareData();
  }, []);

  useEffect(() => {
    if (useActiveGuestRoomId.length > 0) {
      openChatRoom(useActiveGuestRoomId);
    }
  }, [useActiveGuestRoomId]);

  useEffect(() => {
    if (useIsChatListUpdateNeeded) {
      chatRooms = useMatrixClient.getRooms();
    }
  }, [useIsChatListUpdateNeeded]);

  const prepareData = async () => {
    let response = await visitorFieldService.getVisitorFieldsForHive(
      loginHelper.getCurrentHive().id
    );
    setVisitorFieldsForHive(response);
  };

  const sendMessageHandler = () => {
    const content = {
      body: message,
      msgtype: 'm.text',
    };

    useMatrixClient
      .sendEvent(roomId, 'm.room.message', content, '')

      .then((res) => {
        setMessage('');
      })
      .catch((err) => {
        console.log('>>', err);
      });
  };

  const scrollIntoViewHandler = (tempo) => {
    bottomRef.current?.scrollIntoView({
      behavior: tempo,
      block: 'nearest',
      inline: 'start',
    });
  };

  const onMessageHandler = (e) => {
    setMessage(e.target.value);
  };

  const openChatRoom = (roomId) => {
    const room = useMatrixClient.getRoom(roomId);
    setChatRoomName(room.name.replace(/\(.*\)/g, ''));
    setRoomId(roomId);
    dispatch(getRoomInformation(room));
    dispatch(removeNewMessageNotification(roomId));
    setIsChatRoomOpen(true);
    setTimeout(() => {
      scrollIntoViewHandler('instant');
    }, 100);
  };

  useEffect(() => {
    if (
      useRoomInformation.some((room) => room?.event?.room_id.includes(roomId))
    ) {
      setConversationHistory(useRoomInformation);
      setTimeout(() => {
        scrollIntoViewHandler('smooth');
      }, 100);
    } else {
      setConversationHistory([]);
    }
    return;
  }, [useRoomInformation]);

  return (
    <>
      <div className={'chatOverviewScaffold'}>
        <div>
          <Grid container spacing={3} className={'chatUpperContainer'}>
            <Grid item xs={3}>
              <h2>Conversations</h2>
            </Grid>
            <Grid item xs={6} className={'middleItem title'}>
              {chatRoomName}
            </Grid>
            <Grid item xs={3}>
              <div className={'floatRight'}>
                <Button
                  color="primary"
                  component="span"
                  onClick={() => props.requestClose()}
                >
                  <Close />
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            spacing={3}
            className={'chatLowerContainer'}
            style={{ height: height - bufferHeight + 'px' }}
          >
            <Grid item xs={3} className={'chatNetworksOverview'}>
              {chatRooms.length > 0 ? (
                chatRooms.map((room) => {
                  return (
                    <div
                      key={room.roomId}
                      onClick={() => openChatRoom(room.roomId)}
                      className={`${'chatRoom'} ${
                        useNewMessageNotification.includes(room.roomId)
                          ? 'newMessageNotification'
                          : ''
                      }`}
                    >
                      {room.name.replace(/\(.*\)/g, '')}
                    </div>
                  );
                })
              ) : (
                <p>
                  <b>{getLanguage('YouHaveNoOpenChatsAvailable')}</b>
                </p>
              )}
            </Grid>

            <Grid item xs={6} className="middleItem">
              {conversationHistory?.length > 0 && isChatRoomOpen
                ? conversationHistory.map((item) => {
                    return (
                      <div key={item.event.event_id}>
                        <div>{item.sender.rawDisplayName}</div>
                        <div className={'message'}>
                          {item.event.content.body}
                        </div>
                      </div>
                    );
                  })
                : ''}
              <div ref={bottomRef}></div>
              {isChatRoomOpen && (
                <Grid item xs={6} className={'bottomItem'}>
                  <TextField
                    value={message}
                    onChange={(e) => onMessageHandler(e)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        sendMessageHandler();
                      }
                    }}
                    id="outlined-basic"
                    size={'medium'}
                    style={{ width: 500 }}
                    variant="outlined"
                  />
                  <Button
                    onClick={sendMessageHandler}
                    className={'sendMessage'}
                    variant="contained"
                    color="primary"
                    component="span"
                  >
                    Send
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ChatOverview;
