import './HiveUrlEditor.css'
import { Button, FormControl, Grid, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import { Clear, Delete, Edit, Save } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '0 5px 0 5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  highButtons: {
    height: '55px',
    margin: '0 0 0 5px',
  },
  styleItem: {
    margin: '5px 0 5px 0',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}))

const HiveUrlEditor = (props) => {
  const classes = useStyles()

  const [publicUrl, setPublicUrl] = useState('')
  const [publicUrlError, setPublicUrlError] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (props.hiveUrl) {
      setPublicUrl(props.hiveUrl.publicUrl)
    }
  }, [props.hiveUrl])

  const onSave = () => {
    let validationError = false

    // Validate URL not empty
    if (!publicUrl) {
      setPublicUrlError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else {
      setPublicUrlError('')
    }

    if (
      publicUrl &&
      (publicUrl.toLowerCase().startsWith('http://') ||
        publicUrl.toLowerCase().startsWith('https://'))
    ) {
      setPublicUrlError(
        getLanguage('UrlShouldOnlyBeTheDomainWithSubdomainAndRoute'),
      )
      validationError = true
    } else {
      setPublicUrlError('')
    }

    if (validationError) {
      return
    }

    props.onSave({
      publicUrl,
    })

    setIsEditing(false)
  }

  const onEdit = () => {
    setIsEditing(true)
  }

  const onDelete = () => {
    props.onDelete(props.hiveUrl)
  }

  const cancelEdit = () => {
    setIsEditing(false)

    if (props.hiveUrl) {
      setPublicUrl(props.hiveUrl.publicUrl)
    }
  }

  return (
    <div className={classes.styleItem}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSave()
        }}
      >
        <Grid container>
          <Grid item xs={8}>
            {(!props.hiveUrl || isEditing) && (
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <TextField
                  variant="outlined"
                  value={publicUrl}
                  error={publicUrlError}
                  helperText={publicUrlError}
                  onChange={(e) => setPublicUrl(e.target.value)}
                  label={getLanguage('PublicUrl')}
                  className="textField"
                />
              </FormControl>
            )}
            {props.hiveUrl && !isEditing && (
              <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                  variant="outlined"
                  value={publicUrl}
                  disabled
                  className="textField"
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            {(!props.hiveUrl || isEditing) && (
              <>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.button}
                >
                  <Save />
                </Button>
                {props.hiveUrl && (
                  <>
                    {' '}
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => cancelEdit()}
                    >
                      <Clear />
                    </Button>
                  </>
                )}
              </>
            )}
            {props.hiveUrl && !isEditing && (
              <>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => onEdit()}
                >
                  <Edit />
                </Button>{' '}
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => onDelete()}
                >
                  <Delete />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default HiveUrlEditor
