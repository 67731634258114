import english from './../i18n/english.json';

var languageStrings = [];

function getLanguage(languageCode) {
    try {
        // First-time loading in default language
        if (!languageStrings || languageStrings.length === 0) {
            // TODO: Add functionality to get language from user

            // Fall-back when no user set by user
            languageStrings = english;
        }

        var str = languageStrings.find(t => t.Id === languageCode);
        if (str) {
            return str.Text;
        }

        return "{STRING_NOT_FOUND:" + languageCode + "}";
    }
    catch (e) {
        console.log(e)
    }
}

export default getLanguage;