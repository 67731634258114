import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { ResourceService } from '../../resource/services/ResourceService';
import { ToastsStore } from 'react-toasts';
import { makeStyles } from '@material-ui/core/styles';
import getLanguage from '../../../core/Language';
import './editGalleryDetails.css';

const useStyles = makeStyles((theme) => ({
  formControl: { marginTop: theme.spacing(1) },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditGalleryDetails = (props) => {
  const galleryTypes = {
    TITLE: 'Title',
    SUBTITLE: 'Subtitle',
    DESCRIPTION: 'Description',
    CONTACTEMAIL: 'contactEmail',
    CONTACTNAME: 'contactName',
    MAILSUBJECT: 'mailsubject',
    MAILBODY: 'mailbody',
  };

  const {
    TITLE,
    SUBTITLE,
    DESCRIPTION,
    CONTACTEMAIL,
    CONTACTNAME,
    MAILSUBJECT,
    MAILBODY,
  } = galleryTypes;

  const classes = useStyles();
  const resourceService = new ResourceService();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [sortingKey, setSortingKey] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [textError, setTextError] = useState({
    titleError: '',
    contactNameError: '',
    contactEmailError: '',
  });
  const [resources, setResources] = useState([]);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [contantDetails, setContactDetails] = useState({
    contactEmail: '',
    contactName: '',
    mailsubject: '',
    mailsody: '',
  });

  const { contactEmail, contactName, mailsubject, mailbody } = contantDetails;
  const { titleError, contactNameError, contactEmailError } = textError;

  useEffect(() => {
    if (props.galleryItem) {
      const {
        title,
        subtitle,
        description,
        contactEmail,
        contactName,
        sortingKey,
        isActive,
        mailsubject,
        mailbody,
      } = props.galleryItem;
      setTitle(title);
      setSubtitle(subtitle);
      setDescription(description);
      setSortingKey(sortingKey);
      setIsActive(isActive);
      setContactDetails({
        ...contantDetails,
        contactEmail: contactEmail,
        contactName: contactName,
        mailsubject: mailsubject,
        mailbody: mailbody,
      });
    }

    getResources();
  }, []);

  useEffect(() => {
    if (props.galleryItem) {
      setSelectedResourceId(props.galleryItem.resourceId);
    }
  }, [resources]);

  const onSaveClicked = async () => {
    if (!title) {
      setTextError({
        ...textError,
        titleError: getLanguage('ValidationErrorCannotBeEmpty'),
        contactEmailError: '',
        contactNameError: '',
      });
      return;
    }

    if (contactEmail && !contactName) {
      setTextError({
        ...textError,
        contactNameError: getLanguage('WhenContactNameIsSetEmailCannotBeEmpty'),
        contactEmailError: '',
        titleError: '',
      });
      return;
    }

    if (!contactEmail && contactName) {
      setTextError({
        ...textError,
        contactEmailError: getLanguage(
          'WhenContactEmailIsSetNameCannotBeEmpty'
        ),
        contactNameError: '',
        titleError: '',
      });
      return;
    }

    setTextError({
      ...textError,
      titleError: '',
      contactNameError: '',
      contactEmailError: '',
    });

    props.onSave({
      title,
      subtitle,
      description,
      resourceId: selectedResourceId,
      contactName,
      contactEmail,
      sortingKey,
      isActive,
      mailsubject,
      mailbody,
    });
  };

  const getResources = async () => {
    let response = await resourceService.getResources();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      // Filter away resources not being asset uploads
      response = response.filter(
        (t) => t.uploadedFor === 'Assets' && t.fileMimeType.startsWith('image/')
      );
      setResources(response);
    }
  };

  const handleResourceChanged = (event) => {
    setSelectedResourceId(event.target.value);
  };

  const handleTextChange = (textType, e) => {
    const value = e.target.value;
    switch (textType) {
      case TITLE:
        setTitle(value);
        break;
      case SUBTITLE:
        setSubtitle(value);
        break;
      case DESCRIPTION:
        setDescription(value);
        break;
      default:
        return;
    }
  };

  const handleContactDetails = (e, contactType) => {
    const textValue = e.target.value;
    setContactDetails({ ...contantDetails, [contactType]: textValue });
  };

  const getResourceOptions = () => {
    return resources.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.fileName}
      </MenuItem>
    ));
  };

  return (
    <div className='formWrapper'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={titleError}
            helperText={titleError}
            value={title}
            label={getLanguage('Title')}
            onChange={(e) => handleTextChange(TITLE, e)}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={subtitle}
            label={getLanguage('Subtitle')}
            onChange={(e) => handleTextChange(SUBTITLE, e)}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={description}
            label={getLanguage('Description')}
            onChange={(e) => handleTextChange(DESCRIPTION, e)}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel
            shrink={selectedResourceId ? true : false}
            className={classes.selectLabel}
            id='hiveSelector-label'
          >
            {getLanguage('ChooseFromAssets')}
          </InputLabel>
          <Select
            variant='outlined'
            fullWidth={true}
            value={selectedResourceId}
            onChange={handleResourceChanged}
            labelId='hiveSelector-label'
            id='hiveSelector'
          >
            <MenuItem value={''}>
              <em>{getLanguage('PleaseChoose')}</em>
            </MenuItem>
            {getResourceOptions()}
          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={contactEmail}
            error={contactEmailError}
            helperText={contactEmailError}
            label={getLanguage('ContactEmail')}
            onChange={(e) => handleContactDetails(e, CONTACTEMAIL)}
            className='textField'
          ></TextField>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={contactName}
            error={contactNameError}
            helperText={contactNameError}
            label={getLanguage('ContactName')}
            onChange={(e) => handleContactDetails(e, CONTACTNAME)}
            className='textField'
          ></TextField>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={mailsubject}
            label={getLanguage('MailButtonTopic')}
            onChange={(e) => handleContactDetails(e, MAILSUBJECT)}
            className='textField'
          ></TextField>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            multiline
            rows='10'
            contentEditable
            value={mailbody}
            label={getLanguage('MailButtonBody')}
            onChange={(e) => handleContactDetails(e, MAILBODY)}
            className='textField'
          ></TextField>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            type='number'
            value={sortingKey}
            label={getLanguage('SortingKey')}
            onChange={(e) => setSortingKey(e.target.value)}
          ></TextField>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            }
            label={getLanguage('ShowGalleryItem')}
            className='checkbox'
          />
        </FormControl>

        <br />
        <br />

        <br />

        <Button
          type='submit'
          fullWidth={true}
          variant='contained'
          color='primary'
        >
          {getLanguage('Save')}
        </Button>
      </form>
      <div className='gallery-card-wrapper'>
        <div className={`gallery-card ${!isActive && 'card-disabled'}`}>
          <div className='gallery-card-image'>
            {selectedResourceId && (
              <img
                alt='resourceImage'
                src={resourceService.getResourceLink(selectedResourceId)}
              />
            )}
          </div>
          <div className='gallery-card-text-wrapper'>
            <h1 className='gallery-card-title'>{title}</h1>
            <h3 className='gallery-card-subtitle'>{subtitle}</h3>
            <p className='gallery-card-description'>{description}</p>
            {contactEmail && contactName && (
              <button className='gallery-card-cta'>
                {getLanguage('Contact')}
              </button>
            )}
          </div>
        </div>
        <div className={`card-disabled-banner ${!isActive ? 'active' : ''}`}>
          {getLanguage('CardIsNotBeingShownToGuests')}
        </div>
      </div>
    </div>
  );
};

export default EditGalleryDetails;
