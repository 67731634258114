import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import autoAnimate from '@formkit/auto-animate';
import CloseIcon from '@material-ui/icons/Close';
import { PageService } from '../../page/services/PageService';
import { SubMenuService } from '../services/SubMenuService';
import './EditClientSubMenu.css';
import { ToastsStore } from 'react-toasts';
import { LoginHelper } from '../../../core/LoginHelper';
import {
  ListItem,
  TextField,
  List,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { HiveService } from '../../hive/services/HiveService';
import getLanguage from '../../../core/Language';

const EditClientSubMenu = () => {
  const loginHelper = new LoginHelper();
  const pageService = new PageService();
  const hiveService = new HiveService();
  const subMenuService = new SubMenuService();

  const [items, setItems] = useState([]);
  const [activePages, setActivePages] = useState([]);
  const [fieldError, setFieldError] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState({
    language: '',
    languageCode: '',
  });
  const [hiveDetails, setHiveDetails] = useState({});
  const [translations, setTranslations] = useState([]);
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    const loadData = async () => {
      await fetchPageData();
    };

    loadData();
    fetchHiveDetails();
  }, []);

  const fetchHiveDetails = () => {
    hiveService.getHive().then((hiveService) => {
      setHiveDetails(hiveService);
      setSelectedLanguage({
        language: hiveService.defaultLanguage.name,
        languageCode: hiveService.defaultLanguage.id,
      });
      setTranslations(hiveService.hiveLanguages);
      fetchSubMenuDetails(
        loginHelper.getCurrentHive().id,
        hiveService.defaultLanguage.id
      );
    });
  };

  const fetchSubMenuDetails = (hiveId, defaultLanguage) => {
    subMenuService.getSubMenu(hiveId, defaultLanguage).then((subMenu) => {
      setItems(subMenu);
    });
  };

  const handlePageChanged = (event, itemId) => {
    const updatedItems = [...items];

    const itemIndex = updatedItems.findIndex((item) => item.id === itemId);

    updatedItems[itemIndex].pageId = event.target.value;

    setItems(updatedItems);
  };

  const addMenuItemHandler = () => {
    const newItem = {
      id: uuidv4(),
      hiveId: loginHelper.getCurrentHive().id,
      title: '',
      itemOrder: items.length > 0 ? items.length : 0,
      pageId: '',
      titleTextContent: null,
    };
    if (items.length > 0) {
      setItems([...items, newItem]);
    } else {
      setItems([newItem]);
    }
  };

  const removeMenuItemHandler = (itemId) => {
    const updatedItems = items.filter((item) => item.id !== itemId);
    setItems(updatedItems);
  };

  const languageOptions = translations.map((translation) => (
    <MenuItem value={translation.name} key={translation.id} id={translation.id}>
      {translation.name}
    </MenuItem>
  ));

  const handleSelectedLanguage = (event) => {
    setSelectedLanguage({
      language: event.target.value,
      languageCode: event.currentTarget.id,
    });
  };

  const findTranslationIndex = (translations, languageId) =>
    translations.findIndex(
      (translation) => translation.languageId === languageId
    );

  const handleTitleChange = (id, newValue) => {
    const isDefaultLanguage =
      selectedLanguage.languageCode === hiveDetails.defaultLanguage.id;

    if (isDefaultLanguage) {
      // If it's the default language, update the title directly
      const updatedItems = items.map((menuItem) =>
        id === menuItem.id ? { ...menuItem, title: newValue } : menuItem
      );
      setItems(updatedItems);
      return;
    }

    // If it's not the default language, update translations
    const updatedItems = items.map((menuItem) => {
      if (id !== menuItem.id) {
        return menuItem;
      }

      const existingTranslations =
        menuItem.titleTextContent?.translations || [];
      const existingTranslationIndex = findTranslationIndex(
        existingTranslations,
        selectedLanguage.languageCode
      );

      if (existingTranslationIndex !== -1) {
        // If translation for the language already exists, update it
        existingTranslations[existingTranslationIndex].translation = newValue;
      } else {
        // If translation for the language does not exist, add a new one
        existingTranslations.push({
          languageId: selectedLanguage.languageCode,
          translation: newValue,
        });
      }

      return {
        ...menuItem,
        titleTextContent: {
          originalLanguageId: hiveDetails.defaultLanguage.id,
          originalTitle: menuItem.title,
          translations: existingTranslations,
        },
      };
    });

    setItems(updatedItems);
  };

  const moveItemUp = (itemId) => {
    const itemIndex = items.findIndex((item) => item.id === itemId);
    if (itemIndex > 0) {
      const updatedItems = [...items];
      const itemToMove = updatedItems.splice(itemIndex, 1)[0];
      updatedItems.splice(itemIndex - 1, 0, itemToMove);
      updatedItems.forEach((item, index) => {
        item.itemOrder = index;
      });
      setItems(updatedItems);
    }
  };

  const moveItemDown = (itemId) => {
    const itemIndex = items.findIndex((item) => item.id === itemId);
    if (itemIndex < items.length - 1) {
      const updatedItems = [...items];
      const itemToMove = updatedItems.splice(itemIndex, 1)[0];
      updatedItems.splice(itemIndex + 1, 0, itemToMove);
      updatedItems.forEach((item, index) => {
        item.itemOrder = index;
      });
      setItems(updatedItems);
    }
  };

  const fetchPageData = async () => {
    let response = await pageService.getPages(loginHelper.getCurrentHive().id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      let locationPages = [];

      for (const page of response) {
        if (page.canContainEvents) {
          locationPages.push(page);
        }
      }

      setActivePages(locationPages);
    }
  };

  const getPageOptions = () => {
    return activePages.map((data) => (
      <MenuItem key={data.id} value={data.id} data-title={data.title}>
        {data.title}
      </MenuItem>
    ));
  };

  const onSaveClicked = async () => {
    var validationError = false;
    const titleExists = items.every((item) => item.title.length > 0);
    const pageExists = items.every((item) => item.pageId.length > 0);
    if (!titleExists || !pageExists) {
      setFieldError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    }

    if (validationError) {
      return;
    }

    subMenuService.updateSubMenu(loginHelper.getCurrentHive().id, items);
  };

  return (
    <>
      <div className='templateMenuBackground'>
        <div className='templateMenuWrapper'>
          {items?.length > 0 ? (
            items?.map((item) => {
              return (
                item.title.length > 0 && (
                  <div key={item.id} className='templateMenuItem'>
                    {item.title}
                  </div>
                )
              );
            })
          ) : (
            <div className='templateMenuItem'>
              {getLanguage('AddAMenuItemAndSeeTheChangesLive')}
            </div>
          )}
        </div>
      </div>
      <FormControl
        style={{ marginBottom: '40px', width: '100%', maxWidth: 700 }}
        variant='outlined'
      >
        <InputLabel id='categorySelector-label'>
          {getLanguage('Translation')}
        </InputLabel>
        <Select
          variant='outlined'
          fullWidth={true}
          value={selectedLanguage.language}
          onChange={handleSelectedLanguage}
          labelId='categorySelector-label'
          label={getLanguage('Translation')}
          id='categorySelector'
        >
          {languageOptions}
        </Select>
      </FormControl>
      <List style={{ width: '100%', maxWidth: 700 }} ref={parent}>
        {items?.length > 0 &&
          items?.map((item, index) => {
            const isDefaultLanguage =
              selectedLanguage.languageCode === hiveDetails.defaultLanguage?.id;
            const translatedTitle = isDefaultLanguage
              ? item.title
              : item.titleTextContent?.translations.find(
                  (translation) =>
                    translation.languageId === selectedLanguage.languageCode
                )?.translation || '';
            return (
              <ListItem
                style={{
                  border: '1px solid #dee9f1',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '16px 20px',
                  backgroundColor: '#f6f9fb',
                  marginBottom: '20px',
                }}
                key={item.id}
              >
                <TextField
                  size='small'
                  style={{ background: '#fff' }}
                  error={item.title.length === 0 && fieldError ? true : false}
                  helperText={item.title.length === 0 && fieldError}
                  value={translatedTitle}
                  onChange={(e) => handleTitleChange(item.id, e.target.value)}
                  variant='outlined'
                  label={getLanguage('Title')}
                ></TextField>

                <FormControl size='small' style={{ width: 250 }}>
                  <TextField
                    select
                    style={{ background: '#fff' }}
                    size='small'
                    variant='outlined'
                    error={
                      item.pageId.length === 0 && fieldError ? true : false
                    }
                    helperText={item.pageId.length === 0 && fieldError}
                    value={item.pageId || ''}
                    onChange={(e) => handlePageChanged(e, item.id)}
                    label={getLanguage('Page')}
                    id='pageSelector'
                  >
                    <MenuItem value={''}>
                      <em>{getLanguage('PleaseChoose')}</em>
                    </MenuItem>
                    {getPageOptions()}
                  </TextField>
                </FormControl>
                <ArrowUpward
                  style={
                    index === 0
                      ? { color: '#DCDAD1' }
                      : { cursor: 'pointer', color: '#121C42' }
                  }
                  onClick={() => moveItemUp(item.id)}
                />

                <ArrowDownward
                  style={
                    index === items.length - 1
                      ? { color: '#DCDAD1' }
                      : { cursor: 'pointer', color: '#121C42' }
                  }
                  onClick={() => moveItemDown(item.id)}
                />

                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => removeMenuItemHandler(item.id)}
                  color='error'
                />
              </ListItem>
            );
          })}
      </List>
      <Button
        variant='contained'
        color='primary'
        disabled={items.length > 5}
        onClick={addMenuItemHandler}
      >
        {getLanguage('AddMenuItem')}
      </Button>
      <br />
      <br />
      <Button variant='contained' color='primary' onClick={onSaveClicked}>
        Save
      </Button>
    </>
  );
};

export default EditClientSubMenu;
