import React, { useContext } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Router from './Router';
import AppMenu from './AppMenu';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { useLocation } from 'react-router-dom';
import { WebSocketContext } from '../socket/EchoSocket';
import { Container } from 'reactstrap';

const AppScaffold = () => {
  const theme = createMuiTheme({
    root: {
      color: '#000000',
    },
    palette: {
      primary: {
        main: '#121C42',
      },
      secondary: {
        main: '#bfc2c6',
      },
    },
  });

  const location = useLocation();
  const echo = useContext(WebSocketContext);

  const [currentPageId, setCurrentPageId] = React.useState(null);
  console.log(currentPageId)

  React.useEffect(() => {
    // Update Echo about navigating to and away from pages
    if (!location.pathname.startsWith('/editpage/')) {
      if (currentPageId !== null) {
        echo.sendMessage('activity', 'LeftCmsPage', currentPageId, null);
        setCurrentPageId(null);
      }
    } else {
      // TODO: This will break things one day
      let pageId = location.pathname.replace('/editpage/', '');
      echo.sendMessage('activity', 'CmsPage', pageId, null);
      setCurrentPageId(pageId);
      echo.setCurrentPageIdHandler(pageId);
    }
  }, [location, currentPageId, echo]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppMenu />
        <ToastsContainer store={ToastsStore} />
        <div className={'content'}>
          <Container
            style={{
              padding: 0,
              maxWidth: '1200px',
              margin: '0 auto',
            }}
            maxWidth='sm'
          >
            <Router />
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
};

export default AppScaffold;
