import './UserAvatar.css';
import React from 'react';
import { Person } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
import { ResourceService } from '../../resource/services/ResourceService';

const resourceService = new ResourceService();

const UserAvatar = (props) => {
  let profilePicture = null;
  if (props.login) {
    if (props.login.profilePictureResourceId) {
      profilePicture = resourceService.getResourceLink(
        props.login.profilePictureResourceId
      );
    } else if (props.login.ProfilePictureResourceId) {
      profilePicture = resourceService.getResourceLink(
        props.login.ProfilePictureResourceId
      );
    }
  }

  return (
    <Avatar style={{ height: props.size + 'px', width: props.size + 'px' }}>
      {profilePicture && (
        <img
          src={profilePicture}
          alt=""
          style={{ height: props.size + 'px', width: props.size + 'px' }}
        />
      )}
      {!profilePicture && (
        <Person
          style={{ height: props.size + 'px', width: props.size + 'px' }}
        />
      )}
    </Avatar>
  );
};

export default UserAvatar;
