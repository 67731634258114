import './EditClientSetting.css'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  optionLabel: {
    height: '50px',
    marginBottom: '10px',
    fontSize: "22px"
  }
}))

const EditClientSetting = (props) => {
  const classes = useStyles()

  const [value, setValue] = useState('')

  useEffect(() => {
    if (props.hiveSetting) {
      setValue(props.hiveSetting.value)
    }
    // eslint-disable-next-line
  }, [props.hiveSetting])

  const onSaveClicked = async () => {
    props.onSave(value)
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSaveClicked()
        }}
      >
        {props.hiveSetting.type === 'string' && (
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <TextField
              variant="outlined"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              label={getLanguage('Value')}
              className="textField"
            />
          </FormControl>
        )}
        {props.hiveSetting.type === 'number' && (
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <TextField
              variant="outlined"
              value={value ?? parseInt(value)}
              type="number"
              onChange={(e) => setValue(e.target.value.toString())}
              label={getLanguage('Value')}
              className="textField"
            />
          </FormControl>
        )}
        {props.hiveSetting.type === 'boolean' && (
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={value === 'true'}
                  onChange={(e) =>
                    setValue(e.target.checked ? 'true' : 'false')
                  }
                />
              }
              label="Yes/No"
              className="checkbox"
            />
          </FormControl>
        )}

        {props.hiveSetting.type === 'option' && (
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <select
              className={classes.optionLabel}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              {props.hiveSetting.options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </FormControl>
        )}


        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          {getLanguage('Save')}
        </Button>
      </form>
    </>
  )
}

export default EditClientSetting
