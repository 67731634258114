import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMessageLog } from './../actions/Actions';
import { Application_WebSocket_Path } from '../core/ApplicationConfig';
import { LoginHelper } from '../core/LoginHelper';

const { detect } = require('detect-browser');

const WebSocketContext = createContext(null);
const browser = detect();

export { WebSocketContext };

const EchoSocket = ({ children }) => {
  const loginHelper = new LoginHelper();
  const isLoggedIn = loginHelper.isLoggedIn();
  const dispatch = useDispatch();
  const [currentPageId, setCurrentPageId] = useState('');
  const [client, setClient] = useState(null);
  const [authSent, setAuthSent] = useState(false);
  const [onMessage, setOnMessage] = useState(() => () => {});

  let interval;

  const connect = () => {
    if (!client) {
      const queryString =
        isLoggedIn && loginHelper?.getCurrentHive()
          ? `?hiveid=${loginHelper?.getCurrentHive()?.id}`
          : '';
      const newClient = new WebSocket(
        `${Application_WebSocket_Path}${queryString}`
      );
      setClient(newClient);
    }
  };

  useEffect(() => {
    if (!authSent) {
      connect();
    }
  }, [authSent]);

  if (client) {
    client.onopen = () => {
        client.send('WS OPEN');
        interval = setInterval(() => {
          client.send('{"type":"activity","action":"ping"}');
        }, 20000);
      sendAuth();
    };
    client.onmessage = (message) => {
      var messageData = JSON.parse(message.data);
      messageData.data = JSON.parse(messageData.data);
      dispatch(updateMessageLog(messageData));
      onMessage(messageData);
    };
    client.onclose = (e) => {
      clearInterval(interval);
      setTimeout(connect, 1000);
      setAuthSent(false);
      client.send("WS CLOSED")
    };
  }

  const sendAuth = () => {
    if (client) {
      const currentHive = loginHelper.getCurrentHive();
      if (!currentHive) {
        return;
      }
      const payload = {
        authToken: loginHelper.getLoginObject(),
        type: 'auth',
        action: 'UpdateToken',
        identifier: currentHive.id,
        data: JSON.stringify({
          browserName: browser.name,
          browserVersion: browser.version,
          operatingSystem: browser.os,
        }),
      };
      client.send(JSON.stringify(payload));
      if (currentPageId) {
        client.send(
          JSON.stringify({
            type: 'activity',
            action: 'CmsPage',
            identifier: currentPageId,
          })
        );
      }
      setAuthSent(true);
    }
  };

  const setCurrentPageIdHandler = (pageId) => {
    setCurrentPageId(pageId);
  };

  const sendMessage = (type, action, identifier, data) => {
    const payload = { type, action, identifier, data };
    waitForSocketConnection(client, function () {
      console.log(payload)
      client.send(JSON.stringify(payload));
    });
  };

  const waitForSocketConnection = (socket, callback) => {
    if (authSent) {
      callback();
    } else {
      setTimeout(() => waitForSocketConnection(socket, callback), 500);
    }
  };

  const ws = {
    socket: client,
    sendMessage,
    sendAuth,
    setCurrentPageIdHandler,
    setOnMessage,
  };

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default EchoSocket;
