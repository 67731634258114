import React, { useEffect, useState } from 'react';
import Loading from 'react-fullscreen-loading';
import { LoginService } from '../services/LoginService';
import { Button, TextField } from '@material-ui/core';
import getLanguage from '../../../core/Language';
import { navigateToDashboard } from '../../../core/Navigation';

const CreateLogin_Page = (props) => {

    const loginService = new LoginService();

    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");

    useEffect(() => {
        loadPage();
    }, []);

    const loadPage = async () => {
        requestedSetIsLoading(false);
    }

    const requestedSetIsLoading = (val) => {
        setIsLoading(val);
    }



    const createrUser = async (props, login, fullName, pass) => {
        requestedSetIsLoading(true);

        var res = await loginService.createrUser(JSON.stringify({
            Email: login,
            FullName: fullName,
            Password: pass
        }));
        requestedSetIsLoading(false);
        if (res) {
            navigateToDashboard(props)
        }

    }

    return (
        <>
            {isLoading && <Loading loading background="linear-gradient(180deg, #fff, #edf5fe)" loaderColor="#124238" />}
            <div>
                <TextField variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} label={getLanguage("Email")} />
            </div>
            <div>
                <TextField variant="outlined" value={fullName} onChange={(e) => setFullName(e.target.value)} label={getLanguage("FullName")} />
            </div>
            <div>

                <TextField variant="outlined" type="password" value={password} onChange={(e) => setPassword(e.target.value)} label={getLanguage("Password")} />
            </div>
            <div>
                <Button variant="contained" color="primary" variant="contained" onClick={() => createrUser(props, email, fullName, password)} >{getLanguage("Submit")} </Button>
                <Button variant="contained" color="secondary" variant="contained" onClick={() => navigateToDashboard(props)} >{getLanguage("Cancel")}</Button>
            </div>

        </>
    )
}

export default CreateLogin_Page