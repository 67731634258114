import { getApiPath } from '../../../core/ServiceRoutes';
import Axios from 'axios';
import { LoginHelper } from '../../../core/LoginHelper';

export class LanguageApi {
  loginHelper = new LoginHelper();

  async getLanguages() {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.get(`${getApiPath()}/languages`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async addLanguage(language) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.post(
        `${getApiPath()}/language`,
        language,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async addLanguageString(languageId, languageString) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.post(
        `${getApiPath()}/language/${languageId}/languagestring`,
        languageString,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateLanguage(languageId, language) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.put(
        `${getApiPath()}/language/${languageId}`,
        language,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateLanguageString(languageId, identifier, language) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.put(
        `${getApiPath()}/language/${languageId}/languagestring/${identifier}`,
        language,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateLanguageStringForHive(hiveId, languageId, identifier, language) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.put(
        `${getApiPath()}/hive/${
          hiveId ?? this.loginHelper.getCurrentHive().id
        }/language/${languageId}/languagestring/${identifier}`,
        language,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }
}
