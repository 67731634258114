import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Badge from '@material-ui/core/Badge';
import { SettingsDropdown } from './SettingsDropdown';
import getLanguage from './Language';
import EditLogin from '../features/login/components/EditLogin';
import EditProfilePicture from '../features/login/components/EditProfilePicture';
import { GlobalDialog } from './GlobalDialog';
import { Container } from '@material-ui/core';
import { LoginHelper } from './LoginHelper';
import UserAvatar from '../features/activity/components/UserAvatar';

const SettingsButton = (props) => {
  const loginHelper = new LoginHelper();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editProfileOpen, setEditProfileOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge color="secondary">
          <SettingsIcon />
        </Badge>
      </IconButton>

      <SettingsDropdown
        isOpen={dropdownOpen}
        anchorEl={anchorEl}
        requestClose={() => setDropdownOpen(false)}
        requestEditProfile={() => setEditProfileOpen(true)}
      />

      <GlobalDialog
        isDialogOpen={editProfileOpen}
        handleDialogClose={() => {
          setEditProfileOpen(false);
        }}
        title={getLanguage('UpdateProfile')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {editProfileOpen && (
            <>
              <EditLogin requestClose={() => setEditProfileOpen(false)} />
              <br />
              <br />
              <h2>{getLanguage('ProfilePicture')}</h2>
              <UserAvatar login={loginHelper.getCurrentLogin()} size={'150'} />
              <br />
              <h3>{getLanguage('UpdateProfilePicture')}</h3>
              <EditProfilePicture
                loginId={loginHelper.getUserId()}
                profilePictureUpdated={() => setEditProfileOpen(false)}
              />
            </>
          )}
        </Container>
      </GlobalDialog>
    </>
  );
};

export { SettingsButton };
