import { LanguageApi } from './LanguageApi';

export class LanguageService {
  languageApi = new LanguageApi();

  async getLanguages() {
    return await this.languageApi.getLanguages();
  }

  async addLanguage(language) {
    return await this.languageApi.addLanguage(language);
  }

  async addLanguageString(languageId, languageString) {
    return await this.languageApi.addLanguageString(languageId, languageString);
  }

  async updateLanguage(languageId, language) {
    return await this.languageApi.updateLanguage(languageId, language);
  }

  async updateLanguageString(languageId, identifier, language) {
    return await this.languageApi.updateLanguageString(
      languageId,
      identifier,
      language
    );
  }

  async updateLanguageStringForHive(hiveId, languageId, identifier, language) {
    return await this.languageApi.updateLanguageStringForHive(
      hiveId,
      languageId,
      identifier,
      language
    );
  }
}
