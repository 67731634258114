import './ObjectEditor.css'
import { FormControl } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { ToastsStore } from 'react-toasts'
import { LoginHelper } from '../../../core/LoginHelper'
import { makeStyles } from '@material-ui/core/styles'
import { KeywordService } from '../../keyword/services/KeywordService'
import MultiSelector from '../../../core/components/MultiSelector'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const EditPageKeywords = (props) => {
  const classes = useStyles()

  const keywordService = new KeywordService()
  const loginHelper = new LoginHelper()

  const [keywords, setKeywords] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      if (
        loginHelper.userHasRole('PageEditor') ||
        loginHelper.userHasRole('StandEditor')
      ) {
        getAllKeywords()
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [])

  const getAllKeywords = async () => {
    let response = await keywordService.getKeywords()

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
    } else {
      if (props.isStand) {
        response = response.filter((t) => t.showInStands)
      } else {
        response = response.filter((t) => t.showInPages)
      }
      setKeywords(response)

      if (props.preselectedKeywords) {
        setSelectedKeywords(props.preselectedKeywords)
        return
      }

      // Set preselected keywords
      let preselected = []
      if (props.page) {
        for (const pageKeyword of props.page.pageKeywords) {
          preselected.push(pageKeyword.keyword)
        }
      }

      setSelectedKeywords(preselected)
    }
  }

  const onSelect = (selectedList, selectedItem) => {
    setSelectedKeywords(selectedList)

    if (props.keywordsUpdated) {
      props.keywordsUpdated(selectedList)
    }
  }

  const onRemove = (selectedList, removedItem) => {
    setSelectedKeywords(selectedList)

    if (props.keywordsUpdated) {
      props.keywordsUpdated(selectedList)
    }
  }

  return (
    <>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <MultiSelector
          displayValue="name"
          placeholder={getLanguage('SelectKeywords')}
          items={keywords}
          selectedValues={selectedKeywords}
          onSelect={onSelect}
          onRemove={onRemove}
        />
      </FormControl>
    </>
  )
}

export default EditPageKeywords
