import './EditTemplateDetails.css';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import { ResourceService } from '../../resource/services/ResourceService';
import { ToastsStore } from 'react-toasts';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditTemplateDetails = (props) => {
  const classes = useStyles();
  const resourceService = new ResourceService();

  const kinds = [
    'ContentLibrary',
    'ExhibitionHall',
    'ExhibitionStand',
    'Lobby',
    'Login',
    'Lounge',
    'Networking',
    'Reception',
    'Start',
    'Welcome',
    'Other',
  ];

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [kind, setKind] = useState('ExhibitionStand');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [resources, setResources] = useState([]);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [showLinkOrAssets, setShowLinkOrAssets] = useState('link');

  useEffect(() => {
    const getResources = async () => {
      let response = await resourceService.getResources();

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        // Filter away resources not being asset uploads
        response = response.filter((t) => t.uploadedFor === 'Assets');

        setResources(response);
      }
    };

    if (props.template) {
      setId(props.template.id);
      setTitle(props.template.title);
      setKind(props.template.kind);
      setBackgroundUrl(props.template.backgroundUrl);
    }

    getResources();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Attempt to find any selected resource
    if (
      props.template &&
      props.template.backgroundUrl &&
      props.template.backgroundUrl.toLowerCase().indexOf('/resource/') !== -1
    ) {
      let urlParts = props.template.backgroundUrl.split('/');
      let existing = resources.find(
        (t) => t.id === urlParts[urlParts.length - 1]
      );
      if (existing) {
        setShowLinkOrAssets('assets');
        setSelectedResourceId(existing.id);
      }
    }
    // eslint-disable-next-line
  }, [resources]);

  const handleResourceChanged = (event) => {
    setSelectedResourceId(event.target.value);
  };

  const onSaveClicked = async () => {
    var validationError = false;

    // Validate title not empty
    if (!title) {
      setTitleError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setTitleError('');
    }

    if (validationError) {
      return;
    }

    let background = backgroundUrl;

    // If any asset is chosen, update with its actual URL
    if (showLinkOrAssets === 'assets' && selectedResourceId) {
      background = resourceService.getResourceLink(selectedResourceId);
    }

    if (id) {
      props.onSave({
        title,
        kind,
        backgroundUrl: background,
      });
    } else {
      props.onSave({
        hiveId: props.hiveId,
        title,
        kind,
        backgroundUrl: background,
      });
    }
  };

  const getKindOptions = () => {
    return kinds.map((data) => (
      <MenuItem key={data} value={data}>
        {getLanguage(data)}
      </MenuItem>
    ));
  };

  const handleKindChanged = (event) => {
    setKind(event.target.value);
  };

  const getResourceOptions = () => {
    return resources.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.fileName} ({moment(data.created).format('DD.MM.YYYY HH:mm')})
      </MenuItem>
    ));
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={titleError}
            helperText={titleError}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
            label={getLanguage('Title')}
            className='textField'
          />
        </FormControl>

        <div className={classes.selectContainer}>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <InputLabel
              className={classes.selectLabel}
              id='templateSelector-label'
            >
              {getLanguage('Type')}
            </InputLabel>
            <Select
              variant='outlined'
              fullWidth={true}
              value={kind}
              onChange={handleKindChanged}
              labelId='kindSelector-label'
              id='kindSelector'
            >
              {getKindOptions()}
            </Select>
          </FormControl>
        </div>

        <div className='buttons'>
          <div>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <RadioGroup
                    value={showLinkOrAssets}
                    onClick={() => setShowLinkOrAssets('link')}
                  >
                    <FormControlLabel
                      value='link'
                      control={<Radio />}
                      label={getLanguage('ExternalLink')}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <RadioGroup
                    value={showLinkOrAssets}
                    onClick={() => setShowLinkOrAssets('assets')}
                  >
                    <FormControlLabel
                      value='assets'
                      control={<Radio />}
                      label={getLanguage('ChooseFromAssets')}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </div>
            <br />
            {showLinkOrAssets === 'link' && (
              <>
                <FormControl
                  fullWidth={true}
                  variant='outlined'
                  className={classes.formControl}
                >
                  <TextField
                    variant='outlined'
                    value={backgroundUrl}
                    onChange={(e) => setBackgroundUrl(e.target.value)}
                    label={getLanguage('BackgroundImageUrl')}
                    className='textField'
                  />
                </FormControl>
              </>
            )}
            {showLinkOrAssets === 'assets' && (
              <>
                <div className={classes.selectContainer}>
                  <FormControl
                    fullWidth={true}
                    variant='outlined'
                    className={classes.formControl}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      id='hiveSelector-label'
                    >
                      {getLanguage('ChooseFromAssets')}
                    </InputLabel>
                    <Select
                      variant='outlined'
                      fullWidth={true}
                      value={selectedResourceId}
                      onChange={handleResourceChanged}
                      labelId='hiveSelector-label'
                      id='hiveSelector'
                    >
                      <MenuItem value={''}>
                        <em>{getLanguage('PleaseChoose')}</em>
                      </MenuItem>
                      {getResourceOptions()}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}
          </div>
        </div>

        <Button
          type='submit'
          fullWidth={true}
          variant='contained'
          color='primary'
        >
          {getLanguage('Save')}
        </Button>
      </form>
    </>
  );
};

export default EditTemplateDetails;
