import { Button, Container } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import VisitorFieldPossibleValueEditor from './VisitorFieldPossibleValueEditor'
import { VisitorFieldService } from '../services/VisitorFieldService'
import { ToastsStore } from 'react-toasts'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import { GlobalDialog } from '../../../core/GlobalDialog'

const VisitorFieldPossibleValues = (props) => {
  const visitorFieldService = new VisitorFieldService()

  const [isLoading, setIsLoading] = useState(false)
  const [possibleValues, setPossibleValues] = useState([])
  const [possibleValueToRemove, setPossibleValueToRemove] = useState(null)

  useEffect(() => {
    getPossibleValues()
    // eslint-disable-next-line
  }, [])

  const getPossibleValues = async () => {
    setIsLoading(true)

    let response = await visitorFieldService.getVisitorFieldPossibleValue(
      props.visitorField.id,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    response = response.sort((a, b) => {
      return a.value < b.value ? -1 : 1
    })

    setPossibleValues(response)
    setIsLoading(false)
  }

  const addPossibleValue = async (obj) => {
    setIsLoading(true)

    let response = await visitorFieldService.addVisitorFieldPossibleValue(
      obj,
      props.visitorField.id,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    getPossibleValues()
  }

  const updatePossibleValue = async (id, obj) => {
    setIsLoading(true)
    let response = await visitorFieldService.updateVisitorFieldPossibleValue(
      props.visitorField.id,
      id,
      obj,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    getPossibleValues()
  }

  const deletePossibleValue = async () => {
    setIsLoading(true)
    let response = await visitorFieldService.deleteVisitorFieldPossibleValue(
      props.visitorField.id,
      possibleValueToRemove.id,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    getPossibleValues()
    setPossibleValueToRemove(null)
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      {possibleValues.map((visitorFieldPossibleValue) => {
        return (
          <VisitorFieldPossibleValueEditor
            visitorFieldPossibleValue={visitorFieldPossibleValue}
            onSave={(obj) =>
              updatePossibleValue(visitorFieldPossibleValue.id, obj)
            }
            onDelete={(obj) => setPossibleValueToRemove(obj)}
          />
        )
      })}

      <b>{getLanguage('AddNewValue')}</b>
      <VisitorFieldPossibleValueEditor
        onSave={(obj) => addPossibleValue(obj)}
        onDelete={(obj) => deletePossibleValue(obj)}
      />

      <GlobalDialog
        isDialogOpen={possibleValueToRemove !== null}
        handleDialogClose={() => setPossibleValueToRemove(null)}
        title={getLanguage('DeleteValue')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisValue?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => deletePossibleValue()}
          >
            {getLanguage('Delete')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  )
}

export default VisitorFieldPossibleValues
