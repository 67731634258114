import React, { useEffect, useState } from 'react';
import { ToastsStore } from 'react-toasts';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import {
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { Edit, Remove, Add } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { GlobalDialog } from '../../../core/GlobalDialog';
import getLanguage from './../../../core/Language';
import Loading from 'react-fullscreen-loading';
import orderBy from 'lodash/orderBy';
import { StorageHelper } from '../../../core/StorageHelper';
import { EventService } from '../services/EventService';
import EditEventDetails from '../components/EditEventDetails';
import {
  getEventTimezoneFormat,
  getStartDate,
} from '../../../core/helpers/TimeFormatHelper';
import { limitLength } from '../../../core/helpers/StringHelper';
import { PageService } from '../../page/services/PageService';
import { navigateToPagesEvent } from '../../../core/Navigation';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '500px',
  },
  editButton: {
    width: '25px;',
  },
  listWrap: {
    padding: '5px 10px 2px 10px',
    border: '1px solid #BBB',
    margin: '10px 0 5px 0',
    backgroundColor: '#EEE',
  },
  helpButon: {
    marginTop: '10px',
  },
  scollingPreview: {
    position: 'fixed',
    width: '60%',
  },
}));

const EditPageEvents_Page = (props) => {
  const pageService = new PageService();
  const eventService = new EventService();
  const classes = useStyles();
  const storageHelper = new StorageHelper();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [newEvent, setNewEvent] = useState(null);
  const { id } = useParams();
  const [defaultSortColumn, setDefaultSortColumn] = useState('title');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');
  const [thisPage, setThisPage] = useState(null);

  useEffect(() => {
    loadEvents(false, id);
    getPage(id);
    // eslint-disable-next-line
  }, []);

  const getPage = async (pageId) => {
    let response = await pageService.getPage(pageId);
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setThisPage(response);
    }
  };

  const loadEvents = async (forceReload, pageId) => {
    let response = await eventService.getPageEvents(pageId);
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      let sortCol = storageHelper.getHiveSortingColumn();
      let sortDirection = storageHelper.getHiveSortingDirection();

      if (sortCol) {
        setDefaultSortColumn(sortCol);
      }
      if (sortDirection) {
        setDefaultSortDirection(sortDirection);
      }

      setEvents(response);
      setIsLoading(false);
    }
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    storageHelper.setHiveSortingColumn(field);
    storageHelper.setHiveSortingDirection(direction);

    return orderBy(rows, handleField, direction);
  };

  const onEventSave = async (eventObj) => {
    setIsLoading(true);

    let response = await eventService.updateEvent(eventToEdit.id, eventObj);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setEventToEdit(null);
      loadEvents(true, id);
      ToastsStore.success(getLanguage('EventPropertiesWereUpdated'));
    }
  };

  const onNewEventSave = async (eventObj) => {
    setIsLoading(true);

    let response = await eventService.addEvent(eventObj);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setNewEvent(null);
      loadEvents(true, id);
    }
  };

  const onDeleteEvent = async (eventId) => {
    let response = await eventService.deleteEvent(eventId);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setEventToEdit(null);
      loadEvents(true, id);
      ToastsStore.success(getLanguage('DeletedEvent'));
    }
  };

  const createNewClicked = () => {
    setNewEvent({ pageId: id, eventKeywordRels: [], eventPageRels: [] });
  };

  const columns = [
    {
      name: getLanguage('Date'),
      selector: (row) => row.startDate,
      sortable: true,
      cell: (row) => (
        <label>
          <b>{getStartDate(row?.startDate) + ' '}</b>{' '}
          {getEventTimezoneFormat(row.startDate, row.endDate)}
        </label>
      ),
    },
    {
      name: getLanguage('Title'),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link color='inherit' onClick={() => setEventToEdit(row)}>
          <span className={classes.name}>{row.name}</span>
        </Link>
      ),
    },
    {
      name: getLanguage('Description'),
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => limitLength(row.description, 50, '...'),
    },
    {
      // () =>
      name: getLanguage('Options'),
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setEventToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Remove')}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                if (window.confirm(getLanguage('ConfirmDelete'))) {
                  onDeleteEvent(row.id);
                }
              }}
            >
              <Remove />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}

      {thisPage && (
        <>
          <div className={classes.root}>
            <Button
              variant='contained'
              color='default'
              onClick={(e) => {
                e.preventDefault();
                navigateToPagesEvent(props);
              }}
            >
              {getLanguage('BackToList')}
            </Button>
            <Link
              color='inherit'
              href='#'
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <h1>
                {thisPage.title} - {getLanguage('Program')}
              </h1>
            </Link>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div>
                  <Paper className={classes.paper}>
                    <DataTable
                      noHeader
                      columns={columns}
                      data={events}
                      pagination={true}
                      selectableRows={false}
                      sortFunction={customSort}
                      defaultSortField={defaultSortColumn}
                      defaultSortAsc={defaultSortDirection === 'asc'}
                      subHeader={true}
                      subHeaderComponent={
                        <div>
                          <Button
                            className={classes.createButton}
                            variant='contained'
                            color='primary'
                            onClick={() => createNewClicked()}
                          >
                            <Add /> {getLanguage('CreateNewSession')}
                          </Button>
                        </div>
                      }
                    />
                  </Paper>
                </div>
                {/* {objectTypeIds.map((objectTypeId) => {
                                return <Grid container spacing={1} key={objectTypeId}>
                                    <Grid item xs={11}><div className={classes.listWrap}>

                                        <Button fullWidth={true} variant="contained" color="primary" onClick={(e) => { e.preventDefault(); editClicked(objectTypeId) }}>{objectTypeId}</Button>

                                        {page.pageObjects.filter(t => t.templateObject.groupTitle === objectTypeId).map((pageObject) => {
                                            return <PageObjectSummary pageObject={pageObject} />
                                        })}
                                    </div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {tooltips.map((tooltip) => {
                                            if (tooltip.templateObject_GroupTitle === objectTypeId) {
                                                return <>
                                                    <div data-tip data-for={objectTypeId} className={classes.helpButon}><HelpIcon /></div>
                                                    <ReactTooltip id={objectTypeId} aria-haspopup='true'>
                                                        <p><b>{objectTypeId}</b></p>
                                                        <div dangerouslySetInnerHTML={{ __html: tooltip.text.replace("\n", "<br />") }} />
                                                    </ReactTooltip>
                                                </>
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                            })} */}
              </Grid>
              <Grid item xs={0}>
                {/* <Paper className={classes.paper}>
                                <div className={classes.scollingPreview}>
                                    <h1>Program preview here</h1>
                                </div>
                            </Paper> */}
                {/* <div className={classes.scollingPreview}>
                                <iframe name={iframeUniqueId} key={iframeUniqueId} className={classes.iframe} src={`${page.hive?.clientUrl}?tid=${iframeUniqueId}#${page.code}`} />
                                <a style={{ display: "table-cell" }} href={`${page.hive?.clientUrl}?tid=${iframeUniqueId}#${page.code}`} target="_blank">{getLanguage("OpenPreviewInNewTab")}</a>
                            </div> */}
              </Grid>
            </Grid>
          </div>
        </>
      )}

      <GlobalDialog
        isDialogOpen={eventToEdit !== null}
        handleDialogClose={() => setEventToEdit(null)}
        title={getLanguage('EditEventProperties')}
        type='Drawer'
        sizeclass='XL'
      >
        <Container>
          {eventToEdit && (
            <EditEventDetails
              event={eventToEdit}
              onSave={(eventObj) => onEventSave(eventObj)}
              requestSetIsLoading={(val) => setIsLoading(val)}
              requestReload={() => {
                setEventToEdit(null);
                loadEvents(true);
              }}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={newEvent !== null}
        handleDialogClose={() => setNewEvent(null)}
        title={getLanguage('CreateNew')}
        type='Drawer'
        sizeclass='XL'
      >
        <Container>
          {newEvent && (
            <EditEventDetails
              event={newEvent}
              onSave={(eventObj) => onNewEventSave(eventObj)}
              requestReload={() => loadEvents(true)}
            />
          )}
        </Container>
      </GlobalDialog>
    </>
  );
};

export default EditPageEvents_Page;
