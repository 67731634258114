import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from 'axios';
import { LoginHelper } from "../../../core/LoginHelper";

export class ResourceArchiveApi {
    loginHelper = new LoginHelper();

    async deleteResourceAndRelations(hiveId, resourceId) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }
            let response = await Axios.delete(`${getApiPath()}/hive/${hiveId}/resource/${resourceId}/relations`, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }

    async getResourceRelations(hiveId) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }
            let response = await Axios.get(`${getApiPath()}/hive/${hiveId}/resourceRelation`, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }
}