import './ObjectEditor.css';
import { Button, Container, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import getLanguage from '../../../core/Language';
import { ResourceService } from './../../resource/services/ResourceService';
import { GlobalDialog } from '../../../core/GlobalDialog';
import ImageCropUpload from '../../resource/components/ImageCropUpload';

const LogoEditor = (props) => {
  const resourceService = new ResourceService();
  const [removeLogoIsOpen, setRemoveLogoIsOpen] = useState(false);

  const onFileUploadStarted = () => {
    props.requestSetIsLoading(true);
  };

  const onFileUploaded = async (id) => {
    props.onSave({
      logoResourceId: id,
    });
    props.requestSetIsLoading(false);
  };

  const removeLogo = async () => {
    props.requestSetIsLoading(true);
    props.removeLogo();
    props.requestSetIsLoading(false);
  };

  return (
    <>
      <Grid container item xs={12} justify='center'>
        {props.page.logoResourceId && (
          <>
            <img
              height='250'
              width='100%'
              src={resourceService.getResourceLink(props.page.logoResourceId)}
              alt=''
            />
            <Button
              fullWidth={true}
              variant='contained'
              color='primary'
              style={{ marginTop: '10px' }}
              onClick={() => setRemoveLogoIsOpen(true)}
            >
              {getLanguage('RemoveLogo')}
            </Button>
            <br />
          </>
        )}
      </Grid>

      <div className='buttons'>
        <div>
          <ImageCropUpload
            uploadedFor='PageLogo'
            fileUploadStarted={() => onFileUploadStarted()}
            fileUploadedCallback={(id) => onFileUploaded(id)}
            cropAspectRatio={16 / 9}
          />
        </div>
      </div>

      <GlobalDialog
        isDialogOpen={removeLogoIsOpen}
        handleDialogClose={() => setRemoveLogoIsOpen(false)}
        title={getLanguage('RemoveLogo')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheUploadedLogo?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => {
              removeLogo();
              setRemoveLogoIsOpen(false);
            }}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default LogoEditor;
