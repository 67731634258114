import { Button, Grid, makeStyles, Tooltip } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, Delete, DragIndicator, Edit, List } from '@material-ui/icons';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import getLanguage from '../../../core/Language';

const useStyles = makeStyles(() => ({
    checkbox: {
        cursor: "pointer",
        color: "#121C42"
    },
    visitorField: {
        borderBottom: '1px solid gray',
        padding: '0.5rem 1rem',
        marginBottom: '2px',
        backgroundColor: 'white',
        marginTop: "8px"
    },
    button: {
        margin: "10px 0 10px 0",
        background: "#19275d",
        color: "#fff",
        '&:hover': {
            background: "#213377",
        },
    },
}));

export const VisitorField = ({ id, visitorField, index, moveCard, editVisitorFieldOptions, onSave, onEdit, onDelete, onDragEnded }) => {
    const classes = useStyles();
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: "VisitorField",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "VisitorField",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => {
            onDragEnded()
        }
    });

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    const dataTypeAllowsOptions = (dataType) => {
        return dataType === "CheckboxesMultiselect" ||
            dataType === "Dropdown" ||
            dataType === "DropdownMultiselect" ||
            dataType === "RadioButtons"
    }

    const toggleShowInRegistration = (newValue) => {
        onSave({
            ...visitorField,
            showInRegistration: newValue
        });
    }

    const toggleShowInProfile = (newValue) => {
        onSave({
            ...visitorField,
            showInProfile: newValue
        });
    }

    const toggleEditable = (newValue) => {
        onSave({
            ...visitorField,
            editable: newValue
        });
    }

    const toggleRequired = (newValue) => {
        onSave({
            ...visitorField,
            required: newValue
        });
    }

    const togglePoi = (newValue) => {
        onSave({
            ...visitorField,
            poi: newValue
        });
    }

    const toggleNetwork = (newValue) => {
        onSave({
            ...visitorField,
            network: newValue
        })
    }

    return (<div className={classes.visitorField} style={{ opacity }}>
        <Grid container spacing={4}>
            <Grid item xs={1}><span ref={ref} data-handler-id={handlerId} style={{ cursor: "move" }}>
                <DragIndicator />
            </span></Grid>
            <Grid item xs={1}>{visitorField.identifier}</Grid>
            <Grid item xs={2}>
                {getLanguage(visitorField.dataType)}
            </Grid>
            <Grid item xs={1}>{visitorField.showInRegistration ? <CheckBox className={classes.checkbox} onClick={() => toggleShowInRegistration(false)} /> : <CheckBoxOutlineBlank className={classes.checkbox} onClick={() => toggleShowInRegistration(true)} />}</Grid>
            <Grid item xs={1}>{visitorField.showInProfile ? <CheckBox className={classes.checkbox} onClick={() => toggleShowInProfile(false)} /> : <CheckBoxOutlineBlank className={classes.checkbox} onClick={() => toggleShowInProfile(true)} />}</Grid>
            <Grid item xs={1}>{visitorField.required ? <CheckBox className={classes.checkbox} onClick={() => toggleRequired(false)} /> : <CheckBoxOutlineBlank className={classes.checkbox} onClick={() => toggleRequired(true)} />}</Grid>
            <Grid item xs={1}>{visitorField.editable ? <CheckBox className={classes.checkbox} onClick={() => toggleEditable(false)} /> : <CheckBoxOutlineBlank className={classes.checkbox} onClick={() => toggleEditable(true)} />}</Grid>
            <Grid item xs={1}>{visitorField.poi ? <CheckBox className={classes.checkbox} onClick={() => togglePoi(false)} /> : <CheckBoxOutlineBlank className={classes.checkbox} onClick={() => togglePoi(true)} />}</Grid>
            <Grid item xs={1}>{visitorField.network ? <CheckBox className={classes.checkbox} onClick={() => toggleNetwork(false)} /> : <CheckBoxOutlineBlank className={classes.checkbox} onClick={() => toggleNetwork(true)} />}</Grid>
            <Grid item xs={2}>
                <div style={{ marginTop: "-5px" }}>
                    {dataTypeAllowsOptions(visitorField.dataType) && !visitorField.keywordGroupId &&
                        <>
                            <Button variant="contained" className={classes.button} onClick={editVisitorFieldOptions}><List /></Button>
                            {" "}
                        </>}
                    <Tooltip title={getLanguage("Edit")}><Button variant="contained" className={classes.button} onClick={onEdit}><Edit /></Button></Tooltip>
                    {" "}
                    <Tooltip title={getLanguage("Delete")}><Button variant="contained" disabled={visitorField.ownedBySystem} className={classes.button} onClick={onDelete}><Delete /></Button></Tooltip></div>

            </Grid>
        </Grid>


    </div>);
};
