import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import { HiveSelectDropdown } from './HiveSelectDropdown'
import { ExpandMore } from '@material-ui/icons'

const HiveSelectButton = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge color="secondary">
          <ExpandMore />
        </Badge>
      </IconButton>

      <HiveSelectDropdown
        isOpen={dropdownOpen}
        anchorEl={anchorEl}
        requestClose={() => setDropdownOpen(false)}
      />
    </>
  )
}

export { HiveSelectButton }
