export const pathNames = {
  PATH_CONTENT_LIBRARY: '/contentlibrary',
  PATH_MEETINGS: '/meetings',
  PATH_ADMINISTRATORS: '/administrators',
  PATH_VISITORS: '/visitors',
  PATH_STANDS: '/stands',
  PATH_EVENTS: '/events',
  PATH_NEWSITEMS: '/newsitems',
  PATH_POPUP_MESSAGES: '/popupmessages',
  PATH_KEYWORDS: '/keywords',
  PATH_ASSETS: '/assets',
  PATH_MAIL_TEMPLATES: '/mailtemplates',
  PATH_STATISTICS: '/statistics/hive',
  PATH_PAGES: '/pages',
};
