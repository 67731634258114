import { LoginHelper } from '../../../core/LoginHelper';
import { getApiPath } from '../../../core/ServiceRoutes';
import { ToastsStore } from 'react-toasts';
import getLanguage from '../../../core/Language';
import Axios from 'axios';

export class SubMenuApi {
  loginHelper = new LoginHelper();

  async getSubMenu(hiveId, defaultLanguage) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          language: defaultLanguage,
        },
      };
      let response = await Axios.get(`${getApiPath()}/menubaritems`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateSubMenu(hiveId, data) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.put(
        `${getApiPath()}/menubaritems`,
        data,
        config
      );
      ToastsStore.success(getLanguage('MenuHasBeenUpdated'));
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      ToastsStore.error(getLanguage('CouldNotUpdateMenuDueToError'));
      return { ...error };
    }
  }
}
