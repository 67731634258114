import React, { useEffect, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core/styles'
import { LoginHelper } from './LoginHelper'
import { Button, Grid, Typography } from '@material-ui/core'
import { Forward } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px 10px 5px 10px',
    minWidth: '250px',
  },
  createButton: {
    margin: '10px 0 10px 0',
  },
  userInfo: {
    margin: '5px 0 0 0',
  },
  hiveBox: {
    margin: '5px 0 0 0',
  },
}))

function HiveSelectDropdown(props) {
  const classes = useStyles()

  const loginHelper = new LoginHelper()

  const [hives, setHives] = useState([])

  useEffect(() => {
    let hives = loginHelper.getUserHives()
    hives = hives.sort(function (a, b) {
      return ('' + a.Title).localeCompare(b.Title)
    })
    setHives(hives)
    // eslint-disable-next-line
  }, [])

  const changeActiveHive = (hive) => {
    loginHelper.setCurrentHive({ id: hive.Id, title: hive.Title })
    window.location.href = '/'
  }

  return (
    <>
      {props.isOpen && (
        <Menu
          id="simple-menu"
          onClose={() => props.requestClose && props.requestClose()}
          anchorEl={props.anchorEl}
          open={props.isOpen}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <div>
            <div className={classes.root}>
              {hives.map((hive) => {
                return (
                  <Grid container justify="center" className={classes.hiveBox}>
                    <Grid item xs={9} className={classes.userInfo}>
                      <Typography
                        component="p"
                        color="inherit"
                        noWrap
                        className={classes.title}
                      >
                        {hive.Title}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        color="primary"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          changeActiveHive(hive)
                        }}
                      >
                        <Forward />
                      </Button>
                    </Grid>
                  </Grid>
                )
              })}
            </div>
          </div>
        </Menu>
      )}
    </>
  )
}

export { HiveSelectDropdown }
