import { getCachedObject, setObjectInCache } from "../../../core/ObjectCache";
import { MailTemplateApi } from "./MailTemplateApi";

export class MailTemplateService {
    mailTemplateApi = new MailTemplateApi();

    cacheIdentifier = "mailtemplates_default";
    cacheDurationMinutes = 60;

    async getDefaultMailTemplates() {
        let cached = getCachedObject(this.cacheIdentifier);
        if (cached) return cached;

        let result = await this.mailTemplateApi.getDefaultMailTemplates();

        if (!result.isAxiosError) {
            setObjectInCache(this.cacheIdentifier, result, this.cacheDurationMinutes);
        }

        return result;
    }

    async getMailTemplates(hiveId) {
        return await this.mailTemplateApi.getMailTemplates(hiveId);
    }

    async getMailTemplate(hiveId, identifier) {
        return await this.mailTemplateApi.getMailTemplate(hiveId, identifier);
    }

    async updateMailTemplate(hiveId, identifier, mailTemplate) {
        return await this.mailTemplateApi.updateMailTemplate(hiveId, identifier, mailTemplate);
    }
}