import './EditHiveDetails.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { makeStyles } from '@material-ui/core/styles';
import { HiveService } from '../services/HiveService';
import { HiveTemplatesService } from '../services/HiveTemplatesService';
import moment from 'moment';
import orderBy from 'lodash/orderBy';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditHiveDetails = (props) => {
  const classes = useStyles();

  const hiveService = new HiveService();
  const hiveTemplates = new HiveTemplatesService();

  const [hives, setHives] = useState([]);
  const [hiveTemlatesToCopyFrom, setHiveTemplatesToCopyFrom] = useState([]);
  const [selectedHiveId, setSelectedHiveId] = useState('');
  const [selectedHiveTemplate, setSelectedHiveTemplate] = useState('');
  const [copyPagesOnCreate, setCopyPagesOnCreate] = useState(false);
  const [copyStandsOnCreate, setCopyStandsOnCreate] = useState(false);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [clientUrl, setClientUrl] = useState('');

  const activeFrom = moment().format('YYYY-MM-DDT08:00Z');
  const activeTo = moment().add('months', 1).format('YYYY-MM-DDT08:00Z');

  useEffect(() => {
    const fetchData = async () => {
      let response = await hiveService.getHives();

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        response = orderBy(response, ['title'], ['asc']);

        // Attempt to preselect Template hive if exists
        let templateHive = response.find(
          (t) => t.id === '163374c3-0d38-4e8c-ba33-ec54a674866d'
        );
        if (templateHive) {
          setSelectedHiveId('163374c3-0d38-4e8c-ba33-ec54a674866d');
          setCopyPagesOnCreate(true);
        }

        setHives(response.filter((t) => t.displayInCopyHiveList));
      }

      let hiveTemplateResponse = await hiveTemplates.getHiveTemplates();
      if (hiveTemplateResponse.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        setHiveTemplatesToCopyFrom(hiveTemplateResponse);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  const onSaveClicked = async () => {
    var validationError = false;

    // Validate title not empty
    if (!title) {
      setTitleError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setTitleError('');
    }

    if (validationError) {
      return;
    }

    props.onSave({
      copySetupFromHiveId: selectedHiveId,
      title,
      activeFrom,
      activeTo,
      clientUrl,
      copyPages: copyPagesOnCreate,
      excludeStandsInCopy: !copyStandsOnCreate,
      allowRegistration: false,
      isOpen: false,
      selectedHiveTemplate,
    });
  };

  const getHiveOptions = () => {
    return hives.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.title}
      </MenuItem>
    ));
  };

  const getTemplateHiveOptions = () => {
    return hiveTemlatesToCopyFrom.map((data) => (
      <MenuItem key={data.name} value={data.name}>
        {data.name}
      </MenuItem>
    ));
  };

  const handleHiveChanged = (event) => {
    setSelectedHiveId(event.target.value);
    setSelectedHiveTemplate('');
  };

  const handleTemplateChanged = (event) => {
    setSelectedHiveTemplate(event.target.value);
    setSelectedHiveId('');
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={titleError}
            helperText={titleError}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
            label={getLanguage('Title')}
            className='textField'
          />
        </FormControl>

        <div className={classes.selectContainer}>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <InputLabel className={classes.selectLabel} id='hiveSelector-label'>
              {getLanguage('CopySetupFromTemplates')}
            </InputLabel>
            <Select
              variant='outlined'
              fullWidth={true}
              value={selectedHiveTemplate}
              onChange={handleTemplateChanged}
              labelId='hiveSelector-label'
              id='hiveSelector'
            >
              <MenuItem value={''}>
                <em>{getLanguage('PleaseChoose')}</em>
              </MenuItem>
              {getTemplateHiveOptions()}
            </Select>
          </FormControl>
        </div>

        <div className={classes.selectContainer}>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <InputLabel className={classes.selectLabel} id='hiveSelector-label'>
              {getLanguage('CopySetupFromHiveOptional')}
            </InputLabel>
            <Select
              variant='outlined'
              fullWidth={true}
              value={selectedHiveId}
              onChange={handleHiveChanged}
              labelId='hiveSelector-label'
              id='hiveSelector'
            >
              <MenuItem value={''}>
                <em>{getLanguage('PleaseChoose')}</em>
              </MenuItem>
              {getHiveOptions()}
            </Select>
          </FormControl>
        </div>
        {selectedHiveId && (
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={copyPagesOnCreate}
                  onChange={(e) => setCopyPagesOnCreate(e.target.checked)}
                />
              }
              label={getLanguage('CopyPages')}
              className='checkbox'
            />
          </FormControl>
        )}
        {selectedHiveId && copyPagesOnCreate && (
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={copyStandsOnCreate}
                  onChange={(e) => setCopyStandsOnCreate(e.target.checked)}
                />
              }
              label={getLanguage('CopyAllStands')}
              className='checkbox'
            />
          </FormControl>
        )}

        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={clientUrl}
            onChange={(e) => setClientUrl(e.target.value)}
            label={getLanguage('PublicClientUrl')}
            className='textField'
          />
        </FormControl>

        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <Button
            type='submit'
            fullWidth={true}
            variant='contained'
            color='primary'
          >
            {getLanguage('Save')}
          </Button>
        </FormControl>
      </form>

      <br />
    </>
  );
};

export default EditHiveDetails;
