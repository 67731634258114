import { getApiPath } from '../../../core/ServiceRoutes'
import Axios from 'axios'
import { LoginHelper } from '../../../core/LoginHelper'

export class LoginApi {
  loginHelper = new LoginHelper()

  async createrUser(credentials) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins`,
        credentials,
        config,
      )
      return response
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async createUserOrGetIdByEmail(email, fullName) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          returnifexists: true,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins`,
        {
          email,
          fullName,
        },
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateProfileWithProps(props, loginId) {
    console.log(">>", 'yyy 1')
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/logins/${loginId}`,
        props,
        config,
      )
      // let response = await Axios.put(`${getApiPath()}/login/foo`, props, config)
      console.log('yyy 2')
      return response.data
    } catch (error) {
      console.log('yyy 3')
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateProfilePicture(resourceId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/logins/profilepicture/${resourceId}`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async addPageToLogin(pageId, loginId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins/${loginId}/page/${pageId}`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async removePageFromLogin(pageId, loginId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          pageid: pageId,
        },
      }

      let response = await Axios.delete(
        `${getApiPath()}/logins/${loginId}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async resetPassword(email) {
    try {
      let response = await Axios.post(`${getApiPath()}/resetpassword/${email}`)
      return response.status === 200
    } catch (error) {
      return false
    }
  }

  async addHiveToLogin(hiveId, loginId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins/${loginId}/hive/${hiveId ?? this.loginHelper.getCurrentHive().id
        }`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async removeHiveFromLogin(hiveId, loginId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.delete(
        `${getApiPath()}/logins/${loginId}/hive/${hiveId ?? this.loginHelper.getCurrentHive().id
        }`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async addHiveToLoginFrontend(hiveId, loginId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          loginid: loginId,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/frontendlogins`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async removeHiveFromLoginFrontend(hiveId, loginId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        }
      }

      let response = await Axios.delete(
        `${getApiPath()}/logins/${loginId}/frontend`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async getAdministrators() {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.get(`${getApiPath()}/logins/`, config)
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateLoginAdminStatus(loginId, isAdmin) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/logins/${loginId}/admin/${isAdmin ? 'true' : 'false'}`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async consumeResetPasswordToken(token, newPassword) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          ...(this.loginHelper.getAuthToken() && {
            Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
          }),
          // Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins/resetpassword`,
        {
          token,
          newPassword,
        },
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async postWelcomeMailLoginHive(loginId, hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins/${loginId}/hive/${hiveId ?? this.loginHelper.getCurrentHive().id
        }/welcomemail`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async postWelcomeMailLoginHiveFrontend(loginId, hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins/${loginId}/frontend/welcomemail`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async postWelcomeMailLoginPage(loginId, pageId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/logins/${loginId}/page/${pageId}/welcomemail`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async postWelcomeMailToAllLoginHiveFrontend(hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/logins/sendwelcomemailstoallnewvisitors`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async postWelcomeMailToAllLoginPage(hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/hive/${hiveId ?? this.loginHelper.getCurrentHive().id
        }/SendWelcomeMailToAllPageEditors`,
        null,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateRoleAssignmentsForLogin(roles, loginId, hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/logins/${loginId}/roleassignments`,
        roles,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }
}
