import { PageApi } from "./PageApi";

export class PageService {
    pageApi = new PageApi();

    cacheIdentifier = "pages";
    cacheDurationMinutes = 10;

    async getPages(hiveId) {
        var result = await this.pageApi.getPages(hiveId);
        return result;
    }

    async getPagesPaged(hiveId, stands, page, resultsPerPage, sortColumn, sortDirection) {
        var result = await this.pageApi.getPagesPaged(hiveId, stands ? "stands" : "pages", page, resultsPerPage, sortColumn, sortDirection);
        return result;
    }

    async getPagesCount(hiveId, stands) {
        var result = await this.pageApi.getPagesCount(hiveId, stands ? "stands" : "pages");
        return result;
    }

    async getPage(id) {
        return await this.pageApi.getPage(id);
    }

    async addPage(page) {
        return await this.pageApi.addPage(page);
    }

    async updatePage(pageId, page) {
        return await this.pageApi.updatePage(pageId, page);
    }

    async deletePage(id) {
        return await this.pageApi.deletePage(id);
    }

    async removeLogoFromPage(pageId) {
        return await this.pageApi.removeLogoFromPage(pageId);
    }
}