import { SubMenuApi } from './SubMenuApi';

export class SubMenuService {
  subMenuApi = new SubMenuApi();

  async getSubMenu(hiveId, defaultLanguage) {
    var result = await this.subMenuApi.getSubMenu(hiveId, defaultLanguage);
    return result;
  }

  async updateSubMenu(hiveId, data) {
    var result = await this.subMenuApi.updateSubMenu(hiveId, data);
    return result;
  }
}
