import React, { useEffect, useState } from 'react'
import { ToastsStore } from 'react-toasts'
import getLanguage from '../../../core/Language'
import { Button, FormControl, Grid, TextField } from '@material-ui/core'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import { LoginDataService } from '../services/LoginDataService'
import VisitorField from './VisitorField'
import { makeStyles } from '@material-ui/core/styles'
import { VisitorFieldService } from '../../visitorFields/services/VisitorFieldService'
import { LoginHelper } from '../../../core/LoginHelper'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '500px',
  },
  editButton: {
    width: '25px;',
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
    padding: '2px 10px 2px 10px',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
}))

const EditVisitorLoginDatas = (props) => {
  const classes = useStyles()

  const loginDataService = new LoginDataService()
  const visitorFieldService = new VisitorFieldService()
  const loginHelper = new LoginHelper()

  const [isLoading, setIsLoading] = useState(true)
  const [visitorFields, setVisitorFields] = useState([])
  const [updatedLoginDatas, setUpdatedLoginDatas] = useState({})
  //   const [fieldValidations, setFieldValidations] = useState([])

  useEffect(() => {
    const prepareData = async () => {
      let response = await visitorFieldService.getVisitorFieldsForHive(
        loginHelper.getCurrentHive().id,
      )

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo',
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`,
        )
      } else {
        if (response && response.length > 0) {
          response = response.sort(function (a, b) {
            return ('' + a.identifier).localeCompare(b.identifier)
          })
        }

        setVisitorFields(response)
      }

      setIsLoading(false)
    }

    prepareData()
    // eslint-disable-next-line
  }, [])

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const saveData = async () => {
    // Check validation
    // for (const fieldValidation of fieldValidations) {
    //   if (fieldValidation.errorText) {
    //     return
    //   }
    // }
    let response = await loginDataService.updateLoginDatasForVisitor(
      null,
      props.login.id,
      updatedLoginDatas,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      props.onSave()
      setIsLoading(false)
    }
  }

  const valueUpdated = (visitorFieldId, newValue) => {
    let newList = updatedLoginDatas

    newList[visitorFieldId] = newValue

    setUpdatedLoginDatas(newList)
    forceUpdate()
  }

  const updateValidation = (visitorFieldId, errorText) => {}

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              saveData()
            }}
          >
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                disabled={true}
                value={props.login.fullName}
                label={getLanguage('FullName')}
                className="textField"
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                disabled={true}
                value={props.login.email}
                label={getLanguage('Email')}
                className="textField"
              />
            </FormControl>
            {visitorFields.map((visitorField) => {
              var existingValue = updatedLoginDatas[visitorField.id]

              if (existingValue == null) {
                existingValue = props.login.loginDatas.find(
                  (t) => t.visitorFieldId === visitorField.id,
                )?.value
              }

              return (
                <VisitorField
                  visitorField={visitorField}
                  value={existingValue}
                  error={''}
                  onValueUpdate={(newValue) =>
                    valueUpdated(visitorField.id, newValue)
                  }
                  onValidationUpdated={(errorText) =>
                    updateValidation(visitorField.id, errorText)
                  }
                />
              )
            })}

            <Grid container>
              <Grid item xs={5}>
                <Button
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                >
                  {getLanguage('SaveChanges')}
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <Button
                  onClick={() => props.requestClose()}
                  fullWidth={true}
                  variant="contained"
                  color="secondary"
                >
                  {getLanguage('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  )
}

export default EditVisitorLoginDatas
