import React from 'react';
import './App.css';
import AppScaffold from "./core/AppScaffold";
import { BrowserRouter as Router } from 'react-router-dom';
import WebSocketProvider from './socket/EchoSocket';
import { Provider } from 'react-redux'
import store from './store/Store';

function App() {
  return (
    <Provider store={store}>
      <WebSocketProvider>
        <Router>
          <AppScaffold />
        </Router>
      </WebSocketProvider>
    </Provider>
  );
}

export default App;
