
import React, { useContext, useEffect, useState } from 'react';
import { WebSocketContext } from '../../../socket/EchoSocket';
import { AuthorizationService } from '../services/AuthorizationService';

const LogoutPage = (props) => {
    const authService = new AuthorizationService();
    const echo = useContext(WebSocketContext);

    useEffect(() => {
        authService.logOut();
        window.location.href = '/';

    }, []);

    return <></>;
}

export default LogoutPage;