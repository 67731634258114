import React, { useEffect, useState } from 'react';
import { HiveService } from '../../hive/services/HiveService';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from './../../../core/Language';
import EditHiveDetailsBasic from '../../hive/components/EditHiveDetailsBasic';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { PageService } from '../../page/services/PageService';
import orderBy from 'lodash/orderBy';
import { HiveSettingService } from '../services/HiveSettingService';
import { LanguageService } from '../../language/services/LanguageService';
import { LoginHelper } from '../../../core/LoginHelper';
import EditHiveDetailsSetup from '../../hive/components/EditHiveDetailsSetup';
import EditHiveDetailsText from '../../hive/components/EditHiveDetailsText';
import EditHiveDetailsIntegrations from '../../hive/components/EditHiveDetailsIntegrations';

import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component='a'
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  header: {
    background: '#19275d',
    color: '#FFF',
  },
  button: {
    background: '#124238',
    color: '#FFF',
    '&:hover': {
      background: '#195d4f',
    },
  },
}));

const HiveSettings_Page = (props) => {
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();
  const pageService = new PageService();
  const hiveSettingService = new HiveSettingService();
  const languageService = new LanguageService();

  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [hive, setHive] = useState(null);
  const [pages, setpages] = useState([]);
  const [updatedHiveSettings, setUpdatedHiveSettings] = useState([]);
  const [hiveSettings, setHiveSettings] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('HiveSettings')));
    const prepareData = async () => {
      loadHive();
    };

    prepareData();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadHive = async () => {
    let response = await hiveService.getHive(
      loginHelper?.getCurrentHive()?.id,
      true
    );
    let languages = await languageService.getLanguages();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setHive(response);
      setLanguageOptions(languages);
      fetchData();
    }
  };

  const fetchData = async () => {
    let response = await pageService.getPages();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = orderBy(response, ['title'], ['asc']);

      setpages(response);
    }

    let hiveSettings = await hiveSettingService.getHiveSettings();
    if (hiveSettings.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${hiveSettings.response.status} - ${
          hiveSettings.response.statusText
        } + ")`
      );
    } else {
      setHiveSettings(hiveSettings);
    }

    setIsLoading(false);
  };

  const onHiveSave = async () => {
    setIsLoading(true);

    for (const updatedHiveSetting of updatedHiveSettings) {
      await hiveSettingService.upsertHiveSetting(
        hive.id,
        updatedHiveSetting.identifier,
        updatedHiveSetting.value
      );
    }

    let response = await hiveService.updateHive(hive.id, hive);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      loadHive();
      ToastsStore.success(getLanguage('HivePropertiesWereUpdated'));
    }
  };

  const updateHiveData = (field, value) => {
    let newHive = hive;
    hive[field] = value;

    setHive({ ...newHive });
  };

  const updateHiveSetting = (identifier, value) => {
    let newList = [
      ...updatedHiveSettings.filter((t) => t.identifier !== identifier),
    ];

    newList.push({ identifier, value });

    setUpdatedHiveSettings(newList);
  };

  if (isLoading) {
    return (
      <Loading
        loading
        background='linear-gradient(180deg, #fff, #edf5fe)'
        loaderColor='#124238'
      />
    );
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onHiveSave();
        }}
      >
        <div className={classes.paper}>
          <AppBar position='static'>
            <Tabs
              className={classes.header}
              variant='fullWidth'
              value={value}
              onChange={handleChange}
              aria-label='nav tabs example'
            >
              <LinkTab label={getLanguage('Basic')} {...a11yProps(0)} />
              <LinkTab label={getLanguage('Setup')} {...a11yProps(1)} />
              <LinkTab label={getLanguage('Text')} {...a11yProps(2)} />
              <LinkTab label={getLanguage('Integrations')} {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <EditHiveDetailsBasic
              hive={hive}
              hiveSettings={hiveSettings}
              updatedHiveSettings={updatedHiveSettings}
              onHiveDataUpdated={(field, value) => updateHiveData(field, value)}
              onHiveSettingUpdated={(identifier, value) =>
                updateHiveSetting(identifier, value)
              }
              pages={pages}
              requestSetIsLoading={(val) => setIsLoading(val)}
              languageOptions={languageOptions}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EditHiveDetailsSetup
              hive={hive}
              hiveSettings={hiveSettings}
              updatedHiveSettings={updatedHiveSettings}
              onHiveDataUpdated={(field, value) => updateHiveData(field, value)}
              onHiveSettingUpdated={(identifier, value) =>
                updateHiveSetting(identifier, value)
              }
              pages={pages}
              requestSetIsLoading={(val) => setIsLoading(val)}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EditHiveDetailsText
              hive={hive}
              hiveSettings={hiveSettings}
              updatedHiveSettings={updatedHiveSettings}
              onHiveDataUpdated={(field, value) => updateHiveData(field, value)}
              onHiveSettingUpdated={(identifier, value) =>
                updateHiveSetting(identifier, value)
              }
              pages={pages}
              requestSetIsLoading={(val) => setIsLoading(val)}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <EditHiveDetailsIntegrations
              hive={hive}
              hiveSettings={hiveSettings}
              updatedHiveSettings={updatedHiveSettings}
              onHiveDataUpdated={(field, value) => updateHiveData(field, value)}
              onHiveSettingUpdated={(identifier, value) =>
                updateHiveSetting(identifier, value)
              }
              pages={pages}
              requestSetIsLoading={(val) => setIsLoading(val)}
            />
          </TabPanel>
          <Button
            type='submit'
            fullWidth={true}
            variant='contained'
            className={classes.button}
          >
            {getLanguage('Save')}
          </Button>
        </div>
      </form>
      <br />
      <br />
    </>
  );
};

export default HiveSettings_Page;
