import React, { useCallback, useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import { Button, Container, Grid, Paper, AppBar } from '@material-ui/core';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import { LoginHelper } from '../../../core/LoginHelper';
import { Add } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';
import { VisitorFieldService } from '../services/VisitorFieldService';
import VisitorFieldEditor from '../components/VisitorFieldEditor';
import VisitorFieldsForActiveUsersList from '../components/VisitorFieldsForActiveUsersList';
import { HiveSettingService } from '../../settings/services/HiveSettingService';
import { KeywordGroupService } from '../../keywordgroup/services/KeywordGroupService';
import VisitorFieldPossibleValues from '../components/VisitorFieldPossibleValues';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { VisitorField } from '../components/VisitorField';
import update from 'immutability-helper';
import VisitorFieldPreview from '../components/VisitorFieldPreview';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  header: {
    background: '#19275d',
  },
  buttons: {
    float: 'right',
    clear: 'both',
    margin: '5px 5px 20px 0',
  },
}));

const VisitorFields_Page = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const visitorFieldService = new VisitorFieldService();
  const loginHelper = new LoginHelper();
  const hiveSettingService = new HiveSettingService();
  const keywordGroupService = new KeywordGroupService();

  const [isLoading, setIsLoading] = useState(true);
  const [visitorFields, setVisitorFields] = useState([]);
  const [visitorFieldToEdit, setVisitorFieldToEdit] = useState(null);
  const [visitorFieldToRemove, setVisitorFieldToRemove] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [activeUsersConfigOpen, setActiveUsersConfigOpen] = useState(false);
  const [selectedDisplayFields, setSelectedDisplayFields] = useState(null);
  const [selectedFilterFields, setSelectedFilterFields] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [hiveSettings, setHiveSettings] = useState(null);
  const [keywordGroups, setKeywordGroups] = useState([]);
  const [visitorFieldToEditValuesFor, setVisitorFieldToEditValuesFor] =
    useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('VisitorFields')));

    setIsLoading(true);

    getVisitorFields();
    // eslint-disable-next-line
  }, []);

  const getVisitorFields = async () => {
    let response = await visitorFieldService.getVisitorFieldsForHive(
      loginHelper.getCurrentHive().id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setVisitorFields(response);

      if (!hiveSettings) {
        getHiveSettings();
      } else {
        setIsLoading(false);
      }
    }
  };

  const getHiveSettings = async () => {
    let response = await hiveSettingService.getHiveSettings();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setHiveSettings(response);
      getKeywordGroups();
    }
  };

  const getKeywordGroups = async () => {
    let response = await keywordGroupService.getKeywordGroups();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setKeywordGroups(response);
      setIsLoading(false);
    }
  };

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };

  const removeVisitorField = async () => {
    setIsLoading(true);

    let response = await visitorFieldService.deleteVisitorField(
      visitorFieldToRemove.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setVisitorFieldToRemove(null);
    getVisitorFields();
  };

  const onSave = async (obj) => {
    if (createNewIsOpen || visitorFieldToEdit) {
      setIsLoading(true);
    }

    if (obj.id) {
      let response = await visitorFieldService.updateVisitorField(obj.id, obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
      setVisitorFieldToEdit(null);
    } else {
      obj.hiveId = loginHelper.getCurrentHive().id;
      let response = await visitorFieldService.addVisitorField(obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }

      if (createNewIsOpen) {
        setVisitorFieldToEdit(response);
      }
    }

    setCreateNewIsOpen(false);
    getVisitorFields();
  };

  const updateSelectedDisplayFields = (list) => {
    setSelectedDisplayFields(list);
  };

  const updateSelectedFilterFields = (list) => {
    setSelectedFilterFields(list);
  };

  const saveUpdates = async () => {
    // Form and save Metadata.Features.ActiveUsers.DisplayFields
    if (selectedDisplayFields !== null) {
      let settingString = '';

      for (const field of selectedDisplayFields) {
        if (!settingString) {
          settingString = field.id;
        } else {
          settingString += ';' + field.id;
        }
      }

      await hiveSettingService.upsertHiveSetting(
        null,
        'Metadata.Features.ActiveUsers.DisplayFields',
        settingString
      );
    }

    // Form and save Metadata.Features.ActiveUsers.FilterFields
    if (selectedFilterFields !== null) {
      let settingString = '';

      for (const field of selectedFilterFields) {
        if (!settingString) {
          settingString = field.id;
        } else {
          settingString += ';' + field.id;
        }
      }

      await hiveSettingService.upsertHiveSetting(
        null,
        'Metadata.Features.ActiveUsers.FilterFields',
        settingString
      );
    }

    // Save Metadata.Features.ActiveUsers.SortingFields
    if (sortField !== null) {
      await hiveSettingService.upsertHiveSetting(
        null,
        'Metadata.Features.ActiveUsers.SortingFields',
        sortField.id
      );
    }

    // Metadata.Features.ActiveUsers.SortingDirections
    if (sortDirection !== null) {
      await hiveSettingService.upsertHiveSetting(
        null,
        'Metadata.Features.ActiveUsers.SortingDirections',
        sortDirection
      );
    }

    getHiveSettings();

    ToastsStore.success(getLanguage('ChangesHaveBeenSaved'));
    setActiveUsersConfigOpen(false);
  };

  const updateSortingOfVisitorFields = () => {
    let currentIndex = 0;
    let fields = visitorFields;

    for (const field of fields) {
      field.sortingKey = currentIndex;
      currentIndex++;
    }

    visitorFieldService.updateVisitorFields(fields);
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = visitorFields[dragIndex];

      setVisitorFields(
        update(visitorFields, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [visitorFields]
  );

  const renderCard = (visitorField, index) => {
    return (
      <VisitorField
        key={visitorField.id}
        id={visitorField.id}
        index={index}
        visitorField={visitorField}
        moveCard={moveCard}
        editVisitorFieldOptions={() =>
          setVisitorFieldToEditValuesFor(visitorField)
        }
        onSave={(obj) => onSave(obj)}
        onEdit={() => setVisitorFieldToEdit(visitorField)}
        onDelete={() => setVisitorFieldToRemove(visitorField)}
        onDragEnded={() => updateSortingOfVisitorFields()}
      />
    );
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <div className={classes.buttons}>
        {/* <Button variant="contained" color="primary" variant="contained" onClick={() => setPreviewOpen(true)} ><Visibility /> {getLanguage("Preview")} </Button>
                {" "} */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setActiveUsersConfigOpen(true)}
        >
          <Add /> {getLanguage('ParticipantList')}{' '}
        </Button>{' '}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setCreateNewIsOpen(true)}
        >
          <Add /> {getLanguage('CreateNew')}{' '}
        </Button>
      </div>

      <Paper className={classes.paper}>
        <AppBar position="static">
          <div style={{ padding: '0.5rem 1rem', marginBottom: '2px' }}>
            <Grid container spacing={4} className={classes.header}>
              <Grid item xs={1}></Grid>
              <Grid item xs={1}>
                {getLanguage('Label')}
              </Grid>
              <Grid item xs={2}>
                {getLanguage('Type')}
              </Grid>
              <Grid item xs={1}>
                {getLanguage('Register')}
              </Grid>
              <Grid item xs={1}>
                {getLanguage('Profile')}
              </Grid>
              <Grid item xs={1}>
                {getLanguage('Mandatory')}
              </Grid>
              <Grid item xs={1}>
                {getLanguage('Editable')}
              </Grid>
              <Grid item xs={1}>
                {getLanguage('Poi')}
              </Grid>
              <Grid item xs={1}>
                {getLanguage('Network')}
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </div>
        </AppBar>

        {visitorFields && (
          <DndProvider backend={HTML5Backend}>
            <div style={{ width: '100%' }}>
              {visitorFields.map((card, i) => renderCard(card, i))}
            </div>
          </DndProvider>
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('CreateVisitorField')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {createNewIsOpen && (
            <VisitorFieldEditor
              onSave={(obj) => onSave(obj)}
              keywordGroups={keywordGroups}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={visitorFieldToEdit !== null}
        handleDialogClose={() => setVisitorFieldToEdit(null)}
        title={getLanguage('EditVisitorField')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {visitorFieldToEdit && (
            <VisitorFieldEditor
              onSave={(obj) => onSave(obj)}
              visitorField={visitorFieldToEdit}
              keywordGroups={keywordGroups}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={visitorFieldToRemove !== null}
        handleDialogClose={() => setVisitorFieldToRemove(null)}
        title={getLanguage('RemoveVisitorField')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheVisitorField?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => removeVisitorField()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={activeUsersConfigOpen}
        handleDialogClose={() => setActiveUsersConfigOpen(false)}
        title={getLanguage('ParticipantsListConfiguration')}
        type="Drawer"
        sizeclass="XL"
      >
        <Container>
          <p>
            {getLanguage(
              'SelectTheFieldsAndTheirSortingToShowAboutUsersInTheParticipantList'
            )}
          </p>
          {visitorFields && hiveSettings && (
            <VisitorFieldsForActiveUsersList
              visitorFields={visitorFields}
              hiveSettings={hiveSettings}
              selectedDisplayFieldsUpdated={updateSelectedDisplayFields}
              selectedFilterFieldsUpdated={updateSelectedFilterFields}
              updateSortField={(value) => setSortField(value)}
              updateSortDirection={(value) => setSortDirection(value)}
            />
          )}
          <br />
          <br />
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => saveUpdates()}
          >
            {getLanguage('Save')}
          </Button>
          <br />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={visitorFieldToEditValuesFor !== null}
        handleDialogClose={() => setVisitorFieldToEditValuesFor(null)}
        title={getLanguage('EditVisitorFieldValues')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {visitorFieldToEditValuesFor && (
            <VisitorFieldPossibleValues
              onSave={(obj) => onSave(obj)}
              visitorField={visitorFieldToEditValuesFor}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={previewOpen}
        handleDialogClose={() => setPreviewOpen(false)}
        type="Drawer"
        sizeclass="XL"
      >
        <Container>
          <VisitorFieldPreview visitorFields={visitorFields} />
        </Container>
      </GlobalDialog>
    </>
  );
};

export default VisitorFields_Page;
