import { HiveUrlApi } from "./HiveUrlApi";

export class HiveUrlService {
    hiveUrlApi = new HiveUrlApi();

    async getHiveUrls(hiveId) {
        return await this.hiveUrlApi.getHiveUrls(hiveId);
    }

    async addHiveUrl(hiveUrl) {
        return await this.hiveUrlApi.addHiveUrl(hiveUrl);
    }

    async updateHiveUrl(hiveUrlId, hiveUrl) {
        return await this.hiveUrlApi.updateHiveUrl(hiveUrlId, hiveUrl);
    }

    async deleteHiveUrl(hiveUrlId) {
        return await this.hiveUrlApi.deleteHiveUrl(hiveUrlId);
    }
}