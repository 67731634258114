
import React, { Component } from "react";
import { Route, withRouter } from 'react-router-dom';
import { LoginHelper } from "./LoginHelper";
import { Redirect } from 'react-router';

class PageRouteImpl extends Component {

    loginHelper = new LoginHelper();
    render() {
        const { component: Component, ...props } = this.props;
        let loginCheck = this.loginCheck();

        if (!loginCheck && !window.location.href.includes('/login')) {
            return <Redirect to='/login' />
        }
        if (!this.loginHelper.isAdmin() && window.location.href.includes('/login/new')) {
            return <Redirect to="/" />
        } else if (loginCheck && window.location.href.includes('/login') && !window.location.href.includes('/login/new') && !window.location.href.includes('/login/initialsetup')) {
            return <Redirect to="/" />
        }
        if (loginCheck && !this.loginHelper.getUserTimezone() && !window.location.href.includes('/login/initialsetup') && !window.location.href.includes('/logout')) {
            return <Redirect to="/login/initialsetup" />
        }

        if (!this.loginHelper.getCurrentHive() && this.loginHelper.isAdmin() && (!window.location.href.includes('/hive') && !window.location.href.includes('/administrators') && !window.location.href.includes('/login/initialsetup'))) {
            if (!window.location.href.includes('/logout'))
                return <Redirect to="/hives" />
        }


        return (
            <Route
                {...props}
                render={(props) => {
                    let route = null;
                    route = <Component {...props} />;
                    return route;
                }
                }
            />);
    }
    loginCheck() {
        let login = this.loginHelper.getLoginObject();
        if (login == null) {
            return false;
        }
        return true;
    }
}

const PageRoute = withRouter(PageRouteImpl);
export default PageRoute;
