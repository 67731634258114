import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from 'axios';
import { LoginHelper } from "../../../core/LoginHelper";

export class QueuedActionApi {
    loginHelper = new LoginHelper();

    async postQueuedAction(hiveId, queuedAction) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }

            let response = await Axios.post(`${getApiPath()}/hives/${hiveId ?? this.loginHelper.getCurrentHive().id}/queuedaction`, queuedAction, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }
}