import { Button, FormControl, Grid, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { Clear, Delete, Edit, Save } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '0 5px 0 5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
  styleItem: {
    margin: '5px 0 5px 0',
  },
}))

const EventDayEditor = (props) => {
  const classes = useStyles()

  const [eventDate, setEventDate] = useState(moment().format('YYYY-MM-DD'))
  const [title, setTitle] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    if (props.eventProgramDay) {
      setEventDate(moment(props.eventProgramDay.eventDate).format('YYYY-MM-DD'))
      setTitle(props.eventProgramDay.title)
    }
  }, [props.eventProgramDay])

  const onSave = () => {
    if (!eventDate || !title) {
      return
    }

    props.onSave({
      eventDate: moment(eventDate).format('YYYY-MM-DD'),
      title,
    })

    setIsEditing(false)
  }

  const onEdit = () => {
    setIsEditing(true)
  }

  const onDelete = () => {
    props.onDelete(props.eventProgramDay)
  }

  const cancelEdit = () => {
    setIsEditing(false)

    if (props.eventProgramDay) {
      setEventDate(props.eventProgramDay.eventDate)
      setTitle(props.eventProgramDay.title)
    }
  }

  return (
    <div className={classes.styleItem}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSave()
        }}
      >
        <Grid container>
          <Grid item xs={4}>
            {(!props.eventProgramDay || isEditing) && (
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <TextField
                  type="date"
                  variant="outlined"
                  value={eventDate}
                  onChange={(e) => setEventDate(e.target.value)}
                  label={getLanguage('EventDate')}
                  className="textField"
                />
              </FormControl>
            )}
            {props.eventProgramDay && !isEditing && (
              <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                  variant="outlined"
                  value={moment(eventDate).format('DD/MM/YYYY')}
                  disabled
                  className="textField"
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            {(!props.eventProgramDay || isEditing) && (
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <TextField
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  label={getLanguage('Title')}
                  className="textField"
                />
              </FormControl>
            )}
            {props.eventProgramDay && !isEditing && (
              <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                  variant="outlined"
                  value={title}
                  disabled
                  className="textField"
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            {(!props.eventProgramDay || isEditing) && (
              <>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.button}
                >
                  <Save />
                </Button>
                {props.eventProgramDay && (
                  <>
                    {' '}
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => cancelEdit()}
                    >
                      <Clear />
                    </Button>
                  </>
                )}
              </>
            )}
            {props.eventProgramDay && !isEditing && (
              <>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => onEdit()}
                >
                  <Edit />
                </Button>{' '}
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => onDelete()}
                >
                  <Delete />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default EventDayEditor
