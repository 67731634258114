import './NewsItems_Page.css';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { limitLength } from '../../../core/helpers/StringHelper';
import { NewsItemService } from '../services/NewsItemService';
import { HiveService } from '../../hive/services/HiveService';
import Loading from 'react-fullscreen-loading';
import { Button, Container, Paper, Tooltip } from '@material-ui/core';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import { StorageHelper } from '../../../core/StorageHelper';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import { LoginHelper } from '../../../core/LoginHelper';
import { Add, Delete, Edit } from '@material-ui/icons';
import moment from 'moment';
import EditNewsItemDetails from '../components/EditNewsItemDetails';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    animation: '$moveIn 600ms ease-out',
    height: '90vh',
    overflowX: 'scroll',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  inconsistencyInSortingAndPriority: {
    margin: '10px 0 10px 0',
    padding: '10px',
    border: '4px solid #FF4444',
    backgroundColor: '#EEE',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const NewsItems_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const newsItemService = new NewsItemService();
  const storageHelper = new StorageHelper();
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();

  let savedResultsPerPage = storageHelper.getResultsPerPageForNewsItems();

  const [defaultSortColumn, setDefaultSortColumn] = useState('created');
  const [defaultSortDirection, setDefaultSortDirection] = useState('desc');
  const [isLoading, setIsLoading] = useState(true);
  const [newsItems, setNewsItems] = useState([]);
  const [newsItemToEdit, setNewsItemToEdit] = useState(null);
  const [newsItemToRemove, setNewsItemToRemove] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [hive, setHive] = useState({});
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('NewsItems')));

    let sortCol = storageHelper.getNewsItemSortingColumn();
    let sortDirection = storageHelper.getNewsItemSortingDirection();

    if (sortCol) {
      setDefaultSortColumn(sortCol);
    }
    if (sortDirection) {
      setDefaultSortDirection(sortDirection);
    }

    getNewsItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadHive = async () => {
      let response = await hiveService.getHive(
        loginHelper?.getCurrentHive()?.id,
        true
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        setHive(response);
      }
    };
    loadHive();
  }, []);

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    storageHelper.setNewsItemSortingColumn(field);
    storageHelper.setNewsItemSortingDirection(direction);

    return orderBy(rows, handleField, direction);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForNewsItems(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage);
  };

  const columns = [
    {
      name: getLanguage('Created'),
      selector: (row) => row.created,
      sortable: true,
      width: '120px',
      cell: (row) => moment(row.created).format('DD/MM HH:mm'),
    },
    {
      name: getLanguage('Text'),
      selector: (row) => row.text,
      sortable: true,
      cell: (row) => limitLength(row.text, 40, '...'),
    },
    {
      name: getLanguage('ActiveFrom'),
      selector: (row) => row.startDate,
      width: '120px',
      sortable: true,
      cell: (row) =>
        hive.timezone &&
        moment.utc(row?.startDate).tz(hive.timezone).format('HH:mm'),
    },
    {
      name: getLanguage('ActiveTo'),
      selector: (row) => row.endDate,
      width: '120px',
      sortable: true,
      cell: (row) =>
        hive.timezone &&
        moment.utc(row.endDate).tz(hive.timezone).format('HH:mm'),
    },
    {
      name: getLanguage('Options'),
      width: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setNewsItemToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setNewsItemToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getNewsItems = async () => {
    setIsLoading(true);

    let response = await newsItemService.getNewsItems();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setNewsItems(response);
      setIsLoading(false);
    }
  };

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };

  const removeNewsItem = async () => {
    setIsLoading(true);

    let response = await newsItemService.deleteNewsItem(newsItemToRemove.id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setNewsItemToRemove(null);
    getNewsItems();
  };

  const onSave = async (obj) => {
    setIsLoading(true);

    if (newsItemToEdit) {
      let response = await newsItemService.updateNewsItem(
        newsItemToEdit.id,
        obj
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    } else {
      obj.hiveId = loginHelper.getCurrentHive().id;
      let response = await newsItemService.addNewsItem(obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    }

    setCreateNewIsOpen(false);
    setNewsItemToEdit(null);
    getNewsItems();
  };

  let inconsistencyInSortingAndPriority = false;

  for (let i = 0; i < newsItems.length; i++) {
    for (let j = i + 1; j < newsItems.length; j++) {
      let newsA = newsItems[i];
      let newsB = newsItems[j];

      if (
        (newsA.sortingKey !== 0 && newsB.sortingKey === 0) ||
        (newsA.sortingKey === 0 && newsB.sortingKey !== 0)
      ) {
        if (
          moment(newsA.startDate) < moment(newsB.endDate) &&
          moment(newsA.endDate) >= moment(newsB.startDate)
        ) {
          inconsistencyInSortingAndPriority = true;
        }
      }
    }
  }

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}

      {inconsistencyInSortingAndPriority && (
        <div className={classes.inconsistencyInSortingAndPriority}>
          <b>{getLanguage('TheresAProblemWithYourData')}</b>
          <br />
          {getLanguage('InconsistencyInSortingAndPriority')}
        </div>
      )}

      <Paper className={classes.paper}>
        {newsItems && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={newsItems}
            pagination={true}
            selectableRows={false}
            sortFunction={customSort}
            defaultSortField={defaultSortColumn}
            defaultSortAsc={defaultSortDirection === 'asc'}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationPerPage={currentResultsPerPage}
            subHeader={true}
            subHeaderComponent={
              <div>
                <Button
                  variant='contained'
                  className={classes.button}
                  onClick={() => setCreateNewIsOpen(true)}
                >
                  <Add /> {getLanguage('CreateNew')}{' '}
                </Button>
              </div>
            }
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('CreateNewsItem')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {createNewIsOpen && (
            <EditNewsItemDetails
              timeZone={hive.timezone}
              onSave={(obj) => onSave(obj)}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={newsItemToEdit !== null}
        handleDialogClose={() => setNewsItemToEdit(null)}
        title={getLanguage('EditNewsItem')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {newsItemToEdit && (
            <EditNewsItemDetails
              timeZone={hive.timezone}
              onSave={(obj) => onSave(obj)}
              newsItem={newsItemToEdit}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={newsItemToRemove !== null}
        handleDialogClose={() => setNewsItemToRemove(null)}
        title={getLanguage('RemoveNewsItem')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheNewsItem?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            className={classes.button}
            onClick={() => removeNewsItem()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default NewsItems_Page;
