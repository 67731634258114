import {
  Avatar,
  Button,
  Container,
  Link,
  Paper,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { navigateToEditPage_Page } from '../../../core/Navigation';
import { PageService } from '../services/PageService';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from '../../../core/Language';
import { GlobalDialog } from '../../../core/GlobalDialog';
import EditPageDetails from './../components/EditPageDetails';
import { makeStyles } from '@material-ui/core/styles';
import WebIcon from '@material-ui/icons/Web';
import AddIcon from '@material-ui/icons/Add';
import { LoginService } from '../../login/services/LoginService';
import { ResourceService } from '../../resource/services/ResourceService';
import { LoginHelper } from '../../../core/LoginHelper';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { StorageHelper } from '../../../core/StorageHelper';
import EditIcon from '@material-ui/icons/Edit';
import { Delete } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 6,
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  divroot: {
    width: '100%',
  },
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  table: {
    minWidth: 433,
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
  pageTitle: {
    fontSize: '16px',
  },
  pageSubTitle: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
}));

const Pages_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const pageService = new PageService();
  const loginService = new LoginService();
  const resourceService = new ResourceService();
  const loginHelper = new LoginHelper();
  const storageHelper = new StorageHelper();

  let savedResultsPerPage = storageHelper.getResultsPerPageForPages();

  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [pageToEdit, setPageToEdit] = useState(null);
  const [sortColumn, setSortColumn] = useState('sortingKey');
  const [sortDirection, setSortDirection] = useState('asc');
  const [pageToRemove, setPageToRemove] = useState(null);
  const [currentResultPage, setCurrentResultPage] = useState(1);
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );
  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    const loadTotalPages = async () => {
      if (!loginHelper.getCurrentHive()) {
        setIsLoading(false);
        return;
      }

      let response = await pageService.getPagesCount(
        loginHelper.getCurrentHive().id,
        false
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        setTotalResults(response);
      }
    };

    loadTotalPages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Pages')));
    loadPages();
    // eslint-disable-next-line
  }, [
    totalResults,
    currentResultPage,
    currentResultsPerPage,
    sortColumn,
    sortDirection,
  ]);

  const loadPages = async () => {
    if (!loginHelper.getCurrentHive()) {
      setIsLoading(false);
      return;
    }

    let response = await pageService.getPagesPaged(
      loginHelper.getCurrentHive().id,
      false,
      currentResultPage,
      currentResultsPerPage,
      sortColumn,
      sortDirection
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setPages(response);
      setIsLoading(false);
    }
  };

  const createNewClicked = () => {
    setCreateNewIsOpen(true);
  };

  const onNewPageSave = async (pageObj, inviteEmail) => {
    setCreateNewIsOpen(false);
    setIsLoading(true);

    let page = await pageService.addPage(pageObj);

    if (page.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${page.response.status} - ${page.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      // Create login or get ID of existing, if email have been provided
      if (inviteEmail) {
        let login = await loginService.createUserOrGetIdByEmail(
          inviteEmail,
          ''
        );
        if (login.isAxiosError || !login) {
          ToastsStore.error(
            `${getLanguage('SomethingWentWrong')} (${getLanguage(
              'DeveloperInfo'
            )}: ${login.response.status} - ${login.response.statusText} + ")`
          );
          setIsLoading(false);
          return;
        }

        await loginService.addPageToLogin(page.id, login.id);
      }

      setIsLoading(false);
      navigateToEditPage_Page(props, page.id);
    }
  };

  const requestedCloseCreate = async () => {
    setCreateNewIsOpen(false);
  };

  const onSort = (column, sortDirection) => {
    setSortColumn(column.selector);
    setSortDirection(sortDirection);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForPages(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage);
    setCurrentResultPage(currentPage);
  };

  const onChangePage = (page, totalRows) => {
    setCurrentResultPage(page);
  };

  const onPageSave = async (pageObj) => {
    setIsLoading(true);

    let response = await pageService.updatePage(pageToEdit.id, pageObj);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setPageToEdit(null);
      loadPages();
      ToastsStore.success(getLanguage('PagePropertiesWereUpdated'));
    }
  };

  const deletePage = async () => {
    setIsLoading(true);
    await pageService.deletePage(pageToRemove.id);
    setIsLoading(false);
    setPageToRemove(null);
    loadPages(true);
  };

  const removeLogo = async () => {
    setIsLoading(true);

    await pageService.removeLogoFromPage(pageToEdit.id);

    setPageToEdit(null);
    loadPages();
    ToastsStore.success(getLanguage('PagePropertiesWereUpdated'));
  };

  const columns = [
    {
      cell: (row) => (
        <Avatar style={{ background: '#19275d' }}>
          {row.logoResourceId && (
            <img
              height='40'
              src={resourceService.getResourceLink(row.logoResourceId)}
              alt=''
            />
          )}
          {!row.logoResourceId && <WebIcon style={{ background: '#19275d' }} />}
        </Avatar>
      ),
      width: '50px',
    },
    {
      name: getLanguage('Title'),
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => (
        <Link
          color='inherit'
          href='#'
          onClick={(e) => {
            e.preventDefault();
            navigateToEditPage_Page(props, row.id);
          }}
        >
          <span className={classes.pageTitle}>{row.title}</span>
        </Link>
      ),
    },
    {
      name: getLanguage('Updated'),
      selector: (row) => row.updated,
      sortable: true,
      cell: (row) => moment(row.updated).format('DD.MM.YYYY HH:mm'),
    },
    {
      name: getLanguage('Type'),
      selector: (row) => row.template.title,
      sortable: false,
    },
    {
      name: getLanguage('SortingKey'),
      selector: (row) => row.sortingKey,
      sortable: true,
    },
    {
      name: getLanguage('Options'),
      minWidth: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => navigateToEditPage_Page(props, row.id)}
            >
              <EditIcon />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setPageToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}
      <div>
        <Paper className={classes.paper}>
          {pages && (
            <DataTable
              noHeader={true}
              columns={columns}
              data={pages}
              pagination={true}
              paginationServer={true}
              selectableRows={false}
              sortServer={true}
              onSort={onSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onChangeRowsPerPage={onChangeRowsPerPage}
              paginationPerPage={currentResultsPerPage}
              onChangePage={onChangePage}
              defaultSortField={sortColumn}
              paginationTotalRows={totalResults}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
              subHeader={true}
              subHeaderComponent={
                loginHelper.userHasRole('PageEditor') && (
                  <div>
                    <Button
                      className={classes.button}
                      variant='contained'
                      onClick={() => createNewClicked()}
                    >
                      <AddIcon /> {getLanguage('CreateNew')}
                    </Button>
                  </div>
                )
              }
            />
          )}
        </Paper>
      </div>
      <br />

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestedCloseCreate()}
        title={getLanguage('CreateNew')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          <EditPageDetails
            isStand={false}
            onSave={(pageObj, inviteEmail) =>
              onNewPageSave(pageObj, inviteEmail)
            }
            requestSetIsLoading={(val) => setIsLoading(val)}
          />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={pageToEdit !== null}
        handleDialogClose={() => setPageToEdit(null)}
        title={getLanguage('EditPageProperties')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {pageToEdit && (
            <EditPageDetails
              page={pageToEdit}
              onSave={(pageObj) => onPageSave(pageObj)}
              requestSetIsLoading={(val) => setIsLoading(val)}
              removeLogo={() => removeLogo()}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={pageToRemove !== null}
        handleDialogClose={() => setPageToRemove(null)}
        title={getLanguage('DeletePage')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisPage?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => deletePage()}
          >
            {getLanguage('Delete')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default Pages_Page;
