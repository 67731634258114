import React, { useEffect, useState } from 'react'
import {
  getClientDefaultTimezone,
  getTimezoneList,
} from '../../../core/helpers/DateTimeHelper'
import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import getLanguage from '../../../core/Language'
import { LoginHelper } from '../../../core/LoginHelper'
import { LoginService } from '../services/LoginService'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import { ToastsStore } from 'react-toasts'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  selectContainer: {
    marginBottom: '10px',
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
}))

const InitialLoginSetup_Page = (props) => {
  const classes = useStyles()
  const loginHelper = new LoginHelper()
  const loginService = new LoginService()

  const [fullName, setFullName] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [timezones, setTimezones] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [fullNameError, setFullNameError] = useState('')

  useEffect(() => {
    setFullName(loginHelper.getUserFullName())

    setSelectedTimezone(getClientDefaultTimezone())
    setTimezones(getTimezoneList())

    setIsLoading(false)
  }, [])

  const getTimezoneOptions = () => {
    return timezones.map((data) => (
      <MenuItem key={data} value={data}>
        {data}
      </MenuItem>
    ))
  }

  const handleTimezoneChanged = (event) => {
    setSelectedTimezone(event.target.value)
  }

  const submitData = async () => {
    let validationError = false

    // Validate title not empty
    if (!fullName) {
      setFullNameError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else {
      setFullNameError('')
    }

    if (validationError) {
      return
    }

    setIsLoading(true)

    let login = await loginService.updateProfileWithProps({
      fullName,
      IanaTimezone: selectedTimezone,
    })
    if (login.isAxiosError || !login) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${login.response.status} - ${login.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    window.location.href = '/'

    setIsLoading(false)
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {getLanguage('WelcomeToVirtualHive')}
          </Typography>
          <br />
          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <>
              <p>
                {getLanguage('BeforeYouCanStartWeNeedToKnowAFewThingsAboutYou')}
              </p>
              <form
                className={classes.form}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault()
                  submitData()
                }}
              >
                <FormControl fullWidth={true} variant="outlined">
                  <TextField
                    variant="outlined"
                    error={fullNameError}
                    helperText={fullNameError}
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    label={getLanguage('FullName')}
                    className="textField"
                  />
                </FormControl>
                <div className={classes.selectContainer}>
                  <FormControl
                    fullWidth={true}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel
                      className={classes.selectLabel}
                      id="pageSelector-label"
                    >
                      {getLanguage('PleaseChooseYourTimezone')}
                    </InputLabel>
                    <Select
                      variant="outlined"
                      fullWidth={true}
                      value={selectedTimezone}
                      onChange={handleTimezoneChanged}
                      labelId="pageSelector-label"
                      id="pageSelector"
                    >
                      {getTimezoneOptions()}
                    </Select>
                  </FormControl>
                </div>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {getLanguage('UpdateSettings')}
                </Button>
              </form>
            </>
          )}
        </div>
      </Container>
    </>
  )
}

export default InitialLoginSetup_Page
