export function limitLength(input, maxLength, appendOnLimiting) {
    if (!input) return input;

    if (input.length > maxLength) {
        return input.substring(0, maxLength) + appendOnLimiting;
    }
    return input;
}

export function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}