import './EditHiveDetails.css';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Chip,
  Box,
} from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { getTimezoneList } from '../../../core/helpers/DateTimeHelper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditHiveDetailsBasic = (props) => {
  const classes = useStyles();
  const [languagesArray, setLanguagesArray] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [timezones, setTimezones] = useState([]);

  const onLanguageChangeHandler = (event, { props: { id } }) => {
    const language = event.target.value;
    if (!languagesArray.find((item) => item.language === language)) {
      setLanguagesArray([...languagesArray, { id, name: language }]);
    }
  };

  const selectDefaultLanguageHandler = (event, { props: { id } }) => {
    setDefaultLanguage(id);
    props.onHiveDataUpdated('defaultLanguageId', id);
  };

  const handleTimezoneChanged = (event) => {
    setSelectedTimezone(event.target.value);
    props.onHiveDataUpdated('timezone', event.target.value);
  };

  const getTimezoneOptions = () => {
    return timezones.map((data) => (
      <MenuItem key={data} value={data}>
        {data}
      </MenuItem>
    ));
  };

  useEffect(() => {
    if (languagesArray.length === 0) {
      props.onHiveDataUpdated('hiveLanguageIds', []);
      return;
    }

    props.onHiveDataUpdated(
      'hiveLanguageIds',
      [
        defaultLanguage,
        ...new Set(languagesArray.map((language) => language.id)),
      ].filter((item, index) => item !== defaultLanguage || index === 0)
    );
  }, [languagesArray]);

  useEffect(() => {
    if (props.hive?.hiveLanguages.length > 0) {
      setLanguagesArray(props.hive.hiveLanguages);
    }

    if (props.hive?.defaultLanguage) {
      setDefaultLanguage(props.hive.defaultLanguage.id);
    }

    if (props.hive.timezone?.length > 0) {
      setSelectedTimezone(props.hive.timezone);
    }
    setTimezones(getTimezoneList());
  }, []);

  const removeLanguageHandler = (e, languageToDelete) => {
    e.preventDefault();
    setLanguagesArray(
      languagesArray.filter((language) => language.id !== languageToDelete.id)
    );
  };
  const getPageOptions = () => {
    return props.pages
      .filter((t) => t.template.kind !== 'ExhibitionStand')
      .map((data) => (
        <MenuItem key={data.id} value={data.id}>
          {data.title}
        </MenuItem>
      ));
  };

  const languageOptions = props.languageOptions.map(({ id, name }) => (
    <MenuItem value={name} id={id} key={id}>
      {name}
    </MenuItem>
  ));

  const handleStartPageChanged = (event) => {
    props.onHiveDataUpdated('startPageId', event.target.value);
  };

  const handleLoginPageChanged = (event) => {
    props.onHiveDataUpdated('loginPageId', event.target.value);
  };

  return (
    <>
      <FormControl
        fullWidth={true}
        variant='outlined'
        className={classes.formControl}
      >
        <TextField
          variant='outlined'
          value={props.hive.title}
          onChange={(e) => props.onHiveDataUpdated('title', e.target.value)}
          label={getLanguage('Title')}
          className='textField'
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        variant='outlined'
        className={classes.formControl}
      >
        <TextField
          variant='outlined'
          value={props.hive.clientUrl}
          onChange={(e) => props.onHiveDataUpdated('clientUrl', e.target.value)}
          label={getLanguage('PublicClientUrl')}
          className='textField'
        />
      </FormControl>
      <div className={classes.selectContainer}>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel
            className={classes.selectLabel}
            id='startupPageSelector-label'
          >
            {getLanguage('StartupPage')}
          </InputLabel>
          <Select
            variant='outlined'
            fullWidth={true}
            value={props.hive.startPageId}
            onChange={handleStartPageChanged}
            labelId='startupPageSelector-label'
            id='startupPageSelector'
          >
            <MenuItem value={''}>
              <em>{getLanguage('PleaseChoose')}</em>
            </MenuItem>
            {getPageOptions()}
          </Select>
        </FormControl>
      </div>

      <FormControl
        fullWidth={true}
        variant='outlined'
        className={classes.formControl}
      >
        <TextField
          variant='outlined'
          value={props.hive.maxStands}
          onChange={(e) => props.onHiveDataUpdated('maxStands', e.target.value)}
          label={getLanguage('MaxStands(0IsUnlimited)')}
          className='textField'
        />
      </FormControl>
      <div className={classes.selectContainer}>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel
            className={classes.selectLabel}
            id='startupPageSelector-label'
          >
            {getLanguage('ChooseDefaultLanguageForHive')}
          </InputLabel>
          <Select
            variant='outlined'
            defaultValue={props.hive.defaultLanguage?.name}
            fullWidth={true}
            onChange={selectDefaultLanguageHandler}
          >
            {languageOptions}
          </Select>
        </FormControl>
      </div>
      <div className={classes.selectContainer}>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel
            id='demo-multiple-chip-label'
            className={classes.selectLabel}
          >
            {getLanguage('ChooseFromAvailableLanguages')}
          </InputLabel>
          <Select
            style={{
              display: 'flex',
            }}
            fullWidth={true}
            labelId='demo-multiple-chip-label'
            id='demo-multiple-chip'
            defaultValue
            onChange={onLanguageChangeHandler}
            renderValue={() =>
              languagesArray.map((selectedLanguage) => (
                <Box
                  sx={{
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  <Chip
                    style={{
                      backgroundColor: '#121C42',
                      color: '#fff',
                    }}
                    key={selectedLanguage.name}
                    label={selectedLanguage.name}
                    deleteIcon={
                      <CancelIcon
                        style={{ color: '#fff' }}
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    }
                    onDelete={(e) => removeLanguageHandler(e, selectedLanguage)}
                  />
                </Box>
              ))
            }
          >
            {languageOptions}
          </Select>
        </FormControl>
      </div>
      <div className={classes.selectContainer}>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          {' '}
          <InputLabel
            className={classes.selectLabel}
            id='startupPageSelector-label'
          >
            {getLanguage('PleaseChooseYourTimezone')}
          </InputLabel>
          <Select
            variant='outlined'
            value={selectedTimezone}
            fullWidth={true}
            onChange={handleTimezoneChanged}
          >
            {getTimezoneOptions()}
          </Select>
        </FormControl>
      </div>
      <FormControl
        fullWidth={true}
        variant='outlined'
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={props.hive.requiresLogin}
              style={{
                color: '#121C42',
              }}
              onChange={(e) =>
                props.onHiveDataUpdated('requiresLogin', e.target.checked)
              }
            />
          }
          label={getLanguage('AccessToTheHiveRequiresLogin')}
        />
      </FormControl>
      {props.hive.requiresLogin && (
        <div className={classes.selectContainer}>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <InputLabel
              className={classes.selectLabel}
              id='loginPageSelector-label'
            >
              {getLanguage('LoginPage')}
            </InputLabel>
            <Select
              variant='outlined'
              fullWidth={true}
              value={props.hive.loginPageId}
              onChange={handleLoginPageChanged}
              labelId='loginPageSelector-label'
              id='loginPageSelector'
            >
              <MenuItem value={''}>
                <em>{getLanguage('PleaseChoose')}</em>
              </MenuItem>
              {getPageOptions()}
            </Select>
          </FormControl>
        </div>
      )}
      <FormControl
        fullWidth={true}
        variant='outlined'
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: '#121C42',
              }}
              checked={props.hive.allowRegistration}
              onChange={(e) =>
                props.onHiveDataUpdated('allowRegistration', e.target.checked)
              }
            />
          }
          label={getLanguage('AllowPublicRegistration')}
          className='checkbox'
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        variant='outlined'
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: '#121C42',
              }}
              checked={props.hive.isOpen}
              onChange={(e) =>
                props.onHiveDataUpdated('isOpen', e.target.checked)
              }
            />
          }
          label={getLanguage('IsOpen(DisableToPreventLoggingIn)')}
          className='checkbox'
        />
      </FormControl>

      <FormControl
        fullWidth={true}
        variant='outlined'
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: '#121C42',
              }}
              checked={props.hive.displayInCopyHiveList}
              onChange={(e) =>
                props.onHiveDataUpdated(
                  'displayInCopyHiveList',
                  e.target.checked
                )
              }
            />
          }
          label={getLanguage('DisplayInListOfHivesToCopyFrom')}
          className='checkbox'
        />
      </FormControl>
    </>
  );
};

export default EditHiveDetailsBasic;
