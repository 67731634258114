import './VisitorFieldsForActiveUsersList.css'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { ReactSortable } from 'react-sortablejs'
import { makeStyles } from '@material-ui/core/styles'
import { DragIndicator, RemoveCircleOutline } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  helpButton: {
    marginTop: '15px',
    marginleft: '15px',
  },
  headerItem: {},
  fieldOptions: {
    marginLeft: '5px',
  },
}))

const VisitorFieldsForActiveUsersList = (props) => {
  const classes = useStyles()

  const [selectedDisplayFields, setSelectedDisplayFields] = useState([])
  const [selectedAddField, setSelectedAddField] = useState(null)
  const [selectedFilterFields, setSelectedFilterFields] = useState([])
  const [selectedSortField, setSelectedSortField] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')

  useEffect(() => {
    if (props.hiveSettings) {
      updateSelectedDisplayFields(
        props.hiveSettings.find(
          (t) => t.identifier === 'Metadata.Features.ActiveUsers.DisplayFields',
        )?.value,
      )
      updateSeletedFilterFields(
        props.hiveSettings.find(
          (t) => t.identifier === 'Metadata.Features.ActiveUsers.FilterFields',
        )?.value,
      )
      updatedSelectedSortingField(
        props.hiveSettings.find(
          (t) => t.identifier === 'Metadata.Features.ActiveUsers.SortingFields',
        )?.value,
      )
      updatedSortDirection(
        props.hiveSettings.find(
          (t) =>
            t.identifier === 'Metadata.Features.ActiveUsers.SortingDirections',
        )?.value,
      )
    }
    // eslint-disable-next-line
  }, [])

  const updateSelectedDisplayFields = (settingString) => {
    if (!settingString) {
      return
    }

    let selected = []

    for (const displayField of settingString.split(';')) {
      selected.push({ id: displayField, name: displayField })
    }

    updateListOfSelectedDisplayFields(selected)
  }

  const updateSeletedFilterFields = (settingString) => {
    if (!settingString) {
      return
    }

    let selected = []

    for (const displayField of settingString.split(';')) {
      selected.push({ id: displayField, name: displayField })
    }

    updateListOfSelectedFilterFields(selected)
  }

  const updatedSelectedSortingField = (settingString) => {
    if (!settingString) {
      return
    }

    changeSelectedForSorting({ id: settingString, name: settingString })
  }

  const updatedSortDirection = (settingString) => {
    if (!settingString) {
      return
    }

    changeSortDirection(settingString)
  }

  const getAllFieldOptions = () => {
    let options = []

    if (!selectedDisplayFields.find((t) => t.id === 'fullName')) {
      options.push(
        <MenuItem value={'fullName'}>{getLanguage('FullName')}</MenuItem>,
      )
    }

    for (const visitorField of props.visitorFields) {
      if (
        !selectedDisplayFields.find((t) => t.id === visitorField.identifier)
      ) {
        options.push(
          <MenuItem
            key={visitorField.identifier}
            value={visitorField.identifier}
          >
            {visitorField.identifier}
          </MenuItem>,
        )
      }
    }

    return options
  }

  const handleAddFieldChanged = (event) => {
    setSelectedAddField(event.target.value)
  }

  const removeFieldFromSelected = (field) => {
    let newList = selectedDisplayFields

    newList = newList.filter((t) => t.id !== field.id)

    // Update filter & sort, if field was used in either of them
    changeSelectedForFilter(false, field)

    if (selectedSortField && selectedSortField.id === field.id) {
      setSelectedSortField(null)
      props.updateSortField(null)
    }

    updateListOfSelectedDisplayFields(newList)
  }

  const addFieldToSelectedClicked = () => {
    let newList = selectedDisplayFields

    newList.push({
      id: selectedAddField,
      name: selectedAddField,
    })

    updateListOfSelectedDisplayFields(newList)
    setSelectedAddField(null)
  }

  const updateListOfSelectedDisplayFields = (newList) => {
    setSelectedDisplayFields(newList)
    props.selectedDisplayFieldsUpdated(newList)
  }

  const changeSelectedForFilter = (checked, item) => {
    let newList = [...selectedFilterFields].filter((t) => t.id !== item.id)

    if (checked) {
      newList.push(item)
    }

    updateListOfSelectedFilterFields(newList)
  }

  const updateListOfSelectedFilterFields = (newList) => {
    setSelectedFilterFields(newList)
    props.selectedFilterFieldsUpdated(newList)
  }

  const changeSelectedForSorting = (item) => {
    setSelectedSortField(item)
    props.updateSortField(item)
  }

  const handleSortDirectionChanged = (event) => {
    changeSortDirection(event.target.value)
  }

  const changeSortDirection = (value) => {
    setSortDirection(value)
    props.updateSortDirection(value)
  }

  return (
    <>
      <Grid container>
        <Grid xs={9}></Grid>
        <Grid xs={1}></Grid>
        <Grid xs={1} className={classes.headerItem}>
          {getLanguage('Filter')}
        </Grid>
        <Grid xs={1} className={classes.headerItem}>
          {getLanguage('Sort')}
        </Grid>
      </Grid>

      <ReactSortable
        handle={'.dragButton'}
        animation={100}
        list={selectedDisplayFields}
        setList={updateListOfSelectedDisplayFields}
      >
        {selectedDisplayFields.map((item) => (
          <Grid container key={item.id}>
            <Grid xs={9}>
              <div className={'sortField'} key={item.id}>
                <div className={'leftButtons'}>
                  <div className={'dragButton'}>
                    <DragIndicator />
                  </div>
                </div>

                <div className={'title'}>
                  {item.id === 'fullName' ? getLanguage('FullName') : item.name}
                </div>
                <div className={'buttons'}>
                  <div
                    className={'button'}
                    onClick={() => removeFieldFromSelected(item)}
                  >
                    <RemoveCircleOutline />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={1}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={`${classes.formControl} ${classes.fieldOptions}`}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{
                        color: '#121C42',
                      }}
                    />
                  }
                  className="checkbox"
                  onClick={(e) =>
                    changeSelectedForFilter(e.target.checked, item)
                  }
                  checked={
                    selectedFilterFields.find((t) => t.id === item.id) != null
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={1}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={`${classes.formControl} ${classes.fieldOptions}`}
              >
                <FormControlLabel
                  control={<Radio color="primary" />}
                  className="checkbox"
                  onClick={() => changeSelectedForSorting(item)}
                  checked={selectedSortField?.id === item.id}
                />
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </ReactSortable>
      <br />
      <Grid container>
        <Grid xs={9}>
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel
              className={classes.selectLabel}
              id="allFieldsSelector-label"
            >
              {getLanguage('AddField')}
            </InputLabel>
            <Select
              variant="outlined"
              fullWidth={true}
              value={selectedAddField}
              onChange={handleAddFieldChanged}
              labelId="allFieldsSelector-label"
              id="allFieldsSelector"
            >
              {getAllFieldOptions()}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={3} className={'addFieldButton'}>
          <div>
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
              disabled={!selectedAddField}
              onClick={() => addFieldToSelectedClicked()}
            >
              {getLanguage('AddField')}
            </Button>
          </div>
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel
          className={classes.selectLabel}
          id="sortdirectionSelector-label"
        >
          {getLanguage('SortDirection')}
        </InputLabel>
        <Select
          variant="outlined"
          fullWidth={true}
          value={sortDirection}
          onChange={handleSortDirectionChanged}
          labelId="sortdirectionSelector-label"
          id="sortdirectionSelector"
        >
          <MenuItem value={'asc'}>
            <em>{getLanguage('Ascending')}</em>
          </MenuItem>
          <MenuItem value={'desc'}>
            <em>{getLanguage('Descending')}</em>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

export default VisitorFieldsForActiveUsersList
