import React, { useContext, useEffect, useState } from "react";
import getLanguage from "../../../core/Language";
import { ToastsStore } from "react-toasts";
import Loading from "react-fullscreen-loading";
import { Button, Container, Grid, Paper } from "@material-ui/core";
import { GlobalDialog } from "../../../core/GlobalDialog";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import { LoginHelper } from "../../../core/LoginHelper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Remove } from "@material-ui/icons";
import { HiveSettingService } from "../services/HiveSettingService";
import EditIcon from "@material-ui/icons/Edit";
import EditClientSetting from "../components/EditClientSetting";
import { HiveService } from "../../hive/services/HiveService";
import { useDispatch } from "react-redux";
import { updatePageTitle } from "../../../actions/Actions";
import EditClientSubMenu from "../components/EditClientSubMenu";
import Api from "../services/Api";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: "40px",
    height: "90vh",
    overflowX: "scroll",
  },
  formControl: {},
  indented: {
    marginLeft: "20px",
  },
  header: {
    margin: "5px 0 5px 0",
    fontWeight: "bold",
  },
  editButton: {
    marginLeft: "5px",
  },
  defaultValue: {
    color: "#CCC",
  },
}));

const ClientSettings_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [hiveSettings, setHiveSettings] = useState([]);
  const [hiveSettingToEdit, setHiveSettingToEdit] = useState(null);
  const [translations, setTranslations] = useState({});
  const [value, setValue] = React.useState(0);

  const hiveSettingService = new HiveSettingService();
  const hiveService = new HiveService();
  const loginHelper = new LoginHelper();

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage("ClientSettings")));

    getHiveSettings();
  }, []);

  const getHiveSettings = async () => {
    setIsLoading(true);

    const translations = await Api.getJson().then((res) => res.translations);
    setTranslations(translations);

    let response = await hiveSettingService.getHiveSettings();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setHiveSettings(response);
      setIsLoading(false);
    }
  };

  const onSave = async (newValue) => {
    setIsLoading(true);

    let response = await hiveSettingService.upsertHiveSetting(
      null,
      hiveSettingToEdit.identifier,
      newValue
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    await hiveService.clearJsonCache();

    setHiveSettingToEdit(null);
    getHiveSettings();
    ToastsStore.success(getLanguage("SettingWasUpdatedSuccessfully"));
  };

  const getSettingByIdentifier = (identifier, defaultValue) => {
    let existingSetting = hiveSettings.find((t) => t.identifier === identifier);
    if (existingSetting) {
      return existingSetting.value;
    }
    if (defaultValue !== null) {
      return <i className={classes.defaultValue}>{defaultValue}</i>;
    }
    return;
  };

  const editSettingClicked = (identifier, type, options = null) => {
    let existingSetting = hiveSettings.find((t) => t.identifier === identifier);
    if (existingSetting) {
      existingSetting.type = type;
      if (options) {
        existingSetting.options = options;
      }
      setHiveSettingToEdit(existingSetting);
    } else {
      setHiveSettingToEdit({ identifier, value: "", type, options });
    }
  };

  const capitalize = (word) => {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const formatKey = (key) => {
    key = key.toLowerCase();
    let arr = key.split("_");
    arr = arr.map((value) => capitalize(value));
    key = arr.join("_");
    return key;
  };

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <AppBar position="static">
        <Tabs
          className={classes.header}
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab label={getLanguage("Basic")} />
          <LinkTab label={getLanguage("HiveMenu")} />
        </Tabs>
      </AppBar>
      <Paper className={classes.paper}>
        <TabPanel value={value} index={0}>
          {hiveSettings && (
            <>
              <div>
                <b>Id:</b> {loginHelper.getCurrentHive().id}
              </div>
              <div className={classes.header}>Metadata</div>
              <div className={classes.indented}>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Title
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.Title", "string")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier(
                      "Metadata.Title",
                      loginHelper.getCurrentHive().title
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Background
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.Background", "string")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.Background", "#000000")}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    NativeWidth
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.NativeWidth", "number")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.NativeWidth", "1920")}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    NativeHeight
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.NativeHeight", "number")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.NativeHeight", "1080")}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    MaxWidth
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.MaxWidth", "number")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.MaxWidth", "2500")}
                  </Grid>
                </Grid>
                <div className={classes.header}>Translation</div>
                <div className={classes.indented}>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      DefaultLang
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Translation.DefaultLang",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Translation.DefaultLang",
                        "en"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      InNavBox
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Translation.InNavBox",
                            "boolean"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Translation.InNavBox",
                        "false"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      SelectedLanguages (lang codes as comma seperated values)
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Translation.SelectedLanguages",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Translation.SelectedLanguages",
                        ""
                      )}
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Styles
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.Styles", "string")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.Styles", "")}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Scripts
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.Scripts", "string")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.Scripts", "")}
                  </Grid>
                </Grid>
                <div className={classes.header}>Loading</div>
                <div className={classes.indented}>
                  <div className={classes.header}>Background</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Type
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Loading.Background",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Loading.Background",
                          "color"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Color
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Loading.Color",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Loading.Color",
                          "696969"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Url
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked("Metadata.Loading.Url", "string")
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier("Metadata.Loading.Url", "")}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Preload
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Translation.Preload",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Translation.Preload",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={classes.header}>Hive Color theme</div>
                <div className={classes.indented}>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      Background Color Theme
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked("Metadata.Theme.Color", "string")
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier("Metadata.Theme.Color", "''")}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      Background Color Hover Theme
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked("Metadata.Theme.Hover", "string")
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier("Metadata.Theme.Hover", "''")}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      Text Color Theme
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Theme.TextColor",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier("Metadata.Theme.TextColor", "''")}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      Text Color Hover Theme
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Theme.TextHover",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier("Metadata.Theme.TextHover", "''")}
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.header}>Session</div>
                <div className={classes.indented}>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      Authentication method <small>(api or keycloak)</small>
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.AuthenticationMethod",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.AuthenticationMethod",
                        "api"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      keycloak realm
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.KeycloakRealm",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.KeycloakRealm",
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      keycloak client clientId
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.KeycloakClientClientId",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.KeycloakClientClientId",
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      keycloak api clientId
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.KeycloakApiClientId",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.KeycloakApiClientId",
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      keycloak api client secret
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.KeycloakApiClientSecret",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.KeycloakApiClientSecret",
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      Disable custom message
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.LoginDisableCustomMessage",
                            "boolean"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.LoginDisableCustomMessage",
                        "false"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      SessionTimeoutDays
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.SessionTimeoutDays",
                            "number"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.SessionTimeoutDays",
                        "7"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      LoginOfflineTimeoutMinutes
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.LoginOfflineTimeoutMinutes",
                            "number"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.LoginOfflineTimeoutMinutes",
                        "300"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      LoginOnlineTimeoutMinutes
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.LoginOnlineTimeoutMinutes",
                            "number"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.LoginOnlineTimeoutMinutes",
                        "300"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      NeedTrackingAccept
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.NeedTrackingAccept",
                            "boolean"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.NeedTrackingAccept",
                        "false"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      LoginEnabled
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.LoginEnabled",
                            "boolean"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.LoginEnabled",
                        "Using hive setting"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      LoginCheckAll
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.LoginCheckAll",
                            "boolean"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.LoginCheckAll",
                        "Using hive setting"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      LoginCheckStartup
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.LoginCheckStartup",
                            "boolean"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.LoginCheckStartup",
                        "Using hive setting"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      MustAgreePrivacy
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.MustAgreePrivacy",
                            "boolean"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.MustAgreePrivacy",
                        "Depends on PrivacyUrl being set"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      LoginActivePing
                    </Grid>
                    <Grid item xs={9}>
                      <Button className={classes.editButton}>
                        <Remove />
                      </Button>
                      <i className={classes.defaultValue}>Using hive setting</i>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      LoginActivePingSeconds
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.LoginActivePingSeconds",
                            "number"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.LoginActivePingSeconds",
                        "120"
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      PrivacyUrl
                    </Grid>
                    <Grid item xs={9}>
                      <Button className={classes.editButton}>
                        <Remove />
                      </Button>
                      <i className={classes.defaultValue}>Using hive setting</i>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      RegisterUrl
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.RegisterUrl",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.RegisterUrl",
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={3}>
                      Mouseovertext
                    </Grid>
                    <Grid item xs={9}>
                      <Button
                        onClick={() =>
                          editSettingClicked(
                            "Metadata.Session.Mouseovertext",
                            "string"
                          )
                        }
                        className={classes.editButton}
                      >
                        <EditIcon />
                      </Button>
                      {getSettingByIdentifier(
                        "Metadata.Session.Mouseovertext",
                        ""
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.header}>Features</div>
                <div className={classes.indented}>
                  <div className={classes.header}>New menu</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.NewMenu.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.NewMenu.Enabled",
                          "true"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Experience panel</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.ExperiencePanel.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.ExperiencePanel.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Show program replacement text
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.ProgramText.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.ProgramText.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Newsticker</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Newsticker.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Newsticker.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        IncludeScenes
                      </Grid>
                      <Grid item xs={9}>
                        <Button className={classes.editButton}>
                          <Remove />
                        </Button>
                        <i className={classes.defaultValue}>
                          Using hive setting
                        </i>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Program</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Program.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Program.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Title
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Program.Title",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Program.Title",
                          "Program"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        DayFilters
                      </Grid>
                      <Grid item xs={9}>
                        <Button className={classes.editButton}>
                          <Remove />
                        </Button>
                        <i className={classes.defaultValue}>
                          Using hive setting
                        </i>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        TimeLocale
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Program.TimeLocale",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Program.TimeLocale",
                          "en"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Share button
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Program.ShareBtn",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Program.ShareBtn",
                          false
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Chat and Networking</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Chat Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Chat.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Chat.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Networking Enabled{" "}
                        <small>(requires chat to be enabled)</small>
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Networking.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Networking.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Audio</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Audio.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Audio.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Url
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Audio.Url",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Audio.Url",
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Autoplay
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Audio.Autoplay",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Audio.Autoplay",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Popup</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Popup.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Popup.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        LoadIntervalSeconds
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Popup.LoadIntervalSeconds",
                              "number"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Popup.LoadIntervalSeconds",
                          "60"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Meetings</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Meetings.Popup.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Meetings.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>ActiveUsers</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.ActiveUsers.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.ActiveUsers.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        DisplayFields
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.ActiveUsers.DisplayFields",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.ActiveUsers.DisplayFields",
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        SortingFields
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.ActiveUsers.SortingFields",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.ActiveUsers.SortingFields",
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        SortingDirections
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.ActiveUsers.SortingDirections",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.ActiveUsers.SortingDirections",
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        FilterFields
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.ActiveUsers.FilterFields",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.ActiveUsers.FilterFields",
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Stands</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        ShowStandOverviewInExhibition
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Stands.ShowStandOverviewInExhibition",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Stands.ShowStandOverviewInExhibition",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Freshchat</div>
                  <small>
                    Open and close by with functional hotspot button calling
                    "toggleFreshdeskChat"
                  </small>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Freshchat.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Freshchat.Enabled",
                          "false"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Token
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Freshchat.Token",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier("Metadata.Freshchat.Token", "")}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Branding</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Enabled
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Branding.Enabled",
                              "boolean"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Branding.Enabled",
                          "true"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className={classes.header}>Invitations</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Sender Name
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Invitation.Sender.Name",
                              "string"
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Invitation.Sender.Name",
                          "string"
                        )}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Sender Email
                      </Grid>
                      <Grid item xs={9}>
                        <Button
                          onClick={() =>
                            editSettingClicked(
                              "Metadata.Features.Invitation.Sender.Email",
                              "option",
                              [
                                "noreply@virtualhive.live",
                                "noreply@virtualhive.dk",
                              ]
                            )
                          }
                          className={classes.editButton}
                        >
                          <EditIcon />
                        </Button>
                        {getSettingByIdentifier(
                          "Metadata.Features.Invitation.Sender.Email",
                          "string"
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Preloadimages
                  </Grid>
                  <Grid item xs={9}>
                    <Button className={classes.editButton}>
                      <Remove />
                    </Button>
                    <i className={classes.defaultValue}>Using hive setting</i>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Favicon
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.Favicon", "string")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.Favicon", "")}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    StartScene
                  </Grid>
                  <Grid item xs={9}>
                    <Button className={classes.editButton}>
                      <Remove />
                    </Button>
                    <i className={classes.defaultValue}>Using hive setting</i>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    LoginScene
                  </Grid>
                  <Grid item xs={9}>
                    <Button className={classes.editButton}>
                      <Remove />
                    </Button>
                    {getSettingByIdentifier("Metadata.LoginScene", "login")}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    EventHandler
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      onClick={() =>
                        editSettingClicked("Metadata.EventHandler", "string")
                      }
                      className={classes.editButton}
                    >
                      <EditIcon />
                    </Button>
                    {getSettingByIdentifier("Metadata.EventHandler", "")}
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    Debug
                  </Grid>
                  <Grid item xs={9}>
                    <Button className={classes.editButton}>
                      <Remove />
                    </Button>
                    <i className={classes.defaultValue}>Using hive setting</i>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.header}>Scene</div>
              <div className={classes.indented}>
                <i>Generated by server</i>
              </div>
              <div className={classes.header}>Globals</div>
              <div className={classes.indented}>
                <i>No functionality</i>
              </div>

              <div className={classes.header}>Translations</div>
              {Object.values(translations).map((translation) => (
                <div key={translation.code} className={classes.indented}>
                  <div className={classes.header}>{translation.name}</div>
                  <div className={classes.indented}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Code
                      </Grid>
                      <Grid item xs={9}>
                        {translation.code}
                      </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        Name
                      </Grid>
                      <Grid item xs={9}>
                        {translation.name}
                      </Grid>
                    </Grid>
                    <div className={classes.header}>Translation</div>
                    <div className={classes.indented}>
                      {Object.keys(translation.translation).map((key) => (
                        <Grid key={key} container spacing={0}>
                          <Grid item xs={3}>
                            {key}
                          </Grid>
                          <Grid item xs={9}>
                            <Button
                              onClick={() =>
                                editSettingClicked(
                                  "Translations." +
                                    translation.code +
                                    ".Translation." +
                                    formatKey(key),
                                  "string"
                                )
                              }
                              className={classes.editButton}
                            >
                              <EditIcon />
                            </Button>
                            {getSettingByIdentifier(
                              "Translations." +
                                translation.code +
                                ".Translation." +
                                formatKey(key),
                              translation.translation[key]
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditClientSubMenu />
        </TabPanel>
      </Paper>

      <GlobalDialog
        type="Drawer"
        isDialogOpen={hiveSettingToEdit !== null}
        handleDialogClose={() => setHiveSettingToEdit(null)}
        title={hiveSettingToEdit?.identifier ?? ""}
        sizeclass="L"
      >
        <Container>
          {hiveSettingToEdit && (
            <>
              <EditClientSetting
                onSave={(newValue) => onSave(newValue)}
                hiveSetting={hiveSettingToEdit}
              />
            </>
          )}
        </Container>
      </GlobalDialog>
    </>
  );
};

export default ClientSettings_Page;
