import './EditEventDetails.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { makeStyles } from '@material-ui/core/styles';
import { PageService } from '../../page/services/PageService';
import { KeywordService } from '../../keyword/services/KeywordService';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import ReactTooltip from 'react-tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { LoadingIndicator } from '../../../core/LoadingIndicator';
import { LoginHelper } from '../../../core/LoginHelper';
import { Editor } from '@tinymce/tinymce-react';
import MultiSelector from '../../../core/components/MultiSelector';
import { HiveSettingService } from '../../settings/services/HiveSettingService';
import Api from '../../settings/services/Api';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  helpButton: {
    marginTop: '15px',
    marginleft: '15px',
  },

  form: {
    flexBasis: '50%',
  },
}));

const EditEventDetails = (props) => {
  const classes = useStyles();
  // const storageHelper = new StorageHelper();
  const loginHelper = new LoginHelper();
  const keywordService = new KeywordService();
  const pageService = new PageService();
  const [activePages, setActivePages] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState('');
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [id, setId] = useState('');
  const [pageError, setPageError] = useState('');
  const [dateError, setDateError] = useState('');
  const [startDate, setStartDate] = useState(
    moment().format('YYYY-MM-DDTHH:00')
  );
  const [startDateUTC, setStartDateUTC] = useState(
    moment().format('YYYY-MM-DDTHH:mm')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DDTHH:00'));
  const [endDateUTC, setEndDateUTC] = useState(
    moment().format('YYYY-MM-DDTHH:mm')
  );
  const [dataLink, setDataLink] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [enableRegistrations, setEnableRegistrations] = useState(false);
  const [maxRegistrations, setMaxRegistrations] = useState(0);
  const [hiveSettings, setHiveSettings] = useState([]);
  const [translations, setTranslations] = useState({});
  const [selectedPageTitle, setSelectedPageTitle] = useState('');
  const [selectedLangCode, setSelectedLangCode] = useState(null);
  const [defaultLangCode, setDefaultLangCode] = useState(null);
  const [langs, setLangs] = useState(null);

  let hiveSettingService = new HiveSettingService();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await fetchPageData();
      await fetchKeywordData();

      if (props.event) {
        let activeKeywords = [];
        let activePages = [];
        for (const item of props.event.eventKeywordRels) {
          activeKeywords.push(item.keyword);
        }
        for (const item of props.event.eventPageRels) {
          activePages.push(item.page);
        }
        setSelectedKeywords(activeKeywords);
        setSelectedPages(activePages);
        setId(props.event.id);
        setStartDate(
          moment
            .utc(props.event.startDate)
            .tz(props.timeZone)
            .format('YYYY-MM-DDTHH:mm')
        );
        setStartDateUTC(
          moment.utc(props.event.startDate).format('YYYY-MM-DDTHH:mm')
        );
        setEndDate(
          moment
            .utc(props.event.endDate)
            .tz(props.timeZone)
            .format('YYYY-MM-DDTHH:mm')
        );
        setEndDateUTC(
          moment.utc(props.event.endDate).format('YYYY-MM-DDTHH:mm')
        );
        setDataLink(props.event.dataLink);

        setSelectedPageId(props.event.pageId);
        setEnableRegistrations(props.event.enableRegistrations);
        setMaxRegistrations(props.event.maxRegistrations);
        // setResourceId(props.event.resourceId);
      } else {
        setStartDateUTC(
          moment.tz(startDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
        );
        setEndDateUTC(
          moment.tz(startDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
        );
      }

      await fetchHiveSettings().then((values) => {
        const hiveSettings = values[0];
        const defaultLangCodeValue =
          hiveSettings?.find(
            (t) => t.identifier === 'Metadata.Translation.DefaultLang'
          )?.value || 'en';
        setDefaultLangCode(defaultLangCodeValue);
        setSelectedLangCode(defaultLangCodeValue);
        const langsObj = props?.event?.langs
          ? JSON.parse(props.event.langs)
          : {};
        if (!langsObj[defaultLangCodeValue])
          langsObj[defaultLangCodeValue] = {
            name: props?.event?.name,
            host: props?.event?.host,
            hostDescription: props?.event?.hostDescription,
            description: props?.event?.description,
          };
        setLangs(langsObj);
      });

      setIsLoading(false);
    };
    loadData();
    // eslint-disable-next-line
  }, []);

  const fetchHiveSettings = () => {
    return Promise.all([
      hiveSettingService.getHiveSettings(),
      Api.getJson().then((res) => res.translations),
    ]).then((values) => {
      setHiveSettings(values[0]);
      setTranslations({
        da: {
          code: 'da',
          name: 'Dansk',
        },
        en: {
          code: 'en',
          name: 'English',
        },
        fr: {
          code: 'fr',
          name: 'Français',
        },
        de: {
          code: 'de',
          name: 'Deutsch',
        },
        it: {
          code: 'it',
          name: 'Italiano',
        },
      });
      return values;
    });
  };

  const getSettingByIdentifier = (identifier, defaultValue) => {
    let existingSetting = hiveSettings.find((t) => t.identifier === identifier);

    if (existingSetting) {
      return existingSetting.value;
    }
    if (defaultValue !== null) {
      return defaultValue;
    }
    return;
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const fetchPageData = async () => {
    let response = await pageService.getPages(loginHelper.getCurrentHive().id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = customSort(response, ['title'], ['asc']);

      let locationPages = [];

      for (const page of response) {
        if (page.canContainEvents) {
          locationPages.push(page);
        }
      }

      setActivePages(locationPages);
    }
  };

  const fetchKeywordData = async () => {
    let response = await keywordService.getKeywords(
      loginHelper.getCurrentHive().id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = customSort(response, ['name'], ['asc']);

      setKeywords(response.filter((t) => t.showInEvent));
    }
  };

  const onSaveClicked = async () => {
    var validationError = false;

    if (!selectedPageId) {
      setPageError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setPageError('');
    }

    let momStart = moment(startDate);
    let momEnd = moment(endDate);
    if (momEnd.isBefore(momStart) || momEnd.isSame(momStart)) {
      setDateError(getLanguage('EndDateMustbeAfterStartDate'));
      validationError = true;
    } else {
      setDateError('');
    }
    if (validationError) {
      return;
    }

    let pagesObjetList = [];
    for (const element of selectedPages) {
      if (id) {
        pagesObjetList.push({ pageId: element.id, eventId: id });
      } else {
        pagesObjetList.push({ pageId: element.id });
      }
    }

    let keywordObjectList = [];
    for (const element of selectedKeywords) {
      if (id) {
        keywordObjectList.push({ keywordId: element.id, eventId: id });
      } else {
        keywordObjectList.push({ keywordId: element.id });
      }
    }

    props.onSave({
      name: '',
      host: '',
      description: '',
      hostDescription: '',
      id: id || undefined,
      startDate: startDateUTC,
      endDate: endDateUTC,
      dataLink: dataLink,
      isActive: true,
      pageId: selectedPageId,
      embed: '',
      eventPageRels: pagesObjetList,
      eventKeywordRels: keywordObjectList,
      EventResourceRels: [],
      resourceId: '',
      enableRegistrations,
      maxRegistrations,
      langs: JSON.stringify(langs),
    });
  };

  const getPageOptions = () => {
    return activePages.map((data) => (
      <MenuItem key={data.id} value={data.id} data-title={data.title}>
        {data.title}
      </MenuItem>
    ));
  };

  const handlePageChanged = (event) => {
    setSelectedPageId(event.target.value);
    setSelectedPageTitle(event.currentTarget.dataset.title);
    setPageError('');
  };

  const onSelect = (selectedList) => {
    setSelectedKeywords(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedKeywords(selectedList);
  };
  const onSetStartDate = (startDate) => {
    setStartDate(startDate);
    setStartDateUTC(
      moment.tz(startDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
    );
    setDateError('');
  };

  const onSetEndDate = (endDate) => {
    setEndDate(endDate);
    setEndDateUTC(
      moment.tz(endDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
    );
    setDateError('');
  };

  const isStartAndEndDateSame =
    moment(startDate).format('DD') === moment(endDate).format('DD');
  const isStartAndEndMonthSame =
    moment(startDate).format('MM') === moment(endDate).format('MM');

  const handleSelectedLangCodeChange = (e) => {
    const nextLangCode = e.target.value;
    const nextLangs = JSON.parse(JSON.stringify(langs));

    if (!nextLangs[nextLangCode])
      nextLangs[nextLangCode] = { ...nextLangs[defaultLangCode] };
    setLangs(nextLangs);
    setSelectedLangCode(e.target.value);
  };

  const makeHandleTextChange = (str) => (event) => {
    const value = str === 'hostDescription' ? event : event.target.value;
    const nextState = JSON.parse(JSON.stringify(langs));
    nextState[selectedLangCode][str] = value;
    setLangs(nextState);
  };

  const getLang = () => langs?.[selectedLangCode] || {};

  const hiveSelectedLanguages = getSettingByIdentifier(
    'Metadata.Translation.SelectedLanguages',
    []
  );

  const languages = Object.values(translations).filter(
    (i) => hiveSelectedLanguages.includes(i.code) || i.code === defaultLangCode
  );
  const selectedLangName =
    languages?.find((i) => i.code === selectedLangCode)?.name || '';
  const lang = getLang();

  return (
    <>
      {isLoading && <LoadingIndicator />}

      {!isLoading && (
        <div className='formWrapper'>
          <form
            className={classes.form}
            onSubmit={(e) => {
              e.preventDefault();
              onSaveClicked();
            }}
          >
            <div className={classes.selectContainer}>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <Grid container spacing={0}>
                  <Grid item xs>
                    <InputLabel
                      error={pageError}
                      className={classes.selectLabel}
                      id='pageSelector-label'
                    >
                      Translation
                    </InputLabel>
                    <Select
                      error={pageError}
                      variant='outlined'
                      fullWidth={true}
                      value={selectedLangCode}
                      onChange={handleSelectedLangCodeChange}
                      labelId='pageSelector-label'
                      id='pageSelector'
                    >
                      {languages.map((i) => (
                        <MenuItem value={i.code}>{i.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={1}>
                    <div
                      data-tip
                      data-for={'toolTip1'}
                      className={classes.helpButton}
                    >
                      <HelpIcon />
                    </div>
                    <ReactTooltip id={'toolTip1'} aria-haspopup='true'>
                      <div>
                        Select which language to translate the program for
                      </div>
                    </ReactTooltip>
                  </Grid>
                </Grid>
              </FormControl>
            </div>

            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <Grid container spacing={0}>
                <Grid item xs>
                  <TextField
                    variant='outlined'
                    // error={titleError}
                    // helperText={titleError}
                    value={lang.name}
                    onChange={makeHandleTextChange('name')}
                    label={getLanguage('Title') + ' ' + selectedLangName}
                    className='textField'
                  />
                </Grid>
                <Grid item xs={1}>
                  <div
                    data-tip
                    data-for={'toolTip1'}
                    className={classes.helpButton}
                  >
                    <HelpIcon />
                  </div>
                  <ReactTooltip id={'toolTip1'} aria-haspopup='true'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getLanguage('eventTitleTooltip'),
                      }}
                    />
                  </ReactTooltip>
                </Grid>
              </Grid>
            </FormControl>

            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <Grid container spacing={0}>
                <Grid item xs>
                  <TextField
                    variant='outlined'
                    multiline
                    value={lang.description}
                    onChange={makeHandleTextChange('description')}
                    label={getLanguage('Description') + ' ' + selectedLangName}
                    className='textField'
                  />
                </Grid>
                <Grid item xs={1}>
                  <div
                    data-tip
                    data-for={'toolTip3'}
                    className={classes.helpButton}
                  >
                    <HelpIcon />
                  </div>
                  <ReactTooltip id={'toolTip3'} aria-haspopup='true'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getLanguage('eventDescriptionTooltip'),
                      }}
                    />
                  </ReactTooltip>
                </Grid>
              </Grid>
            </FormControl>

            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <Grid container spacing={0}>
                <Grid item xs>
                  <TextField
                    variant='outlined'
                    value={lang.host}
                    onChange={makeHandleTextChange('host')}
                    label={getLanguage('Host') + ' ' + selectedLangName}
                    className='textField'
                  />
                </Grid>
                <Grid item xs={1}>
                  <div
                    data-tip
                    data-for={'toolTip2'}
                    className={classes.helpButton}
                  >
                    <HelpIcon />
                  </div>
                  <ReactTooltip id={'toolTip2'} aria-haspopup='true'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getLanguage('eventHostTooltip'),
                      }}
                    />
                  </ReactTooltip>
                </Grid>
              </Grid>
            </FormControl>

            {lang.host && (
              <>
                <b>{getLanguage('HostDescription') + ' ' + selectedLangName}</b>
                <br />
                <br />
                <Grid container spacing={0}>
                  <Grid item xs>
                    <Editor
                      apiKey='28hyr9gnobmogsua4bqzk4ouufkapzimhfgm71qkgra1yjxl'
                      value={lang.hostDescription}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code',
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | link | image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat code',
                      }}
                      onEditorChange={makeHandleTextChange('hostDescription')}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                <br />
                <br />
              </>
            )}

            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={enableRegistrations}
                    onChange={(e) => setEnableRegistrations(e.target.checked)}
                  />
                }
                label={getLanguage('RequiresRegistrationToAttend')}
                className='checkbox'
              />
            </FormControl>

            {enableRegistrations && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <Grid container spacing={0}>
                  <Grid item xs>
                    <TextField
                      variant='outlined'
                      value={maxRegistrations}
                      onChange={(e) => setMaxRegistrations(e.target.value)}
                      label={getLanguage('MaxRegistrations(0IsUnlimited)')}
                      className='textField'
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </FormControl>
            )}

            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <Grid container spacing={0}>
                <Grid item xs>
                  <div>
                    <TextField
                      error={dateError}
                      helperText={dateError}
                      type='datetime-local'
                      variant='outlined'
                      value={startDate}
                      onChange={(e) => onSetStartDate(e.target.value)}
                      label={getLanguage('StartDate')}
                      className='textFieldHalf'
                    />
                    <TextField
                      error={dateError}
                      type='datetime-local'
                      variant='outlined'
                      value={endDate}
                      onChange={(e) => {
                        onSetEndDate(e.target.value);
                      }}
                      label={getLanguage('EndDate')}
                      className='textFieldHalf'
                    />
                  </div>
                  <i>
                    {getLanguage(
                      'TimesWillBeSavedInTheFollowingTimezone'
                    ).replace('{Timezone}', props.timeZone)}
                  </i>
                  <br />
                  <br />
                </Grid>
                <Grid item xs={1}>
                  <div
                    data-tip
                    data-for={'toolTip4'}
                    className={classes.helpButton}
                  >
                    <HelpIcon />
                  </div>
                  <ReactTooltip id={'toolTip4'} aria-haspopup='true'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getLanguage('eventDateTooltip'),
                      }}
                    />
                  </ReactTooltip>
                </Grid>
              </Grid>
            </FormControl>

            {activePages != null && (
              <>
                <div className={classes.selectContainer}>
                  <FormControl
                    fullWidth={true}
                    variant='outlined'
                    className={classes.formControl}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs>
                        <InputLabel
                          error={pageError}
                          className={classes.selectLabel}
                          id='pageSelector-label'
                        >
                          {getLanguage('Location')}
                        </InputLabel>
                        <Select
                          error={pageError}
                          variant='outlined'
                          fullWidth={true}
                          value={selectedPageId}
                          onChange={handlePageChanged}
                          labelId='pageSelector-label'
                          id='pageSelector'
                        >
                          <MenuItem value={''}>
                            <em>{getLanguage('PleaseChoose')}</em>
                          </MenuItem>
                          {getPageOptions()}
                        </Select>
                        {pageError && (
                          <p className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error'>
                            {pageError}
                          </p>
                        )}
                      </Grid>
                      <Grid item xs={1}>
                        <div
                          data-tip
                          data-for={'toolTip7'}
                          className={classes.helpButton}
                        >
                          <HelpIcon />
                        </div>
                        <ReactTooltip id={'toolTip7'} aria-haspopup='true'>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getLanguage('pageTooltip'),
                            }}
                          />
                        </ReactTooltip>
                      </Grid>
                    </Grid>
                  </FormControl>
                </div>
              </>
            )}

            {keywords !== undefined && (
              <>
                <div className={classes.selectContainer}>
                  {
                    <FormControl
                      fullWidth={true}
                      variant='outlined'
                      className={classes.formControl}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs>
                          <MultiSelector
                            displayValue='name'
                            placeholder={getLanguage('SelectKeywords')}
                            items={keywords}
                            selectedValues={selectedKeywords}
                            onSelect={onSelect}
                            onRemove={onRemove}
                          />
                          <br />
                        </Grid>
                        <Grid item xs={1}>
                          <div
                            data-tip
                            data-for={'toolTip9'}
                            className={classes.helpButton}
                          >
                            <HelpIcon />
                          </div>
                          <ReactTooltip id={'toolTip9'} aria-haspopup='true'>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: loginHelper.userHasRole('Editor')
                                  ? getLanguage('keywordTooltipExtra')
                                  : getLanguage('keywordTooltip'),
                              }}
                            />
                          </ReactTooltip>
                        </Grid>
                      </Grid>
                    </FormControl>
                  }
                </div>
              </>
            )}

            <Grid container spacing={0}>
              <Grid item xs>
                <Button
                  type='submit'
                  fullWidth={true}
                  variant='contained'
                  color='primary'
                >
                  {getLanguage('Save')}
                </Button>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <br />
            <br />
          </form>
          <div className='program'>
            {isStartAndEndDateSame && isStartAndEndMonthSame ? (
              <span className='singleDate'>
                {moment(startDate).format('DD')}
                <sup className='month'>{moment(startDate).format('MMM')}</sup>
                <span className='year'>{moment(startDate).format('YYYY')}</span>
              </span>
            ) : (
              <span className='twoDates'>
                {moment(startDate).format('DD')}
                {isStartAndEndMonthSame ? (
                  ''
                ) : (
                  <sup
                    style={{ position: 'absolute', top: '21px', left: '37px' }}
                    className='month'
                  >
                    {moment(startDate).format('MMM')}
                  </sup>
                )}
                <span> - </span>
                <span className='endDate'>
                  {moment(endDate).format('DD')}
                  <sup className='month'>{moment(endDate).format('MMM')}</sup>
                </span>
                <span className='year'>{moment(startDate).format('YYYY')}</span>
              </span>
            )}
            <span className='time'>
              {moment(startDate).format('HH:mm')} -{' '}
              {moment(endDate).format('HH:mm')}
              <span className='scene'>{selectedPageTitle}</span>
            </span>
            <span className='title'>{lang?.name}</span>
            <span className='speaker'>{lang.host}</span>
            <p className='description'>{lang.description}</p>
            {lang.hostDescription && (
              <div className='speakerInfo'>Event info</div>
            )}

            <div className='divider'>
              {selectedPageId && <div className='attendBtn'>Attend</div>}
              {enableRegistrations && (
                <div className='registerBtn'>Register</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditEventDetails;
