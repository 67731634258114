import { LoginHelper } from "../../../core/LoginHelper";
import { clearFromCache, getCachedObject, setObjectInCache } from "../../../core/ObjectCache";
import { KeywordApi } from "./KeywordApi";

export class KeywordService {
    keywordApi = new KeywordApi();
    loginHelper = new LoginHelper();

    cacheIdentifier = "keywords";
    cacheDurationMinutes = 15;

    async getKeyword(id) {
        return await this.keywordApi.getKeyword(id);
    }

    async getKeywords(hiveId) {
        hiveId = hiveId ?? this.loginHelper.getCurrentHive().id;

        let cached = getCachedObject(this.cacheIdentifier + "_" + hiveId);
        if (cached) return cached;

        var result = await this.keywordApi.getKeywords(hiveId);

        if (!result.isAxiosError) {
            setObjectInCache(this.cacheIdentifier + "_" + hiveId, result, this.cacheDurationMinutes);
        }

        return result;
    }

    async addKeyword(keyword) {
        let hiveId = this.loginHelper.getCurrentHive().id;
        this.clearCache(hiveId);
        return await this.keywordApi.addKeyword(keyword);
    }

    async updateKeyword(id, keyword) {
        let hiveId = this.loginHelper.getCurrentHive().id;
        this.clearCache(hiveId);
        return await this.keywordApi.updateKeyword(id, keyword);
    }

    async deleteKeyword(id) {
        let hiveId = this.loginHelper.getCurrentHive().id;
        this.clearCache(hiveId);
        return await this.keywordApi.deleteKeyword(id);
    }

    async addKeywordToPage(pageId, keywordId) {
        return await this.keywordApi.addKeywordToPage(pageId, keywordId);
    }

    async removeKeywordFromPage(pageId, keywordId) {
        return await this.keywordApi.removeKeywordFromPage(pageId, keywordId);
    }

    clearCache(hiveId) {
        clearFromCache(this.cacheIdentifier + "_" + hiveId);
    }
}