import './ObjectEditor.css';
import { Button, ClickAwayListener, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import LanguageSelector from '../../language/components/LanguageSelector';
import { Help } from '@material-ui/icons';
import ReactTooltip from 'react-tooltip';

const ObjectHtmlEditor = (props) => {
  const [link, setLink] = useState('');
  const [preview, setPreview] = useState('');
  const [shouldFireClickAway, setShouldFireClickAway] = useState(false);

  useEffect(() => {
    setLink(props.pageObject.data);
    setPreview(props.pageObject.data);
    // eslint-disable-next-line
  }, []);

  const testChange = () => {
    setPreview(link);
  };

  const attemptUpdateAttribute = (attribute, newValue) => {
    if (props.updateAttribute) {
      props.updateAttribute(attribute, newValue);
    }
  };

  return (
    <>
      <h2>{getLanguage('EmbedCode')}</h2>
      {preview && (
        <>
          <div dangerouslySetInnerHTML={{ __html: preview }} />
        </>
      )}
      <br />
      <Grid container spacing={1}>
        <Grid item xs>
          <ClickAwayListener
            onClickAway={() => {
              if (shouldFireClickAway) {
                props.handleClickAway();
                setShouldFireClickAway(false);
              }
            }}
          >
            <TextField
              variant='outlined'
              multiline={true}
              rows={5}
              className='textField'
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                attemptUpdateAttribute('data', e.target.value);
                setShouldFireClickAway(true);
              }}
              label={getLanguage('InsertEmbedCode')}
            />
          </ClickAwayListener>
          <LanguageSelector
            pageObject={props.pageObject}
            attemptUpdateAttribute={attemptUpdateAttribute}
            contentType={'embed'}
          >
            <TextField
              variant='outlined'
              multiline={true}
              rows={5}
              className='textField'
              label={getLanguage('InsertEmbedCode')}
            />
          </LanguageSelector>
        </Grid>
        <Grid item xs={1}>
          <div data-tip data-for={'tip-1'} className='helpButon'>
            <Help />
          </div>
          <ReactTooltip id={'tip-1'} aria-haspopup='true'>
            <div
              dangerouslySetInnerHTML={{
                __html: getLanguage('HtmlEmbedHelpText'),
              }}
            />
          </ReactTooltip>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button
            variant='contained'
            fullWidth={true}
            color='primary'
            onClick={() => testChange()}
          >
            {getLanguage('Test')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ObjectHtmlEditor;
