import { GalleryItemApi } from './GalleryItemApi';

export class GalleryItemService {
  galleryItemApi = new GalleryItemApi();

  async getGalleryItems(hiveId) {
    return await this.galleryItemApi.getGalleryItems(hiveId);
  }

  async addGalleryItem(galleryItem) {
    return await this.galleryItemApi.addGalleryItem(galleryItem);
  }

  async updateGalleryItem(id, galleryItem) {
    return await this.galleryItemApi.updateGalleryItem(id, galleryItem);
  }

  async deleteGalleryItem(id) {
    return await this.galleryItemApi.deleteGalleryItem(id);
  }
}
