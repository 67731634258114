import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WebIcon from '@material-ui/icons/Web';
import { makeStyles } from '@material-ui/core/styles';
import getLanguage from './Language';
import {
  ExpandLess,
  ExpandMore,
  EventSeat,
  People,
  Business,
  Announcement,
  PanoramaHorizontal,
  Event,
  SettingsOverscan,
  LocationSearching,
  Info,
  TrendingUp,
  MailOutline,
  PhotoLibrary,
  MeetingRoom,
  SettingsInputComponent,
  Settings,
  Description,
  Attachment,
  ImportExport,
  Dvr,
  Public,
  EventNote,
  VideoLibrary,
  GraphicEq,
  Image,
} from '@material-ui/icons';

import { Collapse, Tooltip } from '@material-ui/core';
import { LoginHelper } from './LoginHelper';
import { Stream } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  gutters: {
    paddingLeft: 12,
  },
  icon: {
    fill: '#FFF',
    color: '#fff',
  },
}));

const MenuList = (props) => {
  const loginHelper = new LoginHelper();
  const classes = useStyles();

  const [hiveSetupOpen, setHiveSetupOpen] = useState(false);
  const [statisticsOpen, setStatisticsOpen] = useState(false);

  const leaveHive = () => {
    loginHelper.setCurrentHive(null);
    window.location.href = '/hives';
  };

  const changeHiveSetupOpenStatus = () => {
    if (hiveSetupOpen) {
      props.closedSecondLevel();
    } else {
      props.openedSecondLevel();
    }

    setHiveSetupOpen(!hiveSetupOpen);
  };

  const changeStatisticsOpenStatus = () => {
    if (statisticsOpen) {
      props.closedSecondLevel();
    } else {
      props.openedSecondLevel();
    }

    setStatisticsOpen(!statisticsOpen);
  };

  return (
    <List>
      {loginHelper.isAdmin() && !loginHelper.getCurrentHive() && (
        <ListItem
          className={classes.gutters}
          button
          onClick={() => props.requestClose && props.requestClose()}
          component={Link}
          to='/hives'
        >
          <ListItemIcon>
            <Tooltip title={getLanguage('Hives')}>
              <Business className={classes.icon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>{getLanguage('Hives')}</ListItemText>
        </ListItem>
      )}

      {loginHelper.isAdmin() && loginHelper.getCurrentHive() && (
        <ListItem
          className={classes.gutters}
          button
          onClick={() => leaveHive()}
          component={Link}
          to='#'
        >
          <ListItemIcon>
            <Tooltip title={getLanguage('Hives')}>
              <Business className={classes.icon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>{getLanguage('Hives')}</ListItemText>
        </ListItem>
      )}
      {loginHelper.isAdmin() && !loginHelper.getCurrentHive() && (
        <ListItem
          className={classes.gutters}
          button
          onClick={() => props.requestClose && props.requestClose()}
          component={Link}
          to='/administrators'
        >
          <ListItemIcon>
            <Tooltip title={getLanguage('Administrators')}>
              <People className={classes.icon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>{getLanguage('Administrators')}</ListItemText>
        </ListItem>
      )}

      {/* {(loginHelper.isAdmin() && !loginHelper.getCurrentHive()) && <ListItem className={classes.gutters} button onClick={() => props.requestClose && props.requestClose()} component={Link} to="/languages" >
                <ListItemIcon><Tooltip title={getLanguage("Languages")}><Translate /></Tooltip></ListItemIcon>
                <ListItemText>{getLanguage("Languages")}</ListItemText>
            </ListItem>} */}

      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('PageEditor') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/pages'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Pages')}>
                <WebIcon className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('Pages')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('StandEditor') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/stands'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Stands')}>
                <PanoramaHorizontal className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('Stands')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('EventAdministration') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/hive/events/'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Events')}>
                <Event className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('Events')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('NewsAdministration') && (
          <>
            <List component='div' disablePadding>
              <ListItem
                className={classes.gutters}
                button
                onClick={() => props.requestClose && props.requestClose()}
                component={Link}
                to='/newsitems'
              >
                <ListItemIcon>
                  <Tooltip title={getLanguage('NewsTicker')}>
                    <Announcement className={classes.icon} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>{getLanguage('NewsTicker')}</ListItemText>
              </ListItem>
            </List>
          </>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('PopupAdministration') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/popupmessages'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('PopupMessages')}>
                <Info className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('PopupMessages')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('KeywordAdministration') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/keywords'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Keywords')}>
                <LocationSearching className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('Keywords')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        (loginHelper.userHasRole('MeetingAdministration') ||
          loginHelper.userHasRole('MeetingRead')) && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/meetings'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Meetings')}>
                <MeetingRoom className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('Meetings')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('AssetAdministration') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/assets'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Assets')}>
                <PhotoLibrary className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('Assets')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() && loginHelper.userHasRole('Gallery') && (
        <ListItem
          className={classes.gutters}
          button
          onClick={() => props.requestClose && props.requestClose()}
          component={Link}
          to='/gallery'
        >
          <ListItemIcon>
            <Tooltip title={getLanguage('Gallery')}>
              <Image className={classes.icon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>{getLanguage('Gallery')}</ListItemText>
        </ListItem>
      )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('ContentLibraryAdministration') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/contentlibrary'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('ContentLibrary')}>
                <VideoLibrary className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('ContentLibrary')}</ListItemText>
          </ListItem>
        )}
      {/* {loginHelper.getCurrentHive() && loginHelper.userHasRole("Languages") && <ListItem className={classes.gutters} button onClick={() => props.requestClose && props.requestClose()} component={Link} to="/languages" >
                <ListItemIcon><Tooltip title={getLanguage("Languages")}><Translate /></Tooltip></ListItemIcon>
                <ListItemText>{getLanguage("Languages")}</ListItemText>
            </ListItem>} */}
      {loginHelper.getCurrentHive() && loginHelper.isAdmin() && (
        <ListItem
          className={classes.gutters}
          button
          onClick={() => props.requestClose && props.requestClose()}
          component={Link}
          to='/hive'
        >
          <ListItemIcon>
            <Tooltip title={getLanguage('Templates')}>
              <SettingsOverscan className={classes.icon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>{getLanguage('Templates')}</ListItemText>
        </ListItem>
      )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('MailTemplateAdministration') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/mailtemplates'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('MailTemplates')}>
                <MailOutline className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('MailTemplates')}</ListItemText>
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('Statistics') && (
          <>
            <ListItem
              className={classes.gutters}
              button
              onClick={() => changeStatisticsOpenStatus()}
            >
              <ListItemIcon>
                <Tooltip title={getLanguage('Statistics')}>
                  <TrendingUp className={classes.icon} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={getLanguage('Statistics')} />
              {statisticsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              className={classes.gutters}
              in={statisticsOpen}
              timeout='auto'
              unmountOnExit
            >
              <List component='div' disablePadding>
                <ListItem
                  className={classes.gutters}
                  button
                  onClick={() => props.requestClose && props.requestClose()}
                  component={Link}
                  to='/statistics/hive'
                >
                  <ListItemIcon>
                    <Tooltip title='Hive'>
                      <GraphicEq className={classes.icon} />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText>Hive</ListItemText>
                </ListItem>
              </List>
              <List component='div' disablePadding>
                <ListItem
                  className={classes.gutters}
                  button
                  onClick={() => props.requestClose && props.requestClose()}
                  component={Link}
                  to='/statistics/csv'
                >
                  <ListItemIcon>
                    <Tooltip title={getLanguage('CsvExport')}>
                      <ImportExport className={classes.icon} />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText>{getLanguage('CsvExport')}</ListItemText>
                </ListItem>
              </List>
              <List component='div' disablePadding>
                <ListItem
                  className={classes.gutters}
                  button
                  onClick={() => props.requestClose && props.requestClose()}
                  component={Link}
                  to='/statistics/whereby'
                >
                  <ListItemIcon>
                    <Tooltip title={getLanguage('Whereby')}>
                      <Stream className={classes.icon} />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText>{getLanguage('Whereby')}</ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('UserAdministration') && (
          <ListItem
            button
            onClick={() => props.requestClose && props.requestClose()}
            className={classes.gutters}
            component={Link}
            to='/hive/administrators'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Users')}>
                <People className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={getLanguage('Users')} />
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('VisitorAdministration') && (
          <ListItem
            button
            onClick={() => props.requestClose && props.requestClose()}
            className={classes.gutters}
            component={Link}
            to='/hive/visitors'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Visitors')}>
                <EventSeat className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={getLanguage('Visitors')} />
          </ListItem>
        )}
      {loginHelper.getCurrentHive() &&
        loginHelper.userHasRole('Administrator') && (
          <ListItem
            className={classes.gutters}
            button
            onClick={() => changeHiveSetupOpenStatus()}
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('HiveSetup')}>
                <Settings className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={getLanguage('HiveSetup')} />
          </ListItem>
        )}

      <Collapse
        className={classes.gutters}
        in={hiveSetupOpen}
        timeout='auto'
        unmountOnExit
      >
        <List component='div' disablePadding>
          {loginHelper.isAdmin() && (
            <ListItem
              button
              onClick={() => props.requestClose && props.requestClose()}
              className={classes.gutters}
              component={Link}
              to='/hive/settings'
            >
              <ListItemIcon>
                <Tooltip title={getLanguage('HiveSettings')}>
                  <SettingsInputComponent className={classes.icon} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={getLanguage('HiveSettings')} />
            </ListItem>
          )}
          {loginHelper.isAdmin() && (
            <ListItem
              className={classes.gutters}
              button
              onClick={() => props.requestClose && props.requestClose()}
              component={Link}
              to='/hive/clientsettings'
            >
              <ListItemIcon>
                <Tooltip title={getLanguage('ClientSettings')}>
                  <Description className={classes.icon} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>{getLanguage('ClientSettings')}</ListItemText>
            </ListItem>
          )}
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/hive/visitorfields'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('VisitorFields')}>
                <Dvr className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('VisitorFields')}</ListItemText>
          </ListItem>
          {loginHelper.isAdmin() && (
            <ListItem
              className={classes.gutters}
              button
              onClick={() => props.requestClose && props.requestClose()}
              component={Link}
              to='/hive/eventdays'
            >
              <ListItemIcon>
                <Tooltip title={getLanguage('EventDays')}>
                  <EventNote className={classes.icon} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>{getLanguage('EventDays')}</ListItemText>
            </ListItem>
          )}
          <ListItem
            className={classes.gutters}
            button
            onClick={() => props.requestClose && props.requestClose()}
            component={Link}
            to='/hive/hiveurls'
          >
            <ListItemIcon>
              <Tooltip title={getLanguage('Urls')}>
                <Public className={classes.icon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText>{getLanguage('Urls')}</ListItemText>
          </ListItem>
          {loginHelper.isAdmin() && (
            <ListItem
              className={classes.gutters}
              button
              onClick={() => props.requestClose && props.requestClose()}
              component={Link}
              to='/hive/clientfiles'
            >
              <ListItemIcon>
                <Tooltip title={getLanguage('ClientFiles')}>
                  <Attachment className={classes.icon} />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>{getLanguage('ClientFiles')}</ListItemText>
            </ListItem>
          )}
        </List>
      </Collapse>
    </List>
  );
};

export default MenuList;
