import { getApiPath } from '../../../core/ServiceRoutes'
import Axios from 'axios'
import { LoginHelper } from '../../../core/LoginHelper'

export class TemplateObjectApi {
  loginHelper = new LoginHelper()

  async addTemplateObject(templateObject) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }
      let response = await Axios.post(
        `${getApiPath()}/templateobjects`,
        templateObject,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateTemplateObject(templateObjectId, templateObject) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/templateobjects/${templateObjectId}`,
        templateObject,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async addTemplateObjectStyle(templateObjectStyle) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }
      let response = await Axios.post(
        `${getApiPath()}/templateobjectstyles`,
        templateObjectStyle,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateTemplateObjectStyle(templateObjectStyleId, templateObjectStyle) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/templateobjectstyles/${templateObjectStyleId}`,
        templateObjectStyle,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async deleteTemplateObject(templateObjectId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.delete(
        `${getApiPath()}/templateobjects/${templateObjectId}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async deleteTemplateObjectStyle(templateObjectStyleId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.delete(
        `${getApiPath()}/templateobjectstyles/${templateObjectStyleId}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async cloneStyles(targetTemplateObjectId, sourceTemplateObjectId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.get(
        `${getApiPath()}/templateobject/${targetTemplateObjectId}/clonestylesfrom/${sourceTemplateObjectId}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }
}
