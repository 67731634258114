import moment from "moment";
import getLanguage from "../../../core/Language";
import { LoginHelper } from "../../../core/LoginHelper";

const loginHelper = new LoginHelper();

export function prepareMessage(contents) {
    if (!contents.startsWith("{SYSTEM:")) return contents;

    switch (contents) {
        case "{SYSTEM:ACCEPTED_INVITATION_TO_CHAT}":
            return getLanguage("JoinedTheChat");
        case "{SYSTEM:DECLINED_INVITATION_TO_CHAT}":
            return getLanguage("DeclinedTheInvitationToJoin");
        case "{SYSTEM:LEFT_CHAT}":
            return getLanguage("LeftTheChat");
        case "{SYSTEM:KICKED_FROM_CHAT}":
            return getLanguage("KickedFromChat");
        case "{SYSTEM:CLOSED_THE_CHAT}":
            return getLanguage("ClosedTheChat");

        default:
            return contents;
    }
}

export function getMessageSnippet(contents, maxLength) {
    contents = prepareMessage(contents);

    if (contents.length > maxLength) {
        return contents.substring(0, maxLength) + "...";
    }

    return contents;
}

export function getParticipantNameForChatNetwork(chatNetwork) {
    if (!chatNetwork) {
        return "";
    }

    let participants = chatNetwork.chatNetworkParticipants.filter(t => !t.kickedAt && t.invitationAccepted);

    // The following two cases are purely fall-back and are unlikely, but not impossible
    if (participants.length === 1) {
        participants = chatNetwork.chatNetworkParticipants.filter(t => t.invitationAccepted);
    }
    if (participants.length === 1) {
        participants = chatNetwork.chatNetworkParticipants;
    }

    if (participants.length === 2) {
        let otherParticipant = participants.find(t => t.loginId !== loginHelper.getUserId());
        return otherParticipant?.login?.fullName;
    }

    let names = "";

    for (const participant of participants.filter(t => t.loginId !== loginHelper.getUserId())) {
        if (names !== "") {
            names += ", ";
        }

        names += participant?.login?.fullName;
    }

    return names;
}

export function sortChatNetworks(chatNetworks) {
    return chatNetworks.sort(function (a, b) {
        let aDate = "";
        if (a.chatNetworkMessages && a.chatNetworkMessages.length > 0) {
            aDate = a.chatNetworkMessages[a.chatNetworkMessages.length - 1].created;
        } else {
            aDate = a.created;
        }

        let bDate = "";
        if (b.chatNetworkMessages && b.chatNetworkMessages.length > 0) {
            bDate = b.chatNetworkMessages[b.chatNetworkMessages.length - 1].created;
        } else {
            bDate = b.created;
        }

        return aDate < bDate ? 1 : -1;
    });
}

export function filterMessagesSentOutsidePeriodOfParticipation(chatNetworks) {
    for (const chatNetwork of chatNetworks.filter(t => !t.pageId)) {
        let messages = [];

        if (chatNetwork.chatNetworkMessages) {
            for (const message of chatNetwork.chatNetworkMessages) {
                var participantMatching = chatNetwork.chatNetworkParticipants.find(t => t.loginId === loginHelper.getUserId() && t.invitationAccepted && t.created < message.created && (!t.kickedAt || t.kickedAt > message.created));
                if (participantMatching) {
                    messages.push(message);
                }
            }
        }

        chatNetwork.chatNetworkMessages = messages;
    }

    return chatNetworks
}

export function getLastActiveOnPageShort(lastActiveDate) {
    let minutes = moment().diff(moment(lastActiveDate), 'minutes');
    if (minutes < 60) {
        return getLanguage("XMinutes").replace("{Minutes}", minutes);
    }
    if (minutes < 1440) {
        return getLanguage("XHours").replace("{Hours}", Math.floor(minutes / 60));
    }
    if (minutes > 1440) {
        return getLanguage("XDays").replace("{Days}", Math.floor(minutes / 1440));
    }
    return minutes;
}

export function convertDateTimesToLocalOfChatNetwork(chatNetwork) {
    if (!chatNetwork || !chatNetwork.created) {
        return chatNetwork;
    }

    var utc = moment.utc(chatNetwork.created).toDate();
    chatNetwork.created = moment(utc).local().format('YYYY-MM-DD HH:mm:ss');

    for (const chatNetworkMessage of chatNetwork.chatNetworkMessages) {
        convertDateTimesToLocalOfChatNetworkMessage(chatNetworkMessage);
    }

    for (const chatNetworkParticipant of chatNetwork.chatNetworkParticipants) {
        convertDateTimesToLocalOfChatNetworkParticipant(chatNetworkParticipant);
    }

    return chatNetwork;
}

export function convertDateTimesToLocalOfChatNetworkMessage(chatNetworkMessage) {
    if (!chatNetworkMessage || !chatNetworkMessage.created) {
        return chatNetworkMessage;
    }

    var utc = moment.utc(chatNetworkMessage.created).toDate();
    chatNetworkMessage.created = moment(utc).local().format('YYYY-MM-DD HH:mm:ss');

    return chatNetworkMessage;
}

export function convertDateTimesToLocalOfChatNetworkParticipant(chatNetworkParticipant) {
    if (!chatNetworkParticipant || !chatNetworkParticipant.created) {
        return chatNetworkParticipant;
    }

    var utc = moment.utc(chatNetworkParticipant.created).toDate();
    chatNetworkParticipant.created = moment(utc).local().format('YYYY-MM-DD HH:mm:ss');

    return chatNetworkParticipant;
}