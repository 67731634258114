import './EditHiveDetails.css'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import React from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import GenericHiveSettingBox from './GenericHiveSettingBox'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const EditHiveDetailsSetup = (props) => {
  const classes = useStyles()

  let contentLibraryMinutesToShowNewUploads = props.updatedHiveSettings.find(
    (t) => t.identifier === 'ContentLibrary.MinutesToShowNewUploads',
  )
  if (!contentLibraryMinutesToShowNewUploads) {
    contentLibraryMinutesToShowNewUploads = props.hiveSettings.find(
      (t) => t.identifier === 'ContentLibrary.MinutesToShowNewUploads',
    )
  }
  let contentLibraryMinutesToShowNewLiveContent = props.updatedHiveSettings.find(
    (t) => t.identifier === 'ContentLibrary.MinutesToShowNewLiveContent',
  )
  if (!contentLibraryMinutesToShowNewLiveContent) {
    contentLibraryMinutesToShowNewLiveContent = props.hiveSettings.find(
      (t) => t.identifier === 'ContentLibrary.MinutesToShowNewLiveContent',
    )
  }

  return (
    <>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.hive.shouldPingActiveUsers}
              onChange={(e) =>
                props.onHiveDataUpdated(
                  'shouldPingActiveUsers',
                  e.target.checked,
                )
              }
            />
          }
          label={getLanguage('KeepTrackOfOnlineUsers')}
          className="checkbox"
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.hive.shouldShowOnlineStatusOfActiveUsers}
              onChange={(e) =>
                props.onHiveDataUpdated(
                  'shouldShowOnlineStatusOfActiveUsers',
                  e.target.checked,
                )
              }
            />
          }
          label={getLanguage('ShowOnlineStatusInVisitorList')}
          className="checkbox"
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.hive.shouldReturnOnlineUsersOfActiveUsers}
              onChange={(e) =>
                props.onHiveDataUpdated(
                  'shouldReturnOnlineUsersOfActiveUsers',
                  e.target.checked,
                )
              }
            />
          }
          label={getLanguage('IncludeOfflineUsersInVisitorList')}
          className="checkbox"
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.hive.debugMode}
              onChange={(e) =>
                props.onHiveDataUpdated('debugMode', e.target.checked)
              }
            />
          }
          label={getLanguage('DebugMode')}
          className="checkbox"
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <TextField
          variant="outlined"
          value={props.hive.preloadImages}
          onChange={(e) =>
            props.onHiveDataUpdated('preloadImages', e.target.value)
          }
          label={getLanguage('PreloadImagesCommaSeparated')}
          className="textField"
        />
      </FormControl>

      <h3>{getLanguage('ContentLibrary')}</h3>
      <GenericHiveSettingBox
        identifier={'ContentLibrary.MinutesToShowNewUploads'}
        title={getLanguage('MinutesToShowNewUploads')}
        defaultValue={120}
        updatedHiveSettings={props.updatedHiveSettings}
        hiveSettings={props.hiveSettings}
        onHiveSettingUpdated={(identifier, val) =>
          props.onHiveSettingUpdated(identifier, val)
        }
        datatype={'number'}
      />
      <GenericHiveSettingBox
        identifier={'ContentLibrary.MinutesToShowNewLiveContent'}
        title={getLanguage('MinutesToShowNewLiveContent')}
        defaultValue={120}
        updatedHiveSettings={props.updatedHiveSettings}
        hiveSettings={props.hiveSettings}
        onHiveSettingUpdated={(identifier, val) =>
          props.onHiveSettingUpdated(identifier, val)
        }
        datatype={'number'}
      />
    </>
  )
}

export default EditHiveDetailsSetup
