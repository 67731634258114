import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from "axios";
import { LoginHelper } from "../../../core/LoginHelper";

export class CsvApi {
  loginHelper = new LoginHelper();

  async uploadPagesAndLoginsCsv(file, hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let formData = new FormData();
      formData.append("fileContent", file);

      let response = await Axios.post(
        `${getApiPath()}/hive/${
          hiveId ?? this.loginHelper.getCurrentHive().id
        }/csvWithPagesAndLogins`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async uploadVisitorsCsv(file, hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };

      let formData = new FormData();
      formData.append("fileContent", file);

      let response = await Axios.post(
        `${getApiPath()}/visitors/csvimport`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }
}
