import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { ToastsStore } from 'react-toasts'
import Loading from 'react-fullscreen-loading'
import { Button, Container, Paper, Tooltip } from '@material-ui/core'
import { GlobalDialog } from '../../../core/GlobalDialog'
import { makeStyles } from '@material-ui/core/styles'
import orderBy from 'lodash/orderBy'
import DataTable from 'react-data-table-component'
import { Delete } from '@material-ui/icons'
import moment from 'moment'
import { ResourceArchiveService } from '../services/ResourceArchiveService'
import { limitLength } from '../../../core/helpers/StringHelper'
import { useDispatch } from 'react-redux'
import { updatePageTitle } from '../../../actions/Actions'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  formControl: {},
}))

const ResourceArchive_Page = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const [relations, setRelations] = useState([])
  const [keywordToEdit, setKeywordToEdit] = useState(null)
  const [relationToRemove, setRelationToRemove] = useState(null)
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false)

  const resourceArchiveService = new ResourceArchiveService()

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('ResourceArchive')))

    getRelationInfo()
    // eslint-disable-next-line
  }, [])

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: getLanguage('pageResourceRelation'),
      selector: (item) => item.pageResourceRelation?.name,
      sortable: true,
      cell: (row) => row.pageResourceRelation?.name,
      width: '200px',
    },
    {
      name: getLanguage('eventResourceRelation'),
      selector: (item) => item.eventResourceRelation?.name,
      sortable: true,
      cell: (row) => limitLength(row.eventResourceRelation?.name, 30, '...'),
    },
    {
      name: getLanguage('embed'),
      selector: row => row.embed,
      sortable: true,
      cell: (row) => limitLength(row.embed, 30, '...'),
    },
    {
      name: getLanguage('ActiveFrom'),
      selector: row => row.activeFrom,
      sortable: true,
      cell: (row) => (
        <label>{moment(row.activeFrom).format('DD.MM.YYYY hh:mm')}</label>
      ),
      width: '180px',
    },
    {
      name: getLanguage('Options'),
      minWidth: '170px',
      cell: (row) => (
        <div>
          {/* <Tooltip title={getLanguage("Edit")}><Button variant="contained" color="primary" onClick={() => setKeywordToEdit(row)}><Edit /></Button></Tooltip>
                {" "} */}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setRelationToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
      width: '150px',
    },
  ]

  const getRelationInfo = async () => {
    setIsLoading(true)

    let response = await resourceArchiveService.getResourceRelations()

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      setRelations(response)
      setIsLoading(false)
    }
  }

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false)
  }

  const removeRelations = async () => {
    setIsLoading(true)

    let response = await resourceArchiveService.deleteResourceAndRelations(
      relationToRemove.id,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    setRelationToRemove(null)
    getRelationInfo()
  }

  // const onSave = async (obj) => {
  //     setIsLoading(true);

  //     if (keywordToEdit) {
  //         let response = await keywordService.updateKeyword(keywordToEdit.id, obj);

  //         if (response.isAxiosError) {
  //             ToastsStore.error(`${getLanguage("SomethingWentWrong")} (${getLanguage("DeveloperInfo")}: ${response.response.status} - ${response.response.statusText} + ")`);
  //             setIsLoading(false);
  //             return;
  //         }
  //     } else {
  //         obj.hiveId = loginHelper.getCurrentHive().id;
  //         let response = await keywordService.addKeyword(obj);

  //         if (response.isAxiosError) {
  //             ToastsStore.error(`${getLanguage("SomethingWentWrong")} (${getLanguage("DeveloperInfo")}: ${response.response.status} - ${response.response.statusText} + ")`);
  //             setIsLoading(false);
  //             return;
  //         }
  //     }

  //     setCreateNewIsOpen(false);
  //     setKeywordToEdit(null);
  //     getRelationInfo();
  // }

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <Paper className={classes.paper}>
        {relations && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={relations}
            pagination={true}
            selectableRows={false}
            sortFunction={customSort}
            defaultSortField={'embed'}
            subHeader={true}
            // subHeaderComponent={
            //     <div><Button variant="contained" color="primary" variant="contained" onClick={() => setCreateNewIsOpen(true)} ><Add /> {getLanguage("CreateNew")} </Button></div>}
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('CreateKeyword')}
        type="Drawer"
        sizeclass="L"
      >
        {/* <Container>
                    {createNewIsOpen &&
                        <EditKeywordDetails onSave={(obj) => onSave(obj)} />
                    }
                </Container> */}
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={keywordToEdit !== null}
        handleDialogClose={() => setKeywordToEdit(null)}
        title={getLanguage('EditKeyword')}
        type="Drawer"
        sizeclass="L"
      >
        {/* <Container>
                    {keywordToEdit &&
                        <EditKeywordDetails onSave={(obj) => onSave(obj)} keyword={keywordToEdit} />
                    }
                </Container> */}
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={relationToRemove !== null}
        handleDialogClose={() => setRelationToRemove(null)}
        title={getLanguage('RemoveArchive')}
        type="Dialog"
      >
        <Container>
          {getLanguage('RemoveArchive?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => removeRelations()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  )
}

export default ResourceArchive_Page
