import { Button, Tooltip, Dialog, DialogTitle } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import FolderIcon from '@material-ui/icons/Folder';
import { ResourceService } from './../services/ResourceService';
import { fileToDataUrl } from '../../../core/helpers/ImageHelper';
import ImageCrop from '../../../core/components/ImageCrop';

const useStyles = makeStyles((theme) => ({
  iconClose: {
    margin: '10px 10px 10px auto',
    cursor: 'pointer',
  },
  dialogContent: {
    textAlign: 'center',
    margin: '0 20px 10px 20px',
  },
}));

const ImageCropUpload = (props) => {
  const classes = useStyles();
  const resourceService = new ResourceService();
  const [cropOpen, setCropOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState('');
  const [isFileTooSmall, setIsFileTooSmall] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    async function cropDroppedFile() {
      if (!props.droppedFile) return;
      let str = await fileToDataUrl(props.droppedFile);
      openCropping(str);
    }

    cropDroppedFile();
  }, [props.droppedFile]);

  async function openCropping(image) {
    var image2 = new Image();
    image2.src = image;
    image2.onload = function () {
      if (image2.naturalWidth >= 200 || image2.naturalHeight >= 200) {
        setCropOpen(true);
        setImageToCrop(image);
        setIsFileTooSmall(false);
        setIsDialogOpen(false);
      } else {
        setIsFileTooSmall(true);
        setIsDialogOpen(true);
      }
    };
  }

  const onFileChange = async (event) => {
    const file = Array.from(event.target.files)[0];

    if (file && file instanceof Blob) {
      var imageReg = /[\/.](gif|jpg|jpeg|tiff|png)$/i;
      if (imageReg.test(file.type)) {
        let reader = new FileReader();
        reader.onload = (e) => {
          openCropping(e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    }

    event.target.value = null;
  };

  async function closeCropping(image) {
    await setCropOpen(false);
    if (image != null) {
      await imageUpload(image);
    }
  }

  const imageUpload = async (image) => {
    props.fileUploadStarted();

    let result = null;

    if (props.uploadedFor === 'Thumb') {
      result = await resourceService.createResourceThumb(
        props.resourceId,
        image
      );
    } else {
      result = await resourceService.createResource(
        props.uploadedFor,
        image,
        'upload.jpg'
      );
    }

    props.fileUploadedCallback(result.id, props.id);
  };

  return (
    <>
      <Tooltip title="image needs to be minimum 200px x 200px">
        <HelpOutlineIcon />
      </Tooltip>
      <input
        id={'fileUpload' + props.uniqueKey}
        type="file"
        onChange={onFileChange}
        hidden
        accept={props.acceptFileTypes}
      />
      <Tooltip title="File explorer">
        <label htmlFor={'fileUpload' + props.uniqueKey}>
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            component="span"
          >
            <FolderIcon /> {getLanguage('SelectFile')}
          </Button>
        </label>
      </Tooltip>

      {cropOpen ? (
        <ImageCrop
          open={cropOpen}
          close={closeCropping}
          image={imageToCrop}
          cropAspectRatio={props.cropAspectRatio}
        />
      ) : null}
      {isFileTooSmall && (
        <Dialog open={isDialogOpen} onClose={!isDialogOpen}>
          <CloseIcon
            onClick={() => setIsDialogOpen(false)}
            className={classes.iconClose}
          />
          <DialogTitle className={classes.dialogContent}>
            image needs to be minimum 200px x 200px
          </DialogTitle>
        </Dialog>
      )}
    </>
  );
};

export default ImageCropUpload;
