import './EditHiveDetails.css'
import { FormControl, TextField } from '@material-ui/core'
import React from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import { LoginHelper } from '../../../core/LoginHelper'
import GenericHiveSettingBox from './GenericHiveSettingBox'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const EditHiveDetailsIntegrations = (props) => {
  const classes = useStyles()
  const loginHelper = new LoginHelper()

  // Find value of GoogleTagManager.ApiKey
  let existingGtmValue = props.updatedHiveSettings.find(
    (t) => t.identifier === 'GoogleTagManager.ApiKey',
  )
  if (!existingGtmValue) {
    existingGtmValue = props.hiveSettings.find(
      (t) => t.identifier === 'GoogleTagManager.ApiKey',
    )
  }

  return (
    <>
      {(loginHelper.isModuleActivatedForCurrentHive('Meetings') ||
        props.hive.moduleActivations.find((t) => t.module === 'Meetings')) && (
        <>
          <h3>{getLanguage('ZoomMeetings')}</h3>
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <TextField
              variant="outlined"
              value={props.hive.zoomApiKey}
              onChange={(e) =>
                props.onHiveDataUpdated('zoomApiKey', e.target.value)
              }
              label={getLanguage('ZoomApiKey')}
              className="textField"
            />
          </FormControl>
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <TextField
              variant="outlined"
              value={props.hive.zoomApiSecret}
              onChange={(e) =>
                props.onHiveDataUpdated('zoomApiSecret', e.target.value)
              }
              label={getLanguage('ZoomApiSecret')}
              className="textField"
            />
          </FormControl>
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <TextField
              variant="outlined"
              value={props.hive.zoomAuthToken}
              onChange={(e) =>
                props.onHiveDataUpdated('zoomAuthToken', e.target.value)
              }
              label={getLanguage('ZoomJwtToken')}
              className="textField"
            />
          </FormControl>
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <TextField
              variant="outlined"
              type="number"
              value={props.hive.zoomMaxSimultaneousMeetingsPerLicense}
              onChange={(e) =>
                props.onHiveDataUpdated(
                  'zoomMaxSimultaneousMeetingsPerLicense',
                  e.target.value,
                )
              }
              label={getLanguage('ZoomMaxSimultaneousMeetingsPerLicense')}
              className="textField"
            />
          </FormControl>
        </>
      )}

      <h3>{getLanguage('GoogleTagManager')}</h3>
      <GenericHiveSettingBox
        identifier={'GoogleTagManager.ApiKey'}
        title={getLanguage('GoogleTagManagerApiKey')}
        defaultValue={''}
        updatedHiveSettings={props.updatedHiveSettings}
        hiveSettings={props.hiveSettings}
        onHiveSettingUpdated={(identifier, val) =>
          props.onHiveSettingUpdated(identifier, val)
        }
        datatype={'text'}
      />
    </>
  )
}

export default EditHiveDetailsIntegrations
