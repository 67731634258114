import './VisualPageEditor.css';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Edit, OpenWith } from '@material-ui/icons';
import { ResourceService } from '../../resource/services/ResourceService';
import { useTimer } from 'react-timer-hook';
import Draggable from 'react-draggable';

const VisualPageEditor = forwardRef((props, ref) => {
  const resourceService = new ResourceService();

  const editorStyles = {
    editState: {
      outline: '2px solid #ffeb3b',
      background: '',
    },
    incompleteState: {
      outline: '2px solid #FF4444',
      background: '#33ff00',
    },
  };

  const [mouseOverId, setMouseOverId] = useState('');
  const [originalX, setOriginalX] = useState(null);
  const [originalY, setOriginalY] = useState(null);
  const [pageObjectIdBeingMovable, setPageObjectIdBeingMovable] = useState('');
  const [deltaPosition, setDeltaPosition] = useState({});
  const { pause, restart } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => setMouseOverId(''),
  });

  useImperativeHandle(ref, () => ({
    stopMovement() {
      props.objectMoved(
        pageObjectIdBeingMovable,
        originalX,
        originalY,
        deltaPosition.x,
        deltaPosition.y
      );
      setPageObjectIdBeingMovable(null);
    },
    cancelMovement() {
      setPageObjectIdBeingMovable(null);
    },
    activateEditorOfGroupTitle(groupTitle) {
      clickedEditGroupTitle(groupTitle);
    },
  }));

  const clickedEditGroupTitle = (groupTitle) => {
    if (pageObjectIdBeingMovable) return;
    props.onEditGroup(groupTitle);
    setPageObjectIdBeingMovable('');
  };

  const groupHasNoMedia = (pageObjects) => {
    return (
      pageObjects.find(
        (t) =>
          t.data &&
          (t.templateObject.kind === 'Image' ||
            t.templateObject.kind === 'Video' ||
            t.templateObject.kind === 'Html')
      ) == null
    );
  };

  const startTimerToRemoveEditBar = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 1);
    restart(time);
  };

  const clickedMoveObject = (pageObject, templateObject) => {
    if (pageObjectIdBeingMovable) return;

    setPageObjectIdBeingMovable(pageObject.id);
    props.onEditGroup('');

    // Calculate new positions
    let top = templateObject.templateObjectStyles.find(
      (t) => t.styleKey === 'top'
    );
    let left = templateObject.templateObjectStyles.find(
      (t) => t.styleKey === 'left'
    );

    if (
      pageObject.customStyles &&
      pageObject.customStyles.top &&
      pageObject.customStyles.left
    ) {
      setOriginalY(parseInt(pageObject.customStyles.top.replace('%', '')));
      setOriginalX(parseInt(pageObject.customStyles.left.replace('%', '')));
    } else {
      setOriginalY(parseInt(top.styleValue.replace('%', '')));
      setOriginalX(parseInt(left.styleValue.replace('%', '')));
    }

    setDeltaPosition({ x: 0, y: 0 });

    props.startedMovingObject(pageObject);
  };

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;

    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  return (
    <>
      <div className={'scaffold'}>
        <div
          style={{
            width: '100%',
            height: '100%',
            padding: '0px',
            position: 'absolute',
            top: '0',
            left: '0',
            overflow: 'hidden',
          }}
        >
          <img
            src={props.page.template.backgroundUrl}
            alt=''
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '100%',
              height: '100%',
            }}
          />
          {props.page.template.templateObjects.map((templateObject) => {
            if (
              props.groupTitleForFullscreenBackground &&
              templateObject.groupTitle ===
                props.groupTitleForFullscreenBackground
            ) {
              return <></>;
            }
            if (templateObject.deleted != null) {
              return <></>;
            }

            // Default styles for all objects
            let styles = {
              position: 'absolute',
            };
            let movableStyles = {
              outline: editorStyles.editState.outline,
            };

            // Add all styles from templateobject
            for (const style of templateObject.templateObjectStyles) {
              style.styleKey = style.styleKey.replace(/-(.)/g, (m, p) =>
                p.toUpperCase()
              );
              styles[style.styleKey] = style.styleValue;

              if (style.styleKey === 'top') {
                movableStyles['top'] = style.styleValue;
              }
              if (style.styleKey === 'left') {
                movableStyles['left'] = style.styleValue;
              }
            }

            let pageObject = props.page.pageObjects.find(
              (t) => t.templateObjectId === templateObject.id
            );

            // Override top/left if exists in customStyles of pageObject
            if (
              pageObject &&
              pageObject.customStyles &&
              pageObject.customStyles.top &&
              pageObject.customStyles.left
            ) {
              styles['top'] = pageObject.customStyles.top;
              styles['left'] = pageObject.customStyles.left;
              movableStyles['top'] = pageObject.customStyles.top;
              movableStyles['left'] = pageObject.customStyles.left;
            }

            let image = '';
            let video = '';
            let html = '';
            let text = '';
            let isButton = false;

            // Manually position editbar if this is a button
            if (
              templateObject.kind === 'FunctionalButton' ||
              templateObject.kind === 'MailButton' ||
              templateObject.kind === 'Button'
            ) {
              styles['width'] = '70px';
              styles['height'] = '15px';
              styles['z-index'] = '200';
              movableStyles['z-index'] = '200';
              isButton = true;

              // Ensure min. z-index if none provided
              if (!styles['z-index']) {
                styles['z-index'] = '10';
                movableStyles['z-index'] = '10';
              }

              if (mouseOverId && mouseOverId === templateObject.id) {
                styles['z-index'] = '1000';
              }
            }

            // Place data in proper variables, if any
            if (pageObject) {
              if (pageObject.resourceId) {
                if (templateObject.kind === 'Image') {
                  image = resourceService.getResourceLink(
                    pageObject.resourceId
                  );
                }
                if (templateObject.kind === 'Video') {
                  video = resourceService.getResourceLink(
                    pageObject.resourceId
                  );
                }
              }

              if (pageObject && pageObject.data) {
                if (templateObject.kind === 'Image') {
                  image = pageObject.data;
                }
                if (templateObject.kind === 'Video') {
                  video = pageObject.data;
                }
                if (templateObject.kind === 'Html') {
                  html = pageObject.data;
                  styles['background'] = 'none';
                }
              }

              if (
                pageObject &&
                !pageObject.data &&
                templateObject.kind === 'Video' &&
                pageObject.resource?.embed
              ) {
                html = pageObject.resource.embed;
                video = null;
              }

              if (pageObject && pageObject.text) {
                text = pageObject.text;
              }
            }

            // Is object yet to be set up
            if (!image && !video && !html && !isButton) {
              // If another object in same group, except for buttons, already have data, don't show anything for current object
              if (
                !groupHasNoMedia(
                  props.page.pageObjects.filter(
                    (t) =>
                      t.templateObject.groupTitle === templateObject.groupTitle
                  )
                )
              ) {
                return <></>;
              }

              // Add styling to mark as incomplete
              styles['outline'] = editorStyles.incompleteState.outline;
              styles['background'] = editorStyles.incompleteState.background;
            }

            // Add border around element if its group is currently being edited
            if (props.currentlyEditingGroup === templateObject.groupTitle) {
              styles['outline'] = editorStyles.editState.outline;
            }

            let isEmpty = !image && !video && !html && !isButton;

            return (
              <>
                {!(
                  pageObjectIdBeingMovable &&
                  pageObjectIdBeingMovable === pageObject.id
                ) && (
                  <div
                    style={styles}
                    onMouseOver={() => {
                      setMouseOverId(templateObject.id);
                      pause();
                    }}
                    onMouseOut={startTimerToRemoveEditBar}
                    className={
                      isEmpty ? 'objectScaffoldEmpty' : 'objectScaffold'
                    }
                  >
                    {!pageObjectIdBeingMovable &&
                      mouseOverId === templateObject.id && (
                        <div
                          className={'editBar'}
                          onMouseOver={() => {
                            setMouseOverId(templateObject.id);
                            pause();
                          }}
                          onMouseOut={startTimerToRemoveEditBar}
                        >
                          {isButton && (
                            <div className={'editBarButton'}>
                              <OpenWith
                                onClick={() =>
                                  clickedMoveObject(pageObject, templateObject)
                                }
                              />
                            </div>
                          )}
                          <div
                            className={'editBarButton'}
                            onClick={() =>
                              clickedEditGroupTitle(templateObject.groupTitle)
                            }
                          >
                            <Edit />
                          </div>
                        </div>
                      )}

                    {image && (
                      <img
                        alt=''
                        className={'objectContents'}
                        src={image}
                        onClick={() =>
                          clickedEditGroupTitle(templateObject.groupTitle)
                        }
                      />
                    )}
                    {video && (
                      <video
                        className={'objectContents'}
                        src={video}
                        onClick={() =>
                          clickedEditGroupTitle(templateObject.groupTitle)
                        }
                      />
                    )}
                    {html && (
                      <div
                        className={'objectContents'}
                        dangerouslySetInnerHTML={{ __html: html }}
                        onClick={() =>
                          clickedEditGroupTitle(templateObject.groupTitle)
                        }
                      />
                    )}
                    {isEmpty && (
                      <div
                        className={'objectContentsEmpty'}
                        onClick={() =>
                          clickedEditGroupTitle(templateObject.groupTitle)
                        }
                      ></div>
                    )}

                    {isButton && (
                      <div
                        className={'vhelement vhbuttonscene vhbutton'}
                        onClick={() =>
                          clickedEditGroupTitle(templateObject.groupTitle)
                        }
                      >
                        <div className={'buttonText'}>{text}</div>
                      </div>
                    )}
                  </div>
                )}

                {pageObjectIdBeingMovable &&
                  pageObjectIdBeingMovable === pageObject.id &&
                  isButton && (
                    <Draggable bounds='parent' onDrag={handleDrag}>
                      <div
                        className={'vhelement vhbuttonscene vhbutton'}
                        style={movableStyles}
                      >
                        <div className={'buttonText'}>{text}</div>
                      </div>
                    </Draggable>
                  )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
});

export default VisualPageEditor;
