import { Button, Container, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { EventService } from '../../event/services/EventService';
import { LoadingIndicator } from '../../../core/LoadingIndicator';
import { ToastsStore } from 'react-toasts';
import { HiveService } from '../../hive/services/HiveService';
import EventDayEditor from '../components/EventDayEditor';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const EventDays_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const eventService = new EventService();
  const hiveService = new HiveService();

  const [isLoading, setIsLoading] = useState(true);
  const [eventProgramDays, setEventProgramDays] = useState([]);
  const [eventProgramDayToRemove, setEventProgramDayToRemove] = useState(null);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('EventDays')));
    getEventProgramDays();
    // eslint-disable-next-line
  }, []);

  const getEventProgramDays = async () => {
    let response = await eventService.getEventProgramDays();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = orderBy(response, ['eventDate'], ['asc']);

      setEventProgramDays(response);
      setIsLoading(false);
    }
  };

  const addEventProgramDay = async (obj) => {
    setIsLoading(true);

    let response = await eventService.addEventProgramDay(obj);

    if (response.isAxiosError) {
      if (response.response?.data === 'DateExists') {
        ToastsStore.error(`${getLanguage('DateAlreadyExists')}`);
      } else {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      }

      setIsLoading(false);
    } else {
      await hiveService.clearJsonCache();
      getEventProgramDays();
    }
  };

  const updateEventProgramDay = async (id, obj) => {
    let response = await eventService.updateEventProgramDay(id, obj);

    if (response.isAxiosError) {
      if (response.response?.data === 'DateExists') {
        ToastsStore.error(`${getLanguage('DateAlreadyExists')}`);
      } else {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      }
    }
    await hiveService.clearJsonCache();
    getEventProgramDays();
  };

  const deleteEventProgramDay = async () => {
    let response = await eventService.deleteEventProgramDay(
      eventProgramDayToRemove.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      await hiveService.clearJsonCache();
      getEventProgramDays();
    }

    setEventProgramDayToRemove(null);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.paper}>
      <Grid container>
        <Grid>
          <h3>{getLanguage('EditProgramDays')}</h3>
        </Grid>
      </Grid>
      {eventProgramDays.map((eventProgramDay) => {
        return (
          <EventDayEditor
            eventProgramDay={eventProgramDay}
            onSave={(obj) => updateEventProgramDay(eventProgramDay.id, obj)}
            onDelete={(obj) => setEventProgramDayToRemove(obj)}
          />
        );
      })}

      <b>{getLanguage('AddEventDay')}</b>
      <EventDayEditor
        onSave={(obj) => addEventProgramDay(obj)}
        onDelete={(obj) => deleteEventProgramDay(obj)}
      />

      <GlobalDialog
        isDialogOpen={eventProgramDayToRemove !== null}
        handleDialogClose={() => setEventProgramDayToRemove(null)}
        title={getLanguage('DeleteEventDay')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisDay?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => deleteEventProgramDay()}
          >
            {getLanguage('Delete')}
          </Button>
        </Container>
      </GlobalDialog>
    </div>
  );
};

export default EventDays_Page;
