import './MailTemplateEditor.css'
import { Button, FormControl, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import { Editor } from '@tinymce/tinymce-react'
import { ResourceService } from '../../resource/services/ResourceService'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    height: '50px',
  },
}))

const MailTemplateEditor = (props) => {
  const classes = useStyles()

  const [topic, setTopic] = useState('')
  const [topicError, setTopicError] = useState('')
  const [htmlContents, setHtmlContents] = useState('')
  const [description, setDescription] = useState('')

  const resourceService = new ResourceService()

  useEffect(() => {
    setTopic(props.topic)
    setHtmlContents(props.htmlContents)
    setDescription(props.description)
    // eslint-disable-next-line
  }, [])

  const onSaveClicked = async () => {
    var validationError = false

    // Validate topic length
    if (!topic) {
      setTopicError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else {
      setTopicError('')
    }

    if (validationError) {
      return
    }

    props.onSave(
      {
        topic,
        htmlContents,
        description,
      },
      null,
    )
  }

  const handleEditorChange = (content, editor) => {
    setHtmlContents(content)
  }

  const getImagesList = () => {
    let imagesList = []

    if (!props.resources) return imagesList

    for (const resource of props.resources) {
      imagesList.push({
        title: resource.fileName,
        value: resourceService.getResourceLink(resource.id),
      })
    }

    return imagesList
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSaveClicked()
        }}
      >
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            variant="outlined"
            value={topic}
            error={topicError}
            helperText={topicError}
            onChange={(e) => {
              setTopic(e.target.value)
              setTopicError('')
            }}
            label={getLanguage('Topic')}
            className="textField"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <Editor
            apiKey="28hyr9gnobmogsua4bqzk4ouufkapzimhfgm71qkgra1yjxl"
            value={htmlContents}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | image link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat code',
              image_list: getImagesList(),
            }}
            onEditorChange={handleEditorChange}
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          {getLanguage('Save')}
        </Button>
        <br />
        <br />
      </form>
    </>
  )
}

export default MailTemplateEditor
