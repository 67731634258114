import './EditKeywordGroupDetails.css'
import { Button, FormControl, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const EditKeywordGroupDetails = (props) => {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  useEffect(() => {
    if (props.keywordgroup) {
      setName(props.keywordgroup.name)
    }
    // eslint-disable-next-line
  }, [])

  const onSaveClicked = async () => {
    var validationError = false

    // Validate name not empty
    if (!name) {
      setNameError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else {
      setNameError('')
    }

    if (validationError) {
      return
    }

    props.onSave({
      name,
    })
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSaveClicked()
        }}
      >
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            variant="outlined"
            error={nameError}
            helperText={nameError}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              setNameError('')
            }}
            label={getLanguage('Name')}
            className="textField"
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          {getLanguage('Save')}
        </Button>
      </form>
    </>
  )
}

export default EditKeywordGroupDetails
