import { LoginHelper } from "../core/LoginHelper";

export const UPDATE_PAGETITLE_REQUEST = "UPDATE_PAGETITLE_REQUEST";
export const UPDATE_PAGETITLE_SUCCESS = "UPDATE_PAGETITLE_SUCCESS";
export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const UPDATE_MESSAGE_LOG = "UPDATE_MESSAGE_LOG";
export const CLEAR_ERROR_REQUEST = "CLEAR_ERROR_REQUEST";
export const CLEAR_ERROR_SUCCESS = "CLEAR_ERROR_SUCCESS";
export const UPDATE_ACTIVE_CHATNETWORKID_REQUEST =
  "UPDATE_ACTIVE_CHATNETWORKID_REQUEST";
export const UPDATE_ACTIVE_CHATNETWORKID_SUCCESS =
  "UPDATE_ACTIVE_CHATNETWORKID_SUCCESS";
export const GET_MATRIXCLIENT = "GET_MATRIXCLIENT";
export const GET_ROOMINFORMATION = "GET_ROOMINFORMATION";
export const UPDATE_CHATLIST = "UPDATE_CHATLIST";
export const GET_NEWMESSAGENOTIFICATION = "GET_NEWMESSAGENOTIFICATION";
export const REMOVE_NEWMESSAGENOTIFICATION = "REMOVE_NEWMESSAGENOTIFICATION";
export const GET_ACTIVEGUESTROOMID = "GET_ACTIVEGUESTROOMID";
export const GET_ONLINEVISITORS = "GET_ONLINEVISITORS";
export const GET_MEETING_GROUP_ID = "GET_MEETING_GROUP_ID";
export const SCENE_ENTERED = "SCENE_ENTERED";
export const SCENE_LEFT = "SCENE_LEFT";


export function updatePageTitleRequest(title) {
  return {
    type: UPDATE_PAGETITLE_REQUEST,
    title,
  };
}

export function updatePageTitleSuccess() {
  return {
    type: UPDATE_PAGETITLE_SUCCESS,
  };
}

export function clearErrorRequest(identifier) {
  return {
    type: CLEAR_ERROR_REQUEST,
    identifier,
  };
}

export function clearErrorSuccess() {
  return {
    type: CLEAR_ERROR_SUCCESS,
  };
}

export function updateMessageLog(update) {
  return {
    type: UPDATE_MESSAGE_LOG,
    update,
  };
}

export function updateActiveChatNetworkIdRequest(id) {
  return {
    type: UPDATE_ACTIVE_CHATNETWORKID_REQUEST,
    id,
  };
}

export function matrixClient(client) {
  return {
    type: GET_MATRIXCLIENT,
    client,
  };
}

export function getRoomInformation(room) {
  return {
    type: GET_ROOMINFORMATION,
    room,
  };
}

export function getNewMessageNotification(roomId) {
  return {
    type: GET_NEWMESSAGENOTIFICATION,
    roomId,
  };
}

export function removeNewMessageNotification(roomId) {
  return {
    type: REMOVE_NEWMESSAGENOTIFICATION,
    roomId,
  };
}

export function isChatListUpdateNeeded(isUpdateNeeded) {
  return {
    type: UPDATE_CHATLIST,
    isUpdateNeeded,
  };
}

export function getActiveGuestRoomId(roomId) {
  return {
    type: GET_ACTIVEGUESTROOMID,
    roomId,
  };
}

export function updateActiveChatNetworkIdSuccess() {
  return {
    type: UPDATE_ACTIVE_CHATNETWORKID_SUCCESS,
  };
}

export function getOnlineVisitors(onlineVisitors, sceneName) {
  return {
    type: GET_ONLINEVISITORS,
    onlineVisitors,
    sceneName,
  };
}

export function GetMeetingGroupId(meetingGroupId) {
  return {
    type: GET_MEETING_GROUP_ID,
    meetingGroupId,
  };
}

export const sceneEntered = (message) => {
  const parsedData = JSON.parse(message.data);
  return {
    type: SCENE_ENTERED,
    data: parsedData,
  };
};

export const sceneLeft = (message) => {
  const parsedData = JSON.parse(message.data);
  return {
    type: SCENE_LEFT,
    data: parsedData,
  };
};

export function updatePageTitle(title) {
  return async function (dispatch) {
    dispatch(updatePageTitleRequest(title));
    dispatch(updatePageTitleSuccess());
  };
}

export function clearError(identifier) {
  return async function (dispatch) {
    dispatch(clearErrorRequest(identifier));
    dispatch(clearErrorSuccess());
  };
}

export function updateActiveChatNetworkId(id) {
  return async function (dispatch) {
    dispatch(updateActiveChatNetworkIdRequest(id));
    dispatch(updateActiveChatNetworkIdSuccess());
  };
}
