import './EditTemplateDetails.css'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import React, { useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import orderBy from 'lodash/orderBy'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  helpButon: {
    margin: '13px 0 0 10px',
  },
}))

const CloneStyles = (props) => {
  const classes = useStyles()

  const [selectedTemplateObjectId, setSelectedTemplateObjectId] = useState('')

  const getTemplateObjectOptions = () => {
    let templateObjects = orderBy(
      props.templateObjects,
      ['groupTitle', 'title'],
      'asc',
    )

    return templateObjects.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.groupTitle} - {data.title}
      </MenuItem>
    ))
  }

  const handleTemplateObjectChanged = (e) => {
    setSelectedTemplateObjectId(e.target.value)
  }

  const onSaveClicked = () => {
    if (!selectedTemplateObjectId) {
      return
    }

    props.onSave(selectedTemplateObjectId)
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSaveClicked()
        }}
      >
        <h3>{getLanguage('CloneStylesFromOtherObject')}</h3>
        <div className={classes.selectContainer}>
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel
              className={classes.selectLabel}
              id="templateSelector-label"
            >
              {getLanguage('TemplateObject')}
            </InputLabel>
            <Select
              variant="outlined"
              fullWidth={true}
              value={selectedTemplateObjectId}
              onChange={handleTemplateObjectChanged}
              labelId="templateSelector-label"
              id="templateSelector"
            >
              <MenuItem value={''}>
                <em>{getLanguage('PleaseChoose')}</em>
              </MenuItem>
              {getTemplateObjectOptions()}
            </Select>
          </FormControl>
        </div>
        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          {getLanguage('CloneNow')}
        </Button>
      </form>
      <br />
    </>
  )
}

export default CloneStyles
