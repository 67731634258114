import './StatisticsCsv_Page.css';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { ToastsStore } from 'react-toasts';
import { QueuedActionService } from '../../queuedactions/services/QueuedActionService';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    height: '90vh',
    background: '#fff',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  submitButton: {
    background: '#124238',
    color: '#FFF',
    '&:hover': {
      background: '#195d4f',
    },
  },
}));

const StatisticsCsv_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queuedActionService = new QueuedActionService();

  const [selectedType, setSelectedType] = useState('');
  const [startDate, setStartDate] = useState(
    moment().add('days', -10).format('YYYY-MM-DDT00:00:00')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DDT00:00:00'));

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('StatisticsCsvDownload')));
    // eslint-disable-next-line
  }, []);

  const onOrderClicked = async () => {
    if (!selectedType) {
      ToastsStore.error(`${getLanguage('PleaseChooseATypeOfDataToExport')}`);
      return;
    }

    let response = queuedActionService.postQueuedAction(null, {
      kind: 'TrackingExport',
      queuedActionDatas: [
        {
          identifier: 'Type',
          value: selectedType,
        },
        {
          identifier: 'StartDate',
          value: moment(startDate).format('YYYY-MM-DDT00:00:00'),
        },
        {
          identifier: 'EndDate',
          value: moment(endDate).add('days', 1).format('YYYY-MM-DDT00:00:00'),
        },
      ],
    });

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      return;
    }

    ToastsStore.success(
      `${getLanguage(
        'TheFileIsBeingProcessedAndWillBeSentToYourEmailWhenReady'
      )}`
    );

    setSelectedType('');
  };

  const getTypeOptions = () => {
    return [
      <MenuItem key={'RegisteredUsers'} value={'RegisteredUsers'}>
        Registrated visitors including any last login time
      </MenuItem>,
      <MenuItem key={'AllClicks'} value={'AllClicks'}>
        Visitor interactions
      </MenuItem>,
      <MenuItem key={'ContentLibrary'} value={'ContentLibrary'}>
        Visitor interactions with Content Library
      </MenuItem>,
      <MenuItem key={'Heartbeats'} value={'Heartbeats'}>
        Visitor minutes per page
      </MenuItem>,
      <MenuItem key={'EmailData'} value={'EmailData'}>
        Email data
      </MenuItem>,
    ];
  };

  const handleTypeChanged = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <div className={classes.paper}>
      <p>
        {getLanguage('UseTheFollowingFormToOrderACsvFileWithYourTrackingData.')}
      </p>
      <p>{getLanguage('TheFileWillBeSentToYourEmailUponCompletion.')}</p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onOrderClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            type="datetime-local"
            variant="outlined"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            label={getLanguage('FirstDate')}
            className="textField"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            type="datetime-local"
            variant="outlined"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            label={getLanguage('LastDate(Included)')}
            className="textField"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel className={classes.selectLabel} id="typeSelector-label">
            {getLanguage('TypeOfDataExport')}
          </InputLabel>
          <Select
            variant="outlined"
            fullWidth={true}
            value={selectedType}
            onChange={handleTypeChanged}
            labelId="typeSelector-label"
            id="typeSelector"
          >
            {getTypeOptions()}
          </Select>
        </FormControl>
        <br />
        <br />
        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          className={classes.submitButton}
        >
          {getLanguage('OrderCsv')}
        </Button>
      </form>
    </div>
  );
};

export default StatisticsCsv_Page;
