import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { KeywordGroupService } from '../services/KeywordGroupService';
import { KeywordService } from '../../keyword/services/KeywordService';
import Loading from 'react-fullscreen-loading';
import { Button, Container, Paper, Tooltip } from '@material-ui/core';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import { LoginHelper } from '../../../core/LoginHelper';
import { Add, Delete, Edit } from '@material-ui/icons';
import EditKeywordGroupDetails from '../components/EditKeywordgroupDetails';
import EditKeywordDetails from '../../keyword/components/EditKeywordDetails';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';
import { StorageHelper } from '../../../core/StorageHelper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  test: {
    paddingLeft: '40px',
  },
  helpButton: {
    marginTop: '5px',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const KeywordGroups_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const keywordGroupService = new KeywordGroupService();
  const keywordService = new KeywordService();
  const loginHelper = new LoginHelper();
  const storageHelper = new StorageHelper();

  let savedResultsPerPage = storageHelper.getResultsPerPageForKeywords();

  const [isLoading, setIsLoading] = useState(true);
  const [keywordGroups, setKeywordGroups] = useState([]);
  const [keywordGroupToEdit, setKeywordGroupToEdit] = useState(null);
  const [keywordGroupToRemove, setKeywordGroupToRemove] = useState(null);
  const [keywordToEdit, setKeywordToEdit] = useState(null);
  const [keywordToRemove, setKeywordToRemove] = useState(null);
  const [createNewKeyword, setCreateNewKeyword] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Keywords')));

    getKeywordGroups();
  }, []);

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForKeywords(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage);
  };

  const keywordGroupColumns = [
    {
      name: getLanguage('Name'),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div>{row.name + ' (' + (row.keywords?.length ?? '0') + ')'}</div>
      ),
    },
    {
      name: getLanguage('Options'),
      minWidth: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setKeywordGroupToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('AddNewKeyword')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setCreateNewKeyword({ keywordGroupId: row.id })}
            >
              <Add />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setKeywordGroupToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const keywordColumns = [
    {
      name: getLanguage('Name'),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: getLanguage('Options'),
      minWidth: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setKeywordToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setKeywordToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getKeywordGroups = async () => {
    setIsLoading(true);

    let responseGroups = await keywordGroupService.getKeywordGroups();

    if (responseGroups.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${responseGroups.response.status} - ${
          responseGroups.response.statusText
        } + ")`
      );
      setIsLoading(false);
    } else {
      let responseKeywords = await keywordService.getKeywords();

      if (responseKeywords.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${responseKeywords.response.status} - ${
            responseKeywords.response.statusText
          } + ")`
        );
      } else {
        for (const keyword of responseKeywords) {
          for (const keywordGroup of responseGroups) {
            if (keywordGroup.keywords === undefined) {
              keywordGroup.keywords = [];
            }
            if (keywordGroup.id === keyword.keywordGroupId) {
              keywordGroup.keywords.push(keyword);
            }
          }
        }
      }
      setKeywordGroups(responseGroups);
      setIsLoading(false);
    }
  };

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };

  const removeKeywordGroup = async () => {
    setIsLoading(true);

    let response = await keywordGroupService.deleteKeywordGroup(
      keywordGroupToRemove.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setKeywordGroupToRemove(null);
    getKeywordGroups();
  };

  const removeKeyword = async () => {
    setIsLoading(true);

    let response = await keywordService.deleteKeyword(keywordToRemove.id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setKeywordToRemove(null);
    getKeywordGroups();
  };

  const onSave = async (obj) => {
    setIsLoading(true);

    if (keywordGroupToEdit) {
      let response = await keywordGroupService.updateKeywordGroup(
        keywordGroupToEdit.id,
        obj
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    } else {
      obj.hiveId = loginHelper.getCurrentHive().id;
      let response = await keywordGroupService.addKeywordGroup(obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    }

    setCreateNewIsOpen(false);
    setKeywordGroupToEdit(null);
    getKeywordGroups();
  };

  const onSaveKeyword = async (obj) => {
    setIsLoading(true);

    if (keywordToEdit) {
      let response = await keywordService.updateKeyword(keywordToEdit.id, obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    } else {
      obj.hiveId = loginHelper.getCurrentHive().id;
      let response = await keywordService.addKeyword(obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    }

    setCreateNewIsOpen(false);
    setCreateNewKeyword(null);
    setKeywordToEdit(null);
    getKeywordGroups();
  };

  const ExpanableComponent = ({ data, ...props }) => (
    <DataTable
      className={classes.test}
      noHeader
      columns={keywordColumns}
      data={data.keywords}
      selectableRows={false}
      sortFunction={customSort}
      defaultSortField={'name'}
    />
  );

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <Paper className={classes.paper}>
        {keywordGroups && (
          <DataTable
            expandableRows
            noHeader={true}
            columns={keywordGroupColumns}
            data={keywordGroups}
            pagination={true}
            selectableRows={false}
            sortFunction={customSort}
            defaultSortField={'name'}
            expandableRowsComponent={ExpanableComponent}
            subHeader={true}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationPerPage={currentResultsPerPage}
            subHeaderComponent={
              <div>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => setCreateNewIsOpen(true)}
                >
                  <Add /> {getLanguage('CreateNewGroup')}{' '}
                </Button>
              </div>
            }
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('CreateKeywordGroup')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {createNewIsOpen && (
            <EditKeywordGroupDetails onSave={(obj) => onSave(obj)} />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={keywordGroupToEdit !== null}
        handleDialogClose={() => setKeywordGroupToEdit(null)}
        title={getLanguage('EditKeywordGroup')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {keywordGroupToEdit && (
            <EditKeywordGroupDetails
              onSave={(obj) => onSave(obj)}
              keywordgroup={keywordGroupToEdit}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={createNewKeyword !== null}
        handleDialogClose={() => setCreateNewKeyword(null)}
        title={getLanguage('CreateKeyword')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {createNewKeyword && (
            <EditKeywordDetails
              onSave={(obj) => onSaveKeyword(obj)}
              keyword={createNewKeyword}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={keywordToEdit !== null}
        handleDialogClose={() => setKeywordToEdit(null)}
        title={getLanguage('EditKeyword')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {keywordToEdit && (
            <EditKeywordDetails
              onSave={(obj) => onSaveKeyword(obj)}
              keyword={keywordToEdit}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={keywordGroupToRemove !== null}
        handleDialogClose={() => setKeywordGroupToRemove(null)}
        title={getLanguage('RemoveKeywordGroup')}
        type="Dialog"
      >
        <Container>
          {getLanguage(
            'DoYouReallyWantToRemoveTheKeywordGroupsAndAllItsAssociatedPagesAndEventsAndKeywords?'
          )}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => removeKeywordGroup()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={keywordToRemove !== null}
        handleDialogClose={() => setKeywordToRemove(null)}
        title={getLanguage('RemoveKeyword')}
        type="Dialog"
      >
        <Container>
          {getLanguage(
            'DoYouReallyWantToRemoveTheKeywordAndAllItsAssociatedPagesAndEvents?'
          )}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => removeKeyword()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default KeywordGroups_Page;
