import { Button, Container, Paper, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ToastsStore } from 'react-toasts'
import Loading from 'react-fullscreen-loading'
import getLanguage from '../../../core/Language'
import { GlobalDialog } from '../../../core/GlobalDialog'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash/orderBy'
import { StorageHelper } from '../../../core/StorageHelper'
import { Edit } from '@material-ui/icons'
import { TemplateObjectService } from '../services/TemplateObjectService'
import EditTemplateObjectDetails from './EditTemplateObjectDetails'
import EditTemplateObjectStyles from './EditTemplateObjectStyles'
import CloneStyles from './CloneStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 6,
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  divroot: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 433,
  },
  createButton: {
    margin: '10px 0 10px 0',
  },
  pageTitle: {
    fontSize: '16px',
  },
  pageSubTitle: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
}))

const TemplateObjectsList = (props) => {
  const classes = useStyles()
  const templateObjectService = new TemplateObjectService()
  const storageHelper = new StorageHelper()

  const [isLoading, setIsLoading] = useState(true)
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false)
  const [defaultSortColumn, setDefaultSortColumn] = useState('title')
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc')
  const [templateObjectBeingEdited, setTemplateObjectBeingEdited] = useState(
    null,
  )

  useEffect(() => {
    const preparePage = async () => {
      let sortCol = storageHelper.getTemplateObjectSortingColumn()
      let sortDirection = storageHelper.getTemplateObjectSortingDirection()

      if (sortCol) {
        setDefaultSortColumn(sortCol)
      }
      if (sortDirection) {
        setDefaultSortDirection(sortDirection)
      }

      setIsLoading(false)
    }

    preparePage()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (templateObjectBeingEdited) {
      let obj = props.template.templateObjects.find(
        (t) => t.id === templateObjectBeingEdited.id,
      )
      setTemplateObjectBeingEdited(obj)
    }
  }, [props.template, templateObjectBeingEdited])

  const createNewClicked = () => {
    setCreateNewIsOpen(true)
  }

  const onNewTemplateObjectSave = async (templateObjectObj) => {
    setIsLoading(true)

    let templateObject = await templateObjectService.addTemplateObject(
      templateObjectObj,
    )

    if (templateObject.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${templateObject.response.status} - ${
          templateObject.response.statusText
        } + ")`,
      )
      setIsLoading(false)
    } else {
      setCreateNewIsOpen(false)
      setTemplateObjectBeingEdited(templateObject)
      setIsLoading(false)
      props.requestReloadTemplate()
    }
  }

  const onEditTemplateObjectSave = async (templateObjectObj) => {
    setIsLoading(true)

    let templateObject = await templateObjectService.updateTemplateObject(
      templateObjectBeingEdited.id,
      templateObjectObj,
    )

    if (templateObject.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${templateObject.response.status} - ${
          templateObject.response.statusText
        } + ")`,
      )
      setIsLoading(false)
    } else {
      setTemplateObjectBeingEdited(null)
      setIsLoading(false)
      props.requestReloadTemplate()
    }
  }

  const cloneStyles = async (sourceTemplateObjectId) => {
    setIsLoading(true)

    await templateObjectService.cloneStyles(
      templateObjectBeingEdited.id,
      sourceTemplateObjectId,
    )

    setIsLoading(false)
    props.requestReloadTemplate()
  }

  const requestedCloseCreate = async () => {
    setCreateNewIsOpen(false)
  }

  const requestedCloseEdit = async () => {
    setTemplateObjectBeingEdited(null)
  }

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    storageHelper.setTemplateObjectSortingColumn(field)
    storageHelper.setTemplateObjectSortingDirection(direction)

    return orderBy(rows, handleField, direction)
  }

  const addStyle = async (obj) => {
    setIsLoading(true)
    await templateObjectService.addTemplateObjectStyle(obj)
    setIsLoading(false)
    props.requestReloadTemplate()
  }

  const updateStyle = async (id, obj) => {
    setIsLoading(true)
    await templateObjectService.updateTemplateObjectStyle(id, obj)
    setIsLoading(false)
    props.requestReloadTemplate()
  }

  const deleteStyle = async (id) => {
    setIsLoading(true)
    await templateObjectService.deleteTemplateObjectStyle(id)
    setIsLoading(false)
    props.requestReloadTemplate()
  }

  const columns = [
    {
      name: getLanguage('Title'),
      selector: row => row.title,
      sortable: true,
    },
    {
      name: getLanguage('Type'),
      selector: (row) => row.kind,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return rowA.template.title > rowB.template.title
      },
      cell: (row) => {
        switch (row.kind) {
          case 'Image':
            return getLanguage('Image')
          case 'Button':
            return getLanguage('Button')
          case 'FunctionalButton':
            return getLanguage('FunctionalButton')
          case 'Video':
            return getLanguage('Video')
          case 'Html':
            return getLanguage('Html')
          case 'MailButton':
            return getLanguage('MailButton')
          default:
            return 'N/A'
        }
      },
    },
    {
      name: getLanguage('GroupTitle'),
      selector: row => row.groupTitle,
      sortable: true,
    },
    {
      name: getLanguage('Options'),
      minWidth: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setTemplateObjectBeingEdited(row)}
            >
              <Edit />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}
      <div>
        <Paper className={classes.paper}>
          {props.template && (
            <DataTable
              noHeader={true}
              columns={columns}
              data={props.template.templateObjects}
              pagination={true}
              selectableRows={false}
              sortFunction={customSort}
              defaultSortField={defaultSortColumn}
              defaultSortAsc={defaultSortDirection === 'asc'}
              subHeader={true}
              subHeaderComponent={
                <div>
                  <Button
                    className={classes.createButton}
                    variant="contained"
                    color="primary"
                    onClick={() => createNewClicked()}
                  >
                    <AddIcon /> {getLanguage('CreateNew')}
                  </Button>
                </div>
              }
            />
          )}
        </Paper>
      </div>
      <br />

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestedCloseCreate()}
        title={getLanguage('CreateNew')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          <EditTemplateObjectDetails
            templateId={props.template.id}
            onSave={(templateObjectObj) =>
              onNewTemplateObjectSave(templateObjectObj)
            }
          />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={templateObjectBeingEdited != null}
        handleDialogClose={() => requestedCloseEdit()}
        title={getLanguage('EditTemplateObject')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {templateObjectBeingEdited && (
            <>
              <EditTemplateObjectDetails
                templateObject={templateObjectBeingEdited}
                onSave={(templateObjectObj) =>
                  onEditTemplateObjectSave(templateObjectObj)
                }
              />
              <br />
              <br />
              <EditTemplateObjectStyles
                templateObject={templateObjectBeingEdited}
                addStyle={(obj) => addStyle(obj)}
                updateStyle={(id, obj) => updateStyle(id, obj)}
                deleteStyle={(id) => deleteStyle(id)}
              />
              <CloneStyles
                templateObjects={props.template.templateObjects}
                onSave={(sourceTemplateObjectId) =>
                  cloneStyles(sourceTemplateObjectId)
                }
              />
              <br />
              <br />
            </>
          )}
        </Container>
      </GlobalDialog>
    </>
  )
}

export default TemplateObjectsList
