import { clearFromCache } from '../../../core/ObjectCache'
import { StorageHelper } from '../../../core/StorageHelper'
import { LoginApi } from './LoginApi'
import { ToastsStore } from 'react-toasts'
import getLanguage from './../../../core/Language'
import { LoginHelper } from '../../../core/LoginHelper'

export class LoginService {
  loginApi = new LoginApi()
  storageHelper = new StorageHelper()
  loginHelper = new LoginHelper()

  cacheIdentifierHives = 'hives'
  cacheIdentifierHive = 'hive'
  cacheIdentifierPages = 'pages'

  async createrUser(credentials) {
    var response = await this.loginApi.createrUser(credentials)
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      return false
    } else {
      ToastsStore.success(`${getLanguage('CreatedUser')} :` + credentials.Email)
      return true
    }
  }

  async createUserOrGetIdByEmail(email, fullName) {
    var response = await this.loginApi.createUserOrGetIdByEmail(email, fullName)
    return response
  }

  async updateProfileWithProps(props) {
    var response = await this.loginApi.updateProfileWithProps(props)
    if (!response.isAxiosError) {
      this.storageHelper.setLoginObject(response)
    }
    return response
  }

  async updateProfilePicture(resourceId) {
    var response = await this.loginApi.updateProfilePicture(resourceId)
    if (!response.isAxiosError) {
      this.storageHelper.setLoginObject(response)
    }
    return response
  }

  async addPageToLogin(pageId, loginId) {
    this.clearCache()
    var response = await this.loginApi.addPageToLogin(pageId, loginId)
    return response
  }

  async removePageFromLogin(pageId, loginId) {
    this.clearCache()
    var response = await this.loginApi.removePageFromLogin(pageId, loginId)
    return response
  }

  async resetPassword(email) {
    var response = await this.loginApi.resetPassword(email)
    return response
  }

  async addHiveToLogin(hiveId, loginId) {
    this.clearCache()
    var response = await this.loginApi.addHiveToLogin(hiveId, loginId)
    return response
  }

  async addHiveToLoginFrontend(hiveId, loginId) {
    this.clearCache()
    var response = await this.loginApi.addHiveToLoginFrontend(hiveId, loginId)
    return response
  }

  async removeHiveFromLogin(hiveId, loginId) {
    this.clearCache()
    var response = await this.loginApi.removeHiveFromLogin(hiveId, loginId)
    return response
  }

  async removeHiveFromLoginFrontend(hiveId, loginId) {
    this.clearCache()
    var response = await this.loginApi.removeHiveFromLoginFrontend(
      hiveId,
      loginId,
    )
    return response
  }

  async getAdministrators() {
    var response = await this.loginApi.getAdministrators()
    return response
  }

  async updateLoginAdminStatus(loginId, isAdmin) {
    this.clearCache()
    return await this.loginApi.updateLoginAdminStatus(loginId, isAdmin)
  }

  async consumeResetPasswordToken(token, newPassword) {
    this.clearCache()
    var response = await this.loginApi.consumeResetPasswordToken(
      token,
      newPassword,
    )
    return response
  }

  async postWelcomeMailLoginHive(loginId, hiveId) {
    this.clearCache()
    var response = await this.loginApi.postWelcomeMailLoginHive(loginId, hiveId)
    return response
  }

  async postWelcomeMailLoginHiveFrontend(loginId, hiveId) {
    this.clearCache()
    var response = await this.loginApi.postWelcomeMailLoginHiveFrontend(
      loginId,
      hiveId,
    )
    return response
  }

  async postWelcomeMailLoginPage(loginId, pageId) {
    this.clearCache()
    var response = await this.loginApi.postWelcomeMailLoginPage(loginId, pageId)
    return response
  }

  async postWelcomeMailToAllLoginHiveFrontend(hiveId) {
    this.clearCache()
    var response = await this.loginApi.postWelcomeMailToAllLoginHiveFrontend(
      hiveId,
    )
    return response
  }

  async postWelcomeMailToAllLoginPage() {
    this.clearCache()
    var response = await this.loginApi.postWelcomeMailToAllLoginPage(
      this.loginHelper.getCurrentHive().id,
    )
    return response
  }

  async updateRoleAssignmentsForLogin(roles, loginId, hiveId) {
    var response = await this.loginApi.updateRoleAssignmentsForLogin(
      roles,
      loginId,
      hiveId,
    )
    this.clearCache()
    return response
  }

  clearCache() {
    clearFromCache(this.cacheIdentifierHives)
    clearFromCache(this.cacheIdentifierHive)
    clearFromCache(this.cacheIdentifierPages)
  }
}
