import { MeetingApi } from "./MeetingApi";

export class MeetingService {
  meetingApi = new MeetingApi();

  async getMeeting(id) {
    return await this.meetingApi.getMeeting(id);
  }

  async getMeetingsForHive(hiveId, pagination, size, search) {
    return await this.meetingApi.getMeetingsForHive(
      hiveId,
      pagination,
      size,
      search
    );
  }

  async getMeetingsCsvForHive(hiveId) {
    return await this.meetingApi.getMeetingsCsvForHive(hiveId);
  }

  async getMeetingsForLogin(loginId, hiveId) {
    return await this.meetingApi.getMeetingsForLogin(loginId, hiveId);
  }

  async getNumberForAllMeetings(hiveId, searchQuery) {
    return await this.meetingApi.getNumberForAllMeetings(hiveId, searchQuery);
  }

  async getVisitorsOnSearch(
    hiveId,
    search,
    page,
    resultsPerPage,
    sortColumn,
    sortDirection
  ) {
    return await this.meetingApi.getVisitorsOnSearch(
      hiveId,
      search,
      page,
      resultsPerPage,
      sortColumn,
      sortDirection
    );
  }

  async addMeeting(
    hiveId,
    title,
    startAt,
    endAt,
    startAtUTC,
    endAtUTC,
    pageId,
    description,
    type,
    startUrl,
    externalUrl,
    maxAttendees,
    isPrivate
  ) {
    try {
      const response = await this.meetingApi.addMeeting(
        hiveId,
        title,
        startAt,
        endAt,
        startAtUTC,
        endAtUTC,
        pageId,
        description,
        type,
        startUrl,
        externalUrl,
        maxAttendees,
        isPrivate
      );

      return response;
    } catch (error) {
      console.error("Error in addMeeting:", error);
      throw error; 
    }
  }

  async updateMeeting(
    id,
    title,
    startAt,
    endAt,
    startAtUTC,
    endAtUTC,
    pageId,
    description,
    type,
    startUrl,
    externalUrl,
    maxAttendees,
    isPrivate
  ) {
    return await this.meetingApi.updateMeeting(
      id,
      title,
      startAt,
      endAt,
      startAtUTC,
      endAtUTC,
      pageId,
      description,
      type,
      startUrl,
      externalUrl,
      maxAttendees,
      isPrivate
    );
  }

  async deleteMeeting(id) {
    return await this.meetingApi.deleteMeeting(id);
  }

  async addMinutesToMeeting(id, minutes) {
    return await this.meetingApi.addMinutesToMeeting(id, minutes);
  }

  async addParticipantToMeeting(meetingGroupId, loginId) {
    return await this.meetingApi.addParticipantToMeeting(
      meetingGroupId,
      loginId
    );
  }

  async removeParticipantFromMeeting(meetingGroupId, loginId) {
    return await this.meetingApi.removeParticipantFromMeeting(
      meetingGroupId,
      loginId
    );
  }

  async removeAllParticipantsFromMeeting(meetingGroupId) {
    return await this.meetingApi.removeAllParticipantsFromMeeting(
      meetingGroupId
    );
  }

  async callMeetingParticipant(hiveId, pageId, loginToken, postData) {
    return await this.meetingApi.callMeetingParticipant(
      hiveId,
      pageId,
      loginToken,
      postData
    );
  }
}
