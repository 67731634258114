import './ObjectEditor.css';
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Checkbox,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { LoginHelper } from '../../../core/LoginHelper';
import { makeStyles } from '@material-ui/core/styles';
import { PageService } from '../services/PageService';
import { ToastsStore } from 'react-toasts';
import ResourceUpload from '../../resource/components/ResourceUpload';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { Link } from 'react-router-dom';
import LanguageSelector from '../../language/components/LanguageSelector';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const ObjectButtonEditor = (props) => {
  const classes = useStyles();

  const [text, setText] = useState('');
  const [data, setData] = useState('');
  const [linkInternalPage, setLinkInternalPage] = useState('');
  const [linkType, setLinkType] = useState('external');
  const loginHelper = new LoginHelper();
  const [isPulse, setIsPulse] = useState(false);
  const [allPages, setAllPages] = useState([]);
  const [pages, setPages] = useState([]);
  const [removeIsOpen, setRemoveIsOpen] = useState(false);
  const [showStandsInPageList, setShowStandsInPageList] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const pageService = new PageService();

  useEffect(() => {
    if (
      !loginHelper.userHasRole('PageEditor') &&
      !loginHelper.userHasRole('StandEditor')
    ) {
      setLinkInternalPage('external');
    } else {
      loadPages();
    }

    setText(props.pageObject.text);
    setData(props.pageObject.data);
    setLinkInternalPage(props.pageObject.linkInternalPage);
    setLinkType(
      props.pageObject.linkInternalPage
        ? 'page'
        : props.pageObject.resourceId
        ? 'upload'
        : 'external'
    );
    setTooltipText(props.pageObject.tooltipText);
    if (props.pageObject.customStyles?.isPulsating) {
      setIsPulse(true);
    }
    // eslint-disable-next-line
  }, []);

  const loadPages = async () => {
    if (!loginHelper.getCurrentHive()) {
      return;
    }

    let response = await pageService.getPages(loginHelper.getCurrentHive().id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      // Hide all ExhibitionStands
      setAllPages(response);

      // Check if selected page of object is a stand
      let showStands = false;
      if (props.pageObject.linkInternalPage) {
        let selectedPage = response.find(
          (t) => t.id === props.pageObject.linkInternalPage
        );
        if (selectedPage && selectedPage.template.kind === 'ExhibitionStand') {
          showStands = true;
          setShowStandsInPageList(true);
        }
      }

      filterPages(response, showStands);
    }
  };

  const filterPages = (newPagesList, showStands) => {
    let list = newPagesList ?? allPages;

    if (!showStands) {
      list = list.filter((t) => t.template.kind !== 'ExhibitionStand');
    }

    list = list.sort(function (a, b) {
      return ('' + a.title).localeCompare(b.title);
    });

    setPages(list);
  };

  const getPageOptions = () => {
    return pages.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.title}
      </MenuItem>
    ));
  };

  const handlePageChanged = (event) => {
    setLinkInternalPage(event.target.value);
    attemptUpdateAttribute('linkInternalPage', event.target.value);
  };

  const onFileUploadStarted = () => {
    props.requestSetIsLoading(true);
  };

  const onFileUploaded = async (id) => {
    props.requestSetIsLoading(false);
    attemptUpdateAttribute('resourceId', id);
  };

  const removeResource = async () => {
    props.removeResource();
    setRemoveIsOpen(false);
  };

  const toggleShowStandsInPageList = () => {
    setShowStandsInPageList(!showStandsInPageList);
    filterPages(undefined, !showStandsInPageList);
  };

  const attemptUpdateAttribute = (attribute, newValue) => {
    if (props.updateAttribute) {
      props.updateAttribute(attribute, newValue);
    }
  };

  const handleChange = (event) => {
    setIsPulse(event.target.checked);
    if (
      (event.target.checked &&
        props.pageObject.templateObject.kindString === 'Button') ||
      (event.target.checked &&
        props.pageObject.templateObject.kindString === 'MailButton') ||
      (event.target.checked &&
        props.pageObject.templateObject.kindString === 'FunctionalButton')
    ) {
      attemptUpdateAttribute('customStyles', {
        ...props.pageObject.customStyles,
        isPulsating: true,
      });
    } else {
      attemptUpdateAttribute('customStyles', {
        ...props.pageObject.customStyles,
        isPulsating: false,
      });
    }
  };

  return (
    <>
      <h2>{getLanguage('Button')}</h2>
      <TextField
        variant='outlined'
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          attemptUpdateAttribute('text', e.target.value);
        }}
        label={getLanguage('TitleMaxCharactersForButton')}
        className='textField'
      />
      <LanguageSelector
        pageObject={props.pageObject}
        attemptUpdateAttribute={attemptUpdateAttribute}
        contentType={'hotspot'}
      >
        <TextField
          variant='outlined'
          label={getLanguage('TitleMaxCharactersForButton')}
          className='textField'
        />
      </LanguageSelector>
      <br />
      {props.pageObject.templateObject.tooltipPosition !== 'None' && (
        <>
          <TextField
            multiline={true}
            rows={3}
            variant='outlined'
            value={tooltipText}
            onChange={(e) => {
              setTooltipText(e.target.value);
              attemptUpdateAttribute('tooltipText', e.target.value);
            }}
            label={getLanguage('MouseoverText')}
            className='textField'
          />
          <br />
        </>
      )}
      <br />
      <div>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <RadioGroup
              value={linkType}
              onClick={() => {
                setLinkInternalPage('');
                attemptUpdateAttribute('linkInternalPage', '');
                setLinkType('external');
              }}
            >
              <FormControlLabel
                value='external'
                control={<Radio />}
                label={getLanguage('ExternalUrl')}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={4}>
            <RadioGroup
              color='secondary'
              value={linkType}
              onClick={() => {
                setData('');
                attemptUpdateAttribute('data', '');
                setLinkType('page');
              }}
            >
              <FormControlLabel
                value='page'
                control={<Radio />}
                label={getLanguage('OtherPage')}
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={4}>
            <RadioGroup
              value={linkType}
              onClick={() => {
                setData('');
                attemptUpdateAttribute('data', '');
                setLinkInternalPage('');
                setLinkType('upload');
              }}
            >
              <FormControlLabel
                value='upload'
                control={<Radio />}
                label={getLanguage('UploadFile')}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </div>
      <br />
      {linkType === 'external' && (
        <TextField
          variant='outlined'
          value={data}
          onChange={(e) => {
            setData(e.target.value);
            attemptUpdateAttribute('data', e.target.value);
          }}
          label={getLanguage('Link')}
          className='textField'
        />
      )}
      {linkType === 'page' && (
        <div className={classes.selectContainer}>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <InputLabel className={classes.selectLabel} id='pageSelector-label'>
              {getLanguage('SelectPage')}
            </InputLabel>
            <Select
              variant='outlined'
              fullWidth={true}
              value={linkInternalPage}
              onChange={handlePageChanged}
              labelId='pageSelector-label'
              id='pageSelector'
            >
              <MenuItem value={'00000000-0000-0000-0000-000000000000'}>
                <em>{getLanguage('PleaseChoose')}</em>
              </MenuItem>
              {getPageOptions()}
            </Select>
          </FormControl>
          <br />
          <Link
            color='inherit'
            href='#'
            onClick={(e) => {
              e.preventDefault();
              toggleShowStandsInPageList();
            }}
          >
            {showStandsInPageList
              ? getLanguage('HideStands')
              : getLanguage('ShowStands')}
          </Link>
        </div>
      )}
      {props.pageObject.templateObject.kindString === 'Button' ||
      props.pageObject.templateObject.kindString === 'MailButton' ||
      props.pageObject.templateObject.kindString === 'FunctionalButton' ? (
        <FormControlLabel
          control={<Checkbox checked={isPulse} onChange={handleChange} />}
          label={getLanguage('AddPulse')}
        />
      ) : (
        ''
      )}
      {linkType === 'upload' && (
        <>
          {props.pageObject.resourceId && (
            <>
              <Button
                fullWidth={true}
                variant='contained'
                color='primary'
                onClick={() => setRemoveIsOpen(true)}
              >
                {getLanguage('RemoveUploadedFile')}
              </Button>
              <br />
              <br />
            </>
          )}
          <ResourceUpload
            uploadedFor='ButtonDownload'
            uniqueKey={props.pageObject.id}
            fileUploadStarted={() => onFileUploadStarted()}
            fileUploadedCallback={(id) => onFileUploaded(id)}
          />
        </>
      )}
      <br />
      <GlobalDialog
        isDialogOpen={removeIsOpen}
        handleDialogClose={() => setRemoveIsOpen(false)}
        title={getLanguage('RemoveUploadedFile')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheUploadedFile?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => removeResource()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default ObjectButtonEditor;
