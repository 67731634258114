import moment from "moment";

export function convertDateTimesToLocalOfLoginActivityEntry(login) {
    if (!login || !login.currentPageOnClientLastUpdate) {
        return login;
    }

    var utc = moment.utc(login.currentPageOnClientLastUpdate).toDate();
    login.currentPageOnClientLastUpdate = moment(utc).local().format('YYYY-MM-DD HH:mm:ss');

    return login;
}