import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { limitLength } from '../../../core/helpers/StringHelper';
import { PopupMessageService } from '../services/PopupMessageService';
import Loading from 'react-fullscreen-loading';
import { Button, Container, Paper, Tooltip } from '@material-ui/core';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import { StorageHelper } from '../../../core/StorageHelper';
import { HiveService } from '../../hive/services/HiveService';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import { LoginHelper } from '../../../core/LoginHelper';
import { Add, Delete, Edit } from '@material-ui/icons';
import moment from 'moment';
import EditPopupMessageDetails from '../components/EditPopupMessageDetails';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  inconsistencyInSortingAndPriority: {
    margin: '10px 0 10px 0',
    padding: '10px',
    border: '4px solid #FF4444',
    backgroundColor: '#EEE',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const PopupMessages_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const popupMessageService = new PopupMessageService();
  const storageHelper = new StorageHelper();
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();

  let savedResultsPerPage = storageHelper.getResultsPerPageForPopups();

  const [defaultSortColumn, setDefaultSortColumn] = useState('activateOn');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const [popupMessages, setPopupMessages] = useState([]);
  const [popupMessageToEdit, setPopupMessageToEdit] = useState(null);
  const [hive, setHive] = useState({});
  const [popupMessageToRemove, setPopupMessageToRemove] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('PopupMessages')));

    getPopupMessages();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadHive = async () => {
      let response = await hiveService.getHive(
        loginHelper?.getCurrentHive()?.id,
        true
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        setHive(response);
      }
    };
    loadHive();
  }, []);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForPopups(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage);
  };

  const columns = [
    {
      name: getLanguage('Created'),
      selector: (row) => row.created,
      sortable: true,
      width: '120px',
      cell: (row) => moment(row.created).format('DD/MM'),
    },
    {
      name: getLanguage('Header'),
      selector: (row) => row.header,
      sortable: true,
      cell: (row) => limitLength(row.header, 50, '...'),
    },
    {
      name: getLanguage('Text'),
      selector: (row) => row.text,
      sortable: true,
      cell: (row) => limitLength(row.text, 50, '...'),
    },
    {
      name: getLanguage('ActivateOn'),
      selector: (row) => row.activateOn,
      width: '120px',
      sortable: true,
      cell: (row) =>
        hive.timezone &&
        moment.utc(row.activateOn).tz(hive.timezone).format('DD/MM HH:mm'),
    },
    {
      name: getLanguage('Options'),
      width: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setPopupMessageToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setPopupMessageToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getPopupMessages = async () => {
    setIsLoading(true);

    let response = await popupMessageService.getPopupMessages();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setPopupMessages(response);
      setIsLoading(false);
    }
  };

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };

  const removePopupMessage = async () => {
    setIsLoading(true);

    let response = await popupMessageService.deletePopupMessage(
      popupMessageToRemove.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setPopupMessageToRemove(null);
    getPopupMessages();
  };

  const onSave = async (obj) => {
    setIsLoading(true);

    if (popupMessageToEdit) {
      let response = await popupMessageService.updatePopupMessage(
        popupMessageToEdit.id,
        obj
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    } else {
      obj.hiveId = loginHelper.getCurrentHive().id;
      let response = await popupMessageService.addPopupMessage(obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    }

    setCreateNewIsOpen(false);
    setPopupMessageToEdit(null);
    getPopupMessages();
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}

      <Paper className={classes.paper}>
        {popupMessages && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={popupMessages}
            pagination={true}
            selectableRows={false}
            defaultSortField={defaultSortColumn}
            defaultSortAsc={defaultSortDirection === 'asc'}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationPerPage={currentResultsPerPage}
            subHeader={true}
            subHeaderComponent={
              <div>
                <Button
                  variant='contained'
                  className={classes.button}
                  onClick={() => setCreateNewIsOpen(true)}
                >
                  <Add /> {getLanguage('CreateNew')}{' '}
                </Button>
              </div>
            }
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('CreatePopupMessage')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {createNewIsOpen && (
            <EditPopupMessageDetails
              timeZone={hive.timezone}
              onSave={(obj) => onSave(obj)}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={popupMessageToEdit !== null}
        handleDialogClose={() => setPopupMessageToEdit(null)}
        title={getLanguage('EditPopupMessage')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {popupMessageToEdit && (
            <EditPopupMessageDetails
              timeZone={hive.timezone}
              onSave={(obj) => onSave(obj)}
              popupMessage={popupMessageToEdit}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={popupMessageToRemove !== null}
        handleDialogClose={() => setPopupMessageToRemove(null)}
        title={getLanguage('RemovePopupMessage')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThePopupMessage?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            className={classes.button}
            onClick={() => removePopupMessage()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default PopupMessages_Page;
