import './EditMeetingDetails.css';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import { ToastsStore } from 'react-toasts';
import { MeetingService } from '../../meetings/services/MeetingService';
import { orderBy } from 'lodash';
import DataTable from 'react-data-table-component';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { LoginHelper } from '../../../core/LoginHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditMeetingsForLogin = (props) => {
  const classes = useStyles();
  const meetingService = new MeetingService();
  const loginHelper = new LoginHelper();

  const [meetingsForLogin, setMeetingsForLogin] = useState([]);
  const [allMeetings, setAllMeetings] = useState([]);
  const [allMeetingsFiltered, setAllMeetingsFiltered] = useState([]);
  const [searchMeeting, setSearchMeeting] = useState('');
  const [meetingId, setMeetingId] = useState('');
  const [meetingToRemove, setMeetingToRemove] = useState(null);

  useEffect(() => {
    getMeetings();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!searchMeeting) {
      setAllMeetingsFiltered(allMeetings);
      return;
    }

    setAllMeetingsFiltered(
      allMeetings.filter(
        (t) => t.title.toLowerCase().indexOf(searchMeeting.toLowerCase()) !== -1
      )
    );
    setMeetingId('');
    // eslint-disable-next-line
  }, [searchMeeting]);

  const getMeetings = async () => {
    let response = await meetingService.getMeetingsForLogin(props.login.id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = response.sort(function (a, b) {
        return ('' + a.title).localeCompare(b.title);
      });

      setMeetingsForLogin(response);
    }

    let responseAllMeetings = await meetingService.getMeetingsForHive(
      loginHelper.getCurrentHive().id,
      1,
      10
    );

    if (responseAllMeetings.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${responseAllMeetings.response.status} - ${
          responseAllMeetings.response.statusText
        } + ")`
      );
    } else {
      responseAllMeetings = responseAllMeetings.sort(function (a, b) {
        return ('' + a.title).localeCompare(b.title);
      });

      setAllMeetings(responseAllMeetings);
      setAllMeetingsFiltered(responseAllMeetings);
    }
  };

  const getMeetingOptions = () => {
    return allMeetingsFiltered.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.title}
      </MenuItem>
    ));
  };

  const handleMeetingChanged = (event) => {
    setMeetingId(event.target.value);
  };

  const addLoginToMeeting = async () => {
    if (!meetingId) {
      return;
    }

    var response = await meetingService.addParticipantToMeeting(
      allMeetings.find((t) => t.id === meetingId).meetingGroupId,
      props.login.id,
      false
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      getMeetings();
      setMeetingId('');
      setSearchMeeting('');
    }
  };

  const removeLoginFromMeeting = async () => {
    if (!meetingToRemove) {
      return;
    }

    var response = await meetingService.removeParticipantFromMeeting(
      allMeetings.find((t) => t.id === meetingToRemove.id).meetingGroupId,
      props.login.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      getMeetings();
      setMeetingToRemove(null);
      setSearchMeeting('');
    }
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const columns = [
    {
      name: getLanguage('Title'),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: getLanguage('Options'),
      width: '150px',
      cell: (row) => (
        <div>
          <Button
            variant='contained'
            color='primary'
            onClick={() => setMeetingToRemove(row)}
          >
            {getLanguage('Remove')}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <Typography component='h2' variant='h6'>
          {getLanguage('Meetings')}
        </Typography>
        <DataTable
          noHeader={true}
          columns={columns}
          data={meetingsForLogin}
          pagination={true}
          selectableRows={false}
          sortFunction={customSort}
          defaultSortField={'startAt'}
          defaultSortAsc={'asc'}
          subHeader={false}
        />
      </div>

      <div>
        <p>
          <b>{getLanguage('AddToMeeting')}</b>
        </p>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={searchMeeting}
            onChange={(e) => {
              setSearchMeeting(e.target.value);
            }}
            label={getLanguage('SearchMeetingsByTitle')}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel className={classes.selectLabel} id='pageSelector-label'>
            {getLanguage('SelectMeeting')}
          </InputLabel>
          <Select
            variant='outlined'
            fullWidth={true}
            value={meetingId}
            onChange={handleMeetingChanged}
            labelId='pageSelector-label'
            id='pageSelector'
          >
            {getMeetingOptions()}
          </Select>
        </FormControl>
        <br />
        <br />
        <Button
          type='button'
          onClick={() => addLoginToMeeting()}
          fullWidth={true}
          variant='contained'
          color='primary'
        >
          {getLanguage('AddUserToMeeting')}
        </Button>
      </div>
      <br />

      <GlobalDialog
        isDialogOpen={meetingToRemove !== null}
        handleDialogClose={() => setMeetingToRemove(null)}
        title={getLanguage('RemoveMeetingFromUser?')}
        type='Dialog'
        sizeclass='L'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisMeetingFromTheUser?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => removeLoginFromMeeting()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default EditMeetingsForLogin;
