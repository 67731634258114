import "./Meetings_Page.css";
import React, { useEffect, useState } from "react";
import getLanguage from "../../../core/Language";
import { ToastsStore } from "react-toasts";
import { limitLength } from "../../../core/helpers/StringHelper";
import { MeetingService } from "../services/MeetingService";
import { HiveService } from "../../hive/services/HiveService";
import { useDebounce } from "../../../core/customHooks/useDebounce";
import Pagination from "../../pagination/Pagination";
import Loading from "react-fullscreen-loading";
import {
  Button,
  Container,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { GlobalDialog } from "../../../core/GlobalDialog";
import { makeStyles } from "@material-ui/core/styles";
import orderBy from "lodash/orderBy";
import DataTable from "react-data-table-component";
import { LoginHelper } from "../../../core/LoginHelper";
import { Add, Delete, Edit, GetApp, VideoCall } from "@material-ui/icons";
import moment from "moment";
import EditMeetingDetails from "../components/EditMeetingDetails";
import { saveAs } from "file-saver";
import { StorageHelper } from "../../../core/StorageHelper";
import { useDispatch } from "react-redux";
import { GetMeetingGroupId, updatePageTitle } from "../../../actions/Actions";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
  },
  formControl: {},
}));

function isEmpty(value) {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
}

const Meetings_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const meetingService = new MeetingService();
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();

  const [defaultSortColumn, setDefaultSortColumn] = useState("startAt");
  const [defaultSortDirection, setDefaultSortDirection] = useState("asc");
  const [totalNumberOfMeetings, setTotalNumberOfMeetings] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [hive, setHive] = useState({});
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [meetingToEdit, setMeetingToEdit] = useState(null);
  const [meetingToRemove, setMeetingToRemove] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [displayDownloadCsvPrompt, setDisplayDownloadCsvPrompt] =
    useState(false);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage("Meetings")));

    getMeetings();
    getNumberForAllMeetings();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadHive = async () => {
      let response = await hiveService.getHive(
        loginHelper?.getCurrentHive()?.id,
        true
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage("SomethingWentWrong")} (${getLanguage(
            "DeveloperInfo"
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        setHive(response);
      }
    };
    loadHive();
  }, []);

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const updatePagination = (page) => {
    getMeetings(page, 10, debouncedSearchTerm);
    setCurrentPage(page);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    getMeetings(currentPage, currentRowsPerPage);
  };

  const getMeetings = async (pagination, size, search) => {
    setIsLoading(true);

    let response = await meetingService.getMeetingsForHive(
      loginHelper.getCurrentHive().id,
      pagination,
      size,
      search
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      var participants = [];

      for (const meeting of response) {
        if (meeting.meetingGroup) {
          for (const participant of meeting.meetingGroup.meetingGroupsLogins) {
            participants.push({
              id: meeting.id,
              name: participant.login.fullName,
              email: participant.login.email,
              startAt: meeting.startAt,
              endAt: meeting.endAt,
              title: meeting.title,
              page: meeting.page,
              status: meeting.status,
              externalId: meeting.externalId,
              meeting,
            });
          }
        }
      }

      setFilteredMeetings(response);
      setIsLoading(false);
    }
  };

  const getNumberForAllMeetings = async (searchQuery) => {
    let response = await meetingService.getNumberForAllMeetings(
      loginHelper.getCurrentHive().id,
      searchQuery
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setTotalNumberOfMeetings(response);
    }
  };

  const columns = [
    {
      name: getLanguage("Created"),
      selector: (row) => row.created,
      sortable: true,
      width: "120px",
      cell: (row) => moment(row.created).format("DD/MM"),
    },
    {
      name: getLanguage("StartAt"),
      selector: (row) => row.startAt,
      sortable: true,
      width: "120px",
      cell: (row) =>
        hive.timezone &&
        moment.utc(row.startAt).tz(hive.timezone).format("DD/MM HH:mm"),
    },
    {
      name: getLanguage("Duration"),
      width: "120px",
      cell: (row) => {
        var duration = moment.duration(
          moment(row.endAt).diff(moment(row.startAt))
        );
        return duration.asMinutes() + " " + getLanguage("minutes");
      },
    },
    {
      name: getLanguage("Title"),
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => {
        return row.title ? (
          limitLength(row.title, 60, "...")
        ) : (
          <i>({getLanguage("NoTitle")})</i>
        );
      },
    },
    {
      name: getLanguage("Page"),
      cell: (row) => limitLength(row.page?.title, 15, "..."),
    },
    {
      name: getLanguage("Participants"),
      selector: (row) => row.text,
      sortable: true,
      cell: (row) => {
        var participantsText = "";

        if (row.meetingGroup) {
          for (const participant of row.meetingGroup.meetingGroupsLogins) {
            var text = isEmpty(participant.login.fullName)
              ? participant.login.email
              : participant.login.fullName;

            if (participantsText) {
              participantsText += ", " + text;
            } else {
              participantsText += text;
            }
          }
        }

        return limitLength(participantsText, 120, "...");
      },
    },
    {
      name: getLanguage("Status"),
      selector: (row) => row.status,
      sortable: true,
      width: "120px",
      cell: (row) => {
        switch (row.status) {
          case "Planned":
            return (
              <div className="statusBox planned">{getLanguage("Planned")}</div>
            );
          case "AboutToStart":
            return (
              <div className="statusBox aboutToStart">
                {getLanguage("AboutToStart")}
              </div>
            );
          case "Ended":
            return (
              <div className="statusBox ended">{getLanguage("Ended")}</div>
            );
          case "EndingSoon":
            return (
              <div className="statusBox endingSoon">
                {getLanguage("EndingSoon")}
              </div>
            );
          case "Active":
            return (
              <div className="statusBox active">{getLanguage("Ongoing")}</div>
            );
          case "Scheduling":
            return (
              <div className="statusBox scheduling">
                {getLanguage("Scheduling")}
              </div>
            );
          default:
        }
      },
    },
    {
      name: getLanguage("Options"),
      width: "170px",
      cell: (row) => (
        <div>
          {loginHelper.userHasRole("MeetingAdministration") && (
            <>
              <div className="buttonRow">
                <Tooltip title={getLanguage("Edit")}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setMeetingToEdit(row)}
                  >
                    <Edit />
                  </Button>
                </Tooltip>{" "}
                <Tooltip title={getLanguage("Delete")}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setMeetingToRemove(row)}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
              </div>

              <div className="buttonRow">
                {row.meetingGroup &&
                  (row.type === "Native" || row.type === "Lounge" || row.type === "NativeView") && (
                    <>
                      <Tooltip title={"join as host"}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => window.open(row.startUrl, "_blank")}
                        >
                          <VideoCall />
                        </Button>
                      </Tooltip>
                    </>
                  )}
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };

  const removeMeeting = async () => {
    setIsLoading(true);

    let response = await meetingService.deleteMeeting(meetingToRemove.id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setMeetingToRemove(null);
    getMeetings();
  };

  const onSave = async (
    title,
    startAt,
    endAt,
    startAtUTC,
    endAtUTC,
    pageId,
    description,
    type,
    startUrl,
    externalUrl,
    maxAttendees,
    isPrivate
  ) => {
    setIsLoading(true);

    if (meetingToEdit) {
      let response = await meetingService.updateMeeting(
        meetingToEdit.id,
        title,
        startAt,
        endAt,
        startAtUTC,
        endAtUTC,
        pageId,
        description,
        type,
        startUrl,
        externalUrl,
        maxAttendees,
        isPrivate
      );

      if (response.isAxiosError) {
        if (
          response.response &&
          response.response.data === "UpdateTimesAfterSettingExternalId"
        ) {
          ToastsStore.error(
            `${getLanguage(
              "CantUpdateMeetingTimesTheMeetingIsAlreadyShceduledInZoom"
            )}`
          );
        } else {
          ToastsStore.error(
            `${getLanguage("SomethingWentWrong")} (${getLanguage(
              "DeveloperInfo"
            )}: ${response.response.status} - ${
              response.response.statusText
            } + ")`
          );
        }

        setIsLoading(false);
        return;
      }
    } else {
      let response = await meetingService.addMeeting(
        loginHelper.getCurrentHive().id,
        title,
        startAt,
        endAt,
        startAtUTC,
        endAtUTC,
        pageId,
        description,
        type,
        startUrl,
        externalUrl,
        maxAttendees,
        isPrivate
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage("SomethingWentWrong")} (${getLanguage(
            "DeveloperInfo"
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }

      dispatch(GetMeetingGroupId(response.meetingGroupId));
    }

    setCreateNewIsOpen(false);
    setMeetingToEdit(null);
    setTimeout(() => {
      getMeetings();
    }, 500);
  };

  const downloadParticipantsCsv = async () => {
    setIsLoading(true);
    let file = await meetingService.getMeetingsCsvForHive();

    file = new Blob([file], { type: "text/plain" });

    if (file.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${file.response.status} - ${file.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    saveAs(file, "meeting-participants.csv");
    setIsLoading(false);
    setDisplayDownloadCsvPrompt(false);
  };

  const updateSearchTerm = (newTerm) => {
    setSearchTerm(newTerm);
  };

  useEffect(() => {
    getMeetings(1, 10, debouncedSearchTerm);
    getNumberForAllMeetings(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <Paper className={classes.paper}>
        {filteredMeetings && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={filteredMeetings}
            pagination={false}
            selectableRows={false}
            sortFunction={customSort}
            defaultSortField={defaultSortColumn}
            defaultSortAsc={defaultSortDirection === "asc"}
            subHeader={true}
            onChangeRowsPerPage={onChangeRowsPerPage}
            subHeaderComponent={
              <div>
                <TextField
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => updateSearchTerm(e.target.value)}
                  label={getLanguage("Search")}
                  size="small"
                  className={classes.searchTextField}
                />
                {loginHelper.userHasRole("MeetingAdministration") && (
                  <>
                    {" "}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setCreateNewIsOpen(true)}
                    >
                      <Add /> {getLanguage("CreateNew")}{" "}
                    </Button>
                  </>
                )}{" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDisplayDownloadCsvPrompt(true)}
                >
                  <GetApp /> {getLanguage("CSV")}{" "}
                </Button>
              </div>
            }
          />
        )}
        <Pagination
          currentPage={currentPage}
          totalCount={totalNumberOfMeetings}
          pageSize={10}
          onPageChange={(page) => updatePagination(page)}
        />
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage("CreateMeeting")}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {createNewIsOpen && (
            <EditMeetingDetails
              timeZone={hive.timezone}
              onSave={(
                title,
                start,
                end,
                startAtUTC,
                endAtUTC,
                pageId,
                description,
                type,
                startUrl,
                externalUrl,
                maxAttendees,
                isPrivate
              ) =>
                onSave(
                  title,
                  start,
                  end,
                  startAtUTC,
                  endAtUTC,
                  pageId,
                  description,
                  type,
                  startUrl,
                  externalUrl,
                  maxAttendees,
                  isPrivate
                )
              }
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={meetingToEdit !== null}
        handleDialogClose={() => setMeetingToEdit(null)}
        title={getLanguage("EditMeeting")}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {meetingToEdit && (
            <EditMeetingDetails
              timeZone={hive.timezone}
              onSave={(
                title,
                start,
                end,
                startAtUTC,
                endAtUTC,
                pageId,
                description,
                type,
                startUrl,
                externalUrl,
                maxAttendees,
                isPrivate
              ) =>
                onSave(
                  title,
                  start,
                  end,
                  startAtUTC,
                  endAtUTC,
                  pageId,
                  description,
                  type,
                  startUrl,
                  externalUrl,
                  maxAttendees,
                  isPrivate
                )
              }
              meeting={meetingToEdit}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={meetingToRemove !== null}
        handleDialogClose={() => setMeetingToRemove(null)}
        title={getLanguage("RemoveMeeting")}
        type="Dialog"
      >
        <Container>
          {getLanguage("DoYouReallyWantToRemoveTheMeeting?")}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => removeMeeting()}
          >
            {getLanguage("Remove")}
          </Button>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={displayDownloadCsvPrompt}
        handleDialogClose={() => setDisplayDownloadCsvPrompt(false)}
        title={getLanguage("DownloadCsv")}
        type="Dialog"
      >
        <Container>
          {getLanguage("ExportAllMeetingDataToCsv?")}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => downloadParticipantsCsv()}
          >
            {getLanguage("Download")}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default Meetings_Page;
