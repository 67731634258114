import { HiveTemplatesApi } from './HiveTemplatesApi';

export class HiveTemplatesService {
  hiveTemplatesApi = new HiveTemplatesApi();

  async getHiveTemplates() {
    var result = await this.hiveTemplatesApi.getHiveTemplates();
    return result;
  }
}
