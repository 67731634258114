import { Button, LinearProgress, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as tus from 'tus-js-client';
import getLanguage from '../../../core/Language';
import FolderIcon from '@material-ui/icons/Folder';
import PublishIcon from '@material-ui/icons/Publish';
import { ResourceService } from './../services/ResourceService';
import { ToastsStore } from 'react-toasts';

const VideoUpload = (props) => {
  const resourceService = new ResourceService();

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState(
    getLanguage('UploadingVideo')
  );
  const [arbitraryCountdown, setArbitraryCountdown] = useState(0);
  const [uploadedResource, setUploadedResource] = useState(null);

  const onFileChange = async (evt) => {
    setSelectedFile(evt.target.files);
  };

  useEffect(() => {
    if (!uploadedResource) return;

    if (arbitraryCountdown > 0) {
      setTimeout(function () {
        setArbitraryCountdown(arbitraryCountdown - 1);
        setUploadPercent(100 - arbitraryCountdown);
      }, 100);
    } else {
      setIsUploading(false);
      props.fileUploadedCallback(uploadedResource.resourceId);
      setUploadedResource(null);
    }
    // eslint-disable-next-line
  }, [arbitraryCountdown]);

  const onFileUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);
      setUploadPercent(0);

      let vimeoResource = await resourceService.getVimeoUploadLink(
        props.uploadedfor,
        selectedFile[0].size
      );

      if (!vimeoResource || !vimeoResource.uploadUrl) {
        return;
      }

      setUploadedResource(vimeoResource);

      const upload = new tus.Upload(selectedFile[0], {
        endpoint: 'https://api.vimeo.com/me/videos',
        uploadUrl: vimeoResource.uploadUrl,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: selectedFile[0].name,
          filetype: selectedFile[0].type,
        },
        headers: {},
        onError: function (error) {
          ToastsStore.error(
            `${getLanguage('SomethingWentWrong')} (${getLanguage(
              'DeveloperInfo'
            )}: ${error} + ")`
          );
          setIsUploading(false);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          let percentage = parseInt(
            ((bytesUploaded / bytesTotal) * 100).toFixed(0)
          );
          setUploadPercent(percentage);
        },
        onSuccess: function () {
          // Arbitrary load because Vimeo could otherwise report falsely that the video have completed transcoding
          setUploadingText(getLanguage('ProcessingVideoPleaseHoldOn'));
          setArbitraryCountdown(10);
          setUploadPercent(0);
        },
      });

      upload.start();
    }
  };

  if (isUploading) {
    return (
      <>
        <p>
          <b>{uploadingText}</b>
        </p>
        <LinearProgress
          color="primary"
          value={uploadPercent}
          variant="determinate"
        />
      </>
    );
  }

  return (
    <>
      <input
        id={'fileUpload' + props.uniqueKey}
        type="file"
        onChange={onFileChange}
        hidden
        accept={props.acceptFileTypes}
      />
      <Tooltip title="File explorer">
        <label htmlFor={'fileUpload' + props.uniqueKey}>
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            component="span"
          >
            <FolderIcon /> {getLanguage('SelectFile')}
          </Button>
        </label>
      </Tooltip>{' '}
      <Tooltip title="Selected file">
        <label component="span">
          {selectedFile ? selectedFile[0].name : ''}
        </label>
      </Tooltip>
      <br />
      <br />
      {selectedFile ? (
        <Tooltip title="Upload">
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            component="span"
            onClick={onFileUpload}
          >
            <PublishIcon /> {getLanguage('Upload')}
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
};

export default VideoUpload;
