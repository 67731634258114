import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { Chat } from '@material-ui/icons';
import ChatOverview from '../features/chat/components/ChatOverview';
import { Dialog, DialogContent } from '@material-ui/core';
import useWindowDimensions from './helpers/ScreenSizeHelper';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveChatNetworkId } from '../actions/Actions';
import { LoginHelper } from './LoginHelper';

const ChatButton = () => {
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const loginHelper = new LoginHelper();

  const activeChatNetworkId = useSelector((state) => state.activeChatNetworkId);
  const chatNetworks = useSelector((state) => state.chatNetworks);
  const chatOverlayLastOpened = useSelector(
    (state) => state.chatOverlayLastOpened
  );
  const chatsLastActivity = useSelector((state) => state.chatsLastActivity);
  const loginId = loginHelper.getUserId();

  const [updateNewNotification, setUpdateNewNotification] = useState([]);

  const handleClick = () => {
    dispatch(updateActiveChatNetworkId(''));
  };

  const handleClose = () => {
    dispatch(updateActiveChatNetworkId(null));
  };

  const useRoomInformation = useSelector((state) => state.roomInformation);

  useEffect(() => {
    if (
      document.getElementById('fc_widget') &&
      document.getElementById('fc_widget').style
    ) {
      document.getElementById('fc_widget').style.display =
        activeChatNetworkId === null ? '' : 'none';
    }

    // Attempt to set a default chat network based on last message, if activeChatNetworkId is empty (meaning user manually opened the overview)
    if (activeChatNetworkId === '') {
      if (chatNetworks && chatNetworks.length > 0) {
        dispatch(updateActiveChatNetworkId(chatNetworks[0].id));
      }
    }
  }, [activeChatNetworkId, chatNetworks, dispatch]);

  const useNewMessageNotification = useSelector(
    (state) => state.newMessageNotification
  );

  useEffect(() => {
    setUpdateNewNotification(useNewMessageNotification);
  }, [useNewMessageNotification, useRoomInformation]);

  return (
    <>
      <IconButton color='inherit' onClick={handleClick}>
        <Badge
          color='#FFBB36'
          variant='dot'
          invisible={updateNewNotification.length === 0}
        >
          <Chat />
        </Badge>
      </IconButton>

      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={activeChatNetworkId !== null}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <div style={{ height: height - 100 + 'px', width: '100%' }}>
            <ChatOverview requestClose={handleClose} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { ChatButton };
