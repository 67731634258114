import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { HiveService } from '../services/HiveService';
import { validateEmail } from '../../../core/helpers/StringHelper';
import { LoginService } from '../../login/services/LoginService';
import Loading from 'react-fullscreen-loading';
import {
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import { StorageHelper } from '../../../core/StorageHelper';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import { LoginHelper } from '../../../core/LoginHelper';
import { Add, Delete, Edit } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';
import RoleEditorForUser from '../components/RoleEditorForUser';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const HiveAdministrator_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const hiveService = new HiveService();
  const loginService = new LoginService();
  const storageHelper = new StorageHelper();
  const loginHelper = new LoginHelper();

  let savedResultsPerPage = storageHelper.getResultsPerPageForUsers();

  const [defaultSortColumn, setDefaultSortColumn] = useState('fullName');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const [hive, setHive] = useState(null);
  const [loginToRemove, setLoginToRemove] = useState(null);
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [editingLogin, setEditingLogin] = useState(null);
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Users')));

    let sortCol = storageHelper.getLoginSortingColumn();
    let sortDirection = storageHelper.getLoginSortingDirection();

    if (sortCol) {
      setDefaultSortColumn(sortCol);
    }
    if (sortDirection) {
      setDefaultSortDirection(sortDirection);
    }

    getHive();
    // eslint-disable-next-line
  }, []);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForUsers(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage - 1);
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    storageHelper.setLoginSortingColumn(field);
    storageHelper.setLoginSortingDirection(direction);

    return orderBy(rows, handleField, direction);
  };

  const editExisting = (login) => {
    setEditingLogin(login);
    setEditIsOpen(true);
  };

  const columns = [
    {
      name: getLanguage('FullName'),
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) =>
        !row.fullName ? <em>({getLanguage('NewUser')})</em> : row.fullName,
    },
    {
      name: getLanguage('Email'),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: getLanguage('Roles'),
      cell: (row) => {
        return row.roleAssignments.length;
      },
    },
    {
      name: getLanguage('Options'),
      minWidth: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => editExisting(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => requestedRemoveAdministrator(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getHive = async () => {
    setIsLoading(true);

    let response = await hiveService.getHive(
      loginHelper?.getCurrentHive()?.id,
      true
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setHive(response);
      dispatch(
        updatePageTitle(
          getLanguage('Users') +
            (loginHelper.getCurrentHive().id !== response.id
              ? ' (' + response.title + ')'
              : '')
        )
      );
      setIsLoading(false);
    }
  };

  const addAdministratorClicked = () => {
    setEditIsOpen(true);
  };

  const requestedRemoveAdministrator = async (login) => {
    setLoginToRemove(login);
  };

  const addLoginToHive = async () => {
    if (!editingLogin) {
      var validationError = false;

      // Validate e-mail valid
      if (!validateEmail(email)) {
        setEmailError(getLanguage('ValidationErrorInvalidEmail'));
        validationError = true;
      } else {
        setEmailError('');
      }

      if (validationError) {
        return;
      }

      setIsLoading(true);

      let login = await loginService.createUserOrGetIdByEmail(email, fullName);
      if (login.isAxiosError || !login) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${login.response.status} - ${login.response.statusText} + ")`
        );
        setIsLoading(false);
        return;
      }

      await loginService.addHiveToLogin(hive.id, login.id);

      ToastsStore.success(`${getLanguage('UserAddedSuccessfully')}`);

      setFullName('');
      setEmail('');

      getHive();
    }
  };

  const removeLoginFromHive = async () => {
    setLoginToRemove(null);

    setIsLoading(true);

    await loginService.removeHiveFromLogin(hive.id, loginToRemove.id);

    ToastsStore.success(`${getLanguage('UsersAccessRemovedSuccessfully')}`);

    getHive();
  };

  const requestCloseCreate = () => {
    setEditingLogin(null);
    setEditIsOpen(false);
  };

  const requestedCloseRemoveUser = () => {
    setLoginToRemove(null);
  };

  const onSaveRoles = async (roles) => {
    setIsLoading(true);

    let response = await loginService.updateRoleAssignmentsForLogin(
      roles,
      editingLogin.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setEditingLogin(null);
      setEditIsOpen(false);
      getHive();
    }
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}
      {hive && (
        <>
          <Paper className={classes.paper}>
            {hive.logins && (
              <DataTable
                noHeader={true}
                columns={columns}
                data={hive.logins}
                pagination={true}
                selectableRows={false}
                sortFunction={customSort}
                defaultSortField={defaultSortColumn}
                defaultSortAsc={defaultSortDirection === 'asc'}
                subHeader={true}
                onChangeRowsPerPage={onChangeRowsPerPage}
                paginationPerPage={currentResultsPerPage}
                subHeaderComponent={
                  loginHelper.userHasRole('UserAdministration') && (
                    <div>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => addAdministratorClicked()}
                      >
                        <Add /> {getLanguage('InviteUser')}{' '}
                      </Button>
                    </div>
                  )
                }
              />
            )}
          </Paper>
        </>
      )}

      <GlobalDialog
        isDialogOpen={editIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={
          editingLogin != null
            ? getLanguage('EditUser')
            : getLanguage('InviteUser')
        }
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {editingLogin == null && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addLoginToHive();
              }}
            >
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  variant="outlined"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  label={getLanguage('FullName')}
                  className="textField"
                />
              </FormControl>
              <FormControl fullWidth={true} variant="outlined">
                <TextField
                  variant="outlined"
                  error={emailError}
                  helperText={emailError}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError('');
                  }}
                  label={getLanguage('Email')}
                  className="textField"
                />
              </FormControl>
              <br />
              <br />
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                className={classes.button}
              >
                {getLanguage('InviteUser')}
              </Button>
            </form>
          )}

          {editingLogin != null && (
            <RoleEditorForUser
              login={editingLogin}
              saveRoles={(roles) => onSaveRoles(roles)}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={loginToRemove !== null}
        handleDialogClose={() => requestedCloseRemoveUser()}
        title={getLanguage('RemoveUser')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveAccessForThisUser?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => removeLoginFromHive()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default HiveAdministrator_Page;
