import './EditPopupMessageDetails.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { LoginHelper } from '../../../core/LoginHelper';
import { PageService } from '../../page/services/PageService';
import { ToastsStore } from 'react-toasts';
import ReactTooltip from 'react-tooltip';
import { Help } from '@material-ui/icons';
import { HiveService } from '../../hive/services/HiveService';
import MultiSelector from '../../../core/components/MultiSelector';

const useStyles = makeStyles((theme) => ({
  formControl: { marginTop: theme.spacing(1) },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    height: '50px',
  },
  helpButton: {
    marginTop: '8px',
  },
}));

const EditPopupMessageDetails = (props) => {
  const classes = useStyles();
  const pageService = new PageService();
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();

  const [header, setHeader] = useState('');
  const [headerError, setHeaderError] = useState('');
  const [text, setText] = useState('');
  const [textError, setTextError] = useState('');
  const [activateOn, setActivateOn] = useState(
    moment().format('YYYY-MM-DDTHH:mm')
  );
  const [activateOnUTC, setActivateOnUTC] = useState(
    moment().format('YYYY-MM-DDTHH:mm')
  );
  const [hiveDetails, setHiveDetails] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({
    language: '',
    languageCode: '',
  });
  const [translationValues, setTranslationValues] = useState({});
  const [translations, setTranslations] = useState([]);
  const [closeButtonActive, setCloseButtonActive] = useState(false);
  const [closeButtonText, setCloseButtonText] = useState('');
  const [navigateButtonActive, setNavigateButtonActive] = useState(false);
  const [navigateButtonText, setNavigateButtonText] = useState('');
  const [navigateButtonPageId, setNavigateButtonPageId] = useState(null);
  const [navigateButtonExternalLink, setNavigateButtonExternalLink] =
    useState('');
  const [linkType, setLinkType] = useState('external');
  const [pages, setPages] = useState([]);
  const [pagesWithPopupEnabled, setPagesWithPopupEnabled] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [displayOnAllStands, setDisplayOnAllStands] = useState(false);
  const [textTextContent, setTextTextContent] = useState({});
  const [textTextContentId, setTextTextContentId] = useState('');
  const [headerTextContentId, setHeaderTextContentId] = useState('');
  const [closeButtonTextTextContentId, setCloseButtonTextTextContentId] =
    useState('');
  const [navigateButtonTextTextContentId, setNavigateButtonTextTextContentId] =
    useState('');
  const [headerTextContent, setHeaderTextContent] = useState({});
  const [closeButtonTextTextContent, setCloseButtonTextTextContent] = useState(
    {}
  );
  const [navigateButtonTextTextContent, setNavigateButtonTextTextContent] =
    useState({});

  const TranslationType = {
    TEXT: 'text',
    HEADER: 'header',
    CLOSE_BUTTON_TEXT: 'closeButtonText',
    NAVIGATE_BUTTON_TEXT: 'navigateButtonText',
  };

  const { TEXT, HEADER, CLOSE_BUTTON_TEXT, NAVIGATE_BUTTON_TEXT } =
    TranslationType;

  useEffect(() => {
    if (props.popupMessage) {
      setHeader(props.popupMessage.header);
      setText(props.popupMessage.text);

      setActivateOn(
        moment
          .utc(props.popupMessage.activateOn)
          .tz(props.timeZone)
          .format('YYYY-MM-DDTHH:mm')
      );
      setActivateOnUTC(
        moment.utc(props.popupMessage.activateOn).format('YYYY-MM-DDTHH:mm')
      );
      setCloseButtonActive(props.popupMessage.closeButtonActive);
      setCloseButtonText(props.popupMessage.closeButtonText);
      setNavigateButtonActive(props.popupMessage.navigateButtonActive);
      setNavigateButtonText(props.popupMessage.navigateButtonText);
      setNavigateButtonPageId(props.popupMessage.navigateButtonPageId);
      setNavigateButtonExternalLink(
        props.popupMessage.navigateButtonExternalLink
      );
      setLinkType(
        props.popupMessage.navigateButtonPageId ? 'page' : 'external'
      );
      setDisplayOnAllStands(props.popupMessage.displayOnAllStands);

      let activePages = [];
      for (const item of props.popupMessage.popupMessagePages) {
        activePages.push(item.page);
      }
      setSelectedPages(activePages);
    } else {
      setActivateOnUTC(
        moment.tz(activateOn, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
      );
    }

    loadPages();
    fetchHiveDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props?.popupMessage?.textTextContent !== null) {
      setTextTextContentId(props?.popupMessage?.textTextContent?.id);
    } else {
      setTextTextContentId('');
    }
  }, [props.popupMessage]);

  useEffect(() => {
    if (props?.popupMessage?.headerTextContent !== null) {
      setHeaderTextContentId(props?.popupMessage?.headerTextContent?.id);
    } else {
      setHeaderTextContentId('');
    }
  }, [props.popupMessage]);

  useEffect(() => {
    if (props?.popupMessage?.closeButtonTextTextContent !== null) {
      setCloseButtonTextTextContentId(
        props?.popupMessage?.closeButtonTextTextContent?.id
      );
    } else {
      setCloseButtonTextTextContentId('');
    }
  }, [props.popupMessage]);

  useEffect(() => {
    if (props?.popupMessage?.navigateButtonTextTextContent !== null) {
      setNavigateButtonTextTextContentId(
        props?.popupMessage?.navigateButtonTextTextContent?.id
      );
    } else {
      setNavigateButtonTextTextContentId('');
    }
  }, [props.popupMessage]);

  const handleContentChange = (event, contentType) => {
    const { value } = event.target;
    setTranslationValues((prevState) => ({
      ...prevState,
      [selectedLanguage.languageCode]: {
        ...prevState[selectedLanguage.languageCode],
        [contentType]: value,
      },
    }));
    const setterFunctions = {
      text: setText,
      header: setHeader,
      closeButtonText: setCloseButtonText,
      navigateButtonText: setNavigateButtonText,
    };
    const setter = setterFunctions[contentType];
    if (setter) {
      setter(value);
    }
  };

  const getOriginalContentHandler = (originalContentValue) => {
    const defaultLanguageObject =
      translationValues[hiveDetails.defaultLanguage?.id];
    return defaultLanguageObject?.[originalContentValue];
  };

  useEffect(() => {
    if (selectedLanguage?.languageCode.length > 0 && props.popupMessage) {
      setTranslationValues({
        ...translationValues,
        [selectedLanguage.languageCode]: {
          header: props.popupMessage.header || '',
          text: props.popupMessage.text || '',
          closeButtonText: props.popupMessage.closeButtonText || '',
          navigateButtonText: props.popupMessage.navigateButtonText || '',
        },
      });
    }
  }, [hiveDetails]);

  const loadPages = async () => {
    if (!loginHelper.getCurrentHive()) {
      return;
    }

    let response = await pageService.getPages(loginHelper.getCurrentHive().id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      // Hide all ExhibitionStands
      response = response.filter((t) => t.template.kind !== 'ExhibitionStand');

      response = response.sort(function (a, b) {
        return ('' + a.title).localeCompare(b.title);
      });

      setPages(response);
      setPagesWithPopupEnabled(
        response.filter((t) => t.canContainPopupMessages)
      );
    }
  };

  const fetchHiveDetails = () => {
    hiveService.getHive().then((hiveService) => {
      setHiveDetails(hiveService);
      setSelectedLanguage({
        language: hiveService.defaultLanguage.name,
        languageCode: hiveService.defaultLanguage.id,
      });
      setTranslations(hiveService.hiveLanguages);
    });
  };

  const languageOptions = translations.map((translation) => (
    <MenuItem value={translation.name} key={translation.id} id={translation.id}>
      {translation.name}
    </MenuItem>
  ));

  const formattedTranslations = (translationValueType) => {
    const defaultLanguageId = hiveDetails?.defaultLanguage?.id;
    return Object.entries(translationValues)
      .filter(([langCode]) => langCode !== defaultLanguageId)
      .map(([langCode, langValues]) => {
        const translation = langValues[translationValueType];
        if (translation) {
          return {
            languageId: langCode,
            translation: translation,
          };
        }
      })
      .filter((obj) => obj !== undefined);
  };

  useEffect(() => {
    const setTextContent = (setTextContentState, contentType, id) => {
      const content = {
        originalText: getOriginalContentHandler(contentType),
        originalLanguageId: hiveDetails?.defaultLanguage?.id,
        translations: formattedTranslations(contentType),
      };

      if (id !== '') {
        content.id = id;
      }

      setTextContentState(content);
    };

    setTextContent(setTextTextContent, TEXT, textTextContentId);
    setTextContent(setHeaderTextContent, HEADER, headerTextContentId);
    setTextContent(
      setCloseButtonTextTextContent,
      CLOSE_BUTTON_TEXT,
      closeButtonTextTextContentId
    );
    setTextContent(
      setNavigateButtonTextTextContent,
      NAVIGATE_BUTTON_TEXT,
      navigateButtonTextTextContentId
    );
  }, [translationValues]);

  const onSaveClicked = async () => {
    var validationError = false;

    // Validate header length
    if (header && header.length > 120) {
      setHeaderError(
        getLanguage('TooLongMaxLengthIs').replace('{maxLength}', '120')
      );
      validationError = true;
    } else {
      setHeaderError('');
    }

    // Validate text not empty
    if (!text) {
      setTextError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else if (text.length > 500) {
      setTextError(
        getLanguage('TooLongMaxLengthIs').replace('{maxLength}', '500')
      );
      validationError = true;
    } else {
      setTextError('');
    }

    if (validationError) {
      return;
    }

    props.onSave(
      {
        header: getOriginalContentHandler(HEADER),
        text: getOriginalContentHandler(TEXT),
        activateOn: activateOnUTC,
        closeButtonActive,
        closeButtonText: getOriginalContentHandler(CLOSE_BUTTON_TEXT),
        navigateButtonActive,
        navigateButtonText: getOriginalContentHandler(NAVIGATE_BUTTON_TEXT),
        navigateButtonPageId,
        textTextContent,
        headerTextContent,
        closeButtonTextTextContent,
        navigateButtonTextTextContent,
        navigateButtonExternalLink,
        updatePages: selectedPages,
        displayOnAllStands,
      },
      null
    );
  };

  const handlePageChanged = (event) => {
    setNavigateButtonPageId(event.target.value);
  };

  const getPageOptions = () => {
    return pages.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.title}
      </MenuItem>
    ));
  };

  const onSelectPages = (selectedList, selectedItem) => {
    setSelectedPages(selectedList);
  };

  const onRemovePages = (selectedList, removedItem) => {
    setSelectedPages(selectedList);
  };

  const updateDateTimeHandler = (dateAndTime) => {
    setActivateOn(moment(dateAndTime).format('YYYY-MM-DDTHH:mm'));
    setActivateOnUTC(
      moment.tz(dateAndTime, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
    );
  };

  const handleSelectedLanguage = (event) => {
    const languageId = event.currentTarget.id;
    const translations = props.popupMessage;

    // Get the translation for the selected language ID for each content type
    const textTranslation = translations?.textTextContent?.translations.find(
      (t) => t.languageId === languageId
    );
    const headerTranslation =
      translations?.headerTextContent?.translations.find(
        (t) => t.languageId === languageId
      );
    const closeButtonTextTranslation =
      translations?.closeButtonTextTextContent?.translations.find(
        (t) => t.languageId === languageId
      );
    const navigateButtonTextTranslation =
      translations?.navigateButtonTextTextContent?.translations.find(
        (t) => t.languageId === languageId
      );

    // Use the translation value if found in translationValues, otherwise fallback on the values from props.popupMessage or an empty string
    const text =
      translationValues[languageId]?.text || textTranslation?.translation || '';
    const header =
      translationValues[languageId]?.header ||
      headerTranslation?.translation ||
      '';
    const closeButtonText =
      translationValues[languageId]?.closeButtonText ||
      closeButtonTextTranslation?.translation ||
      '';
    const navigateButtonText =
      translationValues[languageId]?.navigateButtonText ||
      navigateButtonTextTranslation?.translation ||
      '';

    // Update the state
    setSelectedLanguage({
      language: event.target.value,
      languageCode: languageId,
    });

    setTranslationValues({
      ...translationValues,
      [languageId]: {
        header: header,
        text: text,
        closeButtonText: closeButtonText,
        navigateButtonText: navigateButtonText,
      },
    });
    setText(text);
    setHeader(header);
    setCloseButtonText(closeButtonText);
    setNavigateButtonText(navigateButtonText);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel
            className={classes.selectLabel}
            id='categorySelector-label'
          >
            {getLanguage('Translation')}
          </InputLabel>
          <Select
            variant='outlined'
            fullWidth={true}
            value={selectedLanguage.language}
            onChange={handleSelectedLanguage}
            labelId='categorySelector-label'
            id='categorySelector'
          >
            {languageOptions}
          </Select>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            value={header}
            error={headerError}
            helperText={headerError}
            onChange={(e) => {
              handleContentChange(e, HEADER);
              setHeaderError('');
            }}
            label={getLanguage('Header')}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={textError}
            helperText={textError}
            value={text}
            onChange={(e) => {
              handleContentChange(e, TEXT);
              setTextError('');
            }}
            label={getLanguage('Text')}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            type='datetime-local'
            variant='outlined'
            value={activateOn}
            onChange={(e) => {
              updateDateTimeHandler(e.target.value);
            }}
            label={getLanguage('ActivateOn')}
            className='textField'
          />
        </FormControl>
        <i>
          {getLanguage('TimesWillBeSavedInTheFollowingTimezone').replace(
            '{Timezone}',
            props.timeZone
          )}
        </i>
        <br />
        <br />
        <b>{getLanguage('CloseButton')}</b>
        <br />
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <Grid container spacing={0}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={closeButtonActive}
                    onChange={(e) => setCloseButtonActive(e.target.checked)}
                  />
                }
                label={getLanguage('CloseButtonActive')}
                className='checkbox'
              />
            </Grid>
            <Grid item>
              <div
                data-tip
                data-for={'toolTip1'}
                className={classes.helpButton}
              >
                <Help />
              </div>
              <ReactTooltip id={'toolTip1'} aria-haspopup='true'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getLanguage('PopupTooltipCloseButton'),
                  }}
                />
              </ReactTooltip>
            </Grid>
          </Grid>
        </FormControl>
        {closeButtonActive && (
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <TextField
              variant='outlined'
              value={closeButtonText}
              onChange={(e) => handleContentChange(e, CLOSE_BUTTON_TEXT)}
              label={getLanguage('CloseButtonText')}
              className='textField'
            />
          </FormControl>
        )}
        <b>{getLanguage('NavigationButton')}</b>
        <br />
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <Grid container spacing={0}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={navigateButtonActive}
                    onChange={(e) => setNavigateButtonActive(e.target.checked)}
                  />
                }
                label={getLanguage('NavigateButtonActive')}
                className='checkbox'
              />
            </Grid>
            <Grid item>
              <div
                data-tip
                data-for={'toolTip2'}
                className={classes.helpButton}
              >
                <Help />
              </div>
              <ReactTooltip id={'toolTip2'} aria-haspopup='true'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getLanguage('PopupTooltipNavigateButton'),
                  }}
                />
              </ReactTooltip>
            </Grid>
          </Grid>
        </FormControl>
        {navigateButtonActive && (
          <>
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <TextField
                variant='outlined'
                value={navigateButtonText}
                onChange={(e) => handleContentChange(e, NAVIGATE_BUTTON_TEXT)}
                label={getLanguage('NavigateButtonText')}
                className='textField'
              />
            </FormControl>
            <br />

            <div>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <RadioGroup
                    value={linkType}
                    onClick={() => {
                      setNavigateButtonPageId('');
                      setLinkType('external');
                    }}
                  >
                    <FormControlLabel
                      value='external'
                      control={<Radio />}
                      label={getLanguage('ExternalUrl')}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <RadioGroup
                    value={linkType}
                    onClick={() => {
                      setNavigateButtonExternalLink('');
                      setLinkType('page');
                    }}
                  >
                    <FormControlLabel
                      value='page'
                      control={<Radio />}
                      label={getLanguage('OtherPage')}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </div>
            <br />
            {linkType === 'external' && (
              <TextField
                variant='outlined'
                value={navigateButtonExternalLink}
                onChange={(e) => setNavigateButtonExternalLink(e.target.value)}
                label={getLanguage('Link')}
                className='textField'
              />
            )}
            {linkType === 'page' && (
              <div className={classes.selectContainer}>
                <FormControl
                  fullWidth={true}
                  variant='outlined'
                  className={classes.formControl}
                >
                  <InputLabel
                    className={classes.selectLabel}
                    id='pageSelector-label'
                  >
                    {getLanguage('SelectPage')}
                  </InputLabel>
                  <Select
                    variant='outlined'
                    fullWidth={true}
                    value={navigateButtonPageId}
                    onChange={handlePageChanged}
                    labelId='pageSelector-label'
                    id='pageSelector'
                  >
                    <MenuItem value={''}>
                      <em>{getLanguage('PleaseChoose')}</em>
                    </MenuItem>
                    {getPageOptions()}
                  </Select>
                </FormControl>
              </div>
            )}
            <br />
          </>
        )}
        <b>{getLanguage('WhereToDisplayTheMessage?')}</b>
        <br />
        {pagesWithPopupEnabled !== undefined && (
          <>
            <div className={classes.selectContainer}>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <Grid container spacing={0}>
                  <Grid item xs>
                    <MultiSelector
                      displayValue='title'
                      placeholder={
                        !selectedPages || selectedPages.length === 0
                          ? getLanguage('DisplayingOnAllPages')
                          : getLanguage('WhereToDisplayTheMessage?')
                      }
                      items={pagesWithPopupEnabled}
                      selectedValues={selectedPages}
                      onSelect={onSelectPages}
                      onRemove={onRemovePages}
                    />
                    <br />
                  </Grid>
                </Grid>
              </FormControl>
            </div>
          </>
        )}
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={displayOnAllStands}
                onChange={(e) => setDisplayOnAllStands(e.target.checked)}
              />
            }
            label={getLanguage('DisplayOnAllStands')}
            className='checkbox'
          />
        </FormControl>

        <Button
          type='submit'
          fullWidth={true}
          variant='contained'
          color='primary'
        >
          {getLanguage('Save')}
        </Button>
        <br />
        <br />
      </form>
    </>
  );
};

export default EditPopupMessageDetails;
