import moment from 'moment';
import { LoginHelper } from '../LoginHelper';

export function getTimezoneFormat() {
  return 'DD.MM.YYYY HH:mm';
}

export function getEventTimezoneFormat(startDate, endDate) {
  const loginHelper = new LoginHelper();
  return (
    moment.utc(startDate).tz(loginHelper.getUserTimezone()).format(' HH:mm') +
    '-' +
    moment.utc(endDate).tz(loginHelper.getUserTimezone()).format('HH:mm')
  );
}

export function getStartDate(startDate) {
  const loginHelper = new LoginHelper();
  return moment
    .utc(startDate)
    .tz(loginHelper.getUserTimezone())
    .format('DD.MM');
}
