import React, { useState } from 'react'
import { LoginService } from '../services/LoginService'
import ImageCropUpload from '../../resource/components/ImageCropUpload'
import { LoadingIndicator } from '../../../core/LoadingIndicator'

const EditProfilePicture = (props) => {
  const loginService = new LoginService()

  const [isLoading, setIsLoading] = useState(false)

  const onFileUploadStarted = () => {
    setIsLoading(true)
  }

  const onFileUploaded = async (id) => {
    await loginService.updateProfilePicture(id)
    setIsLoading(false)
    props.profilePictureUpdated()
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <ImageCropUpload
        cropAspectRatio={1}
        uploadedFor="ProfilePicture"
        uniqueKey={props.loginId}
        fileUploadStarted={() => onFileUploadStarted()}
        fileUploadedCallback={(id) => onFileUploaded(id)}
      />
    </>
  )
}

export default EditProfilePicture
