export class StorageHelper {
    clearAllStorageRelatedToUser() {
        this.clearLoginObject();
        this.clearCurrentHive();
        this.clearPreviewAccessToken();
        this.clearPageSortingColumn();
        this.clearPageSortingDirection();
        this.clearLoginSortingColumn();
        this.clearLoginSortingDirection();
        this.clearHiveSortingColumn();
        this.clearHiveSortingDirection();
        this.clearTemplateSortingColumn();
        this.clearTemplateSortingDirection();
        this.clearTemplateObjectSortingColumn();
        this.clearTemplateObjectSortingDirection();
        this.clearNewsItemSortingColumn();
        this.clearNewsItemSortingDirection();
    }

    setCurrentHive(hive) {
        try {
            localStorage.setItem('currentHive', JSON.stringify(hive));
        }
        catch (e) {
            console.log(e);
        }
    }

    getCurrentHive() {
        try {
            let value = localStorage.getItem('currentHive');
            try {
                if (value == null || value === 'null') return null;
                return JSON.parse(value);
            } catch (error) {
                return value;
            }

        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    clearCurrentHive() {
        try {
            localStorage.removeItem('currentHive');
        }
        catch (e) {
            console.log(e);
        }
    }

    setLoginObject(value) {
        try {
            localStorage.setItem('loginObject', value);
        }
        catch (e) {
            console.log(e);
        }
    }

    getLoginObject() {
        try {
            let value = localStorage.getItem('loginObject');

            try {
                if (value == null || value === 'null') return null;
                // let str = JSON.parse(value);
                // return str
                return value
            } catch (error) {
                return value;
            }
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    clearLoginObject() {
        try {
            localStorage.removeItem('loginObject');
        }
        catch (e) {
            console.log(e);
        }
    }

    setPreviewAccessToken(accessToken) {
        try {
            localStorage.setItem('accessToken', JSON.stringify(accessToken));
        }
        catch (e) {
            console.log(e);
        }
    }

    getPreviewAccessToken() {
        try {
            let value = localStorage.getItem('accessToken');

            try {
                if (value == null || value === 'null') return null;
                return JSON.parse(value);
            } catch (error) {
                return value;
            }
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    clearPreviewAccessToken() {
        try {
            localStorage.removeItem('accessToken');
        }
        catch (e) {
            console.log(e);
        }
    }

    setMenuDrawerState(state) {
        try {
            localStorage.setItem('menuDrawerState', state);
        }
        catch (e) {
            console.log(e);
        }
    }

    getMenuDrawerState() {
        try {
            let value = localStorage.getItem('menuDrawerState');

            try {
                if (value == null || value === 'null') return null;
                return value;
            } catch (error) {
                return value;
            }
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    clearMenuDrawerState() {
        try {
            localStorage.removeItem('menuDrawerState');
        }
        catch (e) {
            console.log(e);
        }
    }

    setPageSortingColumn(value) {
        try {
            localStorage.setItem('pageSortingColumn', value);
        }
        catch (e) { }
    }

    getPageSortingColumn() {
        try {
            let value = localStorage.getItem('pageSortingColumn');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearPageSortingColumn() {
        try {
            localStorage.removeItem('pageSortingColumn');
        }
        catch (e) { }
    }

    setPageSortingDirection(value) {
        try {
            localStorage.setItem('pageSortingDirection', value);
        }
        catch (e) { }
    }

    getPageSortingDirection() {
        try {
            let value = localStorage.getItem('pageSortingDirection');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearPageSortingDirection() {
        try {
            localStorage.removeItem('loginSortingDirection');
        }
        catch (e) { }
    }

    setLoginSortingColumn(value) {
        try {
            localStorage.setItem('loginSortingColumn', value);
        }
        catch (e) { }
    }

    getLoginSortingColumn() {
        try {
            let value = localStorage.getItem('loginSortingColumn');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearLoginSortingColumn() {
        try {
            localStorage.removeItem('loginSortingColumn');
        }
        catch (e) { }
    }

    setLoginSortingDirection(value) {
        try {
            localStorage.setItem('loginSortingDirection', value);
        }
        catch (e) { }
    }

    getLoginSortingDirection() {
        try {
            let value = localStorage.getItem('loginSortingDirection');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearLoginSortingDirection() {
        try {
            localStorage.removeItem('pageSortingDirection');
        }
        catch (e) { }
    }

    setHiveSortingColumn(value) {
        try {
            localStorage.setItem('hiveSortingColumn', value);
        }
        catch (e) { }
    }

    getHiveSortingColumn() {
        try {
            let value = localStorage.getItem('hiveSortingColumn');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearHiveSortingColumn() {
        try {
            localStorage.removeItem('hiveSortingColumn');
        }
        catch (e) { }
    }

    setHiveSortingDirection(value) {
        try {
            localStorage.setItem('hiveSortingDirection', value);
        }
        catch (e) { }
    }

    getHiveSortingDirection() {
        try {
            let value = localStorage.getItem('hiveSortingDirection');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearHiveSortingDirection() {
        try {
            localStorage.removeItem('hiveSortingDirection');
        }
        catch (e) { }
    }

    setTemplateSortingColumn(value) {
        try {
            localStorage.setItem('templateSortingColumn', value);
        }
        catch (e) { }
    }

    getTemplateSortingColumn() {
        try {
            let value = localStorage.getItem('templateSortingColumn');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearTemplateSortingColumn() {
        try {
            localStorage.removeItem('templateSortingColumn');
        }
        catch (e) { }
    }

    setTemplateSortingDirection(value) {
        try {
            localStorage.setItem('templateSortingDirection', value);
        }
        catch (e) { }
    }

    getTemplateSortingDirection() {
        try {
            let value = localStorage.getItem('templateSortingDirection');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearTemplateSortingDirection() {
        try {
            localStorage.removeItem('templateSortingDirection');
        }
        catch (e) { }
    }

    setTemplateObjectSortingColumn(value) {
        try {
            localStorage.setItem('templateObjectSortingColumn', value);
        }
        catch (e) { }
    }

    getTemplateObjectSortingColumn() {
        try {
            let value = localStorage.getItem('templateObjectSortingColumn');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearTemplateObjectSortingColumn() {
        try {
            localStorage.removeItem('templateObjectSortingColumn');
        }
        catch (e) { }
    }

    setTemplateObjectSortingDirection(value) {
        try {
            localStorage.setItem('templateObjectSortingDirection', value);
        }
        catch (e) { }
    }

    getTemplateObjectSortingDirection() {
        try {
            let value = localStorage.getItem('templateObjectSortingDirection');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearTemplateObjectSortingDirection() {
        try {
            localStorage.removeItem('templateObjectSortingDirection');
        }
        catch (e) { }
    }

    setNewsItemSortingColumn(value) {
        try {
            localStorage.setItem('newsItemSortingColumn', value);
        }
        catch (e) { }
    }

    getNewsItemSortingColumn() {
        try {
            let value = localStorage.getItem('newsItemSortingColumn');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearNewsItemSortingColumn() {
        try {
            localStorage.removeItem('newsItemSortingColumn');
        }
        catch (e) { }
    }

    setNewsItemSortingDirection(value) {
        try {
            localStorage.setItem('newsItemSortingDirection', value);
        }
        catch (e) { }
    }

    getNewsItemSortingDirection() {
        try {
            let value = localStorage.getItem('newsItemSortingDirection');

            try {
                if (value == null || value === 'null') return null;
                return value
            } catch (error) {
                return value;
            }
        }
        catch {
            return null;
        }
    }

    clearNewsItemSortingDirection() {
        try {
            localStorage.removeItem('newsItemSortingDirection');
        }
        catch (e) { }
    }

    setResultsPerPageForPages(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForPages', value);
        }
        catch (e) { }
    }

    getResultsPerPageForPages() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForPages');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForEvents(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForEvents', value);
        }
        catch (e) { }
    }

    getResultsPerPageForEvents() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForEvents');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForNewsItems(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForNewsItems', value);
        }
        catch (e) { }
    }

    getResultsPerPageForNewsItems() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForNewsItems');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForPopups(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForPopups', value);
        }
        catch (e) { }
    }

    getResultsPerPageForPopups() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForPopups');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForKeywords(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForKeywords', value);
        }
        catch (e) { }
    }

    getResultsPerPageForKeywords() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForKeywords');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForMeetings(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForMeetings', value);
        }
        catch (e) { }
    }

    getResultsPerPageForMeetings() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForMeetings');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForTemplates(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForTemplates', value);
        }
        catch (e) { }
    }

    getResultsPerPageForTemplates() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForTemplates');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForMailTemplates(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForMailTemplates', value);
        }
        catch (e) { }
    }

    getResultsPerPageForMailTemplates() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForMailTemplates');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForUsers(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForUsers', value);
        }
        catch (e) { }
    }

    getResultsPerPageForUsers() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForUsers');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }

    setResultsPerPageForVisitorFields(value) {
        try {
            window.sessionStorage.setItem('resultsPerPageForVisitorFields', value);
        }
        catch (e) { }
    }

    getResultsPerPageForVisitorFields() {
        try {
            let value = window.sessionStorage.getItem('resultsPerPageForVisitorFields');

            try {
                if (value == null || value === 'null') return "10";
                return value
            } catch (error) {
                return "10";
            }
        }
        catch {
            return "10";
        }
    }
}