import React, { useEffect, useState } from 'react';
import { HiveService } from '../services/HiveService';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from './../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import { navigateToEditTemplate } from './../../../core/Navigation';
import { LoginHelper } from '../../../core/LoginHelper';
import TemplateList from '../../templates/components/TemplatesList';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '500px',
  },
  editButton: {
    width: '25px;',
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
    padding: '2px 10px 2px 10px',
  },
  helpButon: {
    marginTop: '18px',
  },
}));

const EditHive_Page = (props) => {
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [hive, setHive] = useState(null);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Templates')));
    const prepareData = async () => {
      loadHive();
    };

    const loadHive = async () => {
      let response = await hiveService.getHive(
        loginHelper?.getCurrentHive()?.id,
        true
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        setHive(response);
        setIsLoading(false);
      }
    };

    prepareData();
    // eslint-disable-next-line
  }, []);

  const requestedNavigateToTemplate = (id) => {
    navigateToEditTemplate(props, id);
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      {hive && (
        <>
          <div className={classes.root}>
            <TemplateList
              requestNavigateToTemplate={(id) =>
                requestedNavigateToTemplate(id)
              }
              hive={hive}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EditHive_Page;
