import React from 'react';
import { Switch as RouterSwitch } from 'react-router-dom';
import DashboardPage from '../features/dashboard/pages/DashboardPage';
import Pages_Page from '../features/page/pages/Pages_Page';
import EditPage_Page from '../features/page/pages/EditPage_Page';
import Login_Page from '../features/login/pages/Login_Page';
import { NotFound } from './components/NotFound';
import PageRoute from './PageRoute';
import CreateLogin_Page from '../features/login/pages/CreateLogin_Page';
import LogoutPage from '../features/login/pages/LogoutPage';
import ForgotLogin_Page from '../features/login/pages/ForgotLogin_Page';
import HiveVisitors_Page from '../features/hive/pages/HiveVisitors_Page';
import HiveAdministrators_Page from '../features/hive/pages/HiveAdministrators_Page';
import Hives_Page from '../features/hive/pages/Hives_Page';
import EditHive_Page from '../features/hive/pages/EditHive_Page';
import EditTemplate_Page from '../features/templates/pages/EditTemplate_Page';
import NewsItems_Page from '../features/newsitem/pages/NewsItems_Page';
import Stands_Page from '../features/page/pages/Stands_Page';
import Events_Page from '../features/event/pages/Events_Page';
import EditPageEvents_Page from '../features/event/pages/EditPageEvents_Page';
import KeywordGroups_Page from '../features/keywordgroup/pages/Keywordgroups_Page';
import Administrators_Page from '../features/administrators/pages/Administrators_Page';
import EventPages_Page from '../features/event/pages/EventPages_Page';
import ResetPassword_Page from '../features/login/pages/ResetPassword_Page';
import PopupMessages_Page from '../features/popupmessages/pages/PopupMessages_Page';
import ResourceArchive_Page from '../features/resourcearchives/pages/ResourceArchive_Page';
import InitialLoginSetup_Page from '../features/login/pages/InitialLoginSetup_Page';
import ClientSettings_Page from '../features/settings/pages/ClientSettings_Page';
import MailTemplates_Page from '../features/mailTemplate/pages/MailTemplates_Page';
import Meetings_Page from '../features/meetings/pages/Meetings_Page';
import HiveSettings_Page from '../features/settings/pages/HiveSettings_Page';
import ClientFiles_Page from '../features/settings/pages/ClientFiles_Page';
import StatisticsCsv_Page from '../features/statistics/pages/StatisticsCsv_Page';
import StatisticsHive_Page from '../features/statistics/pages/StatisticsHive_Page';
import VisitorFields_Page from '../features/visitorFields/pages/VisitorFields_Page';
import HiveUrls_Page from '../features/settings/pages/HiveUrls_Page';
import EventDays_Page from '../features/settings/pages/EventDays_Page';
import ContentLibrary_Page from '../features/resource/pages/ContentLibrary_Page';
import Assets_Page from '../features/resource/pages/Assets_Page';
import Gallery_Page from '../features/gallery/pages/Gallery_Page';
import StatisticsWhereBy from '../features/statistics/pages/StatisticsWhereBy';

export default function Router() {
  return (
    <RouterSwitch>
      <PageRoute exact path='/' component={DashboardPage} />

      <PageRoute exact path='/login' component={Login_Page} />
      <PageRoute exact path='/login/new' component={CreateLogin_Page} />
      <PageRoute exact path='/login/forgot' component={ForgotLogin_Page} />
      <PageRoute exact path='/logout' component={LogoutPage} />
      <PageRoute
        exact
        path='/login/resetpassword/:token'
        component={ResetPassword_Page}
      />
      <PageRoute
        exact
        path='/login/initialsetup'
        component={InitialLoginSetup_Page}
      />

      <PageRoute exact path='/stands' component={Stands_Page} />
      <PageRoute exact path='/pages' component={Pages_Page} />
      <PageRoute exact path='/editpage/:id' component={EditPage_Page} />
      <PageRoute exact path='/newsitems' component={NewsItems_Page} />
      <PageRoute exact path='/keywords' component={KeywordGroups_Page} />
      <PageRoute exact path='/popupmessages' component={PopupMessages_Page} />
      <PageRoute exact path='/statistics/csv' component={StatisticsCsv_Page} />
      <PageRoute
        exact
        path='/statistics/hive'
        component={StatisticsHive_Page}
      />
      <PageRoute
        exact
        path='/statistics/whereby'
        component={StatisticsWhereBy}
      />
      <PageRoute exact path='/mailtemplates' component={MailTemplates_Page} />
      <PageRoute exact path='/assets' component={Assets_Page} />
      <PageRoute exact path='/assets/:kind' component={Assets_Page} />
      <PageRoute exact path='/gallery' component={Gallery_Page} />
      <PageRoute exact path='/contentlibrary' component={ContentLibrary_Page} />
      <PageRoute exact path='/hive/visitors' component={HiveVisitors_Page} />
      <PageRoute
        exact
        path='/hive/administrators'
        component={HiveAdministrators_Page}
      />
      <PageRoute exact path='/hive/events/' component={Events_Page} />
      <PageRoute exact path='/hive/events/pages' component={EventPages_Page} />
      <PageRoute
        exact
        path='/hive/events/pages/:id'
        component={EditPageEvents_Page}
      />
      <PageRoute
        exact
        path='/hive/visitors/:id'
        component={HiveVisitors_Page}
      />
      <PageRoute
        exact
        path='/hive/administrators/:id'
        component={HiveAdministrators_Page}
      />
      <PageRoute exact path='/hives' component={Hives_Page} />
      <PageRoute exact path='/administrators' component={Administrators_Page} />
      <PageRoute exact path='/hive' component={EditHive_Page} />
      <PageRoute
        exact
        path='/resourceArchive'
        component={ResourceArchive_Page}
      />
      <PageRoute exact path='/template/:id' component={EditTemplate_Page} />
      <PageRoute exact path='/hive/settings' component={HiveSettings_Page} />
      <PageRoute
        exact
        path='/hive/clientsettings'
        component={ClientSettings_Page}
      />
      <PageRoute exact path='/hive/clientfiles' component={ClientFiles_Page} />
      <PageRoute
        exact
        path='/hive/visitorfields'
        component={VisitorFields_Page}
      />
      <PageRoute exact path='/hive/hiveurls' component={HiveUrls_Page} />
      <PageRoute exact path='/hive/eventdays' component={EventDays_Page} />
      <PageRoute exact path='/meetings' component={Meetings_Page} />
      <PageRoute component={NotFound} />
    </RouterSwitch>
  );
}
