import { Button, Container, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import orderBy from 'lodash/orderBy'
import { GlobalDialog } from '../../../core/GlobalDialog'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import HiveUrlEditor from './HiveUrlEditor'
import { HiveUrlService } from '../services/HiveUrlService'
import { ToastsStore } from 'react-toasts'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}))

const HiveUrls = (props) => {
  const classes = useStyles()
  const hiveUrlService = new HiveUrlService()

  const [isLoading, setIsLoading] = useState(true)
  const [hiveUrls, setHiveUrls] = useState([])
  const [hiveUrlToRemove, setHiveUrlToRemove] = useState(null)

  useEffect(() => {
    getHiveUrls()
    // eslint-disable-next-line
  }, [])

  const getHiveUrls = async () => {
    let response = await hiveUrlService.getHiveUrls(props.hiveId)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
    } else {
      response = orderBy(response, ['publicUrl'], ['asc'])

      setHiveUrls(response)
      setIsLoading(false)
    }
  }

  const addHiveUrl = async (obj) => {
    setIsLoading(true)

    obj.hiveId = props.hiveId

    let response = await hiveUrlService.addHiveUrl(obj)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      getHiveUrls()
    }
  }

  const updateHiveUrl = async (id, obj) => {
    setIsLoading(true)

    let response = await hiveUrlService.updateHiveUrl(id, obj)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      getHiveUrls()
    }
  }

  const deleteHiveUrl = async () => {
    setIsLoading(true)

    let response = await hiveUrlService.deleteHiveUrl(hiveUrlToRemove.id)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      getHiveUrls()
    }

    setHiveUrlToRemove(null)
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Grid container>
        <Grid>
          <h3>{getLanguage('EditUrlsThatCanAccessTheHive')}</h3>
        </Grid>
      </Grid>
      {hiveUrls.map((hiveUrl) => {
        return (
          <HiveUrlEditor
            hiveUrl={hiveUrl}
            onSave={(obj) => updateHiveUrl(hiveUrl.id, obj)}
            onDelete={(obj) => setHiveUrlToRemove(obj)}
          />
        )
      })}

      <b>{getLanguage('AddNewUrl')}</b>
      <HiveUrlEditor
        onSave={(obj) => addHiveUrl(obj)}
        onDelete={(obj) => deleteHiveUrl(obj)}
      />

      <GlobalDialog
        isDialogOpen={hiveUrlToRemove !== null}
        handleDialogClose={() => setHiveUrlToRemove(null)}
        title={getLanguage('DeleteUrl')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisUrl?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => deleteHiveUrl()}
          >
            {getLanguage('Delete')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  )
}

export default HiveUrls
