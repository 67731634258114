import { useEffect, useState } from 'react'
import { ToastsStore } from 'react-toasts'
import getLanguage from '../../../core/Language'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import { EventRegistrationService } from '../services/EventRegistrationService'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
} from '@material-ui/core'
import DataTable from 'react-data-table-component'
import orderBy from 'lodash.orderby'
import { HiveService } from '../../hive/services/HiveService'
import { GlobalDialog } from '../../../core/GlobalDialog'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  formControl: {},
}))

const RegistrationsForEvent = (props) => {
  const classes = useStyles()
  const eventRegistrationService = new EventRegistrationService()
  const hiveService = new HiveService()

  const [isLoading, setIsLoading] = useState(true)
  const [registrations, setRegistrations] = useState(null)
  const [visitors, setVisitors] = useState([])
  const [userToRemove, setUserToRemove] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredVisitors, setFilteredVisistors] = useState([])

  useEffect(() => {
    getFilteredVisitorsList()
    // eslint-disable-next-line
  }, [visitors, registrations])

  const getRegistrations = async () => {
    let response = await eventRegistrationService.getEventRegistrationsForEvent(
      props.event.id,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
    } else {
      setRegistrations(response)
    }

    setIsLoading(false)
  }

  const removeRegistration = async () => {
    setIsLoading(true)

    await eventRegistrationService.deleteEventRegistration(userToRemove.id)

    setUserToRemove(null)
    getRegistrations()
  }

  const getVisitors = async (search) => {
    let response = await hiveService.getVisitorsPaged(
      null,
      search,
      0,
      10,
      'fullName',
      'asc',
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      setVisitors(response)
      setIsLoading(false)
    }
  }

  const getFilteredVisitorsList = () => {
    let finalList = []

    for (const visitor of visitors) {
      let existingParticipant = registrations.find(
        (t) => t.loginId === visitor.id,
      )
      if (!existingParticipant) {
        finalList.push(visitor)
      }
    }

    setFilteredVisistors(finalList)
  }

  const updateSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm)

    if (newSearchTerm.length > 3) {
      getVisitors(newSearchTerm)
    }
    if (newSearchTerm.length === 0) {
      setVisitors([])
    }
  }

  const addToEvent = async (loginId) => {
    setIsLoading(true)

    await eventRegistrationService.insertEventRegistrationForLogin(
      props.event.id,
      loginId,
    )

    getRegistrations()
  }

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: getLanguage('FullName'),
      sortable: false,
      cell: (row) =>
        !row.login.fullName ? (
          <em>({getLanguage('NewUser')})</em>
        ) : (
          row.login.fullName
        ),
    },
    {
      name: getLanguage('Email'),
      sortable: false,
      cell: (row) => row.login.email,
    },
    {
      name: getLanguage('Options'),
      cell: (row) => (
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => removeRegistrationClicked(row)}
          >
            {getLanguage('Remove')}
          </Button>
        </div>
      ),
    },
  ]

  const visitorColumns = [
    {
      name: getLanguage('FullName'),
      sortable: false,
      cell: (row) =>
        !row.fullName ? <em>({getLanguage('NewUser')})</em> : row.fullName,
    },
    {
      name: getLanguage('Email'),
      sortable: false,
      cell: (row) => row.email,
    },
    {
      name: getLanguage('Options'),
      cell: (row) => (
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => addToEvent(row.id)}
          >
            {getLanguage('Add')}
          </Button>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getRegistrations()
    // eslint-disable-next-line
  }, [])

  const removeRegistrationClicked = (registration) => {
    setUserToRemove(registration)
  }

  const requestedCloseRemoveUser = () => {
    setUserToRemove(null)
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <Paper className={classes.paper}>
        {registrations && (
          <DataTable
            columns={columns}
            data={registrations}
            pagination={true}
            selectableRows={false}
            sortFunction={customSort}
            noHeader={true}
          />
        )}
      </Paper>

      <br />

      <h3>{getLanguage('AddParticipants')}</h3>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <TextField
          variant="outlined"
          value={searchTerm}
          onChange={(e) => updateSearchTerm(e.target.value)}
          label={getLanguage('SearchVisitorsByNameOrEmail')}
          className="textField"
        />
      </FormControl>

      {visitors && visitors.length > 0 && (
        <div>
          <DataTable
            columns={visitorColumns}
            data={filteredVisitors}
            pagination={false}
            selectableRows={false}
            sortFunction={customSort}
            noHeader={true}
          />
        </div>
      )}

      <GlobalDialog
        isDialogOpen={userToRemove !== null}
        handleDialogClose={() => requestedCloseRemoveUser()}
        title={getLanguage('RemoveParticipant')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisUserFromTheEvent?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => removeRegistration()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  )
}

export default RegistrationsForEvent
