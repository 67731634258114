import { ClientFileApi } from "./ClientFileApi";

export class ClientFileService {
    clientFileApi = new ClientFileApi();

    async getClientFiles(hiveId) {
        var result = await this.clientFileApi.getClientFiles(hiveId);
        return result;
    }

    async upsertClientFile(hiveId, clientFile) {
        return await this.clientFileApi.upsertClientFile(hiveId, clientFile);
    }

    async deleteClientFile(id) {
        var result = await this.clientFileApi.deleteClientFile(id);
        return result;
    }
}