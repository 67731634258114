import './EditHiveDetails.css'
import { FormControl, TextField } from '@material-ui/core'
import React from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const EditHiveDetailsText = (props) => {
  const classes = useStyles()

  return (
    <>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <TextField
          multiline={true}
          rows={3}
          variant="outlined"
          value={props.hive.disclaimerText}
          onChange={(e) =>
            props.onHiveDataUpdated('disclaimerText', e.target.value)
          }
          label={getLanguage('DisclaimerText(ShowsOnAllPages)')}
          className="textField"
        />
      </FormControl>
      {props.hive.disclaimerText && (
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            variant="outlined"
            value={props.hive.disclaimerLink}
            onChange={(e) =>
              props.onHiveDataUpdated('disclaimerLink', e.target.value)
            }
            label={getLanguage('DisclaimerLink(Optional)')}
            className="textField"
          />
        </FormControl>
      )}
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <TextField
          variant="outlined"
          value={props.hive.privacyUrl}
          onChange={(e) =>
            props.onHiveDataUpdated('privacyUrl', e.target.value)
          }
          label={getLanguage('PrivacyUrl')}
          className="textField"
        />
      </FormControl>
    </>
  )
}

export default EditHiveDetailsText
