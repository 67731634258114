import React, { useEffect, useState } from 'react';
import { Paper, Button, Container, Tooltip } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { updatePageTitle } from '../../../actions/Actions';
import { ToastsStore } from 'react-toasts';
import { limitLength } from '../../../core/helpers/StringHelper';
import { LoginHelper } from '../../../core/LoginHelper';
import { GalleryItemService } from '../services/GalleryItemService';
import EditGalleryDetails from '../components/EditGalleryDetails';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    animation: '$moveIn 600ms ease-out',
    height: '90vh',
    overflowX: 'scroll',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  inconsistencyInSortingAndPriority: {
    margin: '10px 0 10px 0',
    padding: '10px',
    border: '4px solid #FF4444',
    backgroundColor: '#EEE',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const Gallery_Page = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loginHelper = new LoginHelper();
  const galleryItemService = new GalleryItemService();

  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [galleryItemToEdit, setGalleryItemToEdit] = useState(null);
  const [galleryItemToRemove, setGalleryItemToRemove] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [galleryItems, setGalleryItems] = useState([]);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Gallery')));
    getGalleryItems();
  }, []);

  const onSave = async (obj) => {
    setIsLoading(true);

    if (galleryItemToEdit) {
      let response = await galleryItemService.updateGalleryItem(
        galleryItemToEdit.id,
        obj
      );
      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    } else {
      obj.hiveId = loginHelper.getCurrentHive().id;
      let response = await galleryItemService.addGalleryItem(obj);

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
        return;
      }
    }

    setCreateNewIsOpen(false);
    setGalleryItemToEdit(null);
    getGalleryItems();
  };

  const getGalleryItems = async () => {
    setIsLoading(true);

    let response = await galleryItemService.getGalleryItems();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setGalleryItems(response);
      setIsLoading(false);
    }
  };

  const removeGalleryItem = async () => {
    setIsLoading(true);

    let response = await galleryItemService.deleteGalleryItem(
      galleryItemToRemove.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setGalleryItemToRemove(null);
    getGalleryItems();
  };

  const columns = [
    {
      name: getLanguage('Created'),
      selector: (row) => row.created,
      sortable: true,
      width: '120px',
      cell: (row) => moment(row.created).format('DD/MM HH:mm'),
    },
    {
      name: getLanguage('Title'),
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => limitLength(row.title, 40, '...'),
    },
    {
      name: getLanguage('SortingKey'),
      selector: (row) => row.sortingKey,
      sortable: true,
      cell: (row) => row.sortingKey,
    },
    {
      name: getLanguage('IsDisplayed'),
      selector: 'isDisplayed',
      cell: (row) => (row.isActive ? 'true' : 'false'),
    },

    {
      name: getLanguage('Options'),
      width: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setGalleryItemToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setGalleryItemToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };
  return (
    <>
      <Paper className={classes.paper}>
        {galleryItems && (
          <DataTable
            noHeader={true}
            pagination={true}
            columns={columns}
            data={galleryItems}
            selectableRows={false}
            defaultSortFieldId={3}
            subHeader={true}
            subHeaderComponent={
              <div>
                <Button
                  variant='contained'
                  className={classes.button}
                  onClick={() => setCreateNewIsOpen(true)}
                >
                  <Add /> {getLanguage('CreateNew')}{' '}
                </Button>
              </div>
            }
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('CreateGalleryItem')}
        type='Drawer'
        sizeclass='XL'
      >
        <Container>
          {createNewIsOpen && (
            <EditGalleryDetails onSave={(obj) => onSave(obj)} />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={galleryItemToEdit !== null}
        handleDialogClose={() => setGalleryItemToEdit(null)}
        title={getLanguage('EditGalleryItem')}
        type='Drawer'
        sizeclass='XL'
      >
        <Container>
          {galleryItemToEdit && (
            <EditGalleryDetails
              onSave={(obj) => onSave(obj)}
              galleryItem={galleryItemToEdit}
            />
          )}
        </Container>
      </GlobalDialog>
      <GlobalDialog
        isDialogOpen={galleryItemToRemove !== null}
        handleDialogClose={() => setGalleryItemToRemove(null)}
        title={getLanguage('RemoveNewsItem')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheNewsItem?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            className={classes.button}
            onClick={() => removeGalleryItem()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default Gallery_Page;
