import './EditTemplateDetails.css';
import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import { Clear, Delete, Edit, Save } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '0 5px 0 5px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  highButtons: {
    height: '55px',
    margin: '0 0 0 5px',
  },
  styleItem: {
    margin: '5px 0 5px 0',
  },
}));

const TemplateObjectStyleEditor = (props) => {
  const classes = useStyles();

  const [styleKey, setStyleKey] = useState('');
  const [styleValue, setStyleValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (props.templateObjectStyle) {
      setStyleKey(props.templateObjectStyle.styleKey);
      setStyleValue(props.templateObjectStyle.styleValue);
    }
  }, [props.templateObjectStyle]);

  const onSave = () => {
    if (!styleKey || !styleValue) {
      return;
    }

    if (
      styleKey.toLowerCase() === 'right' ||
      styleKey.toLowerCase() === 'bottom'
    ) {
      setError(getLanguage('YouCannotUseRightOrBottom'));
      return;
    }

    props.onSave({
      styleKey,
      styleValue,
    });

    setStyleKey('');
    setStyleValue('');
    setError('');
    setIsEditing(false);
  };

  const onEdit = () => {
    setIsEditing(true);
  };

  const onDelete = () => {
    props.onDelete(props.templateObjectStyle);
  };

  const cancelEdit = () => {
    setIsEditing(false);

    if (props.templateObjectStyle) {
      setStyleKey(props.templateObjectStyle.styleKey);
      setStyleValue(props.templateObjectStyle.styleValue);
    }
  };

  return (
    <div className={classes.styleItem}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        <Grid container>
          <Grid item xs={4}>
            {(!props.templateObjectStyle || isEditing) && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  error={error}
                  helperText={error}
                  value={styleKey}
                  onChange={(e) => setStyleKey(e.target.value)}
                  label={getLanguage('StyleName')}
                  className='textField'
                />
              </FormControl>
            )}
            {props.templateObjectStyle && !isEditing && (
              <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                  variant='outlined'
                  value={styleKey}
                  disabled
                  className='textField'
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            {(!props.templateObjectStyle || isEditing) && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  value={styleValue}
                  onChange={(e) => setStyleValue(e.target.value)}
                  label={getLanguage('Value')}
                  className='textField'
                />
              </FormControl>
            )}
            {props.templateObjectStyle && !isEditing && (
              <FormControl fullWidth={true} className={classes.formControl}>
                <TextField
                  variant='outlined'
                  value={styleValue}
                  disabled
                  className='textField'
                />
              </FormControl>
            )}
          </Grid>
          <Grid item xs={4}>
            {(!props.templateObjectStyle || isEditing) && (
              <>
                <Button
                  variant='contained'
                  type='submit'
                  color='primary'
                  className={classes.highButtons}
                >
                  <Save />
                </Button>
                {props.templateObjectStyle && (
                  <>
                    {' '}
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.highButtons}
                      onClick={() => cancelEdit()}
                    >
                      <Clear />
                    </Button>
                  </>
                )}
              </>
            )}
            {props.templateObjectStyle && !isEditing && (
              <>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.highButtons}
                  onClick={() => onEdit()}
                >
                  <Edit />
                </Button>{' '}
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.highButtons}
                  onClick={() => onDelete()}
                >
                  <Delete />
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default TemplateObjectStyleEditor;
