import "./ActiveUsersOnPage.css";
import React, { useEffect, useState } from "react";
import getLanguage from "../../../core/Language";
import {
  Button,
  makeStyles,
  AppBar,
  IconButton,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  getActiveGuestRoomId,
  getOnlineVisitors,
} from "../../../actions/Actions";
import { useParams } from "react-router";
import ChatOverview from "../../chat/components/ChatOverview";
import { LoginHelper } from "../../../core/LoginHelper";
import UserCard from "./UserCard";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";
import { Chat, Face, List } from "@material-ui/icons";
import DataTable from "react-data-table-component";
import UserAvatar from "./UserAvatar";
import { VisitorFieldService } from "../../visitorFields/services/VisitorFieldService";
import { LoadingIndicator } from "../../../core/LoadingIndicator";
import { StatisticsService } from "../../statistics/services/StatisticsService";
import useWindowDimensions from "../../../core/helpers/ScreenSizeHelper";
import { getChatPath } from "../../../core/ServiceRoutes";
import { v4 as uuidv4 } from "uuid";
import { Application_Matrix_Domain } from "../../../core/ApplicationConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectContainer: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  selectElement: {
    height: "30px",
    color: "#FFF",
    fontSize: "12px",
  },
  selectedItem: {
    fontSize: "12px",
  },
  textField: {
    marginRight: "8px",
    marginTop: "10px",
    "& .MuiFormLabel-root": {
      color: "#FFF",
      marginTop: "-13px",
      fontSize: "11px",
    },
    "& .MuiInputBase-root": {
      height: "30px",
    },
    "& .Mui-focused": {
      marginTop: "0px",
    },
    "& .MuiFormLabel-filled": {
      marginTop: "0px",
    },
    "& .MuiInputBase-input": {
      color: "#FFF",
      fontSize: "12px",
    },
  },
  chatViewButton: {
    width: "20px",
    marginLeft: "5px",
  },
  online: {
    backgroundColor: "#4caf50",
    position: "absolute",
    borderRadius: "50%",
    width: "10px",
    height: "10px",
    top: "10px",
    left: "30px",
    zIndex: "10",
  },
  offline: {
    color: "#f44336",
  },
  activeSince: {
    marginLeft: "4px",
    fontSize: "8px",
  },
  activityButton: {
    width: "90px",
  },
}));

const ActiveUsersOnPage = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { height } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [viewType, setViewType] = useState("cards");
  const [selectedSort, setSelectedSort] = useState("alphabeticalAZ");
  const [search, setSearch] = useState("");
  const [finalList, setFinalList] = useState([]);
  const [visitorFieldsForHive, setVisitorFieldsForHive] = useState(null);

  const loginHelper = new LoginHelper();
  const visitorFieldService = new VisitorFieldService();
  const statisticsService = new StatisticsService();

  const { id } = useParams();
  const useMatrixClient = useSelector((state) => state.matrixClient);

  useEffect(() => {
    const prepareData = async () => {
      let response = await visitorFieldService.getVisitorFieldsForHive(
        loginHelper.getCurrentHive().id
      );
      setVisitorFieldsForHive(response);
    };

    prepareData();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setIsChatOpen(false);
  };

  const viewTypeClicked = (newType) => {
    setViewType(newType);
  };

  const handleSortChange = (event) => {
    setSelectedSort(event.target.value);
  };

  const openLinkedInProfile = (login) => {
    window.open(login.linkedInProfile);
  };

  const openChat = (guestId) => {
    const roomsArray = useMatrixClient.getVisibleRooms();
    const roomMembers = roomsArray.map((room) => room.currentState.members);
    if (roomMembers.length > 0) {
      const reducedRoomMembers = roomMembers.reduce(
        (prev, current) => ({
          ...prev,
          ...current,
        }),
        []
      );

      if (
        reducedRoomMembers[`@${guestId}:${Application_Matrix_Domain}`]?.roomId
      ) {
        dispatch(
          getActiveGuestRoomId(
            reducedRoomMembers[`@${guestId}:${Application_Matrix_Domain}`]
              .roomId
          )
        );
        setIsChatOpen(true);
      } else {
        useMatrixClient
          .createRoom({
            invite: [`@${guestId}:${Application_Matrix_Domain}`],
            visibility: "private",
          })
          .then((res) => {
            setTimeout(
              () =>
                dispatch(
                  getActiveGuestRoomId(res.room_id),
                  setIsChatOpen(true)
                ),
              300
            );
          })

          .catch((err) => {
            console.log(">>", err);
          });
      }
    }
  };

  useEffect(() => {
    fetch(`${getChatPath()}/scenes/${id}/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginHelper.getAuthToken()}`,
      },
    }).then((res) =>
      res.json().then((res) => dispatch(getOnlineVisitors(res, id)))
    );
  }, []);

  useEffect(() => {
    if (!props.activeUsersClient) {
      return;
    }

    let visitorList = [...props.activeUsersClient];

    // If search is applied, filter the results
    if (search) {
      visitorList = visitorList.filter((t) =>
        t.fullName.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFinalList(visitorList);
  }, [props.activeUsersClient, search]);

  const activeUsersColumns = [
    {
      cell: (row) => (
        <>
          <div className={classes.online}></div>
          <UserAvatar login={row} size={"20"} />
        </>
      ),
    },
    {
      name: <b>{getLanguage("Name")}</b>,
      selector: (row) => row.fullName,
    },
    {
      width: "250px",
      cell: (row) => {
        return (
          <div>
            <Button
              component="span"
              onClick={() => {
                openChat(row.userId);
              }}
              title={getLanguage("Chat")}
            >
              <Chat />
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Grid container>
            <Grid container spacing={1}>
            <div className="text-header">
              <p>List of active users in stand</p>
            </div>
              <div className={classes.selectContainer}>
                <FormControl
                  fullWidth={true}
                  variant="outlined"
                  className={classes.formControl}
                >
                  {/* <Select
                        variant="outlined"
                        fullWidth={true}
                        value={selectedSort}
                        onChange={handleSortChange}
                        labelId="sortSelector-label"
                        id="sortSelector"
                        className={classes.selectElement}
                      >
                        <MenuItem
                          value={"alphabeticalAZ"}
                          className={classes.selectedItem}
                        >
                          <em>{getLanguage("SortByAlphabetivalA-Z")}</em>
                        </MenuItem>
                        <MenuItem
                          value={"alphabeticalZA"}
                          className={classes.selectedItem}
                        >
                          <em>{getLanguage("SortByAlphabetivalZ-A")}</em>
                        </MenuItem>
                      </Select> */}
                </FormControl>
              </div>
              {}
            </Grid>
            {}
          </Grid>
        </AppBar>
      </div>

      {isLoading && <LoadingIndicator />}

      {!isLoading && (
        <>
          {finalList.filter((item) => item.role !== "admin").length === 0 && (
            <p>{getLanguage("ThereAreNoVisitorsOnYourStand")}</p>
          )}

          {viewType === "cards" && finalList.length > 0 && (
            <div className={"activeUsersContainer"}>
              {finalList
                .filter((item) => item.role !== "admin")
                .map((item) => {
                  return (
                    <div className={"activeUserHolder"} key={uuidv4()}>
                      <UserCard
                        showChatNotification={false}
                        key={uuidv4()}
                        mode={"ActiveUsers"}
                        isActiveOnPage={true}
                        showChatButton={true}
                        requestOpenChat={() => {
                          openChat(item.id);
                        }}
                        login={item}
                        openLinkedInProfile={() => openLinkedInProfile(item)}
                      />
                    </div>
                  );
                })}
            </div>
          )}

          {viewType === "list" && finalList.length > 0 && (
            <DataTable
              noHeader={true}
              columns={activeUsersColumns}
              data={finalList}
              pagination={false}
              selectableRows={false}
              subHeader={false}
            />
          )}
        </>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={isChatOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <div style={{ height: height - 100 + "px", width: "100%" }}>
            <ChatOverview requestClose={handleClose} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ActiveUsersOnPage;
