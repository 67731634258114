export const Application_Environment = process.env.REACT_APP_ENVIRONMENT;
export const Application_Api_Path =
  window.REACT_APP_API_URL || 'https://api-service.jx.virtualhiveservices.io';
export const Application_WebSocket_Path =
  window.REACT_APP_WEBSOCKET_URL ||
  'wss://chat-service.jx.virtualhiveservices.io/ws';
export const Application_Cdn_Path =
  window.REACT_APP_CDN_URL || 'https://vh-staging-cdn.azureedge.net';
export const Application_Build_Number = process.env.REACT_APP_BUILD_NUMBER;
export const Application_Chat_Path =
  window.REACT_APP_CHAT_SERVICE_URL ||
  'https://chat-service.jx.virtualhiveservices.io';
export const Application_Matrix_Domain =
  window.REACT_APP_MATRIX_DOMAIN || 'jx.virtualhiveservices.io';
export const Application_Matrix_Url =
  window.REACT_APP_MATRIX_URL || 'https://matrix.jx.virtualhiveservices.io';