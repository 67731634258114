import { CsvApi } from "./CsvApi";

export class CsvService {
    csvApi = new CsvApi();

    async uploadPagesAndLoginsCsv(file, hiveId) {
        try {
            var result = await this.csvApi.uploadPagesAndLoginsCsv(file, hiveId);
            return result;
        } catch (error) {
            console.log(error);
        }
    }

    async uploadVisitorsCsv(file, hiveId) {
        try {
            var result = await this.csvApi.uploadVisitorsCsv(file, hiveId);
            return result;
        } catch (error) {
            console.log(error);
        }
    }
}