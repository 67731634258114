import React, { useEffect, useState } from 'react';
import { LoginHelper } from '../../../core/LoginHelper';
import { Redirect } from 'react-router';
import { roles } from '../../../core/enums/roles';
import { pathNames } from '../../../core/enums/pathNames';
import Loading from 'react-fullscreen-loading';

const DashboardPage = (props) => {
  const [userRole, setUserRole] = useState([]);
  const loginHelper = new LoginHelper();

  useEffect(() => {
    setUserRole(loginHelper.getUserRoles());
  }, []);

  const {
    CONTENT_LIBRARY_ADMINISTRATION,
    CONTENT_LIBRARY_AUTO_UPLOAD,
    MEETING_READ,
    MEETING_ADMINISTRATION,
    USER_ADMINISTRATION,
    VISITOR_ADMINISTRATION,
    NETWORKING,
    STAND_EDITOR,
    STAND_OWNER,
    EVENT_ADMINISTRATION,
    NEWS_ADMINISTRATION,
    POPUP_ADMINISTRATION,
    KEYWORD_ADMINISTRATION,
    ASSET_ADMINISTRATION,
    MAIL_TEMPLATE_ADMINISTRATION,
    STATISTICS,
  } = roles;

  const {
    PATH_CONTENT_LIBRARY,
    PATH_MEETINGS,
    PATH_ADMINISTRATORS,
    PATH_VISITORS,
    PATH_STANDS,
    PATH_EVENTS,
    PATH_NEWSITEMS,
    PATH_POPUP_MESSAGES,
    PATH_KEYWORDS,
    PATH_ASSETS,
    PATH_MAIL_TEMPLATES,
    PATH_STATISTICS,
    PATH_PAGES,
  } = pathNames;

  if (userRole?.length > 0) {
    const firstUserRole = userRole[0]?.Role;

    switch (firstUserRole) {
      case CONTENT_LIBRARY_ADMINISTRATION:
      case CONTENT_LIBRARY_AUTO_UPLOAD:
        return <Redirect to={PATH_CONTENT_LIBRARY} />;
      case MEETING_READ:
      case MEETING_ADMINISTRATION:
        return <Redirect to={PATH_MEETINGS} />;
      case USER_ADMINISTRATION:
        return <Redirect to={PATH_ADMINISTRATORS} />;
      case VISITOR_ADMINISTRATION:
        return <Redirect to={PATH_VISITORS} />;
      case NETWORKING:
      case STAND_EDITOR:
      case STAND_OWNER:
        return <Redirect to={PATH_STANDS} />;
      case EVENT_ADMINISTRATION:
        return <Redirect to={PATH_EVENTS} />;
      case NEWS_ADMINISTRATION:
        return <Redirect to={PATH_NEWSITEMS} />;
      case POPUP_ADMINISTRATION:
        return <Redirect to={PATH_POPUP_MESSAGES} />;
      case KEYWORD_ADMINISTRATION:
        return <Redirect to={PATH_KEYWORDS} />;
      case ASSET_ADMINISTRATION:
        return <Redirect to={PATH_ASSETS} />;
      case MAIL_TEMPLATE_ADMINISTRATION:
        return <Redirect to={PATH_MAIL_TEMPLATES} />;
      case STATISTICS:
        return <Redirect to={PATH_STATISTICS} />;
      default:
        return <Redirect to={PATH_PAGES} />;
    }
  }

  return (
    <Loading
      loading
      background='linear-gradient(180deg, #fff, #edf5fe)'
      loaderColor='#124238'
    />
  );
};

export default DashboardPage;
