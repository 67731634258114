import "./EditMeetingDetails.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Chip,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import getLanguage from "../../../core/Language";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useSelector } from "react-redux";
import { HiveService } from "../../hive/services/HiveService";
import { ToastsStore } from "react-toasts";
import { PageService } from "../../page/services/PageService";
import { MeetingService } from "../services/MeetingService";
import { LoginHelper } from "../../../core/LoginHelper";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: "0 0 30px 0",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: "#FFF",
  },
  selectContainer: {
    marginBottom: "10px",
  },
  checkboxTitle: {
    fontSize: "1.1rem",
    paddingBottom: "10px",
  },
}));
function isEmpty(value) {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
}
const EditMeetingDetails = (props) => {
  const meetingTypes = {
    LOUNGE: "Lounge",
    EMBED: "Embed",
    URL: "URL",
    NATIVE: "Native",
    NATIVEVIEW: "NativeView",
    RESTRICTEDMEETING: "Meeting restricted to named participants",
  };

  const { LOUNGE, EMBED, URL, NATIVE, NATIVEVIEW, RESTRICTEDMEETING } = meetingTypes;

  const classes = useStyles();
  const pageService = new PageService();
  const hiveService = new HiveService();
  const meetingService = new MeetingService();
  const loginHelper = new LoginHelper();

  const fixedOption = [
    {
      fullName: loginHelper.getUserFullName(),
      id: loginHelper.getUserId(),
    },
  ];

  const meetingGroupId = useSelector((state) => state.meetingGroupId);

  const [title, setTitle] = useState("");
  const [startUrl, setStartUrl] = useState("");
  const [externalUrl, setExternalUrl] = useState("");
  const [startAt, setStartAt] = useState(
    moment().startOf("hour").add(1, "hour").format("YYYY-MM-DDTHH:mm")
  );
  const [startAtUTC, setStartAtUTC] = useState(
    moment().format("YYYY-MM-DDTHH:mm")
  );
  const [endAt, setEndAt] = useState(
    moment()
      .startOf("hour")
      .add(1, "hour")
      .add(30, "minutes")
      .format("YYYY-MM-DDTHH:mm")
  );
  const [endAtUTC, setEndAtUTC] = useState(moment().format("YYYY-MM-DDTHH:mm"));
  const [endAtError, setEndAtError] = useState("");
  const [externalUrlError, setExternalUrlError] = useState("");
  const [visitors, setVisitors] = useState([]);
  const [invitedPeople, setInvitedPeople] = useState([]);
  const [pages, setPages] = useState([]);
  const [pageId, setPageId] = useState("");
  const [description, setDescription] = useState("");
  const [meetingType, setMeetingType] = useState(NATIVE);
  const [isPrivate, setIsPrivate] = useState(false);
  const [maxAttendees, setMaxAnttendees] = useState(0);
  const [open, setOpen] = useState(false);
  const [duration, setDuration] = useState(30);

  const urlPattern = /^(https?:\/\/[^\s]+)/;
  const alreadyInvitedPeople = props.meeting?.meetingGroup?.meetingGroupsLogins;

  let searchTimeout;

  useEffect(() => {
    if (props.meeting) {
      setTitle(props.meeting.title);
      setStartAt(
        moment
          .utc(props.meeting.startAt)
          .tz(props.timeZone)
          .format("YYYY-MM-DDTHH:mm")
      );
      setEndAt(
        moment
          .utc(props.meeting.endAt)
          .tz(props.timeZone)
          .format("YYYY-MM-DDTHH:mm")
      );
      setStartAtUTC(
        moment.utc(props.meeting.startAt).format("YYYY-MM-DDTHH:mm")
      );
      setEndAtUTC(moment.utc(props.meeting.endAt).format("YYYY-MM-DDTHH:mm"));
      setPageId(props.meeting.pageId);
      setDescription(props.meeting.description);
      setMeetingType(props.meeting.type);
      setMaxAnttendees(props.meeting.maxAttendees);
      setStartUrl(props.meeting.startUrl);
      setExternalUrl(props.meeting.externalUrl);
      setIsPrivate(props.meeting.isPrivate);
      if (alreadyInvitedPeople.length > 0) {
        alreadyInvitedPeople.forEach((person) => {
          setInvitedPeople((oldArray) => [
            ...oldArray,
            {
              fullName: person.login.fullName || "Undefined",
              id: person.login.id,
            },
          ]);
        });
      }
    } else {
      setStartAtUTC(
        moment.tz(startAt, props.timeZone).utc().format("YYYY-MM-DDTHH:mm")
      );
      setEndAtUTC(
        moment.tz(endAt, props.timeZone).utc().format("YYYY-MM-DDTHH:mm")
      );

      setInvitedPeople(fixedOption);
    }

    getPages();
    getVisitors();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (alreadyInvitedPeople?.length === 0 && invitedPeople.length === 0) {
      return setInvitedPeople(fixedOption);
    }
  }, [isPrivate, alreadyInvitedPeople, fixedOption, invitedPeople]);

  useEffect(() => {
    if (meetingGroupId.length > 0 && isPrivate) {
      const alreadyInvitedIds =
        alreadyInvitedPeople?.length > 0
          ? alreadyInvitedPeople.map((person) => person.login.id)
          : [];

      const invitedPeopleIds =
        invitedPeople?.length > 0
          ? invitedPeople.map((person) => person.id)
          : [];

      invitedPeopleIds.forEach((id) => {
        if (!alreadyInvitedIds.includes(id)) {
          addParticipantToMeeting(meetingGroupId, id);
        }
      });
      getVisitors();
    }
  }, [meetingGroupId]);

  // this useEffect will only be called once and will only execute method on create meeting
  useEffect(() => {
    if (!props.meeting) {
      initializeTimes();
    }
  }, []);

  const initializeTimes = () => {
    const initialStart = moment()
      .startOf("hour")
      .add(1, "hour")
      .format("YYYY-MM-DDTHH:mm");
    setStartAt(initialStart);
    setEndAt(
      moment(initialStart).add(30, "minutes").format("YYYY-MM-DDTHH:mm")
    );
  };

  const filterSearchOptions = (options, { inputValue }) => {
    const lowercasedInput = inputValue.toLowerCase();
    return options.filter((option) => {
      return (
        option.email.toLowerCase().includes(lowercasedInput) ||
        option.username.toLowerCase().includes(lowercasedInput) ||
        option.fullName.toLowerCase().includes(lowercasedInput)
      );
    });
  };

  const getPages = async () => {
    let response = await pageService.getPages();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = response.filter((t) => t.template.kind !== "ExhibitionStand");
      response = response.sort(function (a, b) {
        return ("" + a.title).localeCompare(b.title);
      });
      setPages(response);
    }
  };
  const getVisitors = async (search) => {
    if (!search || search.length < MIN_SEARCH_LENGTH) {
      return;
    }

    // Wait for 300ms second before proceeding
    await new Promise((resolve) => setTimeout(resolve, 300));

    let response = await meetingService.getVisitorsOnSearch(
      null,
      search,
      0,
      10,
      "fullName",
      "asc"
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setVisitors(response);
    }
  };

  const addParticipantToMeeting = async (meetingGroupId, loginId) => {
    let res = await meetingService.addParticipantToMeeting(
      meetingGroupId,
      loginId
    );

    if (res.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${res.response.status} - ${res.response.statusText} + ")`
      );

      return;
    }

    ToastsStore.success(`${getLanguage("UserAddedSuccessfully")}`);
  };

  const removeParticipantFromMeeting = async (loginId) => {
    let res = await meetingService.removeParticipantFromMeeting(
      props.meeting.meetingGroupId,
      loginId
    );

    if (res.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${res.response.status} - ${res.response.statusText} + ")`
      );

      return;
    }

    ToastsStore.success(`${getLanguage("UserRemovedSuccessFully")}`);
  };

  const removeAllParticipantsfromMeeting = async (meetingGroupId) => {
    let res = await meetingService.removeAllParticipantsFromMeeting(
      meetingGroupId
    );

    if (res.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${res.response.status} - ${res.response.statusText} + ")`
      );

      return;
    }

    ToastsStore.success(
      `${getLanguage("AllUsersHasBeenSuccefullyRemovedFromTheMeeting")}`
    );
  };

  const onSaveClicked = async () => {
    const isEndAtValid = moment(endAt).isAfter(moment(startAt));
    const isUrlValid = urlPattern.test(externalUrl) || meetingType !== URL;

    if (!isEndAtValid) {
      setEndAtError(getLanguage("EndAtIsNotAfterStartAt"));
    } else {
      setEndAtError("");
    }

    if (!isUrlValid) {
      setExternalUrlError(getLanguage("TheInsertedUrlIsNotValid"));
    } else {
      setExternalUrlError("");
    }

    if (!isEndAtValid || !isUrlValid) {
      console.log("end time not setted correctly");
      return;
    }

    const alreadyInvitedIds =
      alreadyInvitedPeople?.map((person) => person.login.id) || [];
    const invitedPeopleIds = invitedPeople?.map((person) => person.id) || [];

    if (props.meeting?.meetingGroupId.length > 0) {
      if (isPrivate) {
        invitedPeopleIds
          .filter((id) => !alreadyInvitedIds.includes(id))
          .forEach((id) =>
            addParticipantToMeeting(props.meeting.meetingGroupId, id)
          );

        alreadyInvitedIds
          .filter((id) => !invitedPeopleIds.includes(id))
          .forEach((id) => removeParticipantFromMeeting(id));
      } else if (invitedPeopleIds.length > 0 || alreadyInvitedIds.length > 0) {
        removeAllParticipantsfromMeeting(props.meeting.meetingGroupId);
      }
    }

    props.onSave(
      title,
      startAt,
      endAt,
      startAtUTC,
      endAtUTC,
      pageId ? pageId : null,
      description,
      meetingType,
      startUrl,
      externalUrl,
      maxAttendees,
      isPrivate
    );
  };

  const getPageOptions = () => {
    const networkPages = pages.filter((page) => page.template.kind === LOUNGE);
    const filteredPages = pages.filter((page) => page.template.kind !== LOUNGE);
    return (meetingType === LOUNGE ? networkPages : filteredPages).map(
      (data) => (
        <MenuItem key={data.id} value={data.id}>
          {data.title}
        </MenuItem>
      )
    );
  };

  const handlePageChanged = (event) => {
    setPageId(event.target.value);
  };

  const handleMeetingType = (event) => {
    if (event.target.checked) {
      setMeetingType(event.target.value);
    } else if (meetingType === event.target.value) {
      // if the currently selected checkbox is unchecked, prevent unchecking both checkboxes
      event.preventDefault();
    }
  };

  const handleInvitedPeople = (invitedPersonsArray) => {
    const extractedData = invitedPersonsArray?.map((person) => {
      const { fullName, id } = person;
      return { fullName, id };
    });

    setInvitedPeople(extractedData);
    getVisitors();
  };

  const onSetStartAt = (startDate) => {
    setStartAt(startDate);
    setStartAtUTC(
      moment.tz(startDate, props.timeZone).utc().format("YYYY-MM-DDTHH:mm")
    );

    // Update endAt only if it is before the new startAt
    if (moment(endAt).isBefore(moment(startDate))) {
      const updatedEndAt = moment(startDate)
        .add(duration, "minutes")
        .format("YYYY-MM-DDTHH:mm");
      setEndAt(updatedEndAt);
      setEndAtUTC(
        moment.tz(updatedEndAt, props.timeZone).utc().format("YYYY-MM-DDTHH:mm")
      );
    }
  };

  const onSetEndAt = (endDate) => {
    setEndAt(endDate);
    setEndAtUTC(
      moment.tz(endDate, props.timeZone).utc().format("YYYY-MM-DDTHH:mm")
    );
    setEndAtError("");
  };

  const handleEditorChange = (e) => {
    setDescription(e.target.value);
  };

  const MIN_SEARCH_LENGTH = 3;

  const handleInputChange = (event, value, reason) => {
    if (reason === "input") {
      clearTimeout(searchTimeout);

      if (value.length >= MIN_SEARCH_LENGTH) {
        searchTimeout = setTimeout(() => {
          getVisitors(value);
        }, 300);
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl fullWidth={true} variant="outlined">
          <div className={classes.checkboxTitle}>
            <b>{getLanguage("Title")}</b>
          </div>

          <TextField
            variant="outlined"
            value={title}
            required
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            label={getLanguage("Title")}
            className="textField"
          />
        </FormControl>
        <br />
        <br />
        <div className={classes.checkboxTitle}>
          <b>{getLanguage("MeetingDescription")}</b>
        </div>
        <Grid item xs>
          <TextField
            variant="outlined"
            multiline
            value={description}
            onChange={(e) => handleEditorChange(e)}
            label="Brief overview of the meeting's purpose"
            className="textField"
          />
        </Grid>
        <div className={classes.checkboxTitle}>
            {getLanguage("MeetingType")}
        </div>
        <Grid
          container
          style={{ borderBottom: "1px solid #000", paddingBottom: "20px" }}
        >
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={meetingType === NATIVE}
                  onChange={handleMeetingType}
                  value={NATIVE}
                  name="Built-in"
                  color="primary"
                />
              }
              label="Native Platform Video Technology"
            />
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="subtitle1">
                    Elevate your collaborative experience with our built-in
                    video meetings feature, seamlessly integrated into our
                    platform. Enjoy 100% embedded video meetings that offer
                    unparalleled convenience and efficiency.
                  </Typography>

                  <Typography>
                    <br></br>
                  </Typography>
                  <Typography variant="body1">
                    Host Access and Control:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Admin users have complete access and control over the
                          meetings.
                        </li>
                        <li>
                          Ensure a smooth and organized session with advanced
                          host capabilities.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Participant Capacity:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Accommodate up to 200 participants in each meeting.
                        </li>
                        <li>Foster large-scale collaboration with ease.</li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Camera and Sound:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Participants can enable their cameras and share
                          crystal-clear sound.
                        </li>
                        <li>
                          Enhance engagement and communication through
                          high-quality audiovisual interactions.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>
                </React.Fragment>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={meetingType === NATIVEVIEW}
                  onChange={handleMeetingType}
                  value={NATIVEVIEW}
                  name="Built-in"
                  color="primary"
                />
              }
              label="Native Platform Video Technology - Presentation only"
            />
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="subtitle1">
                    Elevate your collaborative experience with our built-in
                    video meetings feature, seamlessly integrated into our
                    platform. Enjoy 100% embedded video meetings that offer
                    unparalleled convenience and efficiency.
                  </Typography>

                  <Typography>
                    <br></br>
                  </Typography>
                  <Typography variant="body1">
                    Host Access and Control:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Admin users have complete access and control over the
                          meetings.
                        </li>
                        <li>
                          Ensure a smooth and organized session with advanced
                          host capabilities.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Participant Capacity:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Accommodate up to 600 participants in each meeting.
                        </li>
                        <li>Foster large-scale collaboration with ease.</li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Camera and Sound:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Participants can enable their cameras and share
                          crystal-clear sound.
                        </li>
                        <li>
                          Enhance engagement and communication through
                          high-quality audiovisual interactions.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>
                </React.Fragment>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={meetingType === URL}
                  onChange={handleMeetingType}
                  value={URL}
                  name="External meeting link"
                  color="primary"
                />
              }
              label="
              External meeting technology"
            />
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="subtitle1">
                    Enhance your collaborative experience by effortlessly
                    embedding links to external video meetings. Whether it's
                    Teams, Zoom, Hangouts, or other technologies, our platform
                    seamlessly integrates with them. This means that when you
                    click on a meeting link, you'll be taken to the relevant
                    technology while maintaining the immersive feeling of being
                    within our platform.
                  </Typography>
                  <Typography>
                    <br></br>
                  </Typography>
                  <Typography variant="body1">
                    Link Embedding:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Easily incorporate links to external meetings within
                          the platform.
                        </li>
                        <li>
                          Users can access these links with a simple click,
                          ensuring a smooth transition.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Technology Compatibility:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Works seamlessly with popular video meeting
                          technologies such as Teams, Zoom, Hangouts, and more.
                        </li>
                        <li>
                          Meetings open directly in the respective technology,
                          preserving the familiarity for users.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Unified Experience:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Enjoy the convenience of launching external meetings
                          directly from our platform.
                        </li>
                        <li>
                          Maintain a cohesive and unified user experience
                          throughout your collaboration.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Effortless Navigation:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          No need to switch between platforms; access external
                          meetings seamlessly within our interface.
                        </li>
                        <li>
                          Enhance productivity by reducing unnecessary
                          disruptions.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>
                </React.Fragment>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={meetingType === EMBED}
                  onChange={handleMeetingType}
                  value={EMBED}
                  name="Live/Pre-recorded content via embed"
                  color="primary"
                />
              }
              label="Pre-recorded content"
            />
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="subtitle1">
                    Unleash the power of prerecorded content by seamlessly
                    streaming it as if it were a live meeting. Our platform
                    allows you to use an embed code, providing a dynamic and
                    engaging experience for your audience. Leverage various
                    scheduling options to automate the streaming of prerecorded
                    content effortlessly.
                  </Typography>
                  <Typography>
                    <br></br>
                  </Typography>
                  <Typography variant="body1">
                    Embed Code Integration:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Easily incorporate an embed code to seamlessly stream
                          prerecorded content.
                        </li>
                        <li>
                          Create a lifelike, live meeting experience with just a
                          few clicks.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Automated Scheduling:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Utilize the platform's scheduling options to automate
                          the streaming of prerecorded content.
                        </li>
                        <li>
                          Ensure timely and consistent delivery without manual
                          intervention.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Dynamic Engagement:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Keep your audience engaged with content that feels
                          live and interactive.
                        </li>
                        <li>
                          Enhance the overall user experience with simulated
                          live meetings.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Effortless Automation:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Take advantage of scheduling options to automate the
                          streaming process.
                        </li>
                        <li>
                          Save time and resources by letting the platform handle
                          the logistics.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>
                </React.Fragment>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={meetingType === LOUNGE}
                  onChange={handleMeetingType}
                  value={LOUNGE}
                  name="Network room"
                  color="primary"
                />
              }
              label="
              Network groups in native platform video technology"
            />
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="subtitle1">
                    Immerse yourself in collaborative excellence with our
                    built-in video groups, seamlessly embedded within the
                    platform. These exclusive groups are accessible solely from
                    the network area, ensuring a secure and private space for
                    your team. Admin users have complete host access and
                    control, facilitating smooth coordination for up to 200
                    participants, each equipped with camera and sound
                    capabilities.
                  </Typography>
                  <Typography>
                    <br></br>
                  </Typography>
                  <Typography variant="body1">
                    Network-Centric Access:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Video groups are 100% embedded within the platform,
                          accessible exclusively from the network area.
                        </li>
                        <li>
                          Enhance security and privacy by limiting visibility to
                          designated users.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Admin Control:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Admin users enjoy comprehensive host access and
                          control within the video groups.
                        </li>
                        <li>
                          Foster efficient collaboration with advanced
                          administrative capabilities.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Participant Capacity:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Accommodate up to 200 participants in each video
                          group.
                        </li>
                        <li>
                          Enable large-scale discussions and interactions within
                          your secure network.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>

                  <Typography variant="body1">
                    Secure Collaboration Hub:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Enjoy the confidence of collaborating within a secure
                          and designated network space.
                        </li>
                        <li>
                          Protect sensitive discussions and information with
                          restricted access.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>
                  <Typography variant="body1">
                    Effortless Administration:
                    <Typography variant="subtitle2" display="block">
                      <ul>
                        <li>
                          Admin users can easily manage and control video group
                          activities.
                        </li>
                        <li>
                          Ensure a streamlined and organized collaboration
                          experience.
                        </li>
                      </ul>
                    </Typography>
                  </Typography>
                </React.Fragment>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "100%",
            borderBottom: "1px solid #000",
            paddingBottom: "20px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isPrivate}
                onChange={(e) => setIsPrivate(e.target.checked)}
                value={RESTRICTEDMEETING}
                name={RESTRICTEDMEETING}
                color="primary"
              />
            }
            label={RESTRICTEDMEETING}
          />
        </Grid>

        {meetingType === EMBED && (
          <>
            <br />
            <br />
            <TextField
              variant="outlined"
              multiline={true}
              rows={5}
              value={startUrl}
              onChange={(e) => {
                setStartUrl(e.target.value);
              }}
              className="textField"
              label={getLanguage("InsertEmbedCode")}
            />
          </>
        )}
        {isPrivate && (
          <>
            <br />
            <br />
            <div>
              <p>Invite people to meeting</p>
            </div>
            <div>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                onOpen={() => {
                  if (invitedPeople && invitedPeople.length > 0) {
                    setOpen(true);
                  }
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onInputChange={handleInputChange}
                isOptionEqualToValue={(option, defaultValue) =>
                  option.id === defaultValue.id
                }
                filterOptions={filterSearchOptions}
                options={visitors}
                onChange={(event, value) => handleInvitedPeople(value)}
                disableCloseOnSelect
                defaultValue={invitedPeople || null}
                getOptionLabel={(option) =>
                  isEmpty(option.fullName) ? option.email : option.fullName
                }
                renderOption={(props, option) => {
                  props.key = props.id;
                  return (
                    <li {...props}>
                      {`${option.fullName}`}
                      <Chip
                        style={{
                          marginLeft: "auto",
                          color: "#fff",
                          backgroundColor: "#121C42",
                        }}
                        label={option.email}
                      />
                    </li>
                  );
                }}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Please enter at least 3 characters to search."
                  />
                )}
              />
            </div>
          </>
        )}
        <br />
        <br />
        <FormControl fullWidth={true} variant="outlined">
          {meetingType === URL && (
            <TextField
              variant="outlined"
              error={Boolean(externalUrlError)}
              helperText={externalUrlError}
              multiline={false}
              rows={1}
              value={externalUrl}
              onChange={(e) => {
                setExternalUrl(e.target.value);
              }}
              className="textField"
              label={getLanguage("ExternalUrl")}
            />
          )}

          <TextField
            type="datetime-local"
            variant="outlined"
            value={startAt}
            onChange={(e) => {
              onSetStartAt(e.target.value);
            }}
            label={getLanguage("StartAt")}
            className="textField"
          />
        </FormControl>
        <FormControl fullWidth={true} variant="outlined">
          <TextField
            type="datetime-local"
            error={endAtError}
            helperText={endAtError}
            variant="outlined"
            value={endAt}
            onChange={(e) => {
              onSetEndAt(e.target.value);
            }}
            label={getLanguage("EndAt")}
            className="textField"
          />
        </FormControl>
        <i>
          {getLanguage("TimesWillBeSavedInTheFollowingTimezone").replace(
            "{Timezone}",
            props.timeZone
          )}
        </i>
        <br />
        <br />

        {meetingType === LOUNGE && (
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <TextField
              variant="outlined"
              type="number"
              value={maxAttendees}
              onChange={(e) => {
                setMaxAnttendees(e.target.value);
              }}
              label={getLanguage("MaxRegistrations(0IsUnlimited)")}
              className="textField"
            />
          </FormControl>
        )}

        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel
            className={classes.selectLabel}
            id="pageSelector-label"
            required
          >
            {getLanguage("Page")}
          </InputLabel>
          <Select
            variant="outlined"
            fullWidth={true}
            value={pageId}
            onChange={handlePageChanged}
            labelId="pageSelector-label"
            id="pageSelector"
          >
            <MenuItem key={""} value={""}>
              {getLanguage("AllPages")}
            </MenuItem>
            {getPageOptions()}
          </Select>
        </FormControl>

        <br />
        <br />

        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          {getLanguage("Save")}
        </Button>
      </form>
    </>
  );
};

export default EditMeetingDetails;
