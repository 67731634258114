import React from 'react'
import { Multiselect } from 'multiselect-react-dropdown'

const MultiSelector = (props) => {
  let styles = {
    searchBox: { 'min-height': '40px', margin: 'auto', 'font-size': '16px' },
    chips: { height: '30px', 'font-size': '16px' },
    inputField: { 'min-height': '40px', margin: 'auto', 'font-size': '16px' },
    multiselectContainer: { 'margin-bottom': '10px', 'font-size': '16px' },
  }
  return (
    <Multiselect
      options={props.items}
      selectedValues={props.selectedValues}
      onSelect={props.onSelect}
      onRemove={props.onRemove}
      displayValue={props.displayValue}
      placeholder={props.placeholder}
      style={styles}
    />
  )
}

export default MultiSelector
