import './EditTemplateDetails.css'
import { Button, Container, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import TemplateObjectStyleEditor from './TemplateObjectStyleEditor'
import orderBy from 'lodash/orderBy'
import { GlobalDialog } from '../../../core/GlobalDialog'
import { Help } from '@material-ui/icons'
import ReactTooltip from 'react-tooltip'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  helpButon: {
    margin: '13px 0 0 10px',
  },
}))

const EditTemplateObjectStyles = (props) => {
  const classes = useStyles()

  const [styleToRemove, setStyleToRemove] = useState(null)

  const addStyle = async (obj) => {
    obj.templateObjectId = props.templateObject.id
    props.addStyle(obj)
  }

  const updateStyle = async (id, obj) => {
    props.updateStyle(id, obj)
  }

  const deleteStyle = async () => {
    props.deleteStyle(styleToRemove.id)
    setStyleToRemove(null)
  }

  let styles = props.templateObject.templateObjectStyles
  styles = orderBy(styles, ['styleKey'], ['asc'])

  return (
    <>
      <Grid container>
        <Grid>
          <h3>{getLanguage('EditStyles')}</h3>
        </Grid>
        <Grid>
          <div data-tip data-for="EditStylesHelp" className={classes.helpButon}>
            <Help />
          </div>
          <ReactTooltip id="EditStylesHelp" aria-haspopup="true">
            <p>
              <b>{getLanguage('ControlTheCssOfTheObject')}</b>
            </p>
            <div>
              <p>
                {getLanguage('StyleNamesShouldReflectTheActualCssProperties')}
              </p>
              <p>
                {getLanguage('ExamplesOfStyleNames')}
                <ul>
                  <li>top</li>
                  <li>height</li>
                  <li>position</li>
                </ul>
              </p>
            </div>
          </ReactTooltip>
        </Grid>
      </Grid>
      {styles.map((style) => {
        return (
          <TemplateObjectStyleEditor
            templateObjectStyle={style}
            onSave={(obj) => updateStyle(style.id, obj)}
            onDelete={(obj) => setStyleToRemove(obj)}
          />
        )
      })}

      <b>{getLanguage('AddStyle')}</b>
      <TemplateObjectStyleEditor
        onSave={(obj) => addStyle(obj)}
        onDelete={(obj) => deleteStyle(obj)}
      />

      <GlobalDialog
        isDialogOpen={styleToRemove !== null}
        handleDialogClose={() => setStyleToRemove(null)}
        title={getLanguage('DeleteStyle')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisStyle?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => deleteStyle()}
          >
            {getLanguage('Delete')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  )
}

export default EditTemplateObjectStyles
