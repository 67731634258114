import { getApiPath } from '../../../core/ServiceRoutes';
import Axios from 'axios';
import { LoginHelper } from '../../../core/LoginHelper';

export class NewsItemApi {
  loginHelper = new LoginHelper();

  async getNewsItem(id) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.get(`${getApiPath()}/NewsItem/${id}`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getNewsItems(hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.get(`${getApiPath()}/newsitems`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async addNewsItem(newsItem) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: newsItem.hiveId,
        },
      };
      let response = await Axios.post(
        `${getApiPath()}/newsitems`,
        newsItem,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateNewsItem(id, newsItem) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.put(
        `${getApiPath()}/newsitems/${id}`,
        newsItem,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async deleteNewsItem(id) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.delete(
        `${getApiPath()}/newsitems/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }
}
