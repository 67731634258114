import './ObjectEditor.css';
import { TextField, FormControl } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import LanguageSelector from '../../language/components/LanguageSelector';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: { marginTop: theme.spacing(2) },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const ObjectFunctionalButtonEditor = (props) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [tooltipText, setTooltipText] = useState('');
  const [data, setData] = useState('');

  useEffect(() => {
    if (props.pageObject) {
      setText(props.pageObject.text);
      setTooltipText(props.pageObject.tooltipText);
      setData(props.pageObject.data);
    }

    // eslint-disable-next-line
  }, []);

  const attemptUpdateAttribute = (attribute, newValue) => {
    if (props.updateAttribute) {
      props.updateAttribute(attribute, newValue);
    }
  };

  const onDataChange = (e) => {
    setData(e.target.value);
    attemptUpdateAttribute('data', e.target.value);
  };

  return (
    <>
      <h2>{getLanguage('Button')}</h2>
      <TextField
        variant='outlined'
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          attemptUpdateAttribute('text', e.target.value);
        }}
        label={getLanguage('TitleMaxCharactersForButton')}
        className='textField'
      />
      <LanguageSelector
        pageObject={props.pageObject}
        attemptUpdateAttribute={attemptUpdateAttribute}
        contentType={'hotspot'}
      >
        <TextField
          variant='outlined'
          label={getLanguage('TitleMaxCharactersForButton')}
          className='textField'
        />
      </LanguageSelector>
      <br />
      {props.pageObject.templateObject.tooltipPosition !== 'None' && (
        <>
          <TextField
            multiline={true}
            rows={3}
            variant='outlined'
            value={tooltipText}
            onChange={(e) => {
              setTooltipText(e.target.value);
              attemptUpdateAttribute('tooltipText', e.target.value);
            }}
            label={getLanguage('MouseoverText')}
            className='textField'
          />
          <br />
        </>
      )}
      <div>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            rows={1}
            variant='outlined'
            value={data}
            onChange={(e) => onDataChange(e)}
            label={getLanguage('LinkData')}
            className='textField'
          />
        </FormControl>
      </div>
    </>
  );
};

export default ObjectFunctionalButtonEditor;
