import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from '@material-ui/core';
import {
  ExpandMore,
  PermScanWifi,
  AccessTime,
  AccountTree,
  Error,
  Sync,
  WifiTethering,
  DataUsage,
  Language,
} from '@material-ui/icons';
import getLanguage from '../../../core/Language';
import moment from 'moment';
import { LoginHelper } from '../../../core/LoginHelper';

const EmailInfo = ({ emailInfoData, email }) => {
  const loginHelper = new LoginHelper();
  const doesEmailDataExist = emailInfoData?.length > 0;
  return (
    <>
      <Box sx={{ fontSize: '20px', marginBottom: 24 }}>{email}</Box>
      <Box>
        {doesEmailDataExist ? (
          emailInfoData.map((emailInfo, emailIndex) => {
            const doesEmailEventsExist = emailInfo?.events?.length > 0;
            return (
              <Accordion
                style={{ marginBottom: '16px', color: '#19275d' }}
                key={emailIndex}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <PermScanWifi />
                  <Box
                    sx={{
                      display: 'flex',
                      marginLeft: '40px',
                      marginRight: '20px',
                    }}
                  >
                    <Typography sx={{ color: '#19275d' }}>
                      {emailInfo.subject}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box
                      sx={{
                        fontWeight: 600,
                        fontSize: '20px',
                        marginBottom: '16px',
                        color: '#19275d',
                      }}
                    >
                      {getLanguage('EmailStatus')}
                    </Box>
                    {doesEmailEventsExist ? (
                      emailInfo.events.map((mailInfoEvent, eventIndex) => {
                        const mailInfoEntries = Object.entries(mailInfoEvent);
                        const isNotLastEvent =
                          eventIndex !== emailInfo.events.length - 1;
                        return (
                          <ListItem key={eventIndex}>
                            <List>
                              {mailInfoEntries.map(([key, value], idx) => {
                                return (
                                  key !== 'email' && (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '16px',
                                      }}
                                      key={idx}
                                    >
                                      <ListItemAvatar>
                                        <Avatar
                                          style={{
                                            width: 30,
                                            height: 30,
                                            backgroundColor: '#e15b3f',
                                          }}
                                        >
                                          {key === 'timestamp' ? (
                                            <AccessTime
                                              style={{ width: 16, height: 16 }}
                                            />
                                          ) : key === 'event' ? (
                                            <AccountTree
                                              style={{ width: 16, height: 16 }}
                                            />
                                          ) : key === 'reason' ? (
                                            <WifiTethering
                                              style={{ width: 16, height: 16 }}
                                            />
                                          ) : key === 'type' ? (
                                            <DataUsage
                                              Language
                                              style={{ width: 16, height: 16 }}
                                            />
                                          ) : key === 'status' ? (
                                            <Sync
                                              style={{ width: 16, height: 16 }}
                                            />
                                          ) : key === 'url' ? (
                                            <Language
                                              style={{ width: 16, height: 16 }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </Avatar>
                                      </ListItemAvatar>
                                      {key === 'timestamp' ? (
                                        <ListItemText
                                          primary={
                                            <Typography
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {key}
                                            </Typography>
                                          }
                                          secondary={moment
                                            .utc(value)
                                            .tz(loginHelper.getUserTimezone())
                                            .locale('en')
                                            .format('LLL')}
                                        />
                                      ) : (
                                        <ListItemText
                                          primary={
                                            <Typography
                                              style={{
                                                textTransform: 'capitalize',
                                              }}
                                            >
                                              {key}
                                            </Typography>
                                          }
                                          secondary={value}
                                        />
                                      )}
                                    </Box>
                                  )
                                );
                              })}
                              {isNotLastEvent && (
                                <Divider
                                  style={{
                                    width: '1px',
                                    height: '30px',
                                    marginLeft: '14px',
                                    backgroundColor: 'rgba(25, 39, 93, 1)',
                                    textAlign: 'center',
                                  }}
                                  orientation={'vertical'}
                                  flexItem
                                />
                              )}
                            </List>
                          </ListItem>
                        );
                      })
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Error
                          style={{
                            width: 30,
                            height: 30,
                            color: '#ea2429',
                            marginRight: '16px',
                          }}
                        />
                        <Box></Box>
                        {getLanguage('NoDataCouldBeFound')}
                      </Box>
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Error
              style={{
                width: 30,
                height: 30,
                color: '#ea2429',
                marginRight: '16px',
              }}
            />
            <Box>{getLanguage('NoMailHasBeenSentYet')}</Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EmailInfo;
