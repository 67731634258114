import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AuthorizationService } from '../services/AuthorizationService';
import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Link,
} from '@material-ui/core';
import getLanguage from '../../../core/Language';
import { navigateToForgotPassword_Page } from '../../../core/Navigation';

import { makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from '../../../core/LoadingIndicator';
import { WebSocketContext } from '../../../socket/EchoSocket';
import people from '../../../img/people.svg';
import QuoteSlider from '../components/QuoteSlider';

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '480px',
  },
  teaserWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    borderRadius: '12px',
    border: '4px solid #fff',
    height: '100%',
    background: 'transparent',
    color: '#121C42',
    width: '500px',
    animation: '$moveIn 600ms ease-out',
    maxHeight: '500px',
    justifyContent: 'center',
  },
  people: {
    height: '70%',
    width: '70%',
    background: `url(${people}) no-repeat center center`,
    backgroundSize: 'contain',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 80px',
    borderRadius: '12px',
    height: '100%',
    background: '#fff',
    color: '#121C42',
    width: '500px',
    animation: '$moveIn 600ms ease-out',
    maxHeight: '500px',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  '@keyframes wave-animation': {
    '0%': { transform: 'rotate(0deg)' },
    '10%': { transform: 'rotate(14deg)' },
    '20%': { transform: 'rotate(-8deg)' },
    '30%': { transform: 'rotate(14deg)' },
    '40%': { transform: 'rotate(-4deg)' },
    '50%': { transform: 'rotate(10deg)' },
    '60%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
  wave: {
    animationName: '$wave-animation',
    animationDuration: '2.5s',
    animationDelay: '2s',
    transformOrigin: '70% 70%',
    display: 'inline-block',
    marginLeft: '4px',
  },
  form: {
    width: '91%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(6, 0, 6),
    background: '#121C42',
    borderRadius: '6px',
    color: '#fff',
    padding: '10px',
    transition: 'all 400ms ease-in-out',
    '&:hover': {
      background: '#282C74',
      transform: 'scale(1.02)',
    },
  },
  signIn: {
    fontWeight: '400',
    opacity: '0.8',
    fontSize: '0.9rem',
    paddingTop: '10px',
  },

  welcome: {
    margin: theme.spacing(6, 0, 4),
    fontWeight: '600',
  },
  forgotPass: {
    color: '#C1666B',
    fontSize: '0.8rem',
    margin: theme.spacing(2, 0, 0),
    display: 'block',
  },
}));

const Login_Page = (props) => {
  const classes = useStyles();

  const authService = new AuthorizationService();
  const echo = useContext(WebSocketContext);

  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [editIsOpen, setEditIsOpen] = useState(false);

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = async () => {
    requestedSetIsLoading(false);
  };
  const requestedSetIsLoading = (val) => {
    setIsLoading(val);
  };

  const submitData = async (props, login, pass) => {
    requestedSetIsLoading(true);

    var res = await authService.login(
      JSON.stringify({
        Email: login,
        Password: pass,
      })
    );
    if (res) {
      echo.sendAuth();
      window.location.href = '/';
    } else {
      requestedSetIsLoading(false);
    }
  };

  // const requestedCloseEdit = async
  return (
    <div className={classes.loginWrapper}>
      <Container maxWidth='sm'>
        <div className={classes.paper}>
          <Typography className={classes.welcome} component='h1' variant='h5'>
            {getLanguage('WelcomeToVirtualHive')}{' '}
            <div className={classes.wave}>👋</div>
            <div className={classes.signIn}>
              {getLanguage('PleaseLogInToYourAccount')}
            </div>
          </Typography>

          {isLoading && (
            <>
              <br />
              <LoadingIndicator />
            </>
          )}
          {!isLoading && (
            <>
              <form
                className={classes.form}
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  submitData(props, email, password);
                }}
              >
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label={getLanguage('Email')}
                  name='email'
                  autoComplete='email'
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label={getLanguage('Password')}
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Link
                  className={classes.forgotPass}
                  color='inherit'
                  href='#'
                  onClick={(e) => {
                    e.preventDefault();
                    navigateToForgotPassword_Page(props);
                  }}
                >
                  {getLanguage('ForgotPassword')}
                </Link>

                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                >
                  {getLanguage('SignIn')}
                </Button>
              </form>
            </>
          )}
        </div>
      </Container>
      <Container maxWidth='sm'>
        <div className={classes.teaserWrapper}>
          <div className={classes.people}></div>
          <QuoteSlider />
        </div>
      </Container>
    </div>
  );
};

export default Login_Page;
