import { getApiPath } from '../../../core/ServiceRoutes'
import Axios from 'axios'
import { LoginHelper } from '../../../core/LoginHelper'

export class KeywordApi {
  loginHelper = new LoginHelper()

  async getKeyword(id) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }
      let response = await Axios.get(`${getApiPath()}/keywords/${id}`, config)
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async getKeywords(hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      }
      let response = await Axios.get(`${getApiPath()}/keywords`, config)
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async addKeyword(keyword) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }
      let response = await Axios.post(
        `${getApiPath()}/keywords`,
        keyword,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateKeyword(id, keyword) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/keywords/${id}`,
        keyword,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async deleteKeyword(id) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.delete(
        `${getApiPath()}/keywords/${id}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async addKeywordToPage(pageId, keywordId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.post(
        `${getApiPath()}/page/${pageId}/keyword/${keywordId}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async removeKeywordFromPage(pageId, keywordId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.delete(
        `${getApiPath()}/page/${pageId}/keyword/${keywordId}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }
}
