import './EditNewsItemDetails.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { HiveService } from '../../hive/services/HiveService';

const useStyles = makeStyles((theme) => ({
  formControl: { marginTop: theme.spacing(1) },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditNewsItemDetails = (props) => {
  const classes = useStyles();
  const hiveService = new HiveService();

  const [text, setText] = useState('');
  const [translationValues, setTranslationValues] = useState([]);
  const [textError, setTextError] = useState('');
  const [sortingKey, setSortingKey] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().format('YYYY-MM-DDTHH:00')
  );
  const [startDateUTC, setStartDateUTC] = useState(
    moment().format('YYYY-MM-DDTHH:00')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DDTHH:00'));
  const [endDateUTC, setEndDateUTC] = useState(
    moment().format('YYYY-MM-DDTHH:00')
  );
  const [endDateError, setEndDateError] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [hiveDetails, setHiveDetails] = useState({});
  const [translations, setTranslations] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    language: '',
    languageCode: '',
  });
  const [textTextContent, setTextTextContent] = useState({});
  const [textTextContentId, setTexTextContentId] = useState('');
  const [translationObject, setTranslationObject] = useState([]);

  const checkForExistingTextHandler = () => {
    const defaultLanguageObject = translationObject.find(
      (obj) => obj.languageId === hiveDetails.defaultLanguage?.id
    );
    return defaultLanguageObject?.translation.length > 0
      ? defaultLanguageObject.translation
      : props.newsItem?.text;
  };

  useEffect(() => {
    if (props.newsItem) {
      setText(props.newsItem.text);
      setSortingKey(props.newsItem.sortingKey);
      setStartDate(
        moment
          .utc(props.newsItem.startDate)
          .tz(props.timeZone)
          .format('YYYY-MM-DDTHH:mm')
      );
      setStartDateUTC(
        moment.utc(props.newsItem.startDate).format('YYYY-MM-DDTHH:mm')
      );
      setEndDate(
        moment
          .utc(props.newsItem.endDate)
          .tz(props.timeZone)
          .format('YYYY-MM-DDTHH:mm')
      );
      setEndDateUTC(
        moment.utc(props.newsItem.endDate).format('YYYY-MM-DDTHH:mm')
      );
      setIsActive(props.newsItem.isActive);
    } else {
      setStartDateUTC(
        moment.tz(startDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
      );
      setEndDateUTC(
        moment.tz(startDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
      );
    }

    fetchHiveDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTranslationValues({
      ...translationValues,
      [selectedLanguage.languageCode]: props.newsItem?.text || '',
    });
  }, [hiveDetails]);

  useEffect(() => {
    if (props?.newsItem?.textTextContent !== null) {
      setTexTextContentId(props?.newsItem?.textTextContent?.id);
    } else {
      setTexTextContentId('');
    }
  }, [props.newsItem]);

  const fetchHiveDetails = () => {
    hiveService.getHive().then((hiveService) => {
      setHiveDetails(hiveService);
      setSelectedLanguage({
        language: hiveService.defaultLanguage.name,
        languageCode: hiveService.defaultLanguage.id,
      });
      setTranslations(hiveService.hiveLanguages);
    });
  };

  const onSaveClicked = async () => {
    var validationError = false;

    // Validate text not empty
    if (!text) {
      setTextError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setTextError('');
    }

    if (validationError) {
      return;
    }

    // Validate endDate if after startStart
    if (!moment(endDate).isAfter(moment(startDate))) {
      setEndDateError(getLanguage('ActiveToIsNotAfterActiveFrom'));
      validationError = true;
    } else {
      setEndDateError('');
    }

    if (validationError) {
      return;
    }

    props.onSave(
      {
        text: checkForExistingTextHandler(),
        sortingKey,
        importance: 1,
        startDate: startDateUTC,
        endDate: endDateUTC,
        isActive,
        textTextContent,
        newsItemCategoryId: null,
      },
      null
    );
  };

  const onSetStartDate = (startDate) => {
    setStartDate(startDate);
    setStartDateUTC(
      moment.tz(startDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
    );
  };

  const onSetEndDate = (endDate) => {
    setEndDate(endDate);
    setEndDateUTC(
      moment.tz(endDate, props.timeZone).utc().format('YYYY-MM-DDTHH:mm')
    );
  };

  const handleSelectedLanguage = (event) => {
    const translation = props?.newsItem?.textTextContent.translations.find(
      (t) => t.languageId === event.currentTarget.id
    );
    const text =
      translation &&
      translation.translation &&
      !translationValues[event.currentTarget.id]
        ? translation.translation
        : translationValues[event.currentTarget.id] || '';

    setSelectedLanguage({
      language: event.target.value,
      languageCode: event.currentTarget.id,
    });
    setText(text);
  };

  const handleTextChange = (event) => {
    const { value } = event.target;
    setTranslationValues({
      ...translationValues,
      [selectedLanguage.languageCode]: value,
    });
    setText(value);
  };

  useEffect(() => {
    setTranslationObject(
      Object.entries(translationValues).map(([language, translationText]) => ({
        languageId: language,
        translation: translationText,
      }))
    );
  }, [translationValues]);

  useEffect(() => {
    const filteredTranslationObject = translationObject.filter(
      (language) =>
        language.languageId !== hiveDetails.defaultLanguage?.id &&
        language.languageId !== ''
    );

    if (textTextContentId) {
      setTextTextContent({
        id: textTextContentId,
        originalText: checkForExistingTextHandler(),
        originalLanguageId: hiveDetails?.defaultLanguage?.id,
        translations: filteredTranslationObject,
      });
    } else {
      setTextTextContent({
        originalText: checkForExistingTextHandler(),
        originalLanguageId: hiveDetails?.defaultLanguage?.id,
        translations: filteredTranslationObject,
      });
    }
  }, [translationObject]);

  const languageOptions = translations.map((translation) => (
    <MenuItem value={translation.name} key={translation.id} id={translation.id}>
      {translation.name}
    </MenuItem>
  ));

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel
            className={classes.selectLabel}
            id='categorySelector-label'
          >
            {getLanguage('Translation')}
          </InputLabel>
          <Select
            variant='outlined'
            fullWidth={true}
            value={selectedLanguage.language}
            onChange={handleSelectedLanguage}
            labelId='categorySelector-label'
            id='categorySelector'
          >
            {languageOptions}
          </Select>
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={textError}
            helperText={textError}
            value={text}
            onChange={handleTextChange}
            label={`${getLanguage('Text')} - ${selectedLanguage.language}`}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            }
            label={getLanguage('Enabled')}
            className='checkbox'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            type='datetime-local'
            variant='outlined'
            value={startDate}
            onChange={(e) => {
              onSetStartDate(e.target.value);
            }}
            label={getLanguage('ActiveFrom')}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            type='datetime-local'
            error={endDateError}
            helperText={endDateError}
            variant='outlined'
            value={endDate}
            onChange={(e) => {
              onSetEndDate(e.target.value);
              setEndDateError('');
            }}
            label={getLanguage('ActiveTo')}
            className='textField'
          />
        </FormControl>
        <i>
          {getLanguage('TimesWillBeSavedInTheFollowingTimezone').replace(
            '{Timezone}',
            props.timeZone
          )}
        </i>
        <br />
        <br />

        <br />

        <Button
          type='submit'
          fullWidth={true}
          variant='contained'
          color='primary'
        >
          {getLanguage('Save')}
        </Button>
      </form>
    </>
  );
};

export default EditNewsItemDetails;
