import moment from 'moment'

function getCachedObject(identifier) {
  try {
    let cache = localStorage.getItem('objCache_' + identifier)
    if (!cache) {
      return null
    }

    cache = JSON.parse(cache)

    if (moment(cache.expire, 'YYYY-MM-DD HH:mm:ss') < moment()) {
      return null
    }

    return cache.contents
  } catch (e) {
    return null
  }
}

function setObjectInCache(identifier, contents, expireMinutes) {
  try {
    localStorage.setItem(
      'objCache_' + identifier,
      JSON.stringify({
        contents: contents,
        expire: moment().add(expireMinutes, 'm').format('YYYY-MM-DD HH:mm:ss'),
      }),
    )
  } catch (e) {}
}

function clearFromCache(identifier) {
  try {
    localStorage.removeItem('objCache_' + identifier)
  } catch (e) {}
}

function clearAllCaches() {
  try {
    for (let key of Object.entries(localStorage).keys) {
      if (key.startsWith('objCache_')) {
        try {
          localStorage.removeItem(key)
        } catch (e) {}
      }
    }
  } catch (e) {}
}

export { getCachedObject, setObjectInCache, clearFromCache, clearAllCaches }
