import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuList from "./MenuList"

import Divider from '@material-ui/core/Divider';
import { LoginHelper } from './LoginHelper';

const drawerWidth = 260;
const anchor = 'left';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        background: "#19275d",
        color: "#FFF",
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background:"#19275d",
        overflowX: 'hidden',
        color: "#FFF",
        width: theme.spacing(5) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6),
        },
    },
    drawerCloseExtraWide: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(5) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8) + 1,
            
        },
        background:"#19275d",
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        minHeight: 48,
        //  height: 35
    },
    icon: {
        fill: "#FFF"
    }
}));

const MenuDrawer = (props) => {

    let loginHelper = new LoginHelper();
    let currentLogin = loginHelper.getLoginObject();

    const classes = useStyles();
    const theme = useTheme();

    if (props.type === "permanent") {

        return (
            <Drawer hidden={currentLogin == null ? true : false}
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.draweropen,
                    [classes.drawerClose]: !props.draweropen && !props.secondLevelOpen,
                    [classes.drawerCloseExtraWide]: !props.draweropen && props.secondLevelOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.draweropen,
                        [classes.drawerClose]: !props.draweropen && !props.secondLevelOpen,
                        [classes.drawerCloseExtraWide]: !props.draweropen && props.secondLevelOpen,
                    }),
                }}
            >
                <div className={classes.toolbar} >
                    <IconButton onClick={() => props.requestClose && props.requestClose()} className={classes.closeButton}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon className={classes.icon}/> : <ChevronLeftIcon className={classes.icon} />}
                    </IconButton>
                </div>
                <Divider />
                <MenuList closedSecondLevel={props.closedSecondLevel} openedSecondLevel={props.openedSecondLevel} />
            </Drawer>
        );
    }

    if (props.type === "floating") {
        return (
            <Drawer className={clsx(classes.drawer, {
                [classes.drawerOpen]: props.draweropen,
                [classes.drawerClose]: !props.draweropen,
            })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.draweropen,
                        [classes.drawerClose]: !props.draweropen,
                    }),
                }}
                anchor={anchor} open={props.draweropen} onClose={() => props.requestClose && props.requestClose()}>
                <MenuList requestClose={() => props.requestClose && props.requestClose()} closedSecondLevel={props.closedSecondLevel} openedSecondLevel={props.openedSecondLevel} />
            </Drawer>
        );
    }

};

export default MenuDrawer;