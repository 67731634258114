import { Link, Paper, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { StorageHelper } from '../../../core/StorageHelper';
import { navigateToEditPagesEvent } from '../../../core/Navigation';
import { PageService } from '../../page/services/PageService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 6,
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  divroot: {
    width: '100%',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 433,
  },
  createButton: {
    margin: '10px 0 10px 0',
  },
  pageTitle: {
    fontSize: '16px',
  },
  pageSubTitle: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
  scrollingPreview: {
    position: 'fixed',
    width: '58%',
    maxWidth: '885px',
  },
  iframe: {
    width: '95%',
    height: '380px',
  },
}));

const EventPages_Page = (props) => {
  const classes = useStyles();
  const pageService = new PageService();
  const storageHelper = new StorageHelper();

  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [defaultSortColumn, setDefaultSortColumn] = useState('title');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');

  useEffect(() => {
    loadPages(false);
    // eslint-disable-next-line
  }, []);

  const loadPages = async () => {
    let response = await pageService.getPages(
      storageHelper.getCurrentHive().id
    );
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      let sortCol = storageHelper.getHiveSortingColumn();
      let sortDirection = storageHelper.getHiveSortingDirection();

      if (sortCol) {
        setDefaultSortColumn(sortCol);
      }
      if (sortDirection) {
        setDefaultSortDirection(sortDirection);
      }
      let filteredList = [];
      for (const page of response) {
        if (page.canContainEvents) {
          filteredList.push(page);
        }
      }
      setPages(filteredList);
      setIsLoading(false);
    }
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    // storageHelper.setHiveSortingColumn(field);
    // storageHelper.setHiveSortingDirection(direction);

    return orderBy(rows, handleField, direction);
  };

  const columns = [
    {
      name: getLanguage('Title'),
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => (
        <Link
          color='inherit'
          onClick={(e) => {
            e.preventDefault();
            navigateToEditPagesEvent(props, row.id);
          }}
        >
          <span className={classes.name}>{row.title}</span>
        </Link>
      ),
    },
  ];

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}
      <div>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Paper className={classes.paper}>
              {pages && (
                <DataTable
                  title={getLanguage('EventsPickPage')}
                  columns={columns}
                  data={pages}
                  pagination={true}
                  selectableRows={false}
                  sortFunction={customSort}
                  defaultSortField={defaultSortColumn}
                  defaultSortAsc={defaultSortDirection === 'asc'}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={7}>
            {pages && (
              <div className={classes.scrollingPreview}>
                <iframe
                  name='iframe1'
                  key='iframe1'
                  className={classes.iframe}
                  src={`${pages[0]?.hive?.clientUrl}?featprogram=previewfull`}
                />
                <a
                  style={{ display: 'table-cell' }}
                  href={`${pages[0]?.hive?.clientUrl}?featprogram=previewfull`}
                  target='_blank'
                >
                  {getLanguage('OpenPreviewInNewTab')}
                </a>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <br />
    </>
  );
};

export default EventPages_Page;
