import { Button, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import FolderIcon from '@material-ui/icons/Folder'
import { ResourceService } from './../services/ResourceService'

const ResourceUpload = (props) => {
  const resourceService = new ResourceService()

  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    onFileUpload()
    // eslint-disable-next-line
  }, [selectedFile])

  const onFileChange = async (evt) => {
    setSelectedFile(evt.target.files)
  }

  const onFileUpload = async () => {
    if (selectedFile) {
      if (props.blockVideo && selectedFile[0].type.startsWith('video/')) {
        return
      }

      props.fileUploadStarted()
      let result = await resourceService.createResource(
        props.uploadedFor,
        selectedFile[0],
      )
      setSelectedFile(null)
      props.fileUploadedCallback(result.id)
    }
  }

  return (
    <>
      <input
        id={'fileUpload' + props.uniqueKey}
        type="file"
        onChange={onFileChange}
        hidden
        accept={props.acceptFileTypes}
      />
      <Tooltip title="File explorer">
        <label htmlFor={'fileUpload' + props.uniqueKey}>
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            component="span"
          >
            <FolderIcon /> {getLanguage('SelectFile')}
          </Button>
        </label>
      </Tooltip>
    </>
  )
}

export default ResourceUpload
