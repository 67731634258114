import './ObjectEditor.css';
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import getLanguage from '../../../core/Language';
import { ResourceService } from './../../resource/services/ResourceService';
import { GlobalDialog } from '../../../core/GlobalDialog';
import VideoUpload from '../../resource/components/VideoUpload';
import { makeStyles } from '@material-ui/core/styles';
import { HiveService } from '../../hive/services/HiveService';
import moment from 'moment';
import { ToastsStore } from 'react-toasts';
import { LoginHelper } from '../../../core/LoginHelper';
import { v4 as uuidv4 } from 'uuid';
import LanguageSelector from '../../language/components/LanguageSelector';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const ObjectVideoEditor = (props) => {
  const classes = useStyles();
  const resourceService = new ResourceService();
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();

  const [link, setLink] = useState('');
  const [removeIsOpen, setRemoveIsOpen] = useState(false);
  const [removeTranslationIsOpen, setRemoveTranslationIsOpen] = useState(false);
  const [showUploadOrExternalPart, setShowUploadOrExternalPart] =
    useState('upload');
  const [videoIsTranscoding, setVideoIsTranscoding] = useState(null);
  const [resources, setResources] = useState([]);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [hiveDetails, setHiveDetails] = useState({});
  const [translationCode, setTranslationCode] = useState('');
  const [resourceIdsObject, setResourceIdsObject] = useState({});
  const [removeTranslation, setRemoveTranslation] = useState(false);

  useEffect(() => {
    if (props.pageObject.data) {
      setLink(props.pageObject.data);
      setShowUploadOrExternalPart('external');
    }

    checkTranscodeStatus();

    if (
      loginHelper.userHasRole('PageEditor') ||
      loginHelper.userHasRole('StandEditor')
    ) {
      getResources();
    }

    fetchHiveDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Attempt to find any selected resource
    if (
      props.pageObject.data &&
      props.pageObject.data.toLowerCase().indexOf('/resources/') !== -1
    ) {
      let urlParts = props.pageObject.data.split('/');
      let existing = resources.find(
        (t) => t.id === urlParts[urlParts.length - 1]
      );
      if (existing) {
        setShowUploadOrExternalPart('assets');
        setSelectedResourceId(existing.id);
      }
    }
    // eslint-disable-next-line
  }, [resources]);

  const fetchHiveDetails = () => {
    hiveService.getHive().then((hiveService) => {
      setHiveDetails(hiveService);
    });
  };

  const getResources = async () => {
    let response = await resourceService.getResources();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      // Filter away resources not being asset uploads
      response = response.filter(
        (t) => t.uploadedFor === 'Assets' && t.fileMimeType.startsWith('video/')
      );
      setResources(response);
    }
  };

  const checkTranscodeStatus = async () => {
    if (props.pageObject.resource && props.pageObject.resource.embed) {
      let status = await resourceService.getVimeoTranscodeStatus(
        props.pageObject.resource.id
      );

      if (status !== 'complete') {
        setVideoIsTranscoding(true);
        return;
      }
    }

    setVideoIsTranscoding(false);
  };

  const onFileUploadStarted = () => {
    props.requestSetIsLoading(true);
  };

  const onFileUploaded = async (id, isTranslation, languageId) => {
    if (isTranslation) {
      props.setlanguageIds((oldObject) => ({ ...oldObject, [languageId]: id }));
      props.setIsTranslationAdded(true);
      props.requestSetIsLoading(false);
    } else {
      attemptUpdateAttribute('resourceId', id);
      props.requestSetIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.isTranslationAdded) {
      attemptUpdateAttribute('resourceIds', props.languageIds);
    }
  }, [props.isTranslationAdded, props.languageIds]);

  useEffect(() => {
    if (removeTranslation) {
      attemptUpdateAttribute('resourceIds', resourceIdsObject);
    }
  }, [resourceIdsObject]);

  const removeResource = async () => {
    props.updateAttribute('data', '');
    setSelectedResourceId('');
    setLink('');
    setRemoveIsOpen(false);
  };

  const removeTranslationHandler = (translationId) => {
    const removeResourceIdsObject = Object.fromEntries(
      Object.entries(props.pageObject.resourceIds).filter(
        ([key]) => key !== translationId
      )
    );

    setRemoveTranslationIsOpen(false);
    setResourceIdsObject(removeResourceIdsObject);
  };

  let isFullscreenVideo = false;
  let styles = props.pageObject.templateObject.templateObjectStyles;
  if (
    styles.find((t) => t.styleKey === 'left' && t.styleValue === '0%') !=
      null &&
    styles.find((t) => t.styleKey === 'top' && t.styleValue === '0%') != null &&
    styles.find((t) => t.styleKey === 'width' && t.styleValue === '100%') !=
      null &&
    styles.find((t) => t.styleKey === 'height' && t.styleValue === '100%') !=
      null
  ) {
    isFullscreenVideo = true;
  }

  let isGreenscreenIdleVideo = false;
  if (
    props.pageObject?.templateObject?.cssClass?.indexOf('idlevideo-loop') !== -1
  ) {
    isGreenscreenIdleVideo = true;
  }
  let isGreenscreenPlayVideo = false;
  if (
    props.pageObject?.templateObject?.cssClass?.indexOf('idlevideo-play') !== -1
  ) {
    isGreenscreenPlayVideo = true;
  }

  const getResourceOptions = () => {
    return resources.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.fileName} ({moment(data.created).format('DD.MM.YYYY HH:mm')})
      </MenuItem>
    ));
  };

  const handleResourceChanged = (event) => {
    setSelectedResourceId(event.target.value);

    let finalLink = resourceService.getResourceLink(event.target.value);

    attemptUpdateAttribute('data', finalLink);
  };

  if (videoIsTranscoding === null) {
    return <></>;
  }

  const attemptUpdateAttribute = (attribute, newValue) => {
    if (props.updateAttribute) {
      props.updateAttribute(attribute, newValue);
    }
  };

  const LanguageComponents = () => {
    const filteredLanguages = hiveDetails?.hiveLanguages?.filter(
      (language) => language.id !== hiveDetails.defaultLanguage.id
    );

    return (
      <>
        {filteredLanguages?.map((language) => (
          <div key={uuidv4()}>
            <VideoUpload
              uploadedfor={'PageObjectVideo'}
              uniqueKey={uuidv4()}
              fileUploadStarted={() => onFileUploadStarted()}
              fileUploadedCallback={(id) =>
                onFileUploaded(id, true, language.id)
              }
              acceptFileTypes='video/mp4,video/x-m4v,video/*'
            />
            {Object.keys(props.pageObject.resourceIds).find(
              (item) => item === language.id
            ) && (
              <Button
                fullWidth={true}
                variant='contained'
                color='primary'
                onClick={() => (
                  setTranslationCode(language.id),
                  setRemoveTranslationIsOpen(true)
                )}
              >
                {getLanguage('RemoveVideo')}
              </Button>
            )}
            <h3>{language.name}</h3>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      {isGreenscreenIdleVideo && (
        <h2>{getLanguage('GreenscreenIdleLoopVideo')}</h2>
      )}
      {isGreenscreenPlayVideo && <h2>{getLanguage('GreenscreenVideo')}</h2>}
      {!isGreenscreenIdleVideo && !isGreenscreenPlayVideo && (
        <h2>{getLanguage('Video')}</h2>
      )}
      {props.pageObject.data && (
        <>
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => setRemoveIsOpen(true)}
          >
            {getLanguage('RemoveVideo')}
          </Button>
          <br />
          <br />
        </>
      )}
      {isFullscreenVideo && (
        <div className='warningBox'>
          <b>{getLanguage('BackgroundVideo')}</b>
          <br />
          {getLanguage('PleaseNoteThatThisIsTheBackgroundVideoOfTheEntirePage')}
          <br />
          {getLanguage('DontRemoveItWithoutInsertingAnother')}
        </div>
      )}
      <Grid container item xs={12} justify='center'>
        {props.pageObject.resourceId && (
          <>
            {!props.pageObject.resource && (
              <>
                <b>{getLanguage('TheVideoWillBeAttachedWhenSaving')}</b>
              </>
            )}
            {props.pageObject.resource && props.pageObject.resource.embed ? (
              videoIsTranscoding ? (
                <b>
                  {getLanguage(
                    'TheVideoWillBeAvailableToWatchWithinAFewMinutes'
                  )}
                </b>
              ) : (
                <div
                  className='preview boxWithScoll'
                  dangerouslySetInnerHTML={{
                    __html: props.pageObject.resource.embed,
                  }}
                />
              )
            ) : (
              <video
                controls
                height='150'
                width='100%'
                src={resourceService.getResourceLink(
                  props.pageObject.resourceId
                )}
              />
            )}
            <br />
            <br />
            <Button
              fullWidth={true}
              variant='contained'
              color='primary'
              onClick={() => setRemoveIsOpen(true)}
            >
              {getLanguage('RemoveVideoImage')}
            </Button>
            <br />
          </>
        )}
        {props.pageObject.data && (
          <>
            <video
              controls
              height='150'
              width='100%'
              src={props.pageObject.data}
            />
            <br />
          </>
        )}
      </Grid>
      <div className='buttons'>
        <div>
          <div>
            <Grid container spacing={3}>
              <Grid
                item
                xs={
                  loginHelper.userHasRole('PageEditor') ||
                  loginHelper.userHasRole('StandEditor')
                    ? 4
                    : 6
                }
              >
                <RadioGroup
                  value={showUploadOrExternalPart}
                  onClick={() => setShowUploadOrExternalPart('upload')}
                >
                  <FormControlLabel
                    value='upload'
                    control={<Radio />}
                    label={getLanguage('UploadNewVideo')}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={
                  loginHelper.userHasRole('PageEditor') ||
                  loginHelper.userHasRole('StandEditor')
                    ? 4
                    : 6
                }
              >
                <RadioGroup
                  value={showUploadOrExternalPart}
                  onClick={() => setShowUploadOrExternalPart('external')}
                >
                  <FormControlLabel
                    value='external'
                    control={<Radio />}
                    label={getLanguage('UseExternalVideo')}
                  />
                </RadioGroup>
              </Grid>
              {(loginHelper.userHasRole('PageEditor') ||
                loginHelper.userHasRole('StandEditor')) && (
                <Grid item xs={4}>
                  <RadioGroup
                    value={showUploadOrExternalPart}
                    onClick={() => setShowUploadOrExternalPart('assets')}
                  >
                    <FormControlLabel
                      value='assets'
                      control={<Radio />}
                      label={getLanguage('ChooseFromAssets')}
                    />
                  </RadioGroup>
                </Grid>
              )}
            </Grid>
          </div>
          <br />
          {showUploadOrExternalPart === 'upload' && (
            <>
              <VideoUpload
                uploadedfor={'PageObjectVideo'}
                uniqueKey={props.pageObject.id}
                fileUploadStarted={() => onFileUploadStarted()}
                fileUploadedCallback={(id) => onFileUploaded(id, false)}
                acceptFileTypes='video/mp4,video/x-m4v,video/*'
              />
              {LanguageComponents()}
            </>
          )}
          {showUploadOrExternalPart === 'external' && (
            <>
              <TextField
                variant='outlined'
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                  attemptUpdateAttribute('data', e.target.value);
                }}
                label='https://'
                className='textField'
              />
              <LanguageSelector
                attemptUpdateAttribute={attemptUpdateAttribute}
                pageObject={props.pageObject}
                contentType={'embed'}
              >
                <TextField
                  variant='outlined'
                  label='https://'
                  className='textField'
                />
              </LanguageSelector>
            </>
          )}
          {showUploadOrExternalPart === 'assets' && (
            <>
              <div className={classes.selectContainer}>
                <FormControl
                  fullWidth={true}
                  variant='outlined'
                  className={classes.formControl}
                >
                  <InputLabel
                    className={classes.selectLabel}
                    id='hiveSelector-label'
                  >
                    {getLanguage('ChooseFromAssets')}
                  </InputLabel>
                  <Select
                    variant='outlined'
                    fullWidth={true}
                    value={selectedResourceId}
                    onChange={handleResourceChanged}
                    labelId='hiveSelector-label'
                    id='hiveSelector'
                  >
                    <MenuItem value={''}>
                      <em>{getLanguage('PleaseChoose')}</em>
                    </MenuItem>
                    {getResourceOptions()}
                  </Select>
                  <LanguageSelector
                    attemptUpdateAttribute={attemptUpdateAttribute}
                    pageObject={props.pageObject}
                    contentType={'assets'}
                  >
                    <Select
                      variant='outlined'
                      fullWidth={true}
                      labelId='hiveSelector-label'
                      id='hiveSelector'
                    >
                      <MenuItem value={''}>
                        <em>{getLanguage('PleaseChoose')}</em>
                      </MenuItem>
                      {getResourceOptions()}
                    </Select>
                  </LanguageSelector>
                </FormControl>
              </div>
            </>
          )}
        </div>
      </div>

      <GlobalDialog
        isDialogOpen={removeIsOpen}
        handleDialogClose={() => setRemoveIsOpen(false)}
        title={getLanguage('RemoveVideoImage')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheUploadedVideo?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => removeResource(false)}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
      <GlobalDialog
        isDialogOpen={removeTranslationIsOpen}
        handleDialogClose={() => setRemoveTranslationIsOpen(false)}
        title={getLanguage('RemoveVideoImage')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheUploadedVideo?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => (
              removeTranslationHandler(translationCode),
              setRemoveTranslation(true)
            )}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default ObjectVideoEditor;
