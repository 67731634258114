import './PageObjectEdit.css';
import React, { useEffect, useState } from 'react';
import ObjectImageEditor from './ObjectImageEditor';
import ObjectButtonEditor from './ObjectButtonEditor';
import ObjectVideoEditor from './ObjectVideoEditor';
import ObjectHtmlEditor from './ObjectHtmlEditor';
import ObjectFunctionalButtonEditor from './ObjectFunctionalButtonEditor';
import ObjectMailButtonEditor from './ObjectMailButtonEditor';
import { LoadingIndicator } from '../../../core/LoadingIndicator';

const PageObjectEdit = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [languageIds, setlanguageIds] = useState({});
  const [isTranslationAdded, setIsTranslationAdded] = useState(false);

  useEffect(() => {
    if (props.pageObject?.resourceIds) {
      setlanguageIds(props.pageObject.resourceIds);
    }
  }, []);

  const buildEditorForObject = () => {
    switch (props.pageObject.templateObject.kind) {
      case 'Image':
        return (
          <ObjectImageEditor
            languageIds={languageIds}
            setlanguageIds={setlanguageIds}
            isTranslationAdded={isTranslationAdded}
            setIsTranslationAdded={setIsTranslationAdded}
            updateAttribute={(attribute, newValue) =>
              props.updateAttribute(attribute, newValue)
            }
            removeResource={() => removeResource()}
            pageObject={props.pageObject}
            requestSetIsLoading={(val) => setIsLoading(val)}
          />
        );
      case 'Button':
        return (
          <ObjectButtonEditor
            updateAttribute={(attribute, newValue) =>
              props.updateAttribute(attribute, newValue)
            }
            removeResource={() => removeResource()}
            pageObject={props.pageObject}
            requestSetIsLoading={(val) => setIsLoading(val)}
          />
        );
      case 'FunctionalButton':
        return (
          <ObjectFunctionalButtonEditor
            updateAttribute={(attribute, newValue) =>
              props.updateAttribute(attribute, newValue)
            }
            removeResource={() => removeResource()}
            pageObject={props.pageObject}
            requestSetIsLoading={(val) => setIsLoading(val)}
          />
        );
      case 'MailButton':
        return (
          <ObjectMailButtonEditor
            updateAttribute={(attribute, newValue) =>
              props.updateAttribute(attribute, newValue)
            }
            removeResource={() => removeResource()}
            pageObject={props.pageObject}
            requestSetIsLoading={(val) => setIsLoading(val)}
          />
        );
      case 'Video':
        return (
          <ObjectVideoEditor
            languageIds={languageIds}
            setlanguageIds={setlanguageIds}
            isTranslationAdded={isTranslationAdded}
            setIsTranslationAdded={setIsTranslationAdded}
            updateAttribute={(attribute, newValue) =>
              props.updateAttribute(attribute, newValue)
            }
            removeResource={() => removeResource()}
            pageObject={props.pageObject}
            requestSetIsLoading={(val) => setIsLoading(val)}
          />
        );
      case 'Html':
        return (
          <>
            <ObjectHtmlEditor
              handleClickAway={props.handleClickAway}
              updateAttribute={(attribute, newValue) =>
                props.updateAttribute(attribute, newValue)
              }
              removeResource={() => removeResource()}
              pageObject={props.pageObject}
              requestSetIsLoading={(val) => setIsLoading(val)}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  const removeResource = () => {
    props.updateAttribute('resourceId', null);
  };

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <div className="PageObjectEdit">{buildEditorForObject()}</div>
        </>
      )}
    </>
  );
};

export default PageObjectEdit;
