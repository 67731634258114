import { HiveSettingApi } from "./HiveSettingApi";

export class HiveSettingService {
    hiveSettingApi = new HiveSettingApi();

    async getHiveSettings(hiveId) {
        var result = await this.hiveSettingApi.getHiveSettings(hiveId);
        return result;
    }

    async upsertHiveSetting(hiveId, identifier, value) {
        return await this.hiveSettingApi.upsertHiveSetting(hiveId, identifier, value);
    }
}