import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from "axios";
import { LoginHelper } from "../../../core/LoginHelper";

export class HiveApi {
  loginHelper = new LoginHelper();

  async getHive(hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.get(
        `${getApiPath()}/hives/${
          hiveId ?? this.loginHelper.getCurrentHive().id
        }`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getHives() {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.get(`${getApiPath()}/hives`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async addHive(hive) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      if (hive?.selectedHiveTemplate) {
        config.params = {
          template: hive.selectedHiveTemplate,
        };
      }
      let response = await Axios.post(`${getApiPath()}/hives`, hive, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateHive(hiveId, hive) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.put(
        `${getApiPath()}/hives/${hiveId}`,
        hive,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async clearJsonCache(hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.delete(
        `${getApiPath()}/json/${
          hiveId ?? this.loginHelper.getCurrentHive().id
        }`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getVisitorsPaged(
    hiveId,
    search,
    page,
    resultsPerPage,
    sortcolumn,
    sortDirection
  ) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          q: search,
          first: page*resultsPerPage,
          max: resultsPerPage,
          sortcolumn: sortcolumn,
          sortDirection: sortDirection,
        },
      };

      let response = await Axios.get(`${getApiPath()}/api/users`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }

      console.log({ ...error });
      return { ...error };
    }
  }

  async getVisitorsCount(hiveId, search) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          q: search && search.length > 2 ? search : undefined,
        },
      };

      let response = await Axios.get(`${getApiPath()}/api/users/count`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getVisitorEmailInfo(hiveId, email) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          email: email,
        },
      };

      let response = await Axios.get(`${getApiPath()}/mails`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getModuleActivationsHive(hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.get(`${getApiPath()}/modules`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async activateModuleForHive(hiveId, module) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.post(
        `${getApiPath()}/modules/${module}`,
        null,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async deactivateModuleForHive(hiveId, module) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.delete(
        `${getApiPath()}/modules/${module}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async deleteHive(hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.delete(
        `${getApiPath()}/hives/${hiveId}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getHiveExportJson(hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
          responseType: "blob",
        },
      };

      let response = await Axios.get(
        `${getApiPath()}/Hive/${
          hiveId ?? this.loginHelper.getCurrentHive().id
        }/export`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async importHive(hiveId, file) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let formData = new FormData();

      formData.append("fileContent", file);

      let response = await Axios.post(
        `${getApiPath()}/hive/${hiveId}/import`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }
}
