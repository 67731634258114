import { getCachedObject, setObjectInCache, clearFromCache } from "../../../core/ObjectCache";
import { TooltipApi } from "./TooltipApi";

export class TooltipService {
    tooltipApi = new TooltipApi();

    cacheIdentifier = "tooltips";
    cacheDurationMinutes = 30;

    async getTooltips(forceReload, hiveId) {
        if (!forceReload) {
            var cached = getCachedObject(this.cacheIdentifier + "_" + hiveId);
            if (cached) return cached;
        }

        var result = await this.tooltipApi.getTooltips(hiveId);
        if (!result.isAxiosError) {
            setObjectInCache(this.cacheIdentifier + "_" + hiveId, result, this.cacheDurationMinutes);
        }

        return result;
    }

    async updateTooltip(tooltip, hiveId) {
        this.clearCache(hiveId);
        return await this.tooltipApi.updateTooltip(tooltip);
    }

    clearCache(hiveId) {
        clearFromCache(this.cacheIdentifier + "_" + hiveId);
    }
}