import { LoginHelper } from "../../../core/LoginHelper";
import { clearFromCache, getCachedObject, setObjectInCache } from "../../../core/ObjectCache";
import { KeywordGroupApi } from "./KeywordGroupApi";

export class KeywordGroupService {
    keywordGroupApi = new KeywordGroupApi();
    loginHelper = new LoginHelper();

    cacheIdentifier = "keywordgroups";
    cacheDurationMinutes = 15;


    async getKeywordGroups() {
        let hiveId = this.loginHelper.getCurrentHive().id;

        let cached = getCachedObject(this.cacheIdentifier + "_" + hiveId);
        if (cached) return cached;

        var result = await this.keywordGroupApi.getKeywordGroups(hiveId);

        if (!result.isAxiosError) {
            setObjectInCache(this.cacheIdentifier + "_" + hiveId, result, this.cacheDurationMinutes);
        }

        return result;
    }

    async addKeywordGroup(keywordGroup) {
        let hiveId = this.loginHelper.getCurrentHive().id;
        this.clearCache(hiveId);
        return await this.keywordGroupApi.addKeywordGroup(keywordGroup);
    }

    async updateKeywordGroup(id, keywordGroup) {
        let hiveId = this.loginHelper.getCurrentHive().id;
        this.clearCache(hiveId);
        return await this.keywordGroupApi.updateKeywordGroup(id, keywordGroup);
    }

    async deleteKeywordGroup(id) {
        let hiveId = this.loginHelper.getCurrentHive().id;
        this.clearCache(hiveId);
        return await this.keywordGroupApi.deleteKeywordGroup(id);
    }


    clearCache(hiveId) {
        clearFromCache(this.cacheIdentifier + "_" + hiveId);
    }
}