import { HiveService } from "../../hive/services/HiveService";
import { TemplateObjectApi } from "./TemplateObjectApi";

export class TemplateObjectService {
    templateObjectApi = new TemplateObjectApi();
    hiveService = new HiveService();

    async addTemplateObject(templateObject) {
        let response = null;
        if (templateObject.kind === "MediaCombination") {
            templateObject.kind = "Video";
            response = await this.templateObjectApi.addTemplateObject(templateObject);
            templateObject.kind = "Html";
            response = await this.templateObjectApi.addTemplateObject(templateObject);
            templateObject.kind = "Image";
            response = await this.templateObjectApi.addTemplateObject(templateObject);
        } else {
            response = await this.templateObjectApi.addTemplateObject(templateObject);
        }
        this.hiveService.clearJsonCache();
        return response;
    }

    async updateTemplateObject(templateObjectId, templateObject) {
        let response = await this.templateObjectApi.updateTemplateObject(templateObjectId, templateObject);
        this.hiveService.clearJsonCache();
        return response;
    }

    async addTemplateObjectStyle(templateObjectStyle) {
        let response = await this.templateObjectApi.addTemplateObjectStyle(templateObjectStyle);
        this.hiveService.clearJsonCache();
        return response;
    }

    async updateTemplateObjectStyle(templateObjectStyleId, templateObjectStyle) {
        let response = await this.templateObjectApi.updateTemplateObjectStyle(templateObjectStyleId, templateObjectStyle);
        this.hiveService.clearJsonCache();
        return response;
    }

    async deleteTemplateObject(templateObjectId) {
        let response = await this.templateObjectApi.deleteTemplateObject(templateObjectId);
        this.hiveService.clearJsonCache();
        return response;
    }

    async deleteTemplateObjectStyle(templateObjectStyleId) {
        let response = await this.templateObjectApi.deleteTemplateObjectStyle(templateObjectStyleId);
        this.hiveService.clearJsonCache();
        return response;
    }

    async cloneStyles(targetTemplateObjectId, sourceTemplateObjectId) {
        let response = await this.templateObjectApi.cloneStyles(targetTemplateObjectId, sourceTemplateObjectId);
        this.hiveService.clearJsonCache();
        return response;
    }
}