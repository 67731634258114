import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { StatisticsService } from '../services/StatisticsService';
import { ToastsStore } from 'react-toasts';
import { updatePageTitle } from '../../../actions/Actions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    height: '90vh',
    background: '#fff',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  submitButton: {
    background: '#124238',
    color: '#FFF',
    '&:hover': {
      background: '#195d4f',
    },
  },
}));

const StatisticsWhereBy = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statisticsService = new StatisticsService();
  const [whereByStats, setwhereByStats] = useState([]);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('WherebyStatistics')));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    let response = await statisticsService.getWhereByStats();
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setwhereByStats(response);
    }
  };

  return (
    <div className={classes.paper}>
      <h1>{getLanguage('WherebyStatistics')}</h1>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: '50vh', marginBottom: 20 }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>To/From</TableCell>
              <TableCell>Total Participants Minutes</TableCell>
              <TableCell>Number of sessions</TableCell>
              <TableCell>Synchronized</TableCell>
              <TableCell>Meeting type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {whereByStats.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>
                    {moment(row.created).format('YYYY-MM-DD')}
                  </TableCell>
                  <TableCell>
                    {moment(row.startAt).format('HH:mm')} /{' '}
                    {moment(row.endAt).format('HH:mm')}
                  </TableCell>
                  <TableCell>{row.totalParticipantMinutes}</TableCell>
                  <TableCell>{row.totalSessions}</TableCell>
                  <TableCell>{row.usageSynchronized}</TableCell>
                  <TableCell>{row.type}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StatisticsWhereBy;
