import './EditHiveDetails.css'
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { ToastsStore } from 'react-toasts'
import { makeStyles } from '@material-ui/core/styles'
import { HiveService } from '../services/HiveService'
import { LoadingIndicator } from '../../../core/LoadingIndicator'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const EditHiveModules = (props) => {
  const classes = useStyles()

  const hiveService = new HiveService()

  const [isLoading, setIsLoading] = useState(true)
  const [activatedModules, setActivatedModules] = useState([])

  useEffect(() => {
    getModuleActivationsHive()
    // eslint-disable-next-line
  }, [])

  const getModuleActivationsHive = async () => {
    let response = await hiveService.getModuleActivationsHive(props.hiveId)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
    } else {
      setActivatedModules(response)
      setIsLoading(false)
    }
  }

  const changeModuleStatus = async (module, value) => {
    setIsLoading(true)

    if (value) {
      await hiveService.activateModuleForHive(props.hiveId, module)
    } else {
      await hiveService.deactivateModuleForHive(props.hiveId, module)
    }

    getModuleActivationsHive()
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={activatedModules.find((t) => t === 'Meetings') != null}
              onChange={(e) => changeModuleStatus('Meetings', e.target.checked)}
            />
          }
          label={getLanguage('Meetings')}
          className="checkbox"
        />
      </FormControl>

      <FormControl
        fullWidth={true}
        variant="outlined"
        className={classes.formControl}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={activatedModules.find((t) => t === 'Networking') != null}
              onChange={(e) =>
                changeModuleStatus('Networking', e.target.checked)
              }
            />
          }
          label={getLanguage('Networking')}
          className="checkbox"
        />
      </FormControl>
    </>
  )
}

export default EditHiveModules
