import { getApiPath } from '../../../core/ServiceRoutes'
import Axios from 'axios'
import { LoginHelper } from '../../../core/LoginHelper'

export class HiveSettingApi {
  loginHelper = new LoginHelper()

  async getHiveSettings(hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      }
      let response = await Axios.get(`${getApiPath()}/hivesettings`, config)
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async upsertHiveSetting(hiveId, identifier, value) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/hivesettings`,
        {
          identifier,
          value,
        },
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }
}
