import './EditTemplateDetails.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditTemplateObjectDetails = (props) => {
  const classes = useStyles();

  const kinds = [
    'MediaCombination',
    'Button',
    'Image',
    'FunctionalButton',
    'Video',
    'Html',
    'MailButton',
  ];

  const tooltipPositions = [
    'None',
    'Auto',
    'Above',
    'Below',
    'Left',
    'Right',
    'AboveLeft',
    'AboveRight',
    'BelowLeft',
    'BelowRight',
  ];

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [kind, setKind] = useState('');
  const [kindError, setKindError] = useState('');
  const [groupTitle, setGroupTitle] = useState('');
  const [groupTitleError, setGroupTitleError] = useState('');
  const [ref, setRef] = useState('');
  const [data, setData] = useState('');
  const [cssClass, setCssClass] = useState('');
  const [defaultText, setDefaultText] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState('None');
  const [tooltipDefaultText, setTooltipDefaultText] = useState('');
  const [shouldTrack, setShouldTrack] = useState(true);

  useEffect(() => {
    if (props.templateObject) {
      setId(props.templateObject.id);
      setTitle(props.templateObject.title);

      switch (props.templateObject.kind) {
        case 'Image':
          setKind('Image');
          break;
        case 'Button':
          setKind('Button');
          break;
        case 'FunctionalButton':
          setKind('FunctionalButton');
          break;
        case 'Video':
          setKind('Video');
          break;
        case 'Html':
          setKind('Html');
          break;
        case 'MailButton':
          setKind('MailButton');
          break;
        default:
      }

      setGroupTitle(props.templateObject.groupTitle);
      setRef(props.templateObject.ref);
      setData(props.templateObject.data);
      setCssClass(props.templateObject.cssClass);
      setDefaultText(props.templateObject.defaultText);
      setTooltipPosition(
        props.templateObject.tooltipPosition
          ? props.templateObject.tooltipPosition
          : 'None'
      );
      setTooltipDefaultText(props.templateObject.tooltipDefaultText);
      setShouldTrack(props.templateObject.shouldTrack);
    }
    // eslint-disable-next-line
  }, []);

  const onSaveClicked = async () => {
    var validationError = false;

    // Validate title not empty
    if (!title) {
      setTitleError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setTitleError('');
    }

    // Validate Kind selected
    if (!id && !kind) {
      setKindError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setKindError('');
    }

    // Validate groupTitle not empty
    if (!groupTitle) {
      setGroupTitleError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setGroupTitleError('');
    }

    if (validationError) {
      return;
    }

    if (id) {
      props.onSave({
        title,
        groupTitle,
        ref,
        data,
        cssClass,
        kind,
        defaultText,
        tooltipPosition,
        tooltipDefaultText,
        shouldTrack,
      });
    } else {
      props.onSave({
        templateId: props.templateId,
        title,
        kind,
        groupTitle,
        ref,
        data,
        defaultText,
        tooltipPosition,
        tooltipDefaultText,
        shouldTrack,
      });
    }
  };

  const getKindOptions = () => {
    return kinds.map((data) => (
      <MenuItem key={data} value={data}>
        {getLanguage(data)}
      </MenuItem>
    ));
  };

  const getTooltipPositionOptions = () => {
    return tooltipPositions.map((data) => (
      <MenuItem key={data} value={data}>
        {getLanguage(data)}
      </MenuItem>
    ));
  };

  const handleKindChanged = (event) => {
    setKind(event.target.value);
    setKindError('');
  };

  const handleTooltipPositionChanged = (event) => {
    setTooltipPosition(event.target.value);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={titleError}
            helperText={titleError}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
            label={getLanguage('Title')}
            className='textField'
          />
        </FormControl>

        <div className={classes.selectContainer}>
          <FormControl
            helperText={kindError}
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <InputLabel
              className={classes.selectLabel}
              id='templateSelector-label'
            >
              {getLanguage('Type')}
            </InputLabel>
            <Select
              error={kindError}
              variant='outlined'
              fullWidth={true}
              value={kind}
              onChange={handleKindChanged}
              labelId='kindSelector-label'
              id='kindSelector'
            >
              {getKindOptions()}
            </Select>
          </FormControl>
        </div>

        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={groupTitleError}
            helperText={groupTitleError}
            value={groupTitle}
            onChange={(e) => {
              setGroupTitle(e.target.value);
              setGroupTitleError('');
            }}
            label={getLanguage('GroupTitle')}
            className='textField'
          />
        </FormControl>

        {id && (
          <>
            <div className={classes.selectContainer}>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel
                  className={classes.selectLabel}
                  id='tooltipPositionSelector-label'
                >
                  {getLanguage('MouseoverPosition')}
                </InputLabel>
                <Select
                  variant='outlined'
                  fullWidth={true}
                  value={tooltipPosition}
                  onChange={handleTooltipPositionChanged}
                  labelId='tooltipPositionSelector-label'
                  id='tooltipPositionSelector'
                >
                  {getTooltipPositionOptions()}
                </Select>
              </FormControl>
            </div>
            {tooltipPosition !== 'None' && (
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  multiline={true}
                  rows={2}
                  value={tooltipDefaultText}
                  onChange={(e) => setTooltipDefaultText(e.target.value)}
                  label={getLanguage('MouseoverDefaultText')}
                  className='textField'
                />
              </FormControl>
            )}
          </>
        )}

        {id &&
          props.templateObject &&
          props.templateObject.kind === 'FunctionalButton' && (
            <>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  value={ref}
                  onChange={(e) => setRef(e.target.value)}
                  label={getLanguage('LinkRef')}
                  className='textField'
                />
              </FormControl>

              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                  label={getLanguage('LinkData')}
                  className='textField'
                />
              </FormControl>
            </>
          )}

        {id && (
          <>
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <TextField
                variant='outlined'
                value={cssClass}
                onChange={(e) => setCssClass(e.target.value)}
                label={getLanguage('CssClass')}
                className='textField'
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <TextField
                variant='outlined'
                value={defaultText}
                onChange={(e) => setDefaultText(e.target.value)}
                label={getLanguage('DefaultText')}
                className='textField'
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={shouldTrack}
                    onChange={(e) => setShouldTrack(e.target.checked)}
                  />
                }
                label={getLanguage('ShouldTrackClicks')}
                className='checkbox'
              />
            </FormControl>
          </>
        )}

        <Button
          type='submit'
          fullWidth={true}
          variant='contained'
          color='primary'
        >
          {getLanguage('Save')}
        </Button>
      </form>
    </>
  );
};

export default EditTemplateObjectDetails;
