import { PopupMessageApi } from "./PopupMessageApi";

export class PopupMessageService {
    popupMessageApi = new PopupMessageApi();

    async getPopupMessage(id) {
        return await this.popupMessageApi.getPopupMessage(id);
    }

    async getPopupMessages(hiveId) {
        return await this.popupMessageApi.getPopupMessages(hiveId);
    }

    async addPopupMessage(newsItem) {
        return await this.popupMessageApi.addPopupMessage(newsItem);
    }

    async updatePopupMessage(id, newsItem) {
        return await this.popupMessageApi.updatePopupMessage(id, newsItem);
    }

    async deletePopupMessage(id) {
        return await this.popupMessageApi.deletePopupMessage(id);
    }
}