import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import getLanguage from '../../../core/Language'
import { LoginHelper } from '../../../core/LoginHelper'
import UserCard from '../../activity/components/UserCard'
import { VisitorFieldService } from '../../visitorFields/services/VisitorFieldService'

const LostChatNetworks = (props) => {
  const [visitorFieldsForHive, setVisitorFieldsForHive] = useState(null)

  const clientInfos = useSelector((state) => state.clientInfos)

  const loginHelper = new LoginHelper()
  const visitorFieldService = new VisitorFieldService()

  useEffect(() => {
    const prepareData = async () => {
      let response = await visitorFieldService.getVisitorFieldsForHive(
        loginHelper.getCurrentHive().id,
      )
      setVisitorFieldsForHive(response)
    }

    prepareData()
    // eslint-disable-next-line
  }, [])

  const openLinkedInProfile = (login) => {
    window.open(login.linkedInProfile)
  }

  let chatNetworks = props.chatNetworks.filter(
    (t) =>
      t.chatNetworkParticipants.length === 1 &&
      props.activeUsersClient.find((z) => z.login.id === t.ownerLoginId) ==
        null,
  )

  if (chatNetworks.length === 0 || visitorFieldsForHive == null) {
    return null
  }

  return (
    <>
      <h2>{getLanguage('LostMessages')}</h2>
      {chatNetworks.map((chatNetwork) => {
        return (
          <UserCard
            showChatNotification={true}
            key={chatNetwork.id}
            mode={'ActiveUsers'}
            isActiveOnPage={false}
            showChatButton={true}
            requestOpenChat={() => props.requestOpenChatNetwork(chatNetwork.id)}
            login={chatNetwork.chatNetworkParticipants[0].login}
            chatNetwork={chatNetwork}
            openLinkedInProfile={() =>
              openLinkedInProfile(chatNetwork.chatNetworkParticipants[0].login)
            }
            visitorFieldsForHive={visitorFieldsForHive}
            clientInfo={
              clientInfos.find(
                (t) =>
                  t.loginId === chatNetwork.chatNetworkParticipants[0].login.id,
              )?.clientInfo
            }
          />
        )
      })}
    </>
  )
}

export default LostChatNetworks
