import React, { useEffect, useState } from 'react';
import { LoginService } from '../services/LoginService';
import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Link,
} from '@material-ui/core';
import getLanguage from '../../../core/Language';
import {
  navigateToDashboard,
  navigateToLogin_Page,
} from '../../../core/Navigation';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    position: 'absolute',
    top: '120px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    width: '500px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 8),
    background: '#121C42',
    borderRadius: '6px',
    color: '#fff',
    padding: '10px',
    transition: 'all 400ms ease-in-out',
    '&:hover': {
      background: '#282C74',
      transform: 'scale(1.02)',
    },
  },
  signUp: {
    margin: theme.spacing(6, 0, 2),
  },
}));

const ForgotLogin_Page = (props) => {
  const classes = useStyles();

  const loginService = new LoginService();

  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');
  // const [editIsOpen, setEditIsOpen] = useState(false);

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = async () => {
    requestedSetIsLoading(false);
  };
  const requestedSetIsLoading = (val) => {
    setIsLoading(val);
  };

  const submitData = async (props, email) => {
    requestedSetIsLoading(true);

    var res = await loginService.resetPassword(email);
    if (res) {
      navigateToLogin_Page(props);
    }
    requestedSetIsLoading(false);
  };

  // const requestedCloseEdit = async
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {getLanguage('ForgotPassword')}
        </Typography>

        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            submitData(props, email);
          }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={getLanguage('Email')}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {getLanguage('ForgotPassword')}
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ForgotLogin_Page;
