import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from 'axios';
import { LoginHelper } from "../../../core/LoginHelper";

export class EventRegistrationApi {
    loginHelper = new LoginHelper();

    async getEventRegistrationsForEvent(eventId) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }
            let response = await Axios.get(`${getApiPath()}/eventregistrations/allLoginsForEvent?eventId=${eventId}`, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }

    async insertEventRegistrationForLogin(eventId, loginId) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }
            let response = await Axios.post(`${getApiPath()}/hive/${this.loginHelper.getCurrentHive().id}/event/${eventId}/login/${loginId}/eventregistration`, null, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }

    async deleteEventRegistration(eventRegistrationId) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }
            let response = await Axios.delete(`${getApiPath()}/eventregistration/${eventRegistrationId}`, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }


    // The following methods are properly not used, I have not clue why I implemented them....
    async getEventRegistrationsForLogin() {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }
            let response = await Axios.get(`${getApiPath()}/eventregistration`, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }

    async insertEventRegistration(eventId) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                }
            }
            let response = await Axios.post(`${getApiPath()}/event/${eventId}/eventregistration`, null, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }

}