import { clearFromCache, setObjectInCache } from "../../../core/ObjectCache";
import { HiveService } from "../../hive/services/HiveService";
import { TemplateApi } from "./TemplateApi";

export class TemplateService {
    templateApi = new TemplateApi();
    hiveService = new HiveService();

    cacheIdentifier = "templates";
    cacheDurationMinutes = 10;

    async getTemplatesForHive(hiveId) {

        var result = await this.templateApi.getTemplates(hiveId);

        if (!result.isAxiosError) {
            setObjectInCache(this.cacheIdentifier, result, this.cacheDurationMinutes);
        }

        return result;
    }

    async getTemplate(templateId) {
        var result = await this.templateApi.getTemplate(templateId);
        return result;
    }

    async addTemplate(template) {
        this.clearCache();
        let response = await this.templateApi.addTemplate(template);
        return response;
    }

    async updateTemplate(templateId, template) {
        this.clearCache();
        let response = await this.templateApi.updateTemplate(templateId, template);
        this.hiveService.clearJsonCache();
        return response;
    }

    async deleteTemplate(templateId) {
        this.clearCache();
        return await this.templateApi.deleteTemplate(templateId);
    }

    clearCache() {
        clearFromCache(this.cacheIdentifier);
    }
}