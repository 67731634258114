import { FormControl, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
    }
}));

const GenericHiveSettingBox = (props) => {
    const classes = useStyles();

    let existingSetting = props.updatedHiveSettings.find(t => t.identifier === props.identifier)
    if (!existingSetting) {
        existingSetting = props.hiveSettings.find(t => t.identifier === props.identifier)
    }

    return (<FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
        <TextField variant="outlined" type={props.datatype} value={existingSetting?.value ?? props.defaultValue} onChange={(e) => props.onHiveSettingUpdated(props.identifier, e.target.value)} label={props.title} className="textField" />
    </FormControl>);
}

export default GenericHiveSettingBox