import './EditKeywordDetails.css';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { makeStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import { KeywordGroupService } from '../../keywordgroup/services/KeywordGroupService';
import { ToastsStore } from 'react-toasts';
import { LoginHelper } from '../../../core/LoginHelper';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const EditKeywordDetails = (props) => {
  const classes = useStyles();

  const keywordGroupService = new KeywordGroupService();
  const loginHelper = new LoginHelper();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [keywordGroups, setKeywordGroups] = useState([]);
  const [selectedKeywordGroupId, setSelectedkeywordGroupId] = useState('');
  const [showInEvent, setShowInEvent] = useState(false);
  const [showInPages, setShowInPages] = useState(false);
  const [showInStands, setShowInStands] = useState(false);
  const [showInContentLibrary, setShowInContentLibrary] = useState(false);

  useEffect(() => {
    fetchKeywordGroupData();
    if (props.keyword) {
      setName(props.keyword.name);
      setSelectedkeywordGroupId(props.keyword.keywordGroupId);
      setShowInEvent(props.keyword.showInEvent);
      setShowInPages(props.keyword.showInPages);
      setShowInStands(props.keyword.showInStands);
      setShowInContentLibrary(props.keyword.showInContentLibrary);
    }

    // eslint-disable-next-line
  }, []);

  const fetchKeywordGroupData = async () => {
    let response = await keywordGroupService.getKeywordGroups(
      loginHelper.getCurrentHive().id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      response = customSort(response, ['name'], ['asc']);

      setKeywordGroups(response);
    }
  };
  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const onSaveClicked = async () => {
    var validationError = false;

    // Validate name not empty
    if (!name) {
      setNameError(getLanguage('ValidationErrorCannotBeEmpty'));
      validationError = true;
    } else {
      setNameError('');
    }

    if (validationError) {
      return;
    }

    props.onSave({
      name: name,
      keywordGroupId: selectedKeywordGroupId,
      showInEvent,
      showInPages,
      showInStands,
      showInContentLibrary,
    });
  };
  const handlePageChanged = (event) => {
    setSelectedkeywordGroupId(event.target.value);
  };

  const getPageOptions = () => {
    return keywordGroups.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.name}
      </MenuItem>
    ));
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSaveClicked();
        }}
      >
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <TextField
            variant='outlined'
            error={nameError}
            helperText={nameError}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={getLanguage('Name')}
            className='textField'
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel className={classes.selectLabel} id='pageSelector-label'>
            {getLanguage('SelectKeywordGroup')}
          </InputLabel>
          <Select
            variant='outlined'
            fullWidth={true}
            value={selectedKeywordGroupId}
            onChange={handlePageChanged}
            labelId='pageSelector-label'
            id='pageSelector'
          >
            <MenuItem value={''}>
              <em>{getLanguage('PleaseChoose')}</em>
            </MenuItem>
            {getPageOptions()}
          </Select>
          <br />
        </FormControl>
        <p>
          <b>{getLanguage('MakeAvailableAsChoiceIn')}</b>
        </p>
        <div style={{ marginLeft: '20px' }}>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={showInEvent}
                  onChange={(e) => setShowInEvent(e.target.checked)}
                />
              }
              label={getLanguage('ShowInProgram')}
              className='checkbox'
            />
          </FormControl>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={showInPages}
                  onChange={(e) => setShowInPages(e.target.checked)}
                />
              }
              label={getLanguage('ShowInPages')}
              className='checkbox'
            />
          </FormControl>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={showInStands}
                  onChange={(e) => setShowInStands(e.target.checked)}
                />
              }
              label={getLanguage('ShowInStands')}
              className='checkbox'
            />
          </FormControl>
          <FormControl
            fullWidth={true}
            variant='outlined'
            className={classes.formControl}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={showInContentLibrary}
                  onChange={(e) => setShowInContentLibrary(e.target.checked)}
                />
              }
              label={getLanguage('ShowInContentLibrary')}
              className='checkbox'
            />
          </FormControl>
        </div>

        <Button
          type='submit'
          fullWidth={true}
          variant='contained'
          color='primary'
        >
          {getLanguage('Save')}
        </Button>
      </form>
    </>
  );
};

export default EditKeywordDetails;
