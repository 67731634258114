import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { limitLength } from '../../../core/helpers/StringHelper';
import Loading from 'react-fullscreen-loading';
import { Button, Container, Paper, Tooltip } from '@material-ui/core';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import { Edit } from '@material-ui/icons';
import MailTemplateEditor from '../components/MailTemplateEditor';
import { MailTemplateService } from '../services/MailTemplateService';
import { ResourceService } from '../../resource/services/ResourceService';
import { StorageHelper } from '../../../core/StorageHelper';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const MailTemplates_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const mailTemplateService = new MailTemplateService();
  const resourceService = new ResourceService();
  const storageHelper = new StorageHelper();

  let savedResultsPerPage = storageHelper.getResultsPerPageForMailTemplates();

  const [defaultSortColumn, setDefaultSortColumn] = useState('identifier');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');
  const [isLoading, setIsLoading] = useState(true);
  const [defaultMailTemplates, setDefaultMailTemplates] = useState([]);
  const [mailIdentifierToEdit, setMailIdentifierToEdit] = useState(null);
  const [editTopic, setEditTopic] = useState('');
  const [editHtmlContents, setEditHtmlContents] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [resources, setResources] = useState(null);
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('MailTemplates')));

    getDefaultMailTemplates();
    // eslint-disable-next-line
  }, []);

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForMailTemplates(
      currentRowsPerPage.toString()
    );
    setCurrentResultsPerPage(currentRowsPerPage);
  };

  const columns = [
    {
      name: getLanguage('Topic'),
      selector: (row) => row.topic,
      sortable: true,
      cell: (row) => (
        <Link
          color="inherit"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            editMailTemplateClicked(row);
          }}
        >
          <span className={classes.pageTitle}>
            {limitLength(row.topic, 100, '...')}
          </span>
        </Link>
      ),
    },
    {
      name: getLanguage('Description'),
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: getLanguage('Options'),
      width: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => editMailTemplateClicked(row)}
            >
              <Edit />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getDefaultMailTemplates = async () => {
    setIsLoading(true);

    let response = await mailTemplateService.getDefaultMailTemplates();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      // Filter away templates that are not hive-specific
      response = response.filter(
        (t) =>
          t.identifier !== 'AddedAsAdministrator' &&
          t.identifier !== 'Password_ResetToken'
      );

      setDefaultMailTemplates(response);

      if (resources) {
        setIsLoading(false);
      } else {
        getResources();
      }
    }
  };

  const getResources = async () => {
    setIsLoading(true);

    let response = await resourceService.getResources();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      // Filter away resources not being email uploads
      response = response.filter((t) => t.uploadedFor === 'EmailTemplate');

      setResources(response);

      setIsLoading(false);
    }
  };

  const onSave = async (obj) => {
    setIsLoading(true);

    let response = await mailTemplateService.updateMailTemplate(
      null,
      mailIdentifierToEdit,
      obj
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setMailIdentifierToEdit(null);
    setEditTopic('');
    setEditHtmlContents('');
    setEditDescription('');
    setIsLoading(false);
  };

  const editMailTemplateClicked = async (defaultMailTemplate) => {
    // Find any hive-specific
    let hiveSpecific = await mailTemplateService.getMailTemplate(
      null,
      defaultMailTemplate.identifier
    );

    if (hiveSpecific.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${hiveSpecific.response.status} - ${
          hiveSpecific.response.statusText
        } + ")`
      );
      setIsLoading(false);
      return;
    }

    if (hiveSpecific) {
      setEditTopic(hiveSpecific.topic);
      setEditHtmlContents(hiveSpecific.htmlContents);
      setEditDescription(hiveSpecific.description);
    } else {
      setEditTopic(defaultMailTemplate.topic);
      setEditHtmlContents(defaultMailTemplate.htmlContents);
      setEditDescription(defaultMailTemplate.description);
    }

    setMailIdentifierToEdit(defaultMailTemplate.identifier);
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <Paper className={classes.paper}>
        {defaultMailTemplates && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={defaultMailTemplates}
            pagination={true}
            selectableRows={false}
            sortFunction={customSort}
            defaultSortField={defaultSortColumn}
            defaultSortAsc={defaultSortDirection === 'asc'}
            onChangeRowsPerPage={onChangeRowsPerPage}
            paginationPerPage={currentResultsPerPage}
            subHeader={false}
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={mailIdentifierToEdit !== null}
        handleDialogClose={() => setMailIdentifierToEdit(null)}
        title={getLanguage('EditMailTemplate')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {mailIdentifierToEdit && (
            <MailTemplateEditor
              onSave={(obj) => onSave(obj)}
              topic={editTopic}
              htmlContents={editHtmlContents}
              description={editDescription}
              resources={resources}
            />
          )}
        </Container>
      </GlobalDialog>
    </>
  );
};

export default MailTemplates_Page;
