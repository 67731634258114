import { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion/dist/framer-motion';
import './quoteSlider.css';

const quotes = [
  {
    text: '"Virtual Hive provided an excellent environment for us to deliver our key messages in an efficient and impactful manner."',
    cite: ' - Keynote speaker, at the conference from Carlsberg Group',
  },
  {
    text: '"We were very satisfied that we chose a virtual solution for The Learning Festival Online 2021."',
    cite: '- The National Agency for IT and Learning',
  },
  {
    text: '"What Virtual Hive in essence achieved for our summit was the unification of global experts, innovators, and leaders." ',
    cite: '- Lisa Burchardi, Marketing Operations & Event Manager, Configit',
  },
];

const QuoteSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const controls = useAnimation();

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 7000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    handleNext();
  }, []);

  const handleNext = () => {
    controls.start('out');
    setTimeout(() => {
      controls.start('in');
      setCurrentIndex((currentIndex + 1) % quotes.length);
    }, 500);
  };

  return (
    <div className="quote-slider">
      <motion.div
        animate={controls}
        initial="out"
        variants={{
          in: { opacity: 1 },
          out: { opacity: 0 },
        }}
      >
        <p className="quote">{quotes[currentIndex].text}</p>
        <cite className="cite">{quotes[currentIndex].cite}</cite>
      </motion.div>
    </div>
  );
};

export default QuoteSlider;
