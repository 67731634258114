import React, { useEffect } from 'react';
import getLanguage from '../../../core/Language';
import { LoginHelper } from '../../../core/LoginHelper';
import HiveUrls from '../../hiveUrl/components/HiveUrls';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    animation: '$moveIn 600ms ease-out',
    height: '90vh',
    overflowX: 'scroll',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
}));

const HiveUrls_Page = (props) => {
  const loginHelper = new LoginHelper();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Urls')));
    // eslint-disable-next-line
  }, []);

  let hiveId = loginHelper.getCurrentHive().id;

  return (
    <div className={classes.paper}>
      <HiveUrls hiveId={hiveId} />
    </div>
  );
};

export default HiveUrls_Page;
