import { StorageHelper } from "../../../core/StorageHelper";
import { AccessTokenApi } from "./AccessTokenApi";
import moment from 'moment';

export class AccessTokenService {
    accessTokenApi = new AccessTokenApi();
    storageHelper = new StorageHelper();

    async getAccessTokenForHive(hiveId) {
        var existing = await this.storageHelper.getPreviewAccessToken();

        if (existing && existing.expires) {
            if (moment(existing.expires) > moment() && existing.hiveId === hiveId) {
                return existing.token
            }
        }

        var result = await this.accessTokenApi.getAccessTokenForHive(hiveId);

        if (!result.isAxiosError) {
            this.storageHelper.setPreviewAccessToken(result);
            return result.token;
        }

        return result;
    }
}