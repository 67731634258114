import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from 'axios';
import { LoginHelper } from "../../../core/LoginHelper";

export class LoginDataApi {
    loginHelper = new LoginHelper();

    async updateLoginDatasForVisitor(hiveId, loginId, loginDatas) {
        try {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.loginHelper.getAuthToken()}`
                },
                params: {
                    hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
                    loginid: loginId
                }
            }

            let response = await Axios.put(`${getApiPath()}/logindatas`, loginDatas, config);
            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                this.loginHelper.receivedUnauthorizedAnswerFromApi();
            }
            console.log({ ...error });
            return { ...error };
        }
    }
}
