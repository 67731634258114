import React, { useEffect, useState, useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import MenuDrawer from './MenuDrawer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import { LoginHelper } from './LoginHelper';
import { SettingsButton } from './SettingsButton';
import { HiveSelectButton } from './HiveSelectButton';
import { StorageHelper } from './StorageHelper';
import { useSelector, useDispatch } from 'react-redux';
import { ChatButton } from './ChatButton';
import branding from './branding.png';
import {
  matrixClient,
  getRoomInformation,
  getNewMessageNotification,
  isChatListUpdateNeeded,
} from '../actions/Actions';
import { getChatPath } from './ServiceRoutes';
import * as sdk from 'matrix-js-sdk';
import {
  Application_Matrix_Domain,
  Application_Matrix_Url,
} from './ApplicationConfig';

const drawertype = 'permanent';
const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    height: 20,
    width: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: 48,
    //  height: 35
  },
  regular: {
    minHeight: 48,
    background: '#19275d',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  gutters: {
    paddingLeft: 12,
  },
  title: {
    flexGrow: 1,
  },
  titleLink: {
    color: '#ffffff',
  },
  colorSecondary: {
    padding: 6,
  },
}));

const AppMenu = () => {
  const dispatch = useDispatch();
  const [draweropen, setDrawerOpen] = React.useState(false);
  const [countSecondLevelsOpen, setCountSecondLevelsOpen] = React.useState(0);
  const [secondLevelOpen, setSecondLevelOpen] = React.useState(false);
  const currentPageTitle = useSelector((state) => state.currentPageTitle);
  const [chatAccessToken, setChatAccessToken] = useState('');
  const [isMatrixClientSynced, setIsMatrixClientSynced] = useState(false);

  const storageHelper = new StorageHelper();
  const loginHelper = new LoginHelper();
  let currentLogin = loginHelper.getLoginObject();
  const isLoggedIn = loginHelper.isLoggedIn();

  const classes = useStyles();

  useEffect(() => {
    let currentState = storageHelper.getMenuDrawerState();
    if (currentState && currentState === 'true') {
      setDrawerOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoggedIn && loginHelper?.getCurrentHive()?.id) {
      fetch(
        `${getChatPath()}/logins/?userId=${loginHelper?.getUserId()}&hiveid=${
          loginHelper?.getCurrentHive()?.id
        }`,
        {
          method: 'POST',
          body: [],
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
        .then((response) => response.json())

        .then((data) => {
          setChatAccessToken(data.accessToken);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [isLoggedIn, loginHelper?.getCurrentHive()?.id]);

  const autoJoinRoomHandler = useCallback((matrixClient, roomId) => {
    dispatch(isChatListUpdateNeeded(false));
    matrixClient.joinRoom(roomId).then(function () {
      dispatch(isChatListUpdateNeeded(true));
    });
  }, []);

  const newMessageRecievedHandler = useCallback(
    (matrixClient, roomId, sender) => {
      dispatch(getRoomInformation(matrixClient.getRoom(roomId)));
      if (
        sender !== `@${loginHelper?.getUserId()}:${Application_Matrix_Domain}`
      ) {
        dispatch(getNewMessageNotification(roomId));
      }
    },
    []
  );

  useEffect(() => {
    if (chatAccessToken?.length > 0) {
      const client = sdk.createClient({
        baseUrl: Application_Matrix_Url,
        accessToken: chatAccessToken,
        userId: `@${loginHelper?.getUserId()}:${Application_Matrix_Domain}`,
      });

      client.once('sync', function (state, prevState, res) {
        switch (state) {
          case 'PREPARED':
            client.on(
              //@ts-ignore
              'Room.timeline',
              //@ts-ignore
              function (event, room, toStartOfTimeline) {
                // we know we only want to respond to messages
                if (event.getType() !== 'm.room.message') {
                  return;
                }
                newMessageRecievedHandler(
                  client,
                  room.roomId,
                  event.event.sender
                );
              }
            );

            //@ts-ignore
            client.on('RoomMember.membership', function (event, member) {
              if (
                member.membership === 'invite' &&
                member.userId ===
                  `@${loginHelper?.getUserId()}:${Application_Matrix_Domain}`
              ) {
                autoJoinRoomHandler(client, member.roomId);
              }
              const rooms = client.getVisibleRooms();
              rooms.map((room) => {
                //@ts-ignore
                if (room.selfMembership === 'invite') {
                  return client.joinRoom(room.roomId);
                }
              });
            });
            setIsMatrixClientSynced(true);
            break;
        }
      });

      client.startClient({ initialSyncLimit: 50 });
      dispatch(matrixClient(client));
    }
  }, [chatAccessToken, isMatrixClientSynced]);

  const changeDrawerOpenState = (state) => {
    storageHelper.setMenuDrawerState(state);
    setDrawerOpen(state);
  };

  const closedSecondLevel = () => {
    let current = countSecondLevelsOpen;
    current--;

    setCountSecondLevelsOpen(current);
    setSecondLevelOpen(current > 0);
  };

  const openedSecondLevel = () => {
    let current = countSecondLevelsOpen;
    current++;

    setCountSecondLevelsOpen(current);
    setSecondLevelOpen(current > 0);
  };

  return (
    <>
      {isLoggedIn && (
        <AppBar
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: draweropen,
          })}
        >
          <Toolbar className={classes.regular}>
            {currentLogin && (
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={() => changeDrawerOpenState(true)}
                edge='start'
                hidden={currentLogin == null ? true : false}
                className={clsx(classes.menuButton, {
                  [classes.hide]: draweropen,
                })}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              className={classes.title}
            >
              {!loginHelper.getCurrentHive() && <>Virtual Hive</>}

              {loginHelper.getCurrentHive() && (
                <>
                  <a
                    className={classes.titleLink}
                    href={loginHelper.getCurrentHive()?.clientUrl}
                    target='_blank'
                  >
                    {loginHelper.getCurrentHive()?.title}
                    {loginHelper.userInMultipleHives() &&
                      !loginHelper.isAdmin() && <HiveSelectButton />}
                  </a>
                </>
              )}
            </Typography>
            <Typography
              component='h1'
              variant='h5'
              color='inherit'
              noWrap
              className={classes.title}
            >
              {currentPageTitle}
            </Typography>
            {currentLogin &&
              loginHelper.getCurrentHive() &&
              loginHelper.isModuleActivatedForCurrentHive('Networking') && (
                <ChatButton />
              )}
            {currentLogin && <SettingsButton />}
          </Toolbar>
          <img
            src={branding}
            alt=''
            style={{
              width: '150px',
              position: 'absolute',
              right: '130px',
              top: '14px',
            }}
            draggable={false}
          />
        </AppBar>
      )}
      <MenuDrawer
        draweropen={draweropen}
        secondLevelOpen={secondLevelOpen}
        type={drawertype}
        requestClose={() => changeDrawerOpenState(false)}
        closedSecondLevel={closedSecondLevel}
        openedSecondLevel={openedSecondLevel}
      />
    </>
  );
};

export default AppMenu;
