import { LoginHelper } from '../../../core/LoginHelper'
import { EventApi } from './EventApi'

export class EventService {
  eventApi = new EventApi()
  loginHelper = new LoginHelper()

  async getEvent(eventId) {
    return await this.eventApi.getEvent(eventId)
  }

  async getEvents() {
    var result = await this.eventApi.getEvents()
    return result
  }

  async getPageEvents(pageId) {
    var result = await this.eventApi.getPageEvents(pageId)
    return result
  }
  async addEvent(eventId, event) {
    return await this.eventApi.addEvent(eventId, event)
  }

  async updateEvent(eventId, event) {
    return await this.eventApi.updateEvent(eventId, event)
  }

  async getKeywords() {
    return await this.eventApi.getKeywords()
  }

  async deleteEvent(id) {
    return await this.eventApi.deleteEvent(id)
  }

  async getEventProgramDays() {
    return await this.eventApi.getEventProgramDays()
  }

  async addEventProgramDay(eventProgramDay) {
    eventProgramDay.hiveId = this.loginHelper.getCurrentHive().id
    return await this.eventApi.addEventProgramDay(eventProgramDay)
  }

  async updateEventProgramDay(eventProgramDayId, eventProgramDay) {
    return await this.eventApi.updateEventProgramDay(
      eventProgramDayId,
      eventProgramDay,
    )
  }

  async deleteEventProgramDay(eventProgramDayId) {
    return await this.eventApi.deleteEventProgramDay(eventProgramDayId)
  }
}
