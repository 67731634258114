export function getAspectRatioOfTemplateObject(templateObject) {
    let widthStyle = templateObject.templateObjectStyles.find(t => t.styleKey === "width").styleValue;
    let heightStyle = templateObject.templateObjectStyles.find(t => t.styleKey === "height").styleValue;

    if (widthStyle && heightStyle) {
        if (widthStyle.endsWith("%") && heightStyle.endsWith("%")) {
            widthStyle = widthStyle.replace("%", "");
            heightStyle = heightStyle.replace("%", "");

            return (parseFloat(widthStyle) * (1920 / 100)) / (parseFloat(heightStyle) * (1080 / 100));
        }
    }

    return null;
}

export function getPixelExampleOfTemplateObject(templateObject) {
    let widthStyle = templateObject.templateObjectStyles.find(t => t.styleKey === "width").styleValue;
    let heightStyle = templateObject.templateObjectStyles.find(t => t.styleKey === "height").styleValue;

    if (widthStyle && heightStyle) {
        if (widthStyle.endsWith("%") && heightStyle.endsWith("%")) {
            widthStyle = widthStyle.replace("%", "");
            heightStyle = heightStyle.replace("%", "");

            // parseFloat(...result.toFixed(2)) returns up to two decimals, but only if needed
            return "w: " + parseFloat((parseFloat(widthStyle) * (1920 / 100)).toFixed(2)) + " h: " + parseFloat((parseFloat(heightStyle) * (1080 / 100)).toFixed(2));
        }
    }

    return null;
}