import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import getLanguage from './Language';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme, sizeclass) => ({
  paperS: {
    width: '480px',
  },
  paperM: {
    width: '640px',
  },
  paperL: {
    width: '768px',
  },
  paperXL: {
    width: '960px',
  },
  floating: {
    position: 'absolute',
    right: '0',
  },
}));

export const GlobalDialog = ({
  type,
  isDialogOpen,
  handleDialogClose,
  title,
  subtext,
  children,
  anchor,
  variant,
  sizeclass,
  hideCloseButton,
}) => {
  const classes = useStyles();

  if (type === 'Drawer') {
    return (
      <Drawer
        disableEnforceFocus={true}
        variant={variant ? variant : 'temporary'}
        open={isDialogOpen ?? true}
        anchor={anchor ? anchor : 'right'}
        onClose={handleDialogClose}
        classes={{
          paper:
            sizeclass === 'XL'
              ? classes.paperXL
              : sizeclass === 'L'
              ? classes.paperL
              : sizeclass === 'M'
              ? classes.paperM
              : classes.paperS,
        }}
      >
        {!hideCloseButton && (
          <DialogActions className={classes.floating}>
            <Button color='secondary' onClick={handleDialogClose}>
              <CloseIcon />
            </Button>
          </DialogActions>
        )}

        {title ? <DialogTitle>{title}</DialogTitle> : null}
        {children}
      </Drawer>
    );
  }

  if (type === 'Dialog') {
    return (
      <>
        <Dialog
          open={isDialogOpen ?? true}
          onClose={handleDialogClose}
          classes={{
            paper:
              sizeclass === 'XL'
                ? classes.paperXL
                : sizeclass === 'L'
                ? classes.paperL
                : sizeclass === 'M'
                ? classes.paperM
                : classes.paperS,
          }}
        >
          {title ? <DialogTitle>{title}</DialogTitle> : null}
          <DialogContent>
            <DialogContentText>{subtext}</DialogContentText>
            {children}
          </DialogContent>
          {!hideCloseButton && (
            <DialogActions>
              <Button
                variant='contained'
                color='primary'
                onClick={handleDialogClose}
              >
                {getLanguage('Close')}
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </>
    );
  }
};

GlobalDialog.propTypes = {
  type: PropTypes.string.isRequired,
  isDialogOpen: PropTypes.bool,
  handleDialogClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  children: PropTypes.element,
  anchor: PropTypes.string,
  variant: PropTypes.string,
};
