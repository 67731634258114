import './ObjectEditor.css'
import {
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { PageService } from '../services/PageService'
import { LoginService } from '../../login/services/LoginService'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import { ToastsStore } from 'react-toasts'
import DataTable from 'react-data-table-component'
import { StorageHelper } from '../../../core/StorageHelper'
import orderBy from 'lodash/orderBy'
import { makeStyles } from '@material-ui/core/styles'
import { validateEmail } from '../../../core/helpers/StringHelper'
import { GlobalDialog } from '../../../core/GlobalDialog'
import { Drafts, Email } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  formControl: {},
}))

const LoginsForPage = (props) => {
  const classes = useStyles()
  const pageService = new PageService()
  const loginService = new LoginService()
  const storageHelper = new StorageHelper()

  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(null)
  const [defaultSortColumn, setDefaultSortColumn] = useState('fullName')
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [userToRemove, setUserToRemove] = useState(null)
  const [loginToSendWelcomeMail, setLoginToSendWelcomeMail] = useState(null)

  useEffect(() => {
    let sortCol = storageHelper.getLoginSortingColumn()
    let sortDirection = storageHelper.getLoginSortingDirection()

    if (sortCol) {
      setDefaultSortColumn(sortCol)
    }
    if (sortDirection) {
      setDefaultSortDirection(sortDirection)
    }

    getPage()
    // eslint-disable-next-line
  }, [])

  const getPage = async () => {
    let response = await pageService.getPage(props.pageId)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      setPage(response)
      setIsLoading(false)
    }
  }

  const addLoginToPage = async () => {
    var validationError = false

    // Validate e-mail valid
    if (!validateEmail(email)) {
      setEmailError(getLanguage('ValidationErrorInvalidEmail'))
      validationError = true
    } else {
      setEmailError('')
    }

    if (validationError) {
      return
    }

    setIsLoading(true)

    let login = await loginService.createUserOrGetIdByEmail(email, fullName)
    if (login.isAxiosError || !login) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${login.response.status} - ${login.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    await loginService.addPageToLogin(page.id, login.id)

    ToastsStore.success(`${getLanguage('UserAddedSuccessfully')}`)

    setFullName('')
    setEmail('')

    props.requestReloadPages()

    getPage()
  }

  const removeLoginClicked = async (login) => {
    setUserToRemove(login)
  }

  const removeLoginFromPage = async () => {
    setIsLoading(true)

    await loginService.removePageFromLogin(page.id, userToRemove.id)
    setUserToRemove(null)

    ToastsStore.success(`${getLanguage('UsersAccessRemovedSuccessfully')}`)

    props.requestReloadPages()

    getPage()
  }

  const sendWelcomeMail = async (loginId) => {
    setIsLoading(true)

    var response = await loginService.postWelcomeMailLoginPage(loginId, page.id)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    ToastsStore.success(`${getLanguage('WelcomeMailSentSuccessfully')}`)

    setLoginToSendWelcomeMail(null)

    getPage()
  }

  const sendWelcomeMailClicked = (login) => {
    setLoginToSendWelcomeMail(login)
  }

  const requestedCloseRemoveUser = () => {
    setUserToRemove(null)
  }

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    storageHelper.setLoginSortingColumn(field)
    storageHelper.setLoginSortingDirection(direction)

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: getLanguage('FullName'),
      selector: row => row.fullName,
      sortable: true,
      cell: (row) =>
        !row.fullName ? <em>({getLanguage('NewUser')})</em> : row.fullName,
    },
    {
      name: getLanguage('Email'),
      selector: row => row.email,
      sortable: true,
    },
    {
      name: getLanguage('Options'),
      cell: (row) => (
        <div>
          {row.welcomeMailSent && (
            <Tooltip title={getLanguage('WelcomeMailSentClickToSendAgain')}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => sendWelcomeMailClicked(row)}
              >
                <Drafts />
              </Button>
            </Tooltip>
          )}
          {!row.welcomeMailSent && (
            <Tooltip title={getLanguage('SendWelcomeMail')}>
              <Button
                variant="contained"
                color="default"
                onClick={() => sendWelcomeMail(row.id)}
              >
                <Email />
              </Button>
            </Tooltip>
          )}{' '}
          <Button
            variant="contained"
            color="secondary"
            onClick={() => removeLoginClicked(row)}
          >
            {getLanguage('Remove')}
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && page && (
        <>
          <Paper className={classes.paper}>
            {page.logins && (
              <DataTable
                columns={columns}
                data={page.logins}
                pagination={true}
                selectableRows={false}
                sortFunction={customSort}
                defaultSortField={defaultSortColumn}
                defaultSortAsc={defaultSortDirection === 'asc'}
                noHeader={true}
              />
            )}
          </Paper>
          <br />
          <form
            onSubmit={(e) => {
              e.preventDefault()
              addLoginToPage()
            }}
          >
            <h3>{getLanguage('InviteWithEmail')}</h3>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                label={getLanguage('FullName')}
                className="textField"
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                error={emailError}
                helperText={emailError}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setEmailError('')
                }}
                label={getLanguage('Email')}
                className="textField"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
            >
              {getLanguage('AddUser')}
            </Button>
          </form>
        </>
      )}

      <GlobalDialog
        isDialogOpen={userToRemove !== null}
        handleDialogClose={() => requestedCloseRemoveUser()}
        title={getLanguage('RemoveUser')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveAccessForThisUser?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => removeLoginFromPage()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={loginToSendWelcomeMail !== null}
        handleDialogClose={() => setLoginToSendWelcomeMail(null)}
        title={getLanguage('SendWelcomeMail')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouWantToResendTheWelcomeMailToTheUser?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => sendWelcomeMail(loginToSendWelcomeMail.id)}
          >
            {getLanguage('SendWelcomeMail')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  )
}

export default LoginsForPage
