import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { ToastsStore } from 'react-toasts'
import { LoginService } from '../../login/services/LoginService'
import Loading from 'react-fullscreen-loading'
import { Button, Container, Paper, Tooltip } from '@material-ui/core'
import { GlobalDialog } from '../../../core/GlobalDialog'
import { makeStyles } from '@material-ui/core/styles'
import { StorageHelper } from '../../../core/StorageHelper'
import orderBy from 'lodash/orderBy'
import DataTable from 'react-data-table-component'
import { LoginHelper } from '../../../core/LoginHelper'
import { Add, Delete } from '@material-ui/icons'
import EditAdministrator from '../components/EditAdministrator'
import { useDispatch } from 'react-redux'
import { updatePageTitle } from '../../../actions/Actions'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}))

const Administrators_Page = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [defaultSortColumn, setDefaultSortColumn] = useState('fullName')
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc')
  const [isLoading, setIsLoading] = useState(true)
  const [loginToRemove, setLoginToRemove] = useState(null)
  const [editIsOpen, setEditIsOpen] = useState(false)
  const [administrators, setAdministrators] = useState([])

  const loginService = new LoginService()
  const storageHelper = new StorageHelper()
  const loginHelper = new LoginHelper()

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Administrators')))

    let sortCol = storageHelper.getLoginSortingColumn()
    let sortDirection = storageHelper.getLoginSortingDirection()

    if (sortCol) {
      setDefaultSortColumn(sortCol)
    }
    if (sortDirection) {
      setDefaultSortDirection(sortDirection)
    }

    getAdministrators()
    // eslint-disable-next-line
  }, [])

  const getAdministrators = async () => {
    setIsLoading(true)

    let response = await loginService.getAdministrators()

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
    } else {
      setAdministrators(response)
      setIsLoading(false)
    }
  }

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase()
      }

      return row[field]
    }

    storageHelper.setLoginSortingColumn(field)
    storageHelper.setLoginSortingDirection(direction)

    return orderBy(rows, handleField, direction)
  }

  const columns = [
    {
      name: getLanguage('FullName'),
      selector: row => row.fullName,
      sortable: true,
      cell: (row) =>
        !row.fullName ? <em>({getLanguage('NewUser')})</em> : row.fullName,
    },
    {
      name: getLanguage('Email'),
      selector: row => row.email,
      sortable: true,
    },
    {
      name: getLanguage('Options'),
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => requestedRemoveAdministrator(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ]

  const addAdministratorClicked = () => {
    setEditIsOpen(true)
  }

  const requestedRemoveAdministrator = async (login) => {
    setLoginToRemove(login)
  }

  const requestCloseCreate = () => {
    setEditIsOpen(false)
  }

  const requestedCloseRemoveUser = () => {
    setLoginToRemove(null)
  }

  const onNewAdministratorCreated = () => {
    setEditIsOpen(false)
    getAdministrators()
  }

  const removeAdministrator = async () => {
    setIsLoading(true)

    let response = await loginService.updateLoginAdminStatus(
      loginToRemove.id,
      false,
    )

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    setLoginToRemove(null)
    getAdministrators()
  }

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}
      {administrators && (
        <>
          <Paper className={classes.paper}>
            {administrators && (
              <DataTable
                noHeader={true}
                columns={columns}
                data={administrators}
                pagination={true}
                selectableRows={false}
                sortFunction={customSort}
                defaultSortField={defaultSortColumn}
                defaultSortAsc={defaultSortDirection === 'asc'}
                subHeader={true}
                subHeaderComponent={
                  loginHelper.isAdmin() && (
                    <div>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => addAdministratorClicked()}
                      >
                        <Add /> {getLanguage('AddNew')}{' '}
                      </Button>
                    </div>
                  )
                }
              />
            )}
          </Paper>
        </>
      )}

      <GlobalDialog
        isDialogOpen={editIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('AddNewAdministrator')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          <EditAdministrator onSave={() => onNewAdministratorCreated()} />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={loginToRemove !== null}
        handleDialogClose={() => requestedCloseRemoveUser()}
        title={getLanguage('RemoveAdministrator')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveAccessForThisUser?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => removeAdministrator()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  )
}

export default Administrators_Page
