import React, { useEffect, useState } from 'react'
import { ToastsStore } from 'react-toasts'
import getLanguage from '../../../core/Language'
import { Button, FormControl, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import { LoginService } from '../../login/services/LoginService'
import { validateEmail } from '../../../core/helpers/StringHelper'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '500px',
  },
  editButton: {
    width: '25px;',
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
    padding: '2px 10px 2px 10px',
  },
  formControl: {},
}))

const EditAdministrator = (props) => {
  const loginService = new LoginService()

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [emailError, setEmailError] = useState('')

  useEffect(() => {}, [])

  const saveData = async () => {
    let validationError = false

    // Validate e-mail valid
    if (!validateEmail(email)) {
      setEmailError(getLanguage('ValidationErrorInvalidEmail'))
      validationError = true
    } else {
      setEmailError('')
    }

    if (validationError) {
      return
    }

    setIsLoading(true)

    let login = await loginService.createrUser({
      email: email,
      fullName: fullName,
      isAdmin: true,
      returnIfExists: true,
      sendMailWithPassword: true,
    })

    if (login.isAxiosError || !login) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${login.response.status} - ${login.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    props.onSave()
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              saveData()
            }}
          >
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                label={getLanguage('FullName')}
                className="textField"
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                error={emailError}
                helperText={emailError}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setEmailError('')
                }}
                label={getLanguage('Email')}
                className="textField"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              color="primary"
            >
              {getLanguage('CreateNew')}
            </Button>
          </form>
        </>
      )}
    </>
  )
}

export default EditAdministrator
