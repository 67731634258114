import React, { useEffect, useState } from 'react'
import { ToastsStore } from 'react-toasts'
import getLanguage from '../../../core/Language'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LoginHelper } from '../../../core/LoginHelper'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import { LoginService } from '../services/LoginService'
import { validateEmail } from '../../../core/helpers/StringHelper'
import { getTimezoneList } from '../../../core/helpers/DateTimeHelper'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '500px',
  },
  editButton: {
    width: '25px;',
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
    padding: '2px 10px 2px 10px',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
}))

const EditLogin = (props) => {
  const loginHelper = new LoginHelper()
  const loginService = new LoginService()

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [fullNameError, setFullNameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [repeatPasswordError, setRepeatPasswordError] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState('')
  const [timezones, setTimezones] = useState([])
  const [didChangeTimezone, setDidChangeTimezone] = useState(false)

  useEffect(() => {
    const prepareData = async () => {
      setFullName(loginHelper.getUserFullName())
      setEmail(loginHelper.getUserEmail())

      setSelectedTimezone(loginHelper.getUserTimezone())
      setTimezones(getTimezoneList())

      setIsLoading(false)
    }

    prepareData()
    // eslint-disable-next-line
  }, [])

  const saveData = async () => {
    let validationError = false

    // Validate title not empty
    if (!fullName) {
      setFullNameError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else {
      setFullNameError('')
    }

    // Validate e-mail valid
    if (!validateEmail(email)) {
      setEmailError(getLanguage('ValidationErrorInvalidEmail'))
      validationError = true
    } else {
      setEmailError('')
    }

    // Validate password valid
    if (password && password.length < 6) {
      setPasswordError(getLanguage('PasswordIsTooShort'))
      validationError = true
    } else {
      setPasswordError('')
    }

    // Validate repeat password
    if (!validationError && password && password !== repeatPassword) {
      setRepeatPasswordError(getLanguage('TheTwoPasswordsAreNotIdentical'))
      validationError = true
    } else {
      setRepeatPasswordError('')
    }

    if (validationError) {
      return
    }

    setIsLoading(true)

    let login = await loginService.updateProfileWithProps({
      email,
      fullName,
      password,
      IanaTimezone: selectedTimezone,
    })
    if (!login || login.isAxiosError) {
      if (login.response.status === 409) {
        setEmailError(getLanguage('EmailAddressAlreadyExists'))
      } else {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo',
          )}: ${login.response.status} - ${login.response.statusText} + ")`,
        )
      }
      setIsLoading(false)
      return
    }

    if (didChangeTimezone) {
      window.location.reload()
    } else {
      setIsLoading(false)
      props.requestClose()
    }
  }

  const getTimezoneOptions = () => {
    return timezones.map((data) => (
      <MenuItem key={data} value={data}>
        {data}
      </MenuItem>
    ))
  }

  const handleTimezoneChanged = (event) => {
    setSelectedTimezone(event.target.value)
    setDidChangeTimezone(true)
  }

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              saveData()
            }}
          >
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                error={fullNameError}
                helperText={fullNameError}
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value)
                  setFullNameError('')
                }}
                label={getLanguage('FullName')}
                className="textField"
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                error={emailError}
                helperText={emailError}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  setEmailError('')
                }}
                label={getLanguage('Email')}
                className="textField"
              />
            </FormControl>
            <div className={classes.selectContainer}>
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel
                  className={classes.selectLabel}
                  id="pageSelector-label"
                >
                  {getLanguage('PleaseChooseYourTimezone')}
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  value={selectedTimezone}
                  onChange={handleTimezoneChanged}
                  labelId="pageSelector-label"
                  id="pageSelector"
                >
                  {getTimezoneOptions()}
                </Select>
              </FormControl>
            </div>

            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                type="password"
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                error={passwordError}
                helperText={passwordError}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                  setPasswordError('')
                }}
                label={getLanguage('PasswordLeaveEmptyToKeepCurrent')}
                className="textField"
              />
            </FormControl>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <TextField
                variant="outlined"
                type="password"
                inputProps={{
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                error={repeatPasswordError}
                helperText={repeatPasswordError}
                value={repeatPassword}
                onChange={(e) => {
                  setRepeatPassword(e.target.value)
                  setRepeatPasswordError('')
                }}
                label={getLanguage('RepeatPassword')}
                className="textField"
              />
            </FormControl>

            <Grid container>
              <Grid item xs={5}>
                <Button
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                >
                  {getLanguage('SaveChanges')}
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <Button
                  onClick={() => props.requestClose()}
                  fullWidth={true}
                  variant="contained"
                  color="secondary"
                >
                  {getLanguage('Cancel')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  )
}

export default EditLogin
