import './ObjectEditor.css';
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import getLanguage from '../../../core/Language';
import { ResourceService } from './../../resource/services/ResourceService';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { ToastsStore } from 'react-toasts';
import { makeStyles } from '@material-ui/core/styles';
import { HiveService } from '../../hive/services/HiveService';
import moment from 'moment';
import { LoginHelper } from '../../../core/LoginHelper';
import ImageCropUpload from '../../resource/components/ImageCropUpload';
import {
  getAspectRatioOfTemplateObject,
  getPixelExampleOfTemplateObject,
} from '../helpers/PageHelper';
import { v4 as uuidv4 } from 'uuid';
import LanguageSelector from '../../language/components/LanguageSelector';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}));

const ObjectImageEditor = (props) => {
  const classes = useStyles();
  const resourceService = new ResourceService();
  const loginHelper = new LoginHelper();
  const hiveService = new HiveService();

  const [showUploadOrExternalPart, setShowUploadOrExternalPart] =
    useState('upload');
  const [link, setLink] = useState('');
  const [removeIsOpen, setRemoveIsOpen] = useState(false);
  const [removeTranslationIsOpen, setRemoveTranslationIsOpen] = useState(false);
  const [resources, setResources] = useState([]);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [cropAspectRatio, setCropAspectRatio] = useState(null);
  const [pixelExample, setPixelExample] = useState('');
  const [hiveDetails, setHiveDetails] = useState({});
  const [translationCode, setTranslationCode] = useState('');
  const [resourceIdsObject, setResourceIdsObject] = useState({});
  const [removeTranslation, setRemoveTranslation] = useState(false);

  useEffect(() => {
    if (props.pageObject.data) {
      setLink(props.pageObject.data);
      setShowUploadOrExternalPart('external');
    }

    if (
      loginHelper.userHasRole('PageEditor') ||
      loginHelper.userHasRole('StandEditor')
    ) {
      getResources();
    }

    // Attempt to calculate aspect ratio of image to upload, based on applied styles
    let calculatedAspectRatio = getAspectRatioOfTemplateObject(
      props.pageObject.templateObject
    );
    if (calculatedAspectRatio) {
      setCropAspectRatio(calculatedAspectRatio);
      setPixelExample(
        getPixelExampleOfTemplateObject(props.pageObject.templateObject)
      );
    }
    fetchHiveDetails();
    setResourceIdsObject(props.pageObject.resourceIds);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Attempt to find any selected resource
    if (
      props.pageObject.data &&
      props.pageObject.data.toLowerCase().indexOf('/resources/') !== -1
    ) {
      let urlParts = props.pageObject.data.split('/');
      let existing = resources.find(
        (t) => t.id === urlParts[urlParts.length - 1]
      );
      if (existing) {
        setShowUploadOrExternalPart('assets');
        setSelectedResourceId(existing.id);
      }
    }
    // eslint-disable-next-line
  }, [resources]);

  const fetchHiveDetails = () => {
    hiveService.getHive().then((hiveService) => {
      setHiveDetails(hiveService);
    });
  };

  const getResources = async () => {
    let response = await resourceService.getResources();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      // Filter away resources not being asset uploads
      response = response.filter(
        (t) => t.uploadedFor === 'Assets' && t.fileMimeType.startsWith('image/')
      );
      setResources(response);
    }
  };

  const onFileUploadStarted = () => {
    props.requestSetIsLoading(true);
  };

  const onFileUploaded = async (id, isTranslation, languageId) => {
    if (isTranslation) {
      props.setlanguageIds((oldObject) => ({ ...oldObject, [languageId]: id }));
      props.setIsTranslationAdded(true);
      props.requestSetIsLoading(false);
    } else {
      attemptUpdateAttribute('resourceId', id);
      props.requestSetIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.isTranslationAdded) {
      attemptUpdateAttribute('resourceIds', props.languageIds);
    }
  }, [props.isTranslationAdded, props.languageIds]);

  useEffect(() => {
    if (removeTranslation) {
      attemptUpdateAttribute('resourceIds', resourceIdsObject);
    }
  }, [resourceIdsObject]);

  const removeResource = async () => {
    props.removeResource();
    setRemoveIsOpen(false);
  };

  const removeTranslationHandler = (translationId) => {
    const removeResourceIdsObject = Object.fromEntries(
      Object.entries(resourceIdsObject).filter(([key]) => key !== translationId)
    );
    setRemoveTranslationIsOpen(false);
    setResourceIdsObject(removeResourceIdsObject);
  };

  const getResourceOptions = () => {
    return resources.map((data) => (
      <MenuItem key={data.id} value={data.id}>
        {data.fileName} ({moment(data.created).format('DD.MM.YYYY HH:mm')})
      </MenuItem>
    ));
  };

  const handleResourceChanged = (event) => {
    setSelectedResourceId(event.target.value);

    let finalLink = resourceService.getResourceLink(event.target.value);

    attemptUpdateAttribute('data', finalLink);
  };

  const attemptUpdateAttribute = (attribute, newValue) => {
    if (props.updateAttribute) {
      props.updateAttribute(attribute, newValue);
    }
  };

  const LanguageComponents = () => {
    const filteredLanguages = hiveDetails?.hiveLanguages?.filter(
      (language) => language.id !== hiveDetails.defaultLanguage.id
    );

    return (
      <>
        {filteredLanguages?.map((language) => (
          <div key={uuidv4()}>
            <ImageCropUpload
              uniqueKey={uuidv4()}
              uploadedFor='PageObjectImage'
              fileUploadStarted={onFileUploadStarted}
              fileUploadedCallback={(id) =>
                onFileUploaded(id, true, language.id)
              }
            />
            {Object.keys(props.pageObject.resourceIds).find(
              (item) => item === language.id
            ) && (
              <Button
                fullWidth={true}
                variant='contained'
                color='primary'
                onClick={() => (
                  setTranslationCode(language.id),
                  setRemoveTranslationIsOpen(true)
                )}
              >
                {getLanguage('RemoveImage')}
              </Button>
            )}
            <h3>{language.name}</h3>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <h2>{getLanguage('Image')}</h2>
      <Grid container item xs={12} justify='center'>
        {props.pageObject.resourceId && (
          <>
            <img
              height='250'
              width='100%'
              src={resourceService.getResourceLink(props.pageObject.resourceId)}
              alt=''
            />
            <br />
            <Button
              fullWidth={true}
              variant='contained'
              color='primary'
              onClick={() => setRemoveIsOpen(true)}
            >
              {getLanguage('RemoveImage')}
            </Button>
            <br />
          </>
        )}
        {props.pageObject.data && (
          <>
            <img height='250' width='100%' src={props.pageObject.data} alt='' />
            <br />
          </>
        )}
      </Grid>
      <div className='buttons'>
        <div>
          <div>
            <Grid container spacing={3}>
              <Grid
                item
                xs={
                  loginHelper.userHasRole('PageEditor') ||
                  loginHelper.userHasRole('StandEditor')
                    ? 4
                    : 6
                }
              >
                <RadioGroup
                  value={showUploadOrExternalPart}
                  onClick={() => setShowUploadOrExternalPart('upload')}
                >
                  <FormControlLabel
                    value='upload'
                    control={<Radio />}
                    label={getLanguage('UploadNewImage')}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={
                  loginHelper.userHasRole('PageEditor') ||
                  loginHelper.userHasRole('StandEditor')
                    ? 4
                    : 6
                }
              >
                <RadioGroup
                  value={showUploadOrExternalPart}
                  onClick={() => setShowUploadOrExternalPart('external')}
                >
                  <FormControlLabel
                    value='external'
                    control={<Radio />}
                    label={getLanguage('UseExternalImage')}
                  />
                </RadioGroup>
              </Grid>
              {(loginHelper.userHasRole('PageEditor') ||
                loginHelper.userHasRole('StandEditor')) && (
                <Grid item xs={4}>
                  <RadioGroup
                    value={showUploadOrExternalPart}
                    onClick={() => setShowUploadOrExternalPart('assets')}
                  >
                    <FormControlLabel
                      value='assets'
                      control={<Radio />}
                      label={getLanguage('ChooseFromAssets')}
                    />
                  </RadioGroup>
                </Grid>
              )}
            </Grid>
          </div>
          <br />
          {showUploadOrExternalPart === 'upload' && (
            <>
              <ImageCropUpload
                cropAspectRatio={cropAspectRatio}
                uploadedFor='PageObjectImage'
                uniqueKey={props.pageObject.id}
                fileUploadStarted={onFileUploadStarted}
                fileUploadedCallback={(id) => onFileUploaded(id, false)}
              />
              {LanguageComponents()}
            </>
          )}
          {showUploadOrExternalPart === 'external' && (
            <>
              <b>{getLanguage('ExternalImageLink')}</b>
              <br />
              <br />
              <TextField
                variant='outlined'
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                  attemptUpdateAttribute('data', e.target.value);
                }}
                label='https://'
                className='textField'
              />
              <LanguageSelector
                attemptUpdateAttribute={attemptUpdateAttribute}
                pageObject={props.pageObject}
                contentType={'embed'}
              >
                <TextField
                  variant='outlined'
                  label='https://'
                  className='textField'
                />
              </LanguageSelector>
            </>
          )}
          {showUploadOrExternalPart === 'assets' && (
            <>
              <div className={classes.selectContainer}>
                <FormControl
                  fullWidth={true}
                  variant='outlined'
                  className={classes.formControl}
                >
                  <InputLabel
                    className={classes.selectLabel}
                    id='hiveSelector-label'
                  >
                    {getLanguage('ChooseFromAssets')}
                  </InputLabel>
                  <Select
                    variant='outlined'
                    fullWidth={true}
                    value={selectedResourceId}
                    onChange={handleResourceChanged}
                    labelId='hiveSelector-label'
                    id='hiveSelector'
                  >
                    <MenuItem value={''}>
                      <em>{getLanguage('PleaseChoose')}</em>
                    </MenuItem>
                    {getResourceOptions()}
                  </Select>
                  <LanguageSelector
                    attemptUpdateAttribute={attemptUpdateAttribute}
                    pageObject={props.pageObject}
                    contentType={'assets'}
                  >
                    <Select
                      variant='outlined'
                      fullWidth={true}
                      labelId='hiveSelector-label'
                      id='hiveSelector'
                    >
                      <MenuItem value={''}>
                        <em>{getLanguage('PleaseChoose')}</em>
                      </MenuItem>
                      {getResourceOptions()}
                    </Select>
                  </LanguageSelector>
                </FormControl>
              </div>
            </>
          )}
        </div>
        {pixelExample}
      </div>

      <GlobalDialog
        isDialogOpen={removeIsOpen}
        handleDialogClose={() => setRemoveIsOpen(false)}
        title={getLanguage('RemoveImage')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheUploadedImage?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => removeResource()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
      <GlobalDialog
        isDialogOpen={removeTranslationIsOpen}
        handleDialogClose={() => setRemoveTranslationIsOpen(false)}
        title={getLanguage('RemoveImage')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheUploadedImage?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            onClick={() => (
              removeTranslationHandler(translationCode),
              setRemoveTranslation(true)
            )}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default ObjectImageEditor;
