import './EditClientFileDetails.css'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    height: '50px',
  },
}))

const EditClientFileDetails = (props) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')
  const [kind, setKind] = useState('')
  const [kindError, setKindError] = useState('')
  const [data, setData] = useState('')

  useEffect(() => {
    if (props.clientFile) {
      setTitle(props.clientFile.title)
      setKind(props.clientFile.kind)
      setData(props.clientFile.data)
    }
    // eslint-disable-next-line
  }, [])

  const onSaveClicked = async () => {
    var validationError = false

    // Validate title
    if (!title) {
      setTitleError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else if (title.length > 120) {
      setTitleError(
        getLanguage('TooLongMaxLengthIs').replace('{maxLength}', '120'),
      )
      validationError = true
    } else {
      setTitleError('')
    }

    // Validate kind
    if (!kind) {
      setKindError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else if (kind.length > 60) {
      setKindError(
        getLanguage('TooLongMaxLengthIs').replace('{maxLength}', '60'),
      )
      validationError = true
    } else {
      setKindError('')
    }

    if (validationError) {
      return
    }

    props.onSave(
      {
        title,
        kind,
        data,
      },
      null,
    )
  }

  const handleKindChanged = (event) => {
    setKind(event.target.value)
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSaveClicked()
        }}
      >
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            variant="outlined"
            value={title}
            error={titleError}
            helperText={titleError}
            onChange={(e) => {
              setTitle(e.target.value)
              setTitleError('')
            }}
            label={getLanguage('Title')}
            className="textField"
          />
        </FormControl>

        <div className={classes.selectContainer}>
          <FormControl
            fullWidth={true}
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel
              error={kindError}
              className={classes.selectLabel}
              id="kindSelector-label"
            >
              {getLanguage('Kind')}
            </InputLabel>
            <Select
              error={kindError}
              variant="outlined"
              fullWidth={true}
              value={kind}
              onChange={handleKindChanged}
              labelId="kindSelector-label"
              id="kindSelector"
            >
              <MenuItem key={'css'} value={'css'}>
                {getLanguage('Stylesheet(.css)')}
              </MenuItem>
              <MenuItem key={'js'} value={'js'}>
                {getLanguage('JavaScript(.js)')}
              </MenuItem>
            </Select>
            {kindError && (
              <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                {kindError}
              </p>
            )}
          </FormControl>
        </div>
        <br />
        <br />
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            variant="outlined"
            multiline={true}
            rows={10}
            value={data}
            onChange={(e) => {
              setData(e.target.value)
            }}
            label={getLanguage('Data')}
            className="textField"
          />
        </FormControl>

        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          {getLanguage('Save')}
        </Button>
        <br />
        <br />
      </form>
    </>
  )
}

export default EditClientFileDetails
