import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from "axios";
import { LoginHelper } from "../../../core/LoginHelper";

export class ResourceApi {
  loginHelper = new LoginHelper();

  async getResources(hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.get(`${getApiPath()}/resources`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getResourceFileContents(id) {
    let loginHelper = new LoginHelper();
    let loginObject = loginHelper.getLoginObject();
    let loginToken = loginObject ? loginObject.token : "";

    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginToken}`,
        },
        responseType: "blob",
      };
      let response = await Axios.get(`${getApiPath()}/resources/${id}`, config);
      return new Blob([response.data]);
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async createResource(hiveId, uploadedFor, file, overrideFilename) {
    try {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId,
          uploadedfor: uploadedFor,
        },
      };

      let formData = new FormData();
      // formData.append('filename', file.name);
      // formData.append('fileType', file.type);
      if (!overrideFilename) {
        formData.append("fileContent", file);
      } else {
        formData.append("fileContent", file, overrideFilename);
      }

      let response = await Axios.post(
        `${getApiPath()}/resources`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async createResourceThumb(resourceId, file) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: this.loginHelper.getCurrentHive().id,
        },
      };

      let formData = new FormData();
      formData.append("fileContent", file);

      let response = await Axios.post(
        `${getApiPath()}/resources/${resourceId}/thumb`,
        formData,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async createEmbedResource(hiveId, uploadedFor, embed) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId,
        },
      };
      let response = await Axios.post(
        `${getApiPath()}/resources/embed/${uploadedFor}`,
        embed,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateResource(resourceId, resource) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.put(
        `${getApiPath()}/resources/${resourceId}`,
        resource,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateEmbedResource(hiveId, resourceId, embed) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.put(
        `${getApiPath()}/hive/${hiveId}/resources/${resourceId}/embed`,
        embed,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async deleteResource(resourceId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.delete(
        `${getApiPath()}/resources/${resourceId}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async deleteEmbedResource(resourceId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.delete(
        `${getApiPath()}/resources/${resourceId}/embed`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async UpsertPageesourceRels(newRel) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.post(
        `${getApiPath()}/resources/${newRel.resourceId}/page/${newRel.pageId}`,
        newRel,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getPageRelationsFromResource(hiveId, resourceId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId,
        },
      };

      let response = await Axios.get(
        `${getApiPath()}/resources/${resourceId}/pageResourceRelation`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getVimeoUploadLink(hiveId, uploadedfor, size) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId,
        },
      };

      let response = await Axios.post(
        `${getApiPath()}/resources/${uploadedfor}/uploadtoken/${size}`,
        null,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getVimeoTranscodeStatus(resourceId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.get(
        `${getApiPath()}/resources/${resourceId}/transcodestatus`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async copyResource(resourceId, uploadedFor) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.post(
        `${getApiPath()}/resources/${resourceId}/copy/${uploadedFor}`,
        null,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }
}
