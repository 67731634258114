import { Button, Tooltip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ToastsStore } from 'react-toasts'
// import { makeStyles } from '@material-ui/core/styles'
import FolderIcon from '@material-ui/icons/Folder'
import PublishIcon from '@material-ui/icons/Publish'
import { CsvService } from '../services/CsvService'
import getLanguage from '../../../core/Language'
import { LoadingIndicator } from '../../../core/LoadingIndicator'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//   },
// }))

const CsvFrontendLoginImport = (props) => {
  //   const classes = useStyles()
  const csvService = new CsvService()

  const [isLoading, setIsLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const onFileChange = async (evt) => {
    setSelectedFile(evt.target.files)
  }

  const onFileUpload = async () => {
    if (selectedFile) {
      setIsLoading(true)
      let response = await csvService.uploadVisitorsCsv(
        selectedFile[0],
        undefined,
      )

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo',
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`,
        )
      } else {
        setSelectedFile(null)

        setIsLoading(false)

        props.requestReloadLogins()
        props.requestClose()
      }
    }
  }

  useEffect(() => {}, [])

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <h2>{getLanguage('ImportVisitors')}</h2>
      <input
        id="fileUpload"
        type="file"
        onChange={onFileChange}
        hidden
        accept={props.acceptFileTypes}
      />
      <Tooltip title="File explorer">
        <label htmlFor="fileUpload">
          <Button variant="contained" color="primary" component="span">
            <FolderIcon /> {getLanguage('SelectFile')}
          </Button>
        </label>
      </Tooltip>{' '}
      <Tooltip title="Selected file">
        <label component="span">
          {selectedFile ? selectedFile[0].name : 'No files selected...'}
        </label>
      </Tooltip>
      <br />
      <br />
      {selectedFile ? (
        <Tooltip title="Upload">
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={onFileUpload}
          >
            <PublishIcon /> {getLanguage('Upload')}
          </Button>
        </Tooltip>
      ) : null}
    </>
  )
}

export default CsvFrontendLoginImport
