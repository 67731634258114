import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { TemplateService } from '../services/TemplateService'
import { ToastsStore } from 'react-toasts'
import Loading from 'react-fullscreen-loading'
import getLanguage from './../../../core/Language'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TemplateObjectsList from '../../templates/components/TemplateObjectsList'
import { navigateToEditHive_Page } from '../../../core/Navigation'
import { useDispatch } from 'react-redux'
import { updatePageTitle } from '../../../actions/Actions'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '500px',
  },
  editButton: {
    width: '25px;',
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
    padding: '2px 10px 2px 10px',
  },
  helpButon: {
    marginTop: '18px',
  },
}))

const EditTemplate_Page = (props) => {
  const templateService = new TemplateService()

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [template, setTemplate] = useState(null)
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    const prepareData = async () => {
      loadTemplate()
    }

    prepareData()
    // eslint-disable-next-line
  }, [])

  const loadTemplate = async () => {
    let response = await templateService.getTemplate(id)

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${response.response.status} - ${response.response.statusText} + ")`,
      )
    } else {
      setTemplate(response)
      dispatch(updatePageTitle(response.title))
      setIsLoading(false)
    }
  }

  const requestedReloadTemplate = () => {
    loadTemplate()
  }

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      {template && (
        <>
          <div className={classes.root}>
            <Button
              variant="contained"
              color="default"
              onClick={(e) => {
                e.preventDefault()
                navigateToEditHive_Page(props)
              }}
            >
              {getLanguage('BackToList')}
            </Button>
            <br />
            <br />
            <TemplateObjectsList
              template={template}
              requestReloadTemplate={() => requestedReloadTemplate()}
            />
          </div>
        </>
      )}
    </>
  )
}

export default EditTemplate_Page
