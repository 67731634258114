import { LoginHelper } from '../../../core/LoginHelper';
import { StatisticsApi } from './StatisticsApi';

export class StatisticsService {
  statisticsApi = new StatisticsApi();
  loginHelper = new LoginHelper();

  async getAllTrackingEvents(hiveId, fromDate, toDate) {
    hiveId = hiveId ?? this.loginHelper.getCurrentHive().id;

    return await this.statisticsApi.getAllTrackingEvents(
      hiveId,
      fromDate,
      toDate
    );
  }

  async getStats(hiveId, fromDate, toDate, filters) {
    hiveId = hiveId ?? this.loginHelper.getCurrentHive().id;

    return await this.statisticsApi.getStats(hiveId, fromDate, toDate, filters);
  }

  async getPageVisitors(pageId) {
    return await this.statisticsApi.getPageVisitors(pageId);
  }

  async getPageStatsToday(pageId) {
    return await this.statisticsApi.getPageStatsToday(pageId);
  }

  async getPageStats(pageId, fromDate, toDate) {
    return await this.statisticsApi.getPageStats(pageId, fromDate, toDate);
  }

  async getWhereByStats() {
    return await this.statisticsApi.getWhereByStats();
  }
}
