import { getApiPath } from '../../../core/ServiceRoutes'
import Axios from 'axios'
import { LoginHelper } from '../../../core/LoginHelper'

export class AuthorizationApi {
  loginHelper = new LoginHelper()

  async authorize(credentials) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      console.log('Logging in..')
      let response = await Axios.post(
        `${getApiPath()}/authenticate`,
        credentials,
        config,
      )
      return response
    } catch (error) {
      console.log({ ...error })
      return { ...error }
    }
  }
}
