import { useState, useEffect, Children, createElement } from 'react';
import {
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { HiveService } from '../../hive/services/HiveService';
import { v4 as uuidv4 } from 'uuid';
import getLanguage from '../../../core/Language';

const LanguageSelector = ({
  children: initialChildren,
  attemptUpdateAttribute,
  pageObject,
  contentType,
}) => {
  const hiveService = new HiveService();
  const [translations, setTranslations] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [languageArray, setLanguageArray] = useState([]);
  const [hiveDetails, setHiveDetails] = useState({});
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [textContentID, setTextContentID] = useState('');
  const [children, setChildren] = useState([]);
  const [textFields, setTextFields] = useState({});
  const [selectedLanguages, setSelectedLanguages] = useState({});
  const [languageCount, setLanguageCount] = useState(0);
  const [isAttributeUpdateNeeded, setIsAttributeUpdateNeeded] = useState(false);
  const [dataContextID, setDataContextID] = useState('');
  const initialProps = initialChildren.props;

  const addLanguageHandler = () => {
    const childId = uuidv4();
    setLanguageCount((prevCount) => prevCount + 1);
    setChildren((prevChildren) => [
      ...prevChildren,
      {
        ...initialChildren,
        props: { ...initialProps, id: childId },
      },
    ]);
    setTextFields((prevFields) => ({ ...prevFields, [childId]: '' }));
    setSelectedLanguages((prevSelectedLanguages) => {
      return { ...prevSelectedLanguages, [childId]: { language: '', id: '' } };
    });
  };

  const onChangeLanguageHandler = (e, childId, child) => {
    setSelectedLanguages((prevSelectedLanguages) => ({
      ...prevSelectedLanguages,
      [childId]: {
        language: e?.target?.value,
        id: child.props.id,
      },
    }));

    setIsAttributeUpdateNeeded(true);
  };

  const removeLanguageHandler = (childId) => {
    setLanguageCount((prevCount) => prevCount - 1);
    setChildren((prevChildren) =>
      prevChildren.filter((child) => child.props.id !== childId)
    );
    setTextFields((prevFields) =>
      Object.keys(prevFields)
        .filter((key) => key !== childId)
        .reduce((result, key) => {
          result[key] = prevFields[key];
          return result;
        }, {})
    );
    setSelectedLanguages((prevSelectedLanguages) => {
      return Object.keys(prevSelectedLanguages)
        .filter((key) => key !== childId)
        .reduce((result, key) => {
          result[key] = prevSelectedLanguages[key];
          return result;
        }, {});
    });

    setIsAttributeUpdateNeeded(true);
  };

  const textChangeHandler = (id, value) => {
    setTextFields((prevFields) => ({ ...prevFields, [id]: value }));
    setIsAttributeUpdateNeeded(true);
  };

  const fetchHiveDetails = () => {
    hiveService.getHive().then((hiveService) => {
      setHiveDetails(hiveService);
      setTranslations(hiveService.hiveLanguages);
    });
  };

  const createTranslations = () => {
    const result = Object.entries(selectedLanguages)
      .filter(([languageId, _]) => textFields[languageId])
      .map(([languageId, language]) => ({
        languageId: language.id,
        translation: `${
          contentType === 'assets'
            ? 'https://vh-staging-cdn.azureedge.net/resources/' +
              textFields[languageId]
            : textFields[languageId]
        }`,
      }));

    setLanguageArray(result);
  };

  const createLanguageDropdown = (languages, childId) => {
    const filteredLanguages = languages.filter(
      (language) => language.id !== defaultLanguage
    );
    const languageOptions = filteredLanguages.map((item) => (
      <MenuItem value={item.name} key={item.id} id={item.id}>
        {item.name}
      </MenuItem>
    ));

    return (
      <>
        <FormControl
          fullWidth
          style={{
            margin: '30px 0 30px 0',
          }}
        >
          <InputLabel>{getLanguage('ChooseLanguage')}</InputLabel>
          <Select
            value={selectedLanguages[childId]?.language}
            onChange={(e, child) => onChangeLanguageHandler(e, childId, child)}
          >
            {languageOptions}
          </Select>
        </FormControl>
      </>
    );
  };

  const generateExistingTranslationsHandler = (contentTranslations) => {
    const filteredTranslations = translations.filter(
      (language) => language.id !== hiveDetails.defaultLanguageId
    );

    const filteredContentsTranslations = contentTranslations.filter(
      (contentTranslation) =>
        filteredTranslations.some(
          (item) => contentTranslation.languageId === item.id
        )
    );
    setLanguageCount(filteredTranslations.length);

    let childArray = [];
    let textFieldObject = {};
    let selectedLanguageObject = {};

    if (filteredTranslations.length > 0) {
      filteredContentsTranslations.forEach((contentTranslation, i) => {
        if (contentType === 'assets') {
          const translationWithoutCdn =
            contentTranslation.translation.split('resources/')[1];
          const childId = uuidv4();
          childArray.push({
            ...initialChildren,
            props: { ...initialProps, id: childId },
          });
          filteredTranslations.forEach((filteredTranslation) => {
            if (filteredTranslation.id === contentTranslation.languageId) {
              textFieldObject[childId] = translationWithoutCdn;
              selectedLanguageObject[childId] = {
                language: filteredTranslation.name,
                id: filteredTranslation.id,
              };
            }
          });
        } else {
          const childId = uuidv4();
          childArray.push({
            ...initialChildren,
            props: { ...initialProps, id: childId },
          });
          filteredTranslations.forEach((filteredTranslation) => {
            if (filteredTranslation.id === contentTranslation.languageId) {
              textFieldObject[childId] = contentTranslation.translation;
              selectedLanguageObject[childId] = {
                language: filteredTranslation.name,
                id: filteredTranslation.id,
              };
            }
          });
        }
      });

      setChildren((prevChildren) => [...prevChildren, ...childArray]);
      setTextFields((prevFields) => ({ ...prevFields, ...textFieldObject }));
      setSelectedLanguages((prevSelectedLanguages) => ({
        ...prevSelectedLanguages,
        ...selectedLanguageObject,
      }));
    } else {
      console.error(
        "Length of contentTranslations and filteredTranslations don't match."
      );
    }
  };

  useEffect(() => {
    fetchHiveDetails();
    setMetaData(pageObject);
    setTextContentID(pageObject?.textTextContent?.id);
    setDataContextID(pageObject?.dataTextContent?.id);
  }, []);

  useEffect(() => {
    if (pageObject?.textTextContent?.translations.length > 0) {
      generateExistingTranslationsHandler(
        pageObject.textTextContent.translations
      );
    }

    if (pageObject?.dataTextContent?.translations.length > 0) {
      generateExistingTranslationsHandler(
        pageObject.dataTextContent.translations
      );
    }
  }, [translations]);

  useEffect(() => {
    createTranslations();
  }, [selectedLanguages, textFields]);

  useEffect(() => {
    setDefaultLanguage(hiveDetails.defaultLanguage?.id);
  }, [hiveDetails.defaultLanguage]);

  useEffect(() => {
    let updatedAttributes = {};
    switch (contentType) {
      case 'hotspot':
        updatedAttributes = textContentID
          ? {
              id: textContentID,
              originalText: metaData.text,
              originalLanguageId: defaultLanguage,
              translations: languageArray,
            }
          : {
              originalText: metaData.text,
              originalLanguageId: defaultLanguage,
              translations: languageArray,
            };
        isAttributeUpdateNeeded &&
          attemptUpdateAttribute('textTextContent', updatedAttributes);
        break;
      case 'embed':
        updatedAttributes = dataContextID
          ? {
              id: dataContextID,
              originalText: metaData.data,
              originalLanguageId: defaultLanguage,
              translations: languageArray,
            }
          : {
              originalText: metaData.data,
              originalLanguageId: defaultLanguage,
              translations: languageArray,
            };
        isAttributeUpdateNeeded &&
          attemptUpdateAttribute('dataTextContent', updatedAttributes);
        break;
      case 'assets':
        updatedAttributes = dataContextID
          ? {
              id: dataContextID,
              originalText: metaData.data,
              originalLanguageId: defaultLanguage,
              translations: languageArray,
            }
          : {
              originalText: metaData.data,
              originalLanguageId: defaultLanguage,
              translations: languageArray,
            };
        isAttributeUpdateNeeded &&
          attemptUpdateAttribute('dataTextContent', updatedAttributes);
        break;
    }
  }, [selectedLanguages, languageArray]);

  return (
    <>
      {languageCount > 0 && (
        <>
          <div>
            {Children.map(children, (child, index) => {
              return (
                <>
                  <div>
                    {createLanguageDropdown(translations, child.props.id)}
                  </div>
                  {createElement(child.type, {
                    key: index,
                    value: textFields[child.props.id],
                    onChange: (e) =>
                      textChangeHandler(child.props.id, e.target.value),
                    ...child.props,
                  })}
                  <Button
                    variant="contained"
                    color="error"
                    style={{
                      marginTop: 30,
                    }}
                    onClick={() => removeLanguageHandler(child.props.id)}
                  >
                    {getLanguage('RemoveTranslation')}
                  </Button>
                </>
              );
            })}
          </div>
        </>
      )}
      <Button
        variant="contained"
        style={{
          color: '#fff',
          backgroundColor: '#121C42',
          marginTop: 30,
        }}
        onClick={addLanguageHandler}
      >
        {getLanguage('AddTranslation')}
      </Button>
    </>
  );
};

export default LanguageSelector;
