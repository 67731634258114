import {
  UPDATE_MESSAGE_LOG,
  UPDATE_PAGETITLE_REQUEST,
  CLEAR_ERROR_REQUEST,
  UPDATE_ACTIVE_CHATNETWORKID_REQUEST,
  GET_MATRIXCLIENT,
  GET_ROOMINFORMATION,
  UPDATE_CHATLIST,
  GET_NEWMESSAGENOTIFICATION,
  REMOVE_NEWMESSAGENOTIFICATION,
  GET_ACTIVEGUESTROOMID,
  GET_ONLINEVISITORS,
  GET_MEETING_GROUP_ID,
} from "../actions/Actions";
import {
  convertDateTimesToLocalOfChatNetwork,
  convertDateTimesToLocalOfChatNetworkMessage,
  filterMessagesSentOutsidePeriodOfParticipation,
  sortChatNetworks,
} from "../features/chat/helpers/ChatHelper";
import { convertDateTimesToLocalOfLoginActivityEntry } from "../features/activity/helpers/ActivityHelper";

const initialState = {
  currentPageTitle: "",
  activeUsersCms: [],
  activeUsersClient: [],
  errors: [],
  chatNetworks: [],
  activeChatNetworkId: null,
  chatsLastOpened: [],
  chatsLastActivity: [],
  chatOverlayLastOpened: new Date(),
  closedChatNetworks: null,
  clientInfos: [],
  matrixClient: {},
  roomInformation: [],
  isUpdateNeeded: false,
  newMessageNotification: [],
  sceneName: "",
  activeUserRoomId: "",
  meetingGroupId: "",
};

export default function reducer(state, action) {
  if (typeof state === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case UPDATE_PAGETITLE_REQUEST:
      return Object.assign({}, state, {
        currentPageTitle: action.title,
      });

    case CLEAR_ERROR_REQUEST:
      return Object.assign({}, state, {
        errors: [
          ...state.errors.filter((t) => t.identifier !== action.identifier),
        ],
      });

    case GET_MEETING_GROUP_ID:
      return Object.assign({}, state, {
        meetingGroupId: action.meetingGroupId,
      });

    case UPDATE_ACTIVE_CHATNETWORKID_REQUEST:
      if (action.id) {
        return Object.assign({}, state, {
          activeChatNetworkId: action.id,
          chatsLastOpened: [
            ...state.chatsLastOpened.filter(
              (t) => t.chatNetworkId !== action.id
            ),
            { chatNetworkId: action.id, lastOpened: new Date() },
          ],
          chatOverlayLastOpened: new Date(),
        });
      } else {
        let chatsLastOpened = state.chatsLastOpened;

        if (state.activeChatNetworkId) {
          chatsLastOpened = chatsLastOpened.filter(
            (t) => t.chatNetworkId !== state.activeChatNetworkId
          );
          chatsLastOpened.push({
            chatNetworkId: state.activeChatNetworkId,
            lastOpened: new Date(),
          });
        }

        return Object.assign({}, state, {
          activeChatNetworkId: action.id,
          chatOverlayLastOpened: new Date(),
          chatsLastOpened: [...chatsLastOpened],
        });
      }

    case GET_MATRIXCLIENT:
      if (action.client) {
        return { ...state, matrixClient: action.client };
      }
      break;

    case GET_ROOMINFORMATION:
      if (action.room) {
        return {
          ...state,
          roomInformation: [
            ...action.room?.timeline?.filter(
              (item) => item?.event?.type === "m.room.message"
            ),
          ],
        };
      }
      break;

    case GET_ACTIVEGUESTROOMID:
      if (action.roomId) {
        return { ...state, activeUserRoomId: action.roomId };
      }

    case GET_ONLINEVISITORS:
      if (action.onlineVisitors) {
        return {
          ...state,
          activeUsersClient: action.onlineVisitors,
          sceneName: action.sceneName,
          role: action.role
        };
      }

      break;
    case UPDATE_CHATLIST:
      if (action.isUpdateNeeded) {
        return { ...state, isUpdateNeeded: action.isUpdateNeeded };
      }
      break;

    case GET_NEWMESSAGENOTIFICATION:
      if (action.roomId) {
        if (!state.newMessageNotification.includes(action.roomId))
          state.newMessageNotification.push(action.roomId);
      }
      break;

    case REMOVE_NEWMESSAGENOTIFICATION:
      if (action.roomId) {
        const newArrayOfExisitingNotifications =
          state.newMessageNotification.filter(
            (roomid) => roomid !== action.roomId
          );

        state.newMessageNotification = newArrayOfExisitingNotifications;
      }
      break;

    case UPDATE_MESSAGE_LOG:
      switch (action.update.type) {
        case "activity":
          switch (action.update.action) {
            case "FullCmsPage": {
              // Remove all entries for page
              let newList = [];
              let clientInfos = state.clientInfos;

              // Add all incoming
              for (const login of action.update.data) {
                if (!newList.find((t) => t.login.id === login.id)) {
                  newList.push({
                    pageId: action.update.identifier,
                    login: convertDateTimesToLocalOfLoginActivityEntry(login),
                  });
                  clientInfos
                    .filter((t) => t.loginId !== login.id)
                    .push({ loginId: login.id, clientInfo: login.clientInfo });
                }
              }

              return Object.assign({}, state, {
                activeUsersCms: [
                  ...state.activeUsersCms.filter(
                    (t) => t.pageId !== action.update.identifier
                  ),
                  ...newList,
                ],
                clientInfos: [...clientInfos],
              });
            }

            case "LeftCmsPage": {
              return Object.assign({}, state, {
                activeUsersCms: [
                  ...state.activeUsersCms.filter(
                    (t) =>
                      !(
                        t.pageId === action.update.identifier &&
                        t.login.id === action.update.data.id
                      )
                  ),
                ],
              });
            }

            case "sceneLeft": {
              if (action.update.identifier === state.sceneName) {
                return {
                  ...state,
                  activeUsersClient: state.activeUsersClient.filter(
                    (user) => user.userId !== action.update.data.userId
                  ),
                };
              }
              break;
            }

            case "sceneEntered": {
              if (action.update.identifier === state.sceneName) {
                return {
                  ...state,
                  activeUsersClient: [
                    ...state.activeUsersClient,
                    action.update.data,
                  ],
                };
              }
              return state;
            }

            default:
          }
          break;

        case "chat":
          switch (action.update.action) {
            case "Failure":
              return Object.assign({}, state, {
                errors: [
                  ...state.errors,
                  {
                    identifier: "chat",
                    errorCode: action.update.identifier,
                    data: action.update.data,
                  },
                ],
              });

            case "AllChatNetworksForPage":
            case "AllChatNetworksForLogin": {
              let chatsLastActivity = state.chatsLastActivity;
              let chatsLastOpened = state.chatsLastOpened;

              for (const chatNetwork of action.update.data) {
                convertDateTimesToLocalOfChatNetwork(chatNetwork);

                if (
                  chatNetwork.chatNetworkMessages &&
                  chatNetwork.chatNetworkMessages.length > 0
                ) {
                  chatsLastActivity.splice(0, chatsLastActivity.length, {
                    chatNetworkId: chatNetwork.id,
                    lastActivity:
                      chatNetwork.chatNetworkMessages[
                        chatNetwork.chatNetworkMessages.length - 1
                      ].created,
                  });
                }

                chatsLastOpened.push({
                  chatNetworkId: chatNetwork.id,
                  lastOpened: new Date(),
                });
              }

              return Object.assign({}, state, {
                chatNetworks: filterMessagesSentOutsidePeriodOfParticipation(
                  sortChatNetworks([
                    ...state.chatNetworks.filter(
                      (t) =>
                        action.update.data.find((z) => z.id === t.id) == null
                    ),
                    ...action.update.data,
                  ])
                ),
                chatsLastActivity: [
                  ...state.chatsLastActivity.filter(
                    (t) =>
                      action.update.data.find(
                        (z) => z.id === t.chatNetworkId
                      ) == null
                  ),
                  ...chatsLastActivity,
                ],
                chatOverlayLastOpened: state.activeChatNetworkId
                  ? new Date()
                  : state.chatOverlayLastOpened,
              });
            }

            case "NewChatNetwork":
            case "UpdateChatNetwork": {
              let chatsLastActivity = state.chatsLastActivity;

              let chatNetwork = convertDateTimesToLocalOfChatNetwork(
                action.update.data
              );

              if (
                chatNetwork.chatNetworkMessages &&
                chatNetwork.chatNetworkMessages.length > 0
              ) {
                chatsLastActivity.splice(0, chatsLastActivity.length, {
                  chatNetworkId: chatNetwork.id,
                  lastActivity:
                    chatNetwork.chatNetworkMessages[
                      chatNetwork.chatNetworkMessages.length - 1
                    ].created,
                });
              }

              return Object.assign({}, state, {
                chatNetworks: filterMessagesSentOutsidePeriodOfParticipation(
                  sortChatNetworks([
                    ...state.chatNetworks.filter(
                      (t) => chatNetwork.id !== t.id
                    ),
                    chatNetwork,
                  ])
                ),
                chatsLastActivity: [
                  ...state.chatsLastActivity.filter(
                    (t) => chatNetwork.id !== t.chatNetworkId
                  ),
                  ...chatsLastActivity,
                ],
                chatOverlayLastOpened: state.activeChatNetworkId
                  ? new Date()
                  : state.chatOverlayLastOpened,
              });
            }

            case "NewChatNetworkMessage":
              {
                // Find network
                let network = state.chatNetworks.find(
                  (t) => t.id === action.update.data.chatNetworkId
                );

                if (network) {
                  // Prevent duplicate entries
                  if (
                    !network.chatNetworkMessages.find(
                      (t) => t.id === action.update.data.id
                    )
                  ) {
                    // Add new message to chatNetworkMessages
                    network.chatNetworkMessages.push(
                      convertDateTimesToLocalOfChatNetworkMessage(
                        action.update.data
                      )
                    );
                  }

                  let chatsLastActivity = state.chatsLastActivity;
                  let chatsLastOpened = state.chatsLastOpened;

                  if (
                    network.chatNetworkMessages &&
                    network.chatNetworkMessages.length > 0
                  ) {
                    chatsLastActivity.splice(0, chatsLastActivity, {
                      chatNetworkId: network.id,
                      lastActivity:
                        network.chatNetworkMessages[
                          network.chatNetworkMessages.length - 1
                        ].created,
                    });

                    if (state.activeChatNetworkId === network.id) {
                      chatsLastOpened = chatsLastOpened.filter(
                        (t) => t.chatNetworkId !== network.id
                      );
                      chatsLastOpened.push({
                        chatNetworkId: network.id,
                        lastOpened:
                          network.chatNetworkMessages[
                            network.chatNetworkMessages.length - 1
                          ].created,
                      });
                    }
                  }

                  return Object.assign({}, state, {
                    chatNetworks:
                      filterMessagesSentOutsidePeriodOfParticipation(
                        sortChatNetworks([
                          ...state.chatNetworks.filter(
                            (t) => t.id !== network.id
                          ),
                          network,
                        ])
                      ),
                    chatsLastActivity: [
                      ...state.chatsLastActivity.filter(
                        (t) => network.id !== t.chatNetworkId
                      ),
                      ...chatsLastActivity,
                    ],
                    chatOverlayLastOpened: state.activeChatNetworkId
                      ? new Date()
                      : state.chatOverlayLastOpened,
                    chatsLastOpened: [...chatsLastOpened],
                  });
                }
              }
              break;

            case "AllClosedChatNetworksForLogin": {
              let chatNetworks = [];

              for (const chatNetwork of action.update.data) {
                chatNetworks.push(
                  convertDateTimesToLocalOfChatNetwork(chatNetwork)
                );
              }

              return Object.assign({}, state, {
                closedChatNetworks:
                  filterMessagesSentOutsidePeriodOfParticipation(
                    sortChatNetworks(chatNetworks)
                  ),
              });
            }
            default:
          }
          break;
        default:
      }
      break;
    default:
  }

  return state;
}
