export const roles = {
  CONTENT_LIBRARY_ADMINISTRATION: 'ContentLibraryAdministration',
  CONTENT_LIBRARY_AUTO_UPLOAD: 'ContentLibraryAutoUpload',
  MEETING_READ: 'MeetingRead',
  MEETING_ADMINISTRATION: 'MeetingAdministration',
  USER_ADMINISTRATION: 'UserAdministration',
  VISITOR_ADMINISTRATION: 'VisitorAdministration',
  NETWORKING: 'Networking',
  STAND_EDITOR: 'StandEditor',
  STAND_OWNER: 'StandOwner',
  EVENT_ADMINISTRATION: 'EventAdministration',
  NEWS_ADMINISTRATION: 'NewsAdministration',
  POPUP_ADMINISTRATION: 'PopupAdministration',
  KEYWORD_ADMINISTRATION: 'KeywordAdministration',
  ASSET_ADMINISTRATION: 'AssetAdministration',
  MAIL_TEMPLATE_ADMINISTRATION: 'MailTemplateAdministration',
  STATISTICS: 'Statistics',
};
