import './Resources.css';
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ResourceService } from './../services/ResourceService';
import { ToastsStore } from 'react-toasts';
import { useParams } from 'react-router';
import { orderBy } from 'lodash';
import {
  Add,
  Delete,
  Edit,
  InsertDriveFile,
  Theaters,
} from '@material-ui/icons';
import { GlobalDialog } from '../../../core/GlobalDialog';
import ResourceUpload from '../components/ResourceUpload';
import { makeStyles } from '@material-ui/core/styles';
import Loading from 'react-fullscreen-loading';
import VideoUpload from './VideoUpload';
import EditResourceKeywords from './EditResourceKeywords';
import ImageCropUpload from './ImageCropUpload';

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const Resources = (props) => {
  const classes = useStyles();

  const { kind } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [selectedKind, setSelectedKind] = useState('');
  const [orderField, setOrderField] = useState('created');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredResources, setFilteredResources] = useState(0);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [useUploadedFor, setUseUploadedFor] = useState('');
  const [resourceToEdit, setResourceToEdit] = useState(null);
  const [resourceToDelete, setResourceToDelete] = useState(null);
  const [selectedResourceTypeFile, setSelectedResourceTypeFile] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [addEmbedOpen, setAddEmbedOpen] = useState(false);
  const [addEmbedFor, setAddEmbedFor] = useState('ContentLibrary');
  const [embed, setEmbed] = useState('');
  const [uploadVideoOpen, setUploadVideoOpen] = useState(false);
  const [isLiveEvent, setIsLiveEvent] = useState(false);

  const resultsPerPage = 12;

  const resourceService = new ResourceService();

  useEffect(() => {
    if (kind) {
      setSelectedKind(kind);
    }

    if (props.module === 'ContentLibrary') {
      setUseUploadedFor('ContentLibrary');
      setSelectedKind('ContentLibrary');
    }

    loadResources();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterResources();
    // eslint-disable-next-line
  }, [resources, selectedKind]);

  const loadResources = async () => {
    setIsLoading(true);

    let response = await resourceService.getResources();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      if (props.module === 'ContentLibrary') {
        response = response.filter(
          (t) => t.uploadedFor !== null && t.uploadedFor === 'ContentLibrary'
        );
      } else {
        response = response.filter(
          (t) =>
            t.uploadedFor !== null &&
            (t.uploadedFor === 'Assets' || t.uploadedFor === 'EmailTemplate')
        );
      }

      setResources(response);

      setIsLoading(false);
    }
  };

  const filterResources = async () => {
    let result = resources;
    if (selectedKind) {
      result = result.filter((t) => t.uploadedFor === selectedKind);
    } else {
      // Fallback, only show Asset or EmailTemplate
      result = result.filter(
        (t) =>
          t.uploadedFor !== null &&
          (t.uploadedFor === 'Assets' || t.uploadedFor === 'EmailTemplate')
      );
    }

    result = orderBy(result, orderField, 'desc');

    setFilteredResources(result);
  };

  const getResourcesOutput = () => {
    if (!filteredResources) return <></>;
    let resourcesToDisplay = filteredResources.slice(
      0,
      currentPage * resultsPerPage
    );

    let resultsSinceLastReset = 0;

    return resourcesToDisplay.map((resource) => {
      resultsSinceLastReset++;

      if (resultsSinceLastReset === 4) {
        resultsSinceLastReset = 0;
      }

      return (
        <>
          <div className='responsive' key={resource.id}>
            <div className='gallery'>
              <div class='desc'>
                {resource.title ? resource.title : resource.fileName}
                <br />
                <Tooltip title={getLanguage('Edit')}>
                  <Button
                    variant='contained'
                    className={classes.button}
                    onClick={() => updateResourceClicked(resource)}
                  >
                    <Edit />
                  </Button>
                </Tooltip>{' '}
                <Tooltip title={getLanguage('Delete')}>
                  <Button
                    variant='contained'
                    className={classes.button}
                    onClick={() => setResourceToDelete(resource)}
                  >
                    <Delete />
                  </Button>
                </Tooltip>
              </div>
              {getThumbForResource(resource)}
            </div>
          </div>
          {resultsSinceLastReset === 0 && <div class='clearfix'></div>}
        </>
      );
    });
  };

  const getThumbForResource = (resource) => {
    if (
      resource.fileMimeType?.toLowerCase().startsWith('video/') &&
      !resource.thumbExists
    ) {
      return <Theaters className={classes.thumbnail} />;
    }

    if (
      resource.fileMimeType?.toLowerCase().startsWith('image/') &&
      !resource.thumbExists
    ) {
      return (
        <div style={{ padding: '10px' }}>
          <img src={resourceService.getResourceLink(resource.id)} alt='' />
        </div>
      );
    }

    if (resource.thumbExists) {
      return (
        <div style={{ padding: '10px' }}>
          <img src={resourceService.getThumbLink(resource.id)} alt='' />
        </div>
      );
    }

    return <InsertDriveFile className={classes.thumbnail} />;
  };

  const moreResourcesAvailable = () => {
    return filteredResources.length > currentPage * resultsPerPage;
  };

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const onFileUploadStarted = () => {
    setIsLoading(true);
  };

  const onFileUploaded = async (id) => {
    if (selectedKind && selectedKind !== useUploadedFor) {
      setSelectedKind(useUploadedFor);
    }

    if (props.module !== 'ContentLibrary') {
      setUseUploadedFor('');
    }
    setUploadIsOpen(false);
    loadResources();
  };

  const handleKindChanged = (event) => {
    setSelectedKind(event.target.value);
  };

  const handleUploadForChanged = (event) => {
    setUseUploadedFor(event.target.value);
  };

  const deleteResource = async () => {
    setIsLoading(true);

    let response = await resourceService.deleteResource(resourceToDelete.id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setResourceToDelete(null);
      loadResources();
    }
  };

  const updateResourceClicked = (resource) => {
    setResourceToEdit(resource);
    setTitle(resource.title);
    setEmbed(resource.embed);
    setDescription(resource.description);
    setIsLiveEvent(resource.isLiveEvent);
  };

  const onUpdateClicked = async () => {
    setIsLoading(true);

    let response = await resourceService.updateResource(resourceToEdit.id, {
      title,
      description,
      embed,
      updateKeywords: resourceToEdit.updateKeywords,
      isLiveEvent,
    });

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setResourceToEdit(null);
      ToastsStore.success(getLanguage('ResourceUpdatedSuccessfully'));
      loadResources();
    }
  };

  const addEmbedClicked = () => {
    setTitle('');
    setEmbed('');
    setAddEmbedOpen(true);
  };

  const addEmbedResource = async () => {
    setIsLoading(true);

    let response = await resourceService.createEmbedResource(addEmbedFor, {
      title,
      embed,
    });

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setAddEmbedOpen(false);
      ToastsStore.success(getLanguage('EmbedAddedSuccessfully'));
      loadResources();
    }
  };

  const handleResourceFileType = (event) => {
    const filteredByFiletype = resources.filter((file) =>
      file.fileMimeType.includes(event.target.value)
    );
    setFilteredResources(filteredByFiletype);
    setSelectedResourceTypeFile(event.target.value);
  };

  const onVimeoFileUploaded = () => {
    setUploadVideoOpen(false);
    loadResources();
  };

  const updateKeywords = (keywordList) => {
    let newResource = resourceToEdit;
    newResource.updateKeywords = keywordList;
    setResourceToEdit(newResource);
  };

  const onThumbFileUploadStarted = () => {
    setIsLoading(true);
  };

  const onThumbFileUploaded = async (id) => {
    setResourceToEdit(null);
    loadResources();
  };

  if (isLoading) {
    return (
      <Loading
        loading
        background='linear-gradient(180deg, #fff, #edf5fe)'
        loaderColor='#124238'
      />
    );
  }

  return (
    <>
      <Grid justify='space-between' container spacing={24}>
        <Grid item xs={4}>
          {props.module !== 'ContentLibrary' && (
            <>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel
                  className={classes.selectLabel}
                  id='categorySelector-label'
                >
                  {getLanguage('SelectLibrary')}
                </InputLabel>
                <Select
                  variant='outlined'
                  fullWidth={true}
                  value={selectedKind}
                  onChange={handleKindChanged}
                  labelId='categorySelector-label'
                  id='categorySelector'
                >
                  <MenuItem key='' value=''>
                    {getLanguage('All')}
                  </MenuItem>
                  ));
                  <MenuItem key='Assets' value='Assets'>
                    {getLanguage('Assets')}
                  </MenuItem>
                  ));
                  <MenuItem key='EmailTemplate' value='EmailTemplate'>
                    {getLanguage('EmailTemplates')}
                  </MenuItem>
                  ));
                </Select>
              </FormControl>
            </>
          )}
        </Grid>
        {selectedKind !== 'EmailTemplate' && (
          <Grid item xs={4}>
            <FormControl
              fullWidth={true}
              variant='outlined'
              className={classes.formControl}
            >
              <InputLabel
                className={classes.selectLabel}
                id='categorySelector-label'
              >
                {getLanguage('ShowSpecificContent')}
              </InputLabel>
              <Select
                variant='outlined'
                fullWidth={true}
                value={selectedResourceTypeFile}
                onChange={handleResourceFileType}
                labelId='categorySelector-label'
                id='categorySelector'
              >
                <MenuItem key='' value=''>
                  {getLanguage('All')}
                </MenuItem>

                <MenuItem key='image' value='image'>
                  {getLanguage('Image')}
                </MenuItem>

                <MenuItem key='video' value='video'>
                  {getLanguage('Video')}
                </MenuItem>

                <MenuItem key='embed' value='embed'>
                  {getLanguage('embed')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item>
          {props.module === 'ContentLibrary' && (
            <div>
              <Button
                onClick={() => addEmbedClicked()}
                variant='contained'
                className={classes.button}
              >
                <Add /> {getLanguage('AddEmbed')}{' '}
              </Button>{' '}
              <Button
                onClick={() => setUploadVideoOpen(true)}
                variant='contained'
                className={classes.button}
              >
                <Add /> {getLanguage('AddVideo')}{' '}
              </Button>{' '}
              <Button
                onClick={() => setUploadIsOpen(true)}
                variant='contained'
                className={classes.button}
              >
                <Add /> {getLanguage('AddMedia')}{' '}
              </Button>
            </div>
          )}
          {props.module === 'Assets' && (
            <div>
              <Button
                onClick={() => setUploadIsOpen(true)}
                variant='contained'
                className={classes.button}
              >
                <Add /> {getLanguage('Upload')}{' '}
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
      <br />
      {getResourcesOutput()}
      <div class='clearfix'></div>
      {moreResourcesAvailable() && (
        <>
          <br />
          <Button
            onClick={() => loadMore()}
            fullWidth={true}
            variant='contained'
            className={classes.button}
          >
            {getLanguage('LoadMore')}
          </Button>
          <br />
          <br />
        </>
      )}

      <GlobalDialog
        isDialogOpen={uploadIsOpen}
        handleDialogClose={() => setUploadIsOpen(false)}
        title={getLanguage('Upload')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {props.module !== 'ContentLibrary' && (
            <>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <InputLabel
                  className={classes.selectLabel}
                  id='categorySelector-label'
                >
                  {getLanguage('UploadResourceFor')}
                </InputLabel>
                <Select
                  variant='outlined'
                  fullWidth={true}
                  value={useUploadedFor}
                  onChange={handleUploadForChanged}
                  labelId='categorySelector-label'
                  id='categorySelector'
                >
                  <MenuItem key='' value=''>
                    {getLanguage('PleaseChoose')}
                  </MenuItem>
                  ));
                  <MenuItem key='Assets' value='Assets'>
                    {getLanguage('Assets')}
                  </MenuItem>
                  ));
                  <MenuItem key='EmailTemplate' value='EmailTemplate'>
                    {getLanguage('EmailTemplates')}
                  </MenuItem>
                  ));
                </Select>
              </FormControl>
              <br />
              <br />
            </>
          )}
          {useUploadedFor && (
            <ResourceUpload
              blockVideo={props.module === 'ContentLibrary'}
              uploadedFor={useUploadedFor}
              fileUploadStarted={() => onFileUploadStarted()}
              fileUploadedCallback={(id) => onFileUploaded(id)}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={uploadVideoOpen}
        handleDialogClose={() => setUploadVideoOpen(false)}
        title={getLanguage('AddVideo')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          <VideoUpload
            uploadedfor={'ContentLibrary'}
            fileUploadedCallback={(id) => onVimeoFileUploaded(id)}
            acceptFileTypes='video/mp4,video/x-m4v,video/*'
          />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={addEmbedOpen}
        handleDialogClose={() => setAddEmbedOpen(false)}
        title={getLanguage('AddEmbed')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {addEmbedFor && (
            <>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  label={getLanguage('Title')}
                  className='textField'
                />
              </FormControl>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  multiline
                  variant='outlined'
                  value={embed}
                  onChange={(e) => setEmbed(e.target.value)}
                  label={getLanguage('Embed')}
                  className='textField'
                />
              </FormControl>
              <Button
                fullWidth={true}
                variant='contained'
                color='primary'
                onClick={() => addEmbedResource()}
              >
                {getLanguage('Add')}
              </Button>
            </>
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={resourceToEdit !== null}
        handleDialogClose={() => setResourceToEdit(null)}
        title={getLanguage('EditResource')}
        type='Drawer'
        sizeclass='L'
      >
        {resourceToEdit !== null && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onUpdateClicked();
            }}
          >
            <Container>
              {resourceToEdit.fileName && (
                <div>
                  {resourceToEdit.fileName}
                  <br />
                  <br />
                </div>
              )}
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  label={getLanguage('Title')}
                  className='textField'
                />
              </FormControl>
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <TextField
                  variant='outlined'
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  label={getLanguage('Description')}
                  className='textField'
                />
              </FormControl>
              {resourceToEdit.fileMimeType === 'embed' && (
                <FormControl
                  fullWidth={true}
                  variant='outlined'
                  className={classes.formControl}
                >
                  <TextField
                    multiline
                    variant='outlined'
                    value={embed}
                    onChange={(e) => setEmbed(e.target.value)}
                    label={getLanguage('Embed')}
                    className='textField'
                  />
                </FormControl>
              )}
              <FormControl
                fullWidth={true}
                variant='outlined'
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={isLiveEvent}
                      onChange={(e) => setIsLiveEvent(e.target.checked)}
                    />
                  }
                  label={getLanguage('Live')}
                  className='checkbox'
                />
              </FormControl>
              <br />
              <br />
              <h3>{getLanguage('Keywords')}</h3>
              <EditResourceKeywords
                resource={resourceToEdit}
                keywordsUpdated={(keywordList) => updateKeywords(keywordList)}
                preselectedKeywords={resourceToEdit.updateKeywords}
              />
              <br />
              <br />
              <Button
                type='submit'
                fullWidth={true}
                variant='contained'
                className={classes.button}
              >
                {getLanguage('Save')}
              </Button>
              <br />
              <br />
              <h2>{getLanguage('Thumbnail')}</h2>
              {resourceToEdit.thumbExists && (
                <img
                  src={resourceService.getThumbLink(resourceToEdit.id)}
                  alt=''
                  style={{ width: '150px', height: '150px' }}
                />
              )}
              <ImageCropUpload
                cropAspectRatio={1}
                uploadedFor='Thumb'
                resourceId={resourceToEdit.id}
                uniqueKey={resourceToEdit.id}
                fileUploadStarted={() => onThumbFileUploadStarted()}
                fileUploadedCallback={(id) => onThumbFileUploaded(id)}
              />
              <br />
              <br />
              <h2>URL</h2>
              {resourceService.getResourceLink(resourceToEdit.id)}
            </Container>
          </form>
        )}
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={resourceToDelete !== null}
        handleDialogClose={() => setResourceToDelete(null)}
        title={getLanguage('DeleteResource')}
        type='Dialog'
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveThisResource?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant='contained'
            className={classes.button}
            onClick={() => deleteResource()}
          >
            {getLanguage('Delete')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default Resources;
