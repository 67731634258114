import React, { useEffect, useState } from 'react';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { ClientFileService } from '../services/ClientFileService';
import Loading from 'react-fullscreen-loading';
import { Button, Container, Paper, Tooltip } from '@material-ui/core';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import { Add, Delete, Edit } from '@material-ui/icons';
import moment from 'moment';
import EditClientFileDetails from '../components/EditClientFileDetails';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    animation: '$moveIn 600ms ease-out',
    height: '90vh',
    overflowX: 'scroll',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  formControl: {},
  inconsistencyInSortingAndPriority: {
    margin: '10px 0 10px 0',
    padding: '10px',
    border: '4px solid #FF4444',
    backgroundColor: '#EEE',
  },
}));

const ClientFiles_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [clientFiles, setClientFiles] = useState([]);
  const [clientFileToEdit, setClientFileToEdit] = useState(null);
  const [clientFileToRemove, setClientFileToRemove] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);

  const clientFileService = new ClientFileService();

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('ClientFiles')));
    getClientFiles();
    // eslint-disable-next-line
  }, []);

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  const columns = [
    {
      name: getLanguage('LastUpdated'),
      selector: (row) => row.updated,
      sortable: true,
      width: '120px',
      cell: (row) => moment(row.created).format('DD/MM HH:mm'),
    },
    {
      name: getLanguage('Title'),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: getLanguage('Kind'),
      selector: (row) => row.kind,
      sortable: true,
    },
    {
      name: getLanguage('Characters'),
      sortable: false,
      cell: (row) => row.data.length,
    },
    {
      name: getLanguage('Options'),
      width: '170px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setClientFileToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setClientFileToRemove(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const getClientFiles = async () => {
    setIsLoading(true);

    let response = await clientFileService.getClientFiles();

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setClientFiles(response);
      setIsLoading(false);
    }
  };

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };

  const removeClientFile = async () => {
    setIsLoading(true);

    let response = await clientFileService.deleteClientFile(
      clientFileToRemove.id
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setClientFileToRemove(null);
    getClientFiles();
  };

  const onSave = async (obj) => {
    setIsLoading(true);

    let response = await clientFileService.upsertClientFile(null, obj);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    setCreateNewIsOpen(false);
    setClientFileToEdit(null);
    getClientFiles();
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <Paper className={classes.paper}>
        {clientFiles && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={clientFiles}
            pagination={true}
            selectableRows={false}
            sortFunction={customSort}
            defaultSortField={'title'}
            defaultSortAsc={'asc'}
            subHeader={true}
            subHeaderComponent={
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCreateNewIsOpen(true)}
                >
                  <Add /> {getLanguage('CreateNew')}{' '}
                </Button>
              </div>
            }
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage('CreateClientFile')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {createNewIsOpen && (
            <EditClientFileDetails onSave={(obj) => onSave(obj)} />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={clientFileToEdit !== null}
        handleDialogClose={() => setClientFileToEdit(null)}
        title={getLanguage('EditClientFile')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {clientFileToEdit && (
            <EditClientFileDetails
              onSave={(obj) => onSave(obj)}
              clientFile={clientFileToEdit}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={clientFileToRemove !== null}
        handleDialogClose={() => setClientFileToRemove(null)}
        title={getLanguage('RemoveClientFile')}
        type="Dialog"
      >
        <Container>
          {getLanguage('DoYouReallyWantToRemoveTheClientFile?')}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={() => removeClientFile()}
          >
            {getLanguage('Remove')}
          </Button>
        </Container>
      </GlobalDialog>
    </>
  );
};

export default ClientFiles_Page;
