import React, { useEffect, useState, useRef, useCallback } from "react";
import getLanguage from "../../../core/Language";
import { ToastsStore } from "react-toasts";
import { HiveService } from "../services/HiveService";
import { validateEmail } from "../../../core/helpers/StringHelper";
import { LoginService } from "../../login/services/LoginService";
import Loading from "react-fullscreen-loading";
import {
  Button,
  Container,
  FormControl,
  Paper,
  TextField,
  Tooltip,
  Box,
} from "@material-ui/core";
import { GlobalDialog } from "../../../core/GlobalDialog";
import { makeStyles } from "@material-ui/core/styles";
import { StorageHelper } from "../../../core/StorageHelper";
import DataTable from "react-data-table-component";
import { LoginHelper } from "../../../core/LoginHelper";
import {
  Add,
  Delete,
  Drafts,
  Edit,
  Email,
  ImportExport,
  ContactMail,
} from "@material-ui/icons";
import moment from "moment";
import CsvFrontendLoginImport from "./../../csv/components/CsvFrontendLoginImport";
import { useParams } from "react-router-dom";
import EditVisitorLoginDatas from "../../loginData/components/EditVisitorLoginDatas";
import EditMeetingsForLogin from "../../meetings/components/EditMeetingsForLogin";
import { useDispatch } from "react-redux";
import { updatePageTitle } from "../../../actions/Actions";
import EmailInfo from "../components/EmailInfo";

const useStyles = makeStyles(() => ({
  paper: {
    width: "100%",
    padding: "40px",
    borderRadius: "12px",
    animation: "$moveIn 600ms ease-out",
  },
  "@keyframes moveIn": {
    "0%": {
      transform: "translateY(-20px)",
      opacity: 0,
    },
    "100%": {
      transform: "translateY(0px)",
      opacity: 1,
    },
  },
  formControl: {},
  searchTextField: {
    marginTop: "-2px",
    marginRight: "5px",
  },
  button: {
    margin: "10px 10px 10px 0",
    background: "#19275d",
    color: "#fff",
    "&:hover": {
      background: "#213377",
    },
  },
}));

const HiveVisitors_Page = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const hiveService = new HiveService();
  const loginService = new LoginService();
  const storageHelper = new StorageHelper();
  const loginHelper = new LoginHelper();

  let savedResultsPerPage = storageHelper.getResultsPerPageForUsers();

  const [sortColumn, setSortColumn] = useState("fullName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoading, setIsLoading] = useState(true);
  const [visitors, setVisitors] = useState(null);
  const [loginToRemove, setLoginToRemove] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [importIsOpen, setImportIsOpen] = useState(false);
  const [emailInfo, setEmailInfo] = useState([]);
  const [isEmailInfoDialogOpen, setIsEmailInfoDialogOpen] = useState(false);
  const [loginToSendWelcomeMail, setLoginToSendWelcomeMail] = useState(null);
  const [showSendWelcomeMailToAllNew, setShowSendWelcomeMailToAllNew] =
    useState(false);
  const [currentResultPage, setCurrentResultPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debounceTimeoutRef = useRef(null);
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );
  const [visitorEmail, setVisitorEmail] = useState("");
  const [visitorToEdit, setVisitorToEdit] = useState(null);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const { id } = useParams();

  const getVisitors = useCallback(
    async (search) => {
      let response = await hiveService.getVisitorsPaged(
        id,
        search ? search : searchTerm,
        currentResultPage,
        currentResultsPerPage,
        sortColumn,
        sortDirection
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage("SomethingWentWrong")} (${getLanguage(
            "DeveloperInfo"
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
        setIsLoading(false);
      } else {
        setVisitors(response);
        setIsLoading(false);
      }
    },
    [
      currentResultPage,
      currentResultsPerPage,
      id,
      searchTerm,
      sortColumn,
      sortDirection,
    ]
  );

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage("Visitors")));

    loadTotalVisitors();
    getVisitors();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVisitors();
  }, [
    currentResultPage,
    currentResultsPerPage,
    sortColumn,
    sortDirection,
    getVisitors,
  ]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForUsers(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage);
    setCurrentResultPage(currentPage - 1);
  };

  const handleEmailInfo = (email) => {
    getVisitorEmailInfo(email);
    setVisitorEmail(email);
  };

  const loadTotalVisitors = useCallback(async (search) => {
    if (!loginHelper.getCurrentHive()) {
      setIsLoading(false);
      return;
    }

    let response = await hiveService.getVisitorsCount(
      id,
      search ? search : searchTerm
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setTotalResults(response);
    }
  }, []);

  const customSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const columns = [
    {
      name: getLanguage("FullName"),
      selector: (row) => {
        return row.fullName || "fullName";
      },
      sortable: true,
      width: "200px",
      cell: (row) => {
        return !row.firstName && !row.lastName ? (
          <em>({getLanguage("NewUser")})</em>
        ) : (
          `${row.firstName}${row.lastName ? " " + row.lastName : ""}`
        );
      },
    },
    {
      name: getLanguage("Email"),
      selector: (row) => {
        return row.email || "email";
      },
      width: "200px",
      sortable: true,
      cell: (row) => row.email,
    },
    {
      name: getLanguage("RegisterDate"),
      selector: (row) => {
        return row.created || "created";
      },
      sortable: true,
      width: "200px",
      cell: (row) => moment(row.created).format("DD/MM/YYYY HH:mm"),
    },
    {
      name: getLanguage("LastLogin"),
      selector: (row) => {
        return row.lastLogin || "";
      },
      sortable: true,
      width: "200px",
      cell: (row) =>
        row.lastLogin ? (
          <div>{moment(row.lastLogin).format("DD/MM/YYYY HH:mm")}</div>
        ) : (
          "User not logged in"
        ),
    },
    {
      name: getLanguage("Options"),
      width: "250px",
      cell: (row) => (
        <Box sx={{ display: "flex" }}>
          <Tooltip title={getLanguage("Edit")}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setVisitorToEdit(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{" "}
          {row.welcomeMailSent && (
            <Tooltip title={getLanguage("WelcomeMailSentClickToSendAgain")}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => sendWelcomeMailClicked(row)}
              >
                <Drafts />
              </Button>
            </Tooltip>
          )}
          {!row.welcomeMailSent && (
            <Tooltip title={getLanguage("SendWelcomeMail")}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => sendWelcomeMail(row.id)}
              >
                <Email />
              </Button>
            </Tooltip>
          )}{" "}
          <Tooltip title={getLanguage("EmailInfo")}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => handleEmailInfo(row.email)}
            >
              <ContactMail />
            </Button>
          </Tooltip>
          <Tooltip title={getLanguage("Remove")}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => requestedRemoveVisitor(row)}
            >
              <Delete />
            </Button>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const getVisitorEmailInfo = async (email) => {
    const hiveId = loginHelper.getCurrentHive().id;
    let response = await hiveService.getVisitorEmailInfo(hiveId, email);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      console.log(">>", "loader");
    } else {
      setEmailInfo(response);
      setIsEmailInfoDialogOpen(true);
    }
  };

  const addVisitorClicked = () => {
    setCreateNewIsOpen(true);
  };

  const importFromCsvClicked = () => {
    setImportIsOpen(true);
  };

  const requestedRemoveVisitor = async (login) => {
    setLoginToRemove(login);
  };

  const addLoginToHive = async () => {
    var validationError = false;

    // Validate e-mail valid
    if (!validateEmail(email)) {
      setEmailError(getLanguage("ValidationErrorInvalidEmail"));
      validationError = true;
    } else {
      setEmailError("");
    }

    if (validationError) {
      return;
    }

    setIsLoading(true);

    let login = await loginService.createUserOrGetIdByEmail(email, fullName);
    if (login.isAxiosError || !login) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${login.response.status} - ${login.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    await loginService.addHiveToLoginFrontend(undefined, login.id);

    ToastsStore.success(`${getLanguage("UserAddedSuccessfully")}`);

    setFullName("");
    setEmail("");

    setCreateNewIsOpen(false);

    getVisitors();
  };

  const removeLoginFromHive = async () => {
    setLoginToRemove(null);

    setIsLoading(true);

    await loginService.removeHiveFromLoginFrontend(undefined, loginToRemove.id);

    ToastsStore.success(`${getLanguage("UsersAccessRemovedSuccessfully")}`);

    getVisitors();
  };

  const sendWelcomeMail = async (loginId) => {
    setIsLoading(true);

    let response = await loginService.postWelcomeMailLoginHiveFrontend(
      loginId,
      undefined
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    ToastsStore.success(`${getLanguage("WelcomeMailSentSuccessfully")}`);

    setLoginToSendWelcomeMail(null);

    getVisitors();
  };

  const requestCloseCreate = () => {
    setCreateNewIsOpen(false);
  };

  const requestCloseImport = () => {
    setImportIsOpen(false);
  };

  const requestedCloseRemoveUser = () => {
    setLoginToRemove(null);
  };

  const sendWelcomeMailClicked = (login) => {
    setLoginToSendWelcomeMail(login);
  };

  const sendWelcomeMailTooAllNew = async () => {
    setIsLoading(true);
    setShowSendWelcomeMailToAllNew(false);

    let response = await loginService.postWelcomeMailToAllLoginHiveFrontend(
      undefined
    );

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage("SomethingWentWrong")} (${getLanguage(
          "DeveloperInfo"
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
      return;
    }

    ToastsStore.success(`${getLanguage("OperationCompleted")}`);

    getVisitors();
  };

  const onChangePage = (page, totalRows) => {
    setCurrentResultPage(page - 1);
  };

  const updateSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (searchTerm.length >= 1) {
      debounceTimeoutRef.current = setTimeout(() => {
        loadTotalVisitors(searchTerm);
        getVisitors(searchTerm);
      }, 300);
    } else if (searchTerm.length === 0) {
      loadTotalVisitors();
      getVisitors();
    }
  }, [searchTerm, getVisitors, loadTotalVisitors]);

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}

      <Paper className={classes.paper}>
        {visitors && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={visitors}
            pagination={true}
            paginationServer={true}
            selectableRows={false}
            sortServer={true}
            onSort={customSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            defaultSortField={sortColumn}
            paginationTotalRows={totalResults}
            paginationPerPage={currentResultsPerPage}
            subHeader={true}
            subHeaderComponent={
              <div>
                {loginHelper.userHasRole("VisitorAdministration") && (
                  <>
                    <TextField
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => updateSearchTerm(e.target.value)}
                      label={getLanguage("Search")}
                      size="small"
                      className={classes.searchTextField}
                    />
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => addVisitorClicked()}
                    >
                      <Add /> {getLanguage("InviteVisitor")}{" "}
                    </Button>{" "}
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => importFromCsvClicked()}
                    >
                      <ImportExport /> {getLanguage("CsvImport")}{" "}
                    </Button>{" "}
                    <Tooltip title={getLanguage("SendWelcomeMailToAllNew")}>
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => setShowSendWelcomeMailToAllNew(true)}
                      >
                        <Email />
                      </Button>
                    </Tooltip>
                  </>
                )}
              </div>
            }
          />
        )}
      </Paper>

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestCloseCreate()}
        title={getLanguage("InviteVisitor")}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addLoginToHive();
            }}
          >
            <FormControl fullWidth={true} variant="outlined">
              <TextField
                variant="outlined"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                label={getLanguage("FullName")}
                className="textField"
              />
            </FormControl>
            <FormControl fullWidth={true} variant="outlined">
              <TextField
                variant="outlined"
                error={emailError}
                helperText={emailError}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                  setEmailError("");
                }}
                label={getLanguage("Email")}
                className="textField"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              className={classes.button}
            >
              {getLanguage("InviteVisitor")}
            </Button>
          </form>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={importIsOpen}
        handleDialogClose={() => requestCloseImport()}
        title={getLanguage("CsvImport")}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          <CsvFrontendLoginImport
            requestClose={() => requestCloseImport()}
            requestReloadLogins={() => getVisitors()}
          />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={loginToRemove !== null}
        handleDialogClose={() => requestedCloseRemoveUser()}
        title={getLanguage("RemoveUser")}
        type="Dialog"
      >
        <Container>
          {getLanguage("DoYouReallyWantToRemoveAccessForThisUser?")}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => removeLoginFromHive()}
          >
            {getLanguage("Remove")}
          </Button>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={loginToSendWelcomeMail !== null}
        handleDialogClose={() => setLoginToSendWelcomeMail(null)}
        title={getLanguage("SendWelcomeMail")}
        type="Dialog"
      >
        <Container>
          {getLanguage("DoYouWantToResendTheWelcomeMailToTheUser?")}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => sendWelcomeMail(loginToSendWelcomeMail.id)}
          >
            {getLanguage("SendWelcomeMail")}
          </Button>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={isEmailInfoDialogOpen}
        title={getLanguage("EmailInfo")}
        type="Dialog"
        sizeclass={"L"}
        handleDialogClose={() => setIsEmailInfoDialogOpen(false)}
      >
        <EmailInfo emailInfoData={emailInfo} email={visitorEmail} />
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={showSendWelcomeMailToAllNew}
        handleDialogClose={() => setShowSendWelcomeMailToAllNew(false)}
        title={getLanguage("SendWelcomeMailToAllNew")}
        type="Dialog"
      >
        <Container>
          {getLanguage(
            "ThisWillSendAWelcomeMailAndPasswordToAllVisitorsWhoHaventReceivedOneBefore"
          )}
          <br />
          <br />
          {getLanguage("ThisProcessMayTakeAWhile")}
          <br />
          <br />
          <Button
            fullWidth={true}
            variant="contained"
            className={classes.button}
            onClick={() => sendWelcomeMailTooAllNew()}
          >
            {getLanguage("SendWelcomeMailToAllNew")}
          </Button>
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={visitorToEdit !== null}
        handleDialogClose={() => setVisitorToEdit(null)}
        title={getLanguage("EditVisitorData")}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          {visitorToEdit && (
            <>
              <EditVisitorLoginDatas
                login={visitorToEdit}
                onSave={() => {
                  setVisitorToEdit(null);
                  getVisitors();
                }}
                requestClose={() => setVisitorToEdit(null)}
              />
              <br />
              <hr />
              <EditMeetingsForLogin login={visitorToEdit} />
            </>
          )}
        </Container>
      </GlobalDialog>
    </>
  );
};

export default HiveVisitors_Page;
