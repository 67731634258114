import moment from 'moment';
import { LoginHelper } from '../../../core/LoginHelper';
import { getCdnPath } from '../../../core/ServiceRoutes';
import { ResourceApi } from './ResourceApi';

export class ResourceService {
  resourceApi = new ResourceApi();
  loginHelper = new LoginHelper();

  async getResources(hiveId) {
    return await this.resourceApi.getResources(hiveId);
  }

  async getResourceFileContents(id) {
    return this.resourceApi.getResourceFileContents(id);
  }

  async createResource(uploadedFor, file, overrideFilename) {
    try {
      var result = await this.resourceApi.createResource(
        this.loginHelper.getCurrentHive().id,
        uploadedFor,
        file,
        overrideFilename
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async createResourceThumb(resourceId, file) {
    try {
      var result = await this.resourceApi.createResourceThumb(resourceId, file);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async createEmbedResource(uploadedFor, embed) {
    try {
      var result = await this.resourceApi.createEmbedResource(
        this.loginHelper.getCurrentHive().id,
        uploadedFor,
        embed
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  getResourceLink(id) {
    return `${getCdnPath()}/resources/${id}`;
  }

  getThumbLink(id) {
    return (
      `${getCdnPath()}/resources/${id}/thumb/?tid=` +
      moment().format('YYYYMMDDHHmmss')
    );
  }

  async updateResource(resourceId, resource) {
    try {
      var result = await this.resourceApi.updateResource(resourceId, resource);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async updateEmbedResource(resourceId, embed) {
    try {
      var result = await this.resourceApi.updateEmbedResource(
        this.loginHelper.getCurrentHive().id,
        resourceId,
        embed
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async deleteResource(resourceId) {
    return await this.resourceApi.deleteResource(resourceId);
  }

  async deleteEmbedResource(resourceId) {
    return await this.resourceApi.deleteEmbedResource(resourceId);
  }

  async UpsertPageesourceRels(newRel) {
    try {
      var result = await this.resourceApi.UpsertPageesourceRels(newRel);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async getPageRelationsFromResource(hiveId, resourceId) {
    try {
      var result = await this.resourceApi.getPageRelationsFromResource(
        hiveId,
        resourceId
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async getVimeoUploadLink(uploadedfor, size) {
    var result = await this.resourceApi.getVimeoUploadLink(
      this.loginHelper.getCurrentHive().id,
      uploadedfor,
      size
    );
    return result;
  }

  async getVimeoTranscodeStatus(resourceId) {
    var result = await this.resourceApi.getVimeoTranscodeStatus(resourceId);
    return result;
  }

  async copyResource(resourceId, uploadedFor) {
    try {
      var result = await this.resourceApi.copyResource(resourceId, uploadedFor);
      return result;
    } catch (error) {
      console.log(error);
    }
  }
}
