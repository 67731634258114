import React, { useState } from 'react'
import { saveAs } from 'file-saver'
import { HiveService } from '../services/HiveService'
import { LoadingIndicator } from '../../../core/LoadingIndicator'
import getLanguage from '../../../core/Language'
import { Folder, SaveAlt } from '@material-ui/icons'
import { ToastsStore } from 'react-toasts'
import { Button, Tooltip } from '@material-ui/core'

const ImportExportHive = (props) => {
  const hiveService = new HiveService()

  const [isLoading, setIsLoading] = useState(false)

  const downloadExport = async () => {
    setIsLoading(true)
    let file = await hiveService.getHiveExportJson(props.hiveId)

    file = new Blob([JSON.stringify(file)], { type: 'text/plain' })

    if (file.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${file.response.status} - ${file.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    saveAs(file, 'hive-export.json')
    setIsLoading(false)
  }

  const onFileChange = async (evt) => {
    setIsLoading(true)

    var result = await hiveService.importHive(props.hiveId, evt.target.files[0])

    if (result.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo',
        )}: ${result.response.status} - ${result.response.statusText} + ")`,
      )
      setIsLoading(false)
      return
    }

    ToastsStore.success(getLanguage('HiveImported'))
    setIsLoading(false)
    props.requestClose()
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <h2>{getLanguage('ExportHive')}</h2>
      <Button
        fullWidth={true}
        variant="contained"
        color="primary"
        onClick={() => downloadExport()}
      >
        <SaveAlt /> {getLanguage('ExportNow')}
      </Button>
      <br />
      <br />
      <hr />
      <br />
      <h2>{getLanguage('ImportHive')}</h2>
      <p>
        {getLanguage(
          'WarningThisWillOverwriteEverythingOnTheHiveWithTheNewData',
        )}
      </p>

      <input id={'fileUpload'} type="file" onChange={onFileChange} hidden />
      <Tooltip title="File explorer">
        <label htmlFor={'fileUpload'}>
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            component="span"
          >
            <Folder /> {getLanguage('SelectFile')}
          </Button>
        </label>
      </Tooltip>
    </>
  )
}

export default ImportExportHive
