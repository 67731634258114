import './EditHiveDetails.css'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'
import { LoginHelper } from '../../../core/LoginHelper'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const RoleEditorForUser = (props) => {
  const classes = useStyles()
  const loginHelper = new LoginHelper()

  const [selectedRoles, setSelectedRoles] = useState([])

  useEffect(() => {
    if (props.login?.roleAssignments) {
      let roles = []
      for (const role of props.login.roleAssignments.filter(
        (t) => t.hiveId === loginHelper.getCurrentHive().id,
      )) {
        roles.push(role.role)
      }

      setSelectedRoles(roles)
    }
    // eslint-disable-next-line
  }, [])

  const onSaveClicked = async () => {
    // If Administrator selected, only return that one role
    if (selectedRoles.find((t) => t === 'Administrator') != null) {
      props.saveRoles(['Administrator'])
      return
    }

    // If Edtiro selected, only return that one role
    if (selectedRoles.find((t) => t === 'Editor') != null) {
      props.saveRoles(['Editor'])
      return
    }

    props.saveRoles(selectedRoles)
  }

  const roleClicked = (role, selected) => {
    let newRoles = selectedRoles.filter((t) => t !== role)

    if (selected) {
      newRoles.push(role)
    }

    // Check if deselecting Administrator or Editor, then deselect all other roles
    if ((role === 'Administrator' || role === 'Editor') && !selected) {
      newRoles = []
    }

    setSelectedRoles(newRoles)
  }

  const roleIsSelected = (role) => {
    // Administrator implicitly selects all roles
    if (selectedRoles.find((t) => t === 'Administrator') != null) {
      return true
    }

    // Editor implicitly selects all but administrator
    if (
      role !== 'Administrator' &&
      role !== 'UserAdministration' &&
      selectedRoles.find((t) => t === 'Editor') != null
    ) {
      return true
    }

    return selectedRoles.find((t) => t === role) != null
  }

  const roleIsDeactivated = (role) => {
    if (role === 'Administrator') {
      return false
    }

    if (
      role === 'Editor' &&
      selectedRoles.find((t) => t === 'Administrator') != null
    ) {
      return true
    }

    if (role === 'Editor') {
      return false
    }

    if (
      selectedRoles.find((t) => t === 'Administrator') != null ||
      selectedRoles.find((t) => t === 'Editor') != null
    ) {
      return true
    }
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSaveClicked()
        }}
      >
        <b>{getLanguage('Administrator')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('Administrator')}
                onChange={(e) => roleClicked('Administrator', e.target.checked)}
                disabled={roleIsDeactivated('Administrator')}
              />
            }
            label={getLanguage('FullAdministrationAccessToAllFunctions')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('Editor')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('Editor')}
                onChange={(e) => roleClicked('Editor', e.target.checked)}
                disabled={roleIsDeactivated('Editor')}
              />
            }
            label={getLanguage('CanAccessAndEditEverythingExceptUsers')}
            className="checkbox"
          />
        </FormControl>

        <hr />

        <b>{getLanguage('Users')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('UserAdministration')}
                onChange={(e) =>
                  roleClicked('UserAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('UserAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditOtherUsersAndTheirRoles')}
            className="checkbox"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('VisitorAdministration')}
                onChange={(e) =>
                  roleClicked('VisitorAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('VisitorAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditVisitors')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('Content')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('PageEditor')}
                onChange={(e) => roleClicked('PageEditor', e.target.checked)}
                disabled={roleIsDeactivated('PageEditor')}
              />
            }
            label={getLanguage('CanViewAndEditPages')}
            className="checkbox"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('StandEditor')}
                onChange={(e) => roleClicked('StandEditor', e.target.checked)}
                disabled={roleIsDeactivated('StandEditor')}
              />
            }
            label={getLanguage('CanViewAndEditStands')}
            className="checkbox"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('ContentLibraryAutoUpload')}
                onChange={(e) =>
                  roleClicked('ContentLibraryAutoUpload', e.target.checked)
                }
                disabled={roleIsDeactivated('ContentLibraryAutoUpload')}
              />
            }
            label={getLanguage(
              'CanUploadAssetsFromPageOrStandToContentLibrary',
            )}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('Events')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('EventAdministration')}
                onChange={(e) =>
                  roleClicked('EventAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('EventAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditEvents')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('NewsTicker')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('NewsAdministration')}
                onChange={(e) =>
                  roleClicked('NewsAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('NewsAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditNews')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('PopupMessages')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('PopupAdministration')}
                onChange={(e) =>
                  roleClicked('PopupAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('PopupAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditPopups')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('Keywords')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('KeywordAdministration')}
                onChange={(e) =>
                  roleClicked('KeywordAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('KeywordAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditKeywords')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('ResourceArchive')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('ResourceArchiveAdministration')}
                onChange={(e) =>
                  roleClicked('ResourceArchiveAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('ResourceArchiveAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditResourceArchive')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('Assets')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('AssetAdministration')}
                onChange={(e) =>
                  roleClicked('AssetAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('AssetAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditAssets')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('ContentLibrary')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('ContentLibraryAdministration')}
                onChange={(e) =>
                  roleClicked('ContentLibraryAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('ContentLibraryAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditContentLibrary')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('MailTemplates')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('MailTemplateAdministration')}
                onChange={(e) =>
                  roleClicked('MailTemplateAdministration', e.target.checked)
                }
                disabled={roleIsDeactivated('MailTemplateAdministration')}
              />
            }
            label={getLanguage('CanViewAndEditMailTemplates')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('Statistics')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('Statistics')}
                onChange={(e) => roleClicked('Statistics', e.target.checked)}
                disabled={roleIsDeactivated('Statistics')}
              />
            }
            label={getLanguage('CanViewStatistics')}
            className="checkbox"
          />
        </FormControl>

        <b>{getLanguage('Languages')}</b>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={roleIsSelected('LanguageEditor')}
                onChange={(e) =>
                  roleClicked('LanguageEditor', e.target.checked)
                }
                disabled={roleIsDeactivated('LanguageEditor')}
              />
            }
            label={getLanguage('CanViewAndEditLanguageStrings')}
            className="checkbox"
          />
        </FormControl>

            <b>{getLanguage('Networking')}</b>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={roleIsSelected('Networking')}
                    onChange={(e) =>
                      roleClicked('Networking', e.target.checked)
                    }
                    disabled={roleIsDeactivated('Networking')}
                  />
                }
                label={getLanguage('CanViewActiveUsersAndCreateAndJoinChats')}
                className="checkbox"
              />
            </FormControl>

            <b>{getLanguage('Meetings')}</b>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={roleIsSelected('MeetingRead')}
                    onChange={(e) =>
                      roleClicked('MeetingRead', e.target.checked)
                    }
                    disabled={roleIsDeactivated('MeetingRead')}
                  />
                }
                label={getLanguage('CanViewAndJoinMeetings')}
                className="checkbox"
              />
            </FormControl>

            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.formControl}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={roleIsSelected('MeetingAdministration')}
                    onChange={(e) =>
                      roleClicked('MeetingAdministration', e.target.checked)
                    }
                    disabled={roleIsDeactivated('MeetingAdministration')}
                  />
                }
                label={getLanguage('CanViewJoinAndEditMeetings')}
                className="checkbox"
              />
            </FormControl>

        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            color="primary"
          >
            {getLanguage('Save')}
          </Button>
        </FormControl>
      </form>

      <br />
    </>
  )
}

export default RoleEditorForUser
