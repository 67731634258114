import { LoginHelper } from "../../../core/LoginHelper";
import { EventRegistrationApi } from "./EventRegistrationApi";

export class EventRegistrationService {
    eventRegistrationApi = new EventRegistrationApi();
    loginHelper = new LoginHelper();

    async getEventRegistrationsForEvent(eventId) {
        return await this.eventRegistrationApi.getEventRegistrationsForEvent(eventId);
    }

    async insertEventRegistrationForLogin(eventId, loginId) {
        return await this.eventRegistrationApi.insertEventRegistrationForLogin(eventId, loginId);
    }

    async deleteEventRegistration(eventRegistrationId) {
        return await this.eventRegistrationApi.deleteEventRegistration(eventRegistrationId);
    }


    // The following methods are properly not used, I have not clue why I implemented them....
    async getEventRegistrationsForLogin() {
        return await this.eventRegistrationApi.getEventRegistrationsForLogin();
    }

    async insertEventRegistration(eventId) {
        return await this.eventRegistrationApi.insertEventRegistration(eventId);
    }
}