import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { StatisticsService } from '../services/StatisticsService';
import { LoadingIndicator } from '../../../core/LoadingIndicator';
import getLanguage from '../../../core/Language';
import { ToastsStore } from 'react-toasts';
import { TextField, Button, TableRow } from '@material-ui/core';
import { LoginHelper } from '../../../core/LoginHelper';
import { getApiPath } from '../../../core/ServiceRoutes';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from '@mui/material';
import icon1 from './icons/1.svg';
import icon2 from './icons/2.svg';
import icon3 from './icons/3.svg';
import icon4 from './icons/4.svg';
import icon5 from './icons/5.svg';
import icon6 from './icons/6.svg';
import icon7 from './icons/7.svg';
import './styles.css';

export const StandStats = (props) => {
  const statisticsService = new StatisticsService();
  const [currentStats, setCurrentStats] = useState(null);
  const [filter, setFilter] = useState({
    startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
    endDate: moment().add(1, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
  });

  const removeAdminHandler = [...props.currentVisitors].filter(
    (visitor) => visitor.role !== 'admin'
  );
  const currentVisitorsList = [
    ...new Map(
      removeAdminHandler.map((item) => [item['fullName'], item])
    ).values(),
  ];

  useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, []);

  const updateData = async () => {
    let response = await statisticsService.getPageStats(
      props.pageId,
      filter.startDate,
      filter.endDate
    );
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setCurrentStats(response);
    }
  };

  const handleFilterChange = (prop) => (e) =>
    setFilter({ ...filter, [prop]: e.target.value });

  const handleRefreshClick = (e) => {
    e.preventDefault();
    setCurrentStats(null);
    updateData();
  };

  if (!currentStats) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div className='filter'>
        <div>
          <TextField
            type='datetime-local'
            variant='outlined'
            value={filter.startDate}
            onChange={handleFilterChange('startDate')}
            label={getLanguage('FirstDate')}
            className='textField'
            size='small'
          />
        </div>
        <div>
          <TextField
            type='datetime-local'
            variant='outlined'
            value={filter.endDate}
            onChange={handleFilterChange('endDate')}
            label={getLanguage('EndDate')}
            className='textField'
            size='small'
          />
        </div>
        <Button
          variant='contained'
          color='primary'
          onClick={handleRefreshClick}
        >
          {getLanguage('RefreshData')}
        </Button>
      </div>
      <div className='layout'>
        <div className='layout__long'>
          <div className='boxContainer'>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon7} alt='' draggable='false' />
              </div>
              <div className='box__title'>Video Plays</div>
              <div className='box__value'>{currentStats.totalVideoPlays}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon6} draggable='false' alt='' />
              </div>
              <div className='box__title'>Downloads</div>
              <div className='box__value'>{currentStats.totalDownloads}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon5} alt='' draggable='false' />
              </div>
              <div className='box__title'>Avr. browse time (min)</div>
              <div className='box__value'>{currentStats.avgBrowseTime}</div>
              <div className='box__movment'>
                All stands avg. {currentStats.avgBrowseTimeForHive}
              </div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon4} alt='' draggable='false' />
              </div>
              <div className='box__title'>Chats</div>
              <div className='box__value'>{currentStats.totalChats}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon3} alt='' draggable='false' />
              </div>
              <div className='box__title'>Meetings</div>
              <div className='box__value'>{currentStats.totalMailClicks}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon2} alt='' draggable='false' />
              </div>
              <div className='box__title'>Overall interactions</div>
              <div className='box__value'>{currentStats.totalClicks}</div>
              <div className='box__movment'>
                All stands avg. {currentStats.avgClicksForHive}
              </div>
            </div>
          </div>
        </div>
        <div className='layout__short'>
          <div className='boxTall'>
            <div className='boxTall__icon'>
              <img src={icon1} alt='' draggable='false' />
            </div>
            <div className='boxTall__title'>Visitors</div>
            <div className='boxTall__lines'>
              <div className='line'>
                <div className='line__label'>Current:</div>
                <div className='line__value'>{currentVisitorsList.length}</div>
              </div>
              <div className='line--divider' />
              <div className='line'>
                <div className='line__label'>Unique:</div>
                <div className='line__value'>{currentStats.uniqueVisitors}</div>
              </div>
              <div className='line'>
                <div className='line__label'>All:</div>
                <div className='line__value'>{currentStats.totalVisitors}</div>
              </div>
            </div>
            <div className='boxTall__movment'>
              All stands avg. {currentStats.avgTotalStandVisitorsForHive}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '90vh',
    background: '#fff',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
    overflowX: 'scroll',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
}));

export const HiveStandsStats = (props) => {
  const classes = useStyles();
  const loginHelper = new LoginHelper();
  const statisticsService = new StatisticsService();
  const [currentStats, setCurrentStats] = useState(null);
  const [filter, setFilter] = useState({
    startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
    endDate: moment().add(1, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
  });
  const [dataTableFilter, setDataTableFilter] = useState({
    startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
    endDate: moment().add(1, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
  });
  const [hivestats, setHiveStats] = useState([]);

  useEffect(() => {
    updateData();
    getHiveStats();
    // eslint-disable-next-line
  }, []);

  const updateData = async () => {
    let response = await statisticsService.getStats(
      loginHelper?.getCurrentHive()?.id,
      filter.startDate,
      filter.endDate
    );
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      setCurrentStats(response);
    }
  };

  const getHiveStats = () => {
    fetch(
      `${getApiPath()}/hivestats/pages?hiveid=${
        loginHelper?.getCurrentHive()?.id
      }&fromtimestamp=${dataTableFilter.startDate}&totimestamp=${
        dataTableFilter.endDate
      }`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${loginHelper.getAuthToken()}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => setHiveStats(res));
  };

  const handleFilterChange = (prop) => (e) =>
    setFilter({ ...filter, [prop]: e.target.value });

  const handleRefreshClick = (e) => {
    e.preventDefault();
    setCurrentStats(null);
    updateData();
    getHiveStats();
  };

  const handleFilterDataTableChange = (prop) => (e) =>
    setDataTableFilter({ ...dataTableFilter, [prop]: e.target.value });

  const hivestatsKeys = hivestats
    .map((item) => Object.keys(item))
    .flat()
    .filter((item) => item !== 'pageId');

  if (!currentStats) {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.paper}>
      <h1 className='title'>Overall metrics for hive</h1>
      <div className='filter'>
        <div>
          <TextField
            type='datetime-local'
            variant='outlined'
            value={filter.startDate}
            onChange={handleFilterChange('startDate')}
            label={getLanguage('FirstDate')}
            className='textField'
          />
        </div>
        <div>
          <TextField
            type='datetime-local'
            variant='outlined'
            value={filter.endDate}
            onChange={handleFilterChange('endDate')}
            label={getLanguage('EndDate')}
            className='textField'
          />
        </div>
        <Button
          variant='contained'
          color='primary'
          onClick={handleRefreshClick}
        >
          {getLanguage('RefreshData')}
        </Button>
      </div>
      <div className='layout'>
        <div className='layout__long'>
          <div className='boxContainer'>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon7} alt='' draggable='false' />
              </div>
              <div className='box__title'>Video Plays</div>
              <div className='box__value'>{currentStats.totalVideoPlays}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon6} alt='' draggable='false' />
              </div>
              <div className='box__title'>Downloads</div>
              <div className='box__value'>{currentStats.totalDownloads}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon5} alt='' draggable='false' />
              </div>
              <div className='box__title'>Avr. browse time (min)</div>
              <div className='box__value'>{currentStats.avgBrowseTime}</div>
              {/* <div className="box__movment">Avr. 4.21 <img src={iconUp} draggable="false" /></div> */}
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon4} alt='' draggable='false' />
              </div>
              <div className='box__title'>Chats</div>
              <div className='box__value'>{currentStats.totalChats}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon3} alt='' draggable='false' />
              </div>
              <div className='box__title'>Meetings</div>
              <div className='box__value'>{currentStats.totalMailClicks}</div>
            </div>
            <div className='box'>
              <div className='box__icon'>
                <img src={icon2} alt='' draggable='false' />
              </div>
              <div className='box__title'>Overall interactions</div>
              <div className='box__value'>{currentStats.totalClicks}</div>
              {/* <div className="box__movment">Avr. 4.21 <img src={iconDown} draggable="false" /></div> */}
            </div>
          </div>
        </div>
        <div className='layout__short'>
          <div className='boxTall'>
            <div className='boxTall__icon'>
              <img src={icon1} alt='' draggable='false' />
            </div>
            <div className='boxTall__title'>Visitors</div>
            <div className='boxTall__lines'>
              <div className='line'>
                <div className='line__label'>Current</div>
                <div className='line__value'>
                  {currentStats.currentVisitors}
                </div>
              </div>
              <div className='line'>
                <div className='line__label'>Unique</div>
                <div className='line__value'>{currentStats.uniqueVisitors}</div>
              </div>
              <div className='line'>
                <div className='line__label'>All</div>
                <div className='line__value'>{currentStats.totalVisitors}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className='title'>Visitors per page</h1>
      <div className='filter'>
        <div>
          <TextField
            type='datetime-local'
            variant='outlined'
            value={dataTableFilter.startDate}
            onChange={handleFilterDataTableChange('startDate')}
            label={getLanguage('FirstDate')}
            className='textField'
          />
        </div>
        <div>
          <TextField
            type='datetime-local'
            variant='outlined'
            value={dataTableFilter.endDate}
            onChange={handleFilterDataTableChange('endDate')}
            label={getLanguage('EndDate')}
            className='textField'
          />
        </div>
        <Button
          variant='contained'
          color='primary'
          onClick={handleRefreshClick}
        >
          {getLanguage('RefreshData')}
        </Button>
      </div>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: '50vh', marginBottom: 20 }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Total browse time for page / min</TableCell>
              <TableCell>No. of unique visitors</TableCell>
              <TableCell>Page title</TableCell>
              <TableCell>Page type</TableCell>
              <TableCell>Avg. browser time for page / min</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hivestats.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.totalBrowseTime}</TableCell>
                  <TableCell>{row.uniqueVisitors}</TableCell>
                  <TableCell>{row.pageTitle}</TableCell>
                  <TableCell>{row.pageType}</TableCell>
                  <TableCell>{row.visitorAvg}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
