import { Button, Container, Link, Paper, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { EventService } from '../services/EventService';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from '../../../core/Language';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { StorageHelper } from '../../../core/StorageHelper';
import EditIcon from '@material-ui/icons/Edit';
import EditEventDetails from '../components/EditEventDetails';
import { HiveService } from '../../hive/services/HiveService';
import { LoginHelper } from '../../../core/LoginHelper';
import { Delete, EventSeat } from '@material-ui/icons';
import { limitLength } from '../../../core/helpers/StringHelper';
import { getStartDate } from '../../../core/helpers/TimeFormatHelper';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';
import RegistrationsForEvent from '../components/RegistrationsForEvent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  divroot: {
    width: '100%',
  },
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  table: {
    minWidth: 433,
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
  pageTitle: {
    fontSize: '16px',
  },
  pageSubTitle: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
}));

const Events_Page = (props) => {
  const classes = useStyles();
  const eventService = new EventService();
  const storageHelper = new StorageHelper();
  const hiveService = new HiveService();
  const loginHelper = new LoginHelper();
  const dispatch = useDispatch();

  let savedResultsPerPage = storageHelper.getResultsPerPageForEvents();

  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [hive, setHive] = useState({});
  const [eventToEdit, setEventToEdit] = useState(null);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [defaultSortColumn, setDefaultSortColumn] = useState('title');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );
  const [eventToViewRegistrations, setEventToViewRegistrations] =
    useState(null);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Events')));
    loadEvents(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const loadHive = async () => {
      let response = await hiveService.getHive(
        loginHelper?.getCurrentHive()?.id,
        true
      );

      if (response.isAxiosError) {
        ToastsStore.error(
          `${getLanguage('SomethingWentWrong')} (${getLanguage(
            'DeveloperInfo'
          )}: ${response.response.status} - ${
            response.response.statusText
          } + ")`
        );
      } else {
        setHive(response);
      }
    };
    loadHive();
  }, []);

  const loadEvents = async (forceReload) => {
    let response = await eventService.getEvents(forceReload);
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      let sortCol = storageHelper.getHiveSortingColumn();
      let sortDirection = storageHelper.getHiveSortingDirection();

      if (sortCol) {
        setDefaultSortColumn(sortCol);
      }
      if (sortDirection) {
        setDefaultSortDirection(sortDirection);
      }

      setEvents(response);
      setIsLoading(false);
    }
  };

  const createNewClicked = () => {
    setCreateNewIsOpen(true);
  };

  const onNewEventSave = async (eventObj) => {
    setCreateNewIsOpen(false);
    setIsLoading(true);

    let response = await eventService.addEvent(eventObj);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      loadEvents(true);
    }
  };

  const onEventSave = async (eventObj) => {
    setIsLoading(true);

    let response = await eventService.updateEvent(eventToEdit.id, eventObj);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setEventToEdit(null);
      loadEvents();
      ToastsStore.success(getLanguage('EventPropertiesWereUpdated'));
    }
  };

  const requestedCloseCreate = async () => {
    setCreateNewIsOpen(false);
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    storageHelper.setHiveSortingColumn(field);
    storageHelper.setHiveSortingDirection(direction);

    return orderBy(rows, handleField, direction);
  };

  const onDeleteEvent = async (id) => {
    let response = await eventService.deleteEvent(id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setEventToEdit(null);
      loadEvents();
      ToastsStore.success(getLanguage('DeletedEvent'));
    }
  };

  const lang = (row, str) => {
    let obj = null;
    if (row.langs) {
      try {
        obj = JSON.parse(row.langs);
        const firstLangCode = Object.keys(obj)[0];
        obj = obj[firstLangCode];
      } catch (e) {
        obj = row;
      }
    } else obj = row;
    return obj[str];
  };

  const columns = [
    {
      name: getLanguage('Date'),
      selector: (row) => row.startDate,
      sortable: true,
      cell: (row) => <label>{getStartDate(row?.startDate)}</label>,
      width: '150px',
    },
    {
      name: getLanguage('PageProgram'),
      selector: (row) => row.page.title,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        let res = rowA.page.title.localeCompare(rowB.page.title);
        return res;
      },
      width: '150px',
    },
    {
      name: getLanguage('Title'),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <Link color='inherit' onClick={() => setEventToEdit(row)}>
          <span className={classes.name}>
            {limitLength(lang(row, 'name'), 30, '...')}
          </span>
        </Link>
      ),
    },
    {
      name: getLanguage('Host'),
      selector: (row) => row.host,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        rowA.host = rowA.host || '';
        rowB.host = rowB.host || '';
        let res = 0;
        if (rowA.host === '') {
          res = 1;
        } else if (rowB.host === '') {
          res = -1;
        } else {
          res = rowA.host.localeCompare(rowB.host);
        }
        return res;
      },
      cell: (row) => limitLength(lang(row, 'host'), 30, '...'),
    },
    {
      name: getLanguage('Registrations'),
      selector: (row) => row.countRegistrations,
      sortable: true,
      cell: (row) =>
        row.enableRegistrations ? (
          row.countRegistrations
        ) : (
          <span style={{ color: '#DDD' }}>{getLanguage('Disabled')}</span>
        ),
    },
    {
      // () =>
      name: getLanguage('Options'),
      minWidth: '270px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setEventToEdit(row)}
            >
              <EditIcon />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Registrations')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setEventToViewRegistrations(row)}
            >
              <EventSeat />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Remove')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => {
                if (window.confirm(getLanguage('ConfirmDelete'))) {
                  onDeleteEvent(row.id);
                }
              }}
            >
              <Delete />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForEvents(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage);
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}
      <div>
        <Paper className={classes.paper}>
          {events && (
            <DataTable
              noHeader={true}
              columns={columns}
              data={events}
              pagination={true}
              selectableRows={false}
              sortFunction={customSort}
              defaultSortField={defaultSortColumn}
              defaultSortAsc={defaultSortDirection === 'asc'}
              subHeader={true}
              paginationRowsPerPageOptions={[100, 250, 500, 1000, 2000, 4000]}
              onChangeRowsPerPage={onChangeRowsPerPage}
              paginationPerPage={currentResultsPerPage}
              subHeaderComponent={
                <div>
                  <Button
                    className={classes.button}
                    variant='contained'
                    onClick={() => createNewClicked()}
                  >
                    <AddIcon /> {getLanguage('CreateNew')}
                  </Button>
                </div>
              }
            />
          )}
        </Paper>
      </div>
      <br />
      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestedCloseCreate()}
        title={getLanguage('CreateNew')}
        type='Drawer'
        sizeclass='XL'
      >
        <Container>
          <EditEventDetails
            timeZone={hive.timezone}
            onSave={(eventObj) => onNewEventSave(eventObj)}
            requestReload={() => loadEvents(true)}
          />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={eventToEdit !== null}
        handleDialogClose={() => setEventToEdit(null)}
        title={getLanguage('EditEventProperties')}
        type='Drawer'
        sizeclass='XL'
      >
        <Container>
          {eventToEdit && (
            <EditEventDetails
              timeZone={hive.timezone}
              event={eventToEdit}
              onSave={(eventObj) => onEventSave(eventObj)}
              requestSetIsLoading={(val) => setIsLoading(val)}
              requestReload={() => {
                setEventToEdit(null);
                loadEvents(true);
              }}
            />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={eventToViewRegistrations !== null}
        handleDialogClose={() => {
          setEventToViewRegistrations(null);
          loadEvents(true);
        }}
        title={getLanguage('Registrations')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {eventToViewRegistrations && (
            <RegistrationsForEvent event={eventToViewRegistrations} />
          )}
        </Container>
      </GlobalDialog>
    </>
  );
};

export default Events_Page;
