import { PageObjectApi } from "./PageObjectApi";

export class PageObjectService {
    pageObjectApi = new PageObjectApi();

    async updatePageObject(id, pageObject) {
        return await this.pageObjectApi.updatePageObject(id, pageObject);
    }

    async updatePageObjects(pageId, pageObjects) {
        return await this.pageObjectApi.updatePageObjects(pageId, pageObjects);
    }

    async removeResourceFromPageObject(pageObjectId) {
        return await this.pageObjectApi.removeResourceFromPageObject(pageObjectId);
    }
}