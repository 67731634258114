import './StatisticsCsv_Page.css'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updatePageTitle } from '../../../actions/Actions'
import { HiveStandsStats } from '../components/StandStats'

const StatisticsHive_Page = (props) => {
  const dispatch = useDispatch()

  useEffect(
    () => {
      dispatch(updatePageTitle('Hive Statistics'))
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <>
      <HiveStandsStats/>
    </>
  )
}

export default StatisticsHive_Page
