import React, { useRef, useState } from "react";
import { Button, Grid, Dialog, CircularProgress, DialogContent } from "@material-ui/core";
import "./ImageCrop.css";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Crop } from "@material-ui/icons";
import getLanguage from "../Language";

const ImageCrop = (props) => {
    const cropperRef = useRef(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const handleClose = () => {
        setButtonsDisabled(false);
        props.close(null);
    };

    const functionToSaveImage = () => {
        setButtonsDisabled(true);

        const imageElement = cropperRef.current;
        const cropper = imageElement.cropper;

        cropper.getCroppedCanvas().toBlob((blob) => {
            props.close(blob);
        }, 'image/png', 0.80);
    }

    return (<>
        <div>
            <Dialog
                fullScreen
                open={props.open}
                onClose={handleClose}>
                <DialogContent>
                    <div>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item>
                                <h2> <Crop /> {getLanguage("CropImage")}</h2>
                            </Grid>
                            <Grid item>
                                <div>
                                    <Button
                                        variant="contained"
                                        disabled={buttonsDisabled}
                                        onClick={handleClose}
                                        aria-label={getLanguage("Cancel")}
                                        color="default">
                                        {getLanguage("Cancel")}
                                    </Button>
                                    {" "}
                                    <Button
                                        variant="contained"
                                        disabled={buttonsDisabled}
                                        aria-label={getLanguage("Save")}
                                        onClick={functionToSaveImage}
                                        color="primary">
                                        {getLanguage("SaveAndUpload")}
                                        {buttonsDisabled && <CircularProgress size={20} />}
                                    </Button>

                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <div>
                            <div>
                                <Cropper
                                    ref={cropperRef}
                                    src={props.image}
                                    style={{ height: '100%', width: '100%' }}
                                    aspectRatio={props.cropAspectRatio}
                                    guides={false}
                                    center={false}
                                    viewMode={0}
                                    zoomable={true}
                                    zoomOnTouch={true}
                                    zoomOnWheel={true}
                                    movable={true}
                                    dragMode={'move'}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    </>);
}

export default ImageCrop