import { getApiPath } from '../../../core/ServiceRoutes'
import Axios from 'axios'
import { LoginHelper } from '../../../core/LoginHelper'

export class HiveUrlApi {
  loginHelper = new LoginHelper()

  async getHiveUrls(hiveId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      }
      let response = await Axios.get(`${getApiPath()}/hiveurls`, config)
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async addHiveUrl(hiveUrl) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveUrl.hiveId,
        },
      }
      let response = await Axios.post(
        `${getApiPath()}/hiveurls`,
        hiveUrl,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async updateHiveUrl(hiveUrlId, hiveUrl) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.put(
        `${getApiPath()}/hiveurls/${hiveUrlId}`,
        hiveUrl,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }

  async deleteHiveUrl(hiveUrlId) {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      }

      let response = await Axios.delete(
        `${getApiPath()}/hiveurls/${hiveUrlId}`,
        config,
      )
      return response.data
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi()
      }
      console.log({ ...error })
      return { ...error }
    }
  }
}
