import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';
import getLanguage from '../../../core/Language';
import Resources from '../components/Resources';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    background: '#fff',
    animation: '$moveIn 600ms ease-out',
    height: '90vh',
    overflowX: 'scroll',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
}));

const ContentLibrary_Page = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('ContentLibrary')));
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.paper}>
      <Resources module={'ContentLibrary'} />
    </div>
  );
};

export default ContentLibrary_Page;
