import "./UserCard.css";
import React, { useEffect, useState, useContext } from "react";
import UserAvatar from "./UserAvatar";
import { Grid, makeStyles, Popover } from "@material-ui/core";
import getLanguage from "../../../core/Language";
import { MeetingService } from "../../meetings/services/MeetingService";
import { LoginHelper } from "../../../core/LoginHelper";
import { WebSocketContext } from "../../../socket/EchoSocket";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  poiContainer: {
    margin: "20px 0 0 10px",
    height: "auto",
  },
  poiItem: {
    margin: "0px",
    padding: "10px",
  },
  chip: {
    marginRight: "5px",
  },
  online: {
    backgroundColor: "#4caf50",
    position: "absolute",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    top: "14px",
    left: "52px",
    zIndex: "10",
  },
  offline: {
    color: "#f44336",
  },
  activeSince: {
    marginLeft: "4px",
    fontSize: "8px",
  },
  popOverContainer: {
    padding: "0 10px 0 10px",
  },
}));

const UserCard = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const loginHelper = new LoginHelper();
  const meetingService = new MeetingService();
  const echo = useContext(WebSocketContext);
  const location = useLocation();
  const pageId = location.pathname.split("/").pop();

  const handleCallClick = async () => {
    try {
      const now = new Date();

      const meetingData = {
        hiveId: loginHelper.getCurrentHive().id,
        title: `One-to-One Meeting with ${props.login.userName} and ${
          loginHelper.getCurrentLogin().FullName
        }`,
        description: "OneToOne",
        type: "OneToOne",
      };

      // Data for the API request
      const postData = {
        title: meetingData.title,
        description: meetingData.description,
        type: meetingData.type,
      };

      // Call the method to call a participant
      const response = await meetingService.callMeetingParticipant(
        meetingData.hiveId,
        pageId,
        loginHelper.getAuthToken(),
        postData
      );

      console.log("LOGIN TOKEN:", loginHelper.getAuthToken());

      // Check for meeting URL in the response
      if (response && response.startUrl) {
        window.open(response.startUrl, "_blank");
      } else {
        console.error("No meeting URL received");
      }
    } catch (error) {
      console.error("Error in creating one-to-one meeting:", error);
    }
  };

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const getPoiVisitorFields = () => {
    let fieldsPart = [];

    if (!props.visitorFieldsForHive) {
      return [];
    }

    return fieldsPart;
  };

  return (
    <>
      <div className={"userCardScaffold"}>
        <Grid container className={"userProfileContainer"} spacing={2}>
          <Grid item xs={1}>
            <UserAvatar login={props.login} size={"40"} />
          </Grid>
          <Grid item xs={4}>
            {props.login.userName}
          </Grid>
          <button onClick={handleCallClick} className="chatButton">
            Call
          </button>
        </Grid>

        <div className={classes.poiContainer}>{getPoiVisitorFields()}</div>
      </div>

      <Popover
        id={props.login.id}
        open={anchorEl != null}
        anchorEl={anchorEl}
        onClose={handleCloseOptionsMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popOverContainer}>
          <p>
            <b>{getLanguage("Email")}: </b>
            <a href={"mailto:" + props.login.email}>{props.login.email}</a>
          </p>
          {props.login.clientInfo && (
            <>
              <p>
                <b>{getLanguage("BrowserName")}: </b>
                {props.login.clientInfo.browserName}
              </p>
              <p>
                <b>{getLanguage("BrowserVersion")}: </b>
                {props.login.clientInfo.browserVersion}
              </p>
              <p>
                <b>{getLanguage("OperatingSystem")}: </b>
                {props.login.clientInfo.operatingSystem}
              </p>
            </>
          )}
          {!props.login.clientInfo && props.clientInfo && (
            <>
              <p>
                <b>{getLanguage("BrowserName")}: </b>
                {props.clientInfo.browserName}
              </p>
              <p>
                <b>{getLanguage("BrowserVersion")}: </b>
                {props.clientInfo.browserVersion}
              </p>
              <p>
                <b>{getLanguage("OperatingSystem")}: </b>
                {props.clientInfo.operatingSystem}
              </p>
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default UserCard;
