import './ObjectEditor.css'
import { Grid, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { Help } from '@material-ui/icons'
import ReactTooltip from 'react-tooltip'

const ObjectMailButtonEditor = (props) => {
  const [title, setTitle] = useState('')
  const [recipient, setRecipient] = useState('')
  const [topic, setTopic] = useState('')
  const [body, setBody] = useState('')
  const [tooltipText, setTooltipText] = useState('')

  useEffect(() => {
    setTitle(props.pageObject.text)
    setRecipient(props.pageObject.extra)
    setTopic(props.pageObject.topic)
    setBody(props.pageObject.data)
    setTooltipText(props.pageObject.tooltipText)
    // eslint-disable-next-line
  }, [])

  const attemptUpdateAttribute = (attribute, newValue) => {
    if (props.updateAttribute) {
      props.updateAttribute(attribute, newValue)
    }
  }

  return (
    <>
      <h2>{getLanguage('Button')}</h2>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            variant="outlined"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
              attemptUpdateAttribute('text', e.target.value)
            }}
            label={getLanguage('TitleMaxCharactersForButton')}
            className="textField"
          />
          <br />
          {props.pageObject.templateObject.tooltipPosition !== 'None' && (
            <>
              <TextField
                multiline={true}
                rows={3}
                variant="outlined"
                value={tooltipText}
                onChange={(e) => {
                  setTooltipText(e.target.value)
                  attemptUpdateAttribute('tooltipText', e.target.value)
                }}
                label={getLanguage('MouseoverText')}
                className="textField"
              />
              <br />
            </>
          )}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>

      <h2>{getLanguage('EmailTemplateForBookMeeting')}</h2>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            variant="outlined"
            value={recipient}
            onChange={(e) => {
              setRecipient(e.target.value)
              attemptUpdateAttribute('extra', e.target.value)
            }}
            label={getLanguage('MailButtonRecipient')}
            className="textField"
          />
        </Grid>
        <Grid item xs={1}>
          <div data-tip data-for={'tip1'} className="helpButon">
            <Help />
          </div>
          <ReactTooltip id={'tip1'} aria-haspopup="true">
            <div
              dangerouslySetInnerHTML={{
                __html: getLanguage('EmailHelpTextRecipient'),
              }}
            />
          </ReactTooltip>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            variant="outlined"
            value={topic}
            onChange={(e) => {
              setTopic(e.target.value)
              attemptUpdateAttribute('topic', e.target.value)
            }}
            label={getLanguage('MailButtonTopic')}
            className="textField"
          />
          <br />
        </Grid>
        <Grid item xs={1}>
          <div data-tip data-for={'tip2'} className="helpButon">
            <Help />
          </div>
          <ReactTooltip id={'tip2'} aria-haspopup="true">
            <div
              dangerouslySetInnerHTML={{
                __html: getLanguage('EmailHelpTextTopic'),
              }}
            />
          </ReactTooltip>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            variant="outlined"
            multiline
            rows="10"
            value={body}
            onChange={(e) => {
              setBody(e.target.value)
              attemptUpdateAttribute('data', e.target.value)
            }}
            label={getLanguage('MailButtonBody')}
            className="textField"
          />
          <br />
        </Grid>
        <Grid item xs={1}>
          <div data-tip data-for={'tip3'} className="helpButon">
            <Help />
          </div>
          <ReactTooltip id={'tip3'} aria-haspopup="true">
            <div
              dangerouslySetInnerHTML={{
                __html: getLanguage('EmailHelpTextText'),
              }}
            />
          </ReactTooltip>
        </Grid>
      </Grid>
    </>
  )
}

export default ObjectMailButtonEditor
