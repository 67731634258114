import { Button, Container, Paper, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { TemplateService } from '../services/TemplateService';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from '../../../core/Language';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { StorageHelper } from '../../../core/StorageHelper';
import EditTemplateDetails from './EditTemplateDetails';
import { Edit, Web } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 6,
  },
  listWrap: {
    '&:hover': {
      background: '#F2F2F2',
    },
  },
  divroot: {
    width: '100%',
  },
  paper: {
    width: '100%',
    padding: '40px',
    borderRadius: '12px',
    animation: '$moveIn 600ms ease-out',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  table: {
    minWidth: 433,
  },
  pageTitle: {
    fontSize: '16px',
  },
  pageSubTitle: {
    fontSize: '11px',
    fontStyle: 'italic',
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
}));

const TemplateList = (props) => {
  const classes = useStyles();
  const templateService = new TemplateService();
  const storageHelper = new StorageHelper();

  let savedResultsPerPage = storageHelper.getResultsPerPageForTemplates();

  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [defaultSortColumn, setDefaultSortColumn] = useState('title');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');
  const [templateBeingEdited, setTemplateBeingEdited] = useState(null);
  const [currentResultsPerPage, setCurrentResultsPerPage] = useState(
    parseInt(savedResultsPerPage)
  );

  useEffect(() => {
    preparePage();
    // eslint-disable-next-line
  }, []);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    storageHelper.setResultsPerPageForTemplates(currentRowsPerPage.toString());
    setCurrentResultsPerPage(currentRowsPerPage);
  };

  const preparePage = async () => {
    let response = await templateService.getTemplatesForHive(props.hive.id);

    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      let sortCol = storageHelper.getTemplateSortingColumn();
      let sortDirection = storageHelper.getTemplateSortingDirection();

      if (sortCol) {
        setDefaultSortColumn(sortCol);
      }
      if (sortDirection) {
        setDefaultSortDirection(sortDirection);
      }
      setTemplates(response);
      setIsLoading(false);
    }
  };

  const createNewClicked = () => {
    setCreateNewIsOpen(true);
  };

  const onNewTemplateSave = async (templateObj) => {
    setIsLoading(true);

    let template = await templateService.addTemplate(templateObj);

    if (template.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${template.response.status} - ${template.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setCreateNewIsOpen(false);
      preparePage();
    }
  };

  const onEditTemplateSave = async (templateObj) => {
    setIsLoading(true);

    let template = await templateService.updateTemplate(
      templateBeingEdited.id,
      templateObj
    );

    if (template.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${template.response.status} - ${template.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      setTemplateBeingEdited(null);
      preparePage();
    }
  };

  const requestedCloseCreate = async () => {
    setCreateNewIsOpen(false);
  };

  const requestedCloseEdit = async () => {
    setTemplateBeingEdited(null);
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    storageHelper.setTemplateSortingColumn(field);
    storageHelper.setTemplateSortingDirection(direction);

    return orderBy(rows, handleField, direction);
  };

  const columns = [
    {
      name: getLanguage('Title'),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: getLanguage('Type'),
      selector: (row) => row.kind,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return rowA.template.title > rowB.template.title;
      },
      cell: (row) => {
        return getLanguage(row.kind);
      },
    },
    {
      name: getLanguage('Options'),
      minWidth: '240px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Edit')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setTemplateBeingEdited(row)}
            >
              <Edit />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('SetupObjects')}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => props.requestNavigateToTemplate(row.id)}
            >
              <Web />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background="linear-gradient(180deg, #fff, #edf5fe)"
          loaderColor="#124238"
        />
      )}
      <div>
        <Paper className={classes.paper}>
          {templates && (
            <DataTable
              noHeader={true}
              columns={columns}
              data={templates}
              pagination={true}
              selectableRows={false}
              sortFunction={customSort}
              defaultSortField={defaultSortColumn}
              defaultSortAsc={defaultSortDirection === 'asc'}
              subHeader={true}
              onChangeRowsPerPage={onChangeRowsPerPage}
              paginationPerPage={currentResultsPerPage}
              subHeaderComponent={
                <div>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => createNewClicked()}
                  >
                    <AddIcon /> {getLanguage('CreateNew')}
                  </Button>
                </div>
              }
            />
          )}
        </Paper>
      </div>
      <br />

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestedCloseCreate()}
        title={getLanguage('CreateNew')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          <EditTemplateDetails
            hiveId={props.hive.id}
            onSave={(templateObj) => onNewTemplateSave(templateObj)}
          />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={templateBeingEdited != null}
        handleDialogClose={() => requestedCloseEdit()}
        title={getLanguage('EditTemplate')}
        type="Drawer"
        sizeclass="L"
      >
        <Container>
          <EditTemplateDetails
            template={templateBeingEdited}
            onSave={(templateObj) => onEditTemplateSave(templateObj)}
          />
        </Container>
      </GlobalDialog>
    </>
  );
};

export default TemplateList;
