import { clearAllCaches } from "../../../core/ObjectCache";
import { StorageHelper } from "../../../core/StorageHelper";
import { AuthorizationApi } from "./AuthorizationApi";
import { ToastsStore } from 'react-toasts';
import getLanguage from '../../../core/Language';

export class AuthorizationService {
    authorizationApi = new AuthorizationApi();
    storageHelper = new StorageHelper();

    async login(credentials) {
        var response = await this.authorizationApi.authorize(credentials);
        if (!response.isAxiosError) {
            this.storageHelper.setLoginObject(response.data);
            return true;
        }

        if (response.response.status !== 401) {
            ToastsStore.error(`${getLanguage("SomethingWentWrong")} (${getLanguage("DeveloperInfo")}: ${response.response.status} - ${response.response.statusText} + ")`);
        } else {
            ToastsStore.error(`${getLanguage("WrongEmailOrPassword")}`);
        }

        return false;
    }

    async logOut() {
        this.storageHelper.clearAllStorageRelatedToUser();
        clearAllCaches();
    }
}