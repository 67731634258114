import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    iframe: {
        width: '100%',
        height: '500px'
    },
    editButton: {
        width: '25px;'
    },
    listWrap: {
        '&:hover': {
            background: "#F2F2F2",
        },
        padding: "2px 10px 2px 10px"
    },
    selectContainer: {
        marginBottom: '10px'
    },
    formControl: {
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectLabel: {
        background: "#FFF"
    },
}));

const VisitorField = (props) => {
    const classes = useStyles();

    const valueChanged = (newValue) => {
        // TODO: implement validation

        props.onValueUpdate(newValue);
    }

    // TODO: implement all datatypes
    return (<>
        <FormControl fullWidth={true} variant="outlined" className={classes.formControl}>
            <TextField variant="outlined" error={props.error} helperText={props.error} type={props.visitorField.dataType === "Integer" ? "number" : ""} value={props.value} onChange={(e) => { valueChanged(e.target.value); }} label={props.visitorField.identifier} className="textField" />
        </FormControl>
    </>)
}

export default VisitorField