import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import getLanguage from '../../../core/Language'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    background: '#FFF',
  },
  selectContainer: {
    marginBottom: '10px',
  },
}))

const VisitorFieldEditor = (props) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')
  const [dataType, setDataType] = useState('')
  const [dataTypeError, setDataTypeError] = useState('')
  const [selectedKeywordGroupId, setSelectedKeywordGroupId] = useState(null)
  const [useKeywordGroup, setUseKeywordGroup] = useState('true')

  useEffect(() => {
    if (props.visitorField) {
      setTitle(props.visitorField.identifier)
      setDataType(props.visitorField.dataType)
      setSelectedKeywordGroupId(props.visitorField.keywordGroupId)
      setUseKeywordGroup(
        props.visitorField.keywordGroupId != null ? 'true' : 'false',
      )
    }
    // eslint-disable-next-line
  }, [])

  const onSaveClicked = async () => {
    var validationError = false

    // Validate title not empty
    if (!title) {
      setTitleError(getLanguage('ValidationErrorCannotBeEmpty'))
      validationError = true
    } else {
      setTitleError('')
    }

    // Validate datatype selected
    if (!dataType) {
      setDataTypeError(getLanguage('ValidationErrorMustSelectOne'))
      validationError = true
    } else {
      setDataTypeError('')
    }

    if (validationError) {
      return
    }

    props.onSave(
      {
        ...props.visitorField,
        identifier: title,
        dataType,
        keywordGroupId:
          useKeywordGroup === 'true' ? selectedKeywordGroupId : null,
      },
      null,
    )
  }

  const getDataTypeOptions = () => {
    return [
      <MenuItem key={'Checkbox'} value={'Checkbox'}>
        {getLanguage('Checkbox')}
      </MenuItem>,
      <MenuItem key={'CheckboxesMultiselect'} value={'CheckboxesMultiselect'}>
        {getLanguage('CheckboxesMultiselect')}
      </MenuItem>,
      <MenuItem key={'Dropdown'} value={'Dropdown'}>
        {getLanguage('Dropdown')}
      </MenuItem>,
      <MenuItem key={'DropdownMultiselect'} value={'DropdownMultiselect'}>
        {getLanguage('DropdownMultiselect')}
      </MenuItem>,
      <MenuItem key={'Header'} value={'Header'}>
        {getLanguage('Header')}
      </MenuItem>,
      <MenuItem key={'Integer'} value={'Integer'}>
        {getLanguage('Number')}
      </MenuItem>,
      <MenuItem key={'RadioButtons'} value={'RadioButtons'}>
        {getLanguage('RadioButtons')}
      </MenuItem>,
      <MenuItem key={'String'} value={'String'}>
        {getLanguage('String')}
      </MenuItem>,
      <MenuItem key={'Textarea'} value={'Textarea'}>
        {getLanguage('Textarea')}
      </MenuItem>,
    ]
  }

  const getKeywordGroupOptions = () => {
    return props.keywordGroups.map((keywordGroup) => (
      <MenuItem key={keywordGroup.id} value={keywordGroup.id}>
        {keywordGroup.name}
      </MenuItem>
    ))
  }

  const handleDataTypeChanged = (event) => {
    setDataType(event.target.value)
  }

  const handleKeywordGroupChanged = (event) => {
    setSelectedKeywordGroupId(event.target.value)
  }

  const currentDataTypeAllowsOptions = () => {
    return (
      dataType === 'CheckboxesMultiselect' ||
      dataType === 'Dropdown' ||
      dataType === 'DropdownMultiselect' ||
      dataType === 'RadioButtons'
    )
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSaveClicked()
        }}
      >
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <TextField
            variant="outlined"
            error={titleError}
            helperText={titleError}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
              setTitleError('')
            }}
            label={getLanguage('Label')}
            className="textField"
          />
        </FormControl>
        <FormControl
          fullWidth={true}
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel
            error={dataTypeError}
            className={classes.selectLabel}
            id="datatypeSelector-label"
          >
            {getLanguage('Type')}
          </InputLabel>
          <Select
            error={dataTypeError}
            variant="outlined"
            fullWidth={true}
            value={dataType}
            onChange={handleDataTypeChanged}
            labelId="datatypeSelector-label"
            id="datatypeSelector"
          >
            {getDataTypeOptions()}
          </Select>
          {dataTypeError && (
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
              {dataTypeError}
            </p>
          )}
        </FormControl>

        {currentDataTypeAllowsOptions() && (
          <>
            <h4>{getLanguage('AvailableValues')}</h4>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <RadioGroup
                    value={useKeywordGroup}
                    onClick={() => setUseKeywordGroup('false')}
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={getLanguage('EnterOptionsManually')}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6}>
                  <RadioGroup
                    value={useKeywordGroup}
                    onClick={() => setUseKeywordGroup('true')}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={getLanguage('UseKeywordGroup')}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </div>
            {useKeywordGroup === 'true' && (
              <FormControl
                fullWidth={true}
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel
                  className={classes.selectLabel}
                  id="keywordgroupSelector-label"
                >
                  {getLanguage('SelectKeywordGroup')}
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  value={selectedKeywordGroupId}
                  onChange={handleKeywordGroupChanged}
                  labelId="keywordgroupSelector-label"
                  id="keywordgroupSelector"
                >
                  {getKeywordGroupOptions()}
                </Select>
              </FormControl>
            )}
            {useKeywordGroup === 'false' && (
              <p>
                {getLanguage(
                  'UseTheValuesButtonOnTheOverviewToEditTheAvailableValues',
                )}
              </p>
            )}
          </>
        )}
        <br />
        <br />
        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
        >
          {getLanguage('Save')}
        </Button>
      </form>
    </>
  )
}

export default VisitorFieldEditor
