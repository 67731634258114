import { LoginHelper } from "../../../core/LoginHelper";
import { clearFromCache, getCachedObject, setObjectInCache } from "../../../core/ObjectCache";
import { VisitorFieldApi } from "./VisitorFieldApi";

export class VisitorFieldService {
    visitorFieldApi = new VisitorFieldApi();
    loginHelper = new LoginHelper();

    cacheIdentifier = "visitorFields";
    cacheDurationMinutes = 15;

    async getVisitorFieldsForHive(hiveId) {
        if (hiveId) {
            let cached = getCachedObject(this.cacheIdentifier + "_" + hiveId);
            if (cached) return cached;
        }

        var result = await this.visitorFieldApi.getVisitorFieldsForHive(hiveId);

        if (!result.isAxiosError && hiveId) {
            setObjectInCache(this.cacheIdentifier + "_" + hiveId, result, this.cacheDurationMinutes);
        }

        return result;
    }

    async addVisitorField(visitorField) {
        this.clearCache();
        return await this.visitorFieldApi.addVisitorField(visitorField);
    }

    async updateVisitorField(id, visitorField) {
        this.clearCache();
        return await this.visitorFieldApi.updateVisitorField(id, visitorField);
    }

    async updateVisitorFields(visitorFields) {
        this.clearCache();
        return await this.visitorFieldApi.updateVisitorFields(visitorFields);
    }

    async deleteVisitorField(id) {
        this.clearCache();
        return await this.visitorFieldApi.deleteVisitorField(id);
    }

    async getVisitorFieldPossibleValue(visitorFieldId) {
        return await this.visitorFieldApi.getVisitorFieldPossibleValue(visitorFieldId);
    }

    async addVisitorFieldPossibleValue(visitorFieldPossibleValue, visitorFieldId) {
        this.clearCache();
        return await this.visitorFieldApi.addVisitorFieldPossibleValue(visitorFieldPossibleValue, visitorFieldId);
    }

    async updateVisitorFieldPossibleValue(visitorFieldId, visitorFieldPossibleValueId, visitorFieldPossibleValue) {
        this.clearCache();
        return await this.visitorFieldApi.updateVisitorFieldPossibleValue(visitorFieldId, visitorFieldPossibleValueId, visitorFieldPossibleValue);
    }

    async deleteVisitorFieldPossibleValue(visitorFieldId, visitorFieldPossibleValueId) {
        this.clearCache();
        return await this.visitorFieldApi.deleteVisitorFieldPossibleValue(visitorFieldId, visitorFieldPossibleValueId);
    }

    clearCache() {
        clearFromCache(this.cacheIdentifier + "_" + this.loginHelper.getCurrentHive().id);
    }

}