import { Button, Container, Link, Paper, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { HiveService } from '../services/HiveService';
import { ToastsStore } from 'react-toasts';
import Loading from 'react-fullscreen-loading';
import getLanguage from '../../../core/Language';
import { GlobalDialog } from '../../../core/GlobalDialog';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DataTable from 'react-data-table-component';
import orderBy from 'lodash/orderBy';
import { StorageHelper } from '../../../core/StorageHelper';
import EditHiveDetails from '../components/EditHiveDetails';
import { Delete, Forward, ImportExport, ViewModule } from '@material-ui/icons';
import { LoginHelper } from '../../../core/LoginHelper';
import EditHiveModules from '../components/EditHiveModules';
import { useDispatch } from 'react-redux';
import { updatePageTitle } from '../../../actions/Actions';
import ImportExportHive from '../components/ImportExportHive';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    overflow: 'scroll',
    height: '90vh',
    padding: '40px',
    animation: '$moveIn 800ms ease-out',
    borderRadius: '12px',
  },
  '@keyframes moveIn': {
    '0%': {
      transform: 'translateY(-20px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0px)',
      opacity: 1,
    },
  },
  table: {
    minWidth: 433,
  },
  button: {
    margin: '10px 0 10px 0',
    background: '#19275d',
    color: '#fff',
    '&:hover': {
      background: '#213377',
    },
  },
  pageTitle: {
    fontSize: '16px',
    color: '#121C42',
  },
  pageSubTitle: {
    fontSize: '11px',
    fontStyle: 'italic',
    color: '#121C42',
  },
}));

const Hives_Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hiveService = new HiveService();
  const storageHelper = new StorageHelper();
  const loginHelper = new LoginHelper();

  const [isLoading, setIsLoading] = useState(true);
  const [hives, setHives] = useState([]);
  const [createNewIsOpen, setCreateNewIsOpen] = useState(false);
  const [defaultSortColumn, setDefaultSortColumn] = useState('title');
  const [defaultSortDirection, setDefaultSortDirection] = useState('asc');
  const [hiveToEditModulesFor, setHiveToEditModulesFor] = useState(null);
  const [hiveToDelete, setHiveToDelete] = useState(null);
  const [hiveToEditImportExportFor, setHiveToEditImportExportFor] =
    useState(null);

  useEffect(() => {
    dispatch(updatePageTitle(getLanguage('Hives')));
    loadHives(false);
    // eslint-disable-next-line
  }, []);

  const loadHives = async (forceReload) => {
    let response = await hiveService.getHives(true);
    if (response.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${response.response.status} - ${response.response.statusText} + ")`
      );
    } else {
      let sortCol = storageHelper.getHiveSortingColumn();
      let sortDirection = storageHelper.getHiveSortingDirection();

      if (sortCol) {
        setDefaultSortColumn(sortCol);
      }
      if (sortDirection) {
        setDefaultSortDirection(sortDirection);
      }

      // Filter away master template, unless user is developer
      // TODO: Guess what, we're gonna change this when we get an actual role-system
      let userId = loginHelper.getUserId();
      if (
        userId !== 'd83f1aea-c961-474a-bfec-21a5f05a0e31' &&
        userId !== '293cf0a1-fb52-4fe7-9210-403ff6747f0a' &&
        userId !== '022d62cb-7390-4676-948b-530849efe6ad' &&
        userId !== '7d8b34be-b850-4ece-95f2-ea3ae1a69e26' &&
        userId !== 'c2e3c077-f428-44c8-b1c9-7be406bf417f' &&
        userId !== '63a3951c-0113-4909-a328-5e76249bbef9'
      ) {
        response = response.filter(
          (t) => t.id !== '163374c3-0d38-4e8c-ba33-ec54a674866d'
        );
      }

      setHives(response);
      setIsLoading(false);
    }
  };

  const createNewClicked = () => {
    setCreateNewIsOpen(true);
  };

  const onNewHiveSave = async (hiveObj) => {
    setCreateNewIsOpen(false);
    setIsLoading(true);

    let hive = await hiveService.addHive(hiveObj);

    if (hive.isAxiosError) {
      ToastsStore.error(
        `${getLanguage('SomethingWentWrong')} (${getLanguage(
          'DeveloperInfo'
        )}: ${hive.response.status} - ${hive.response.statusText} + ")`
      );
      setIsLoading(false);
    } else {
      storageHelper.setCurrentHive({ id: hive.id, title: hive.title });
      window.location.href = '/';
    }
  };

  const requestedCloseCreate = async () => {
    setCreateNewIsOpen(false);
  };

  const customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (row[field] && isNaN(row[field])) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    storageHelper.setHiveSortingColumn(field);
    storageHelper.setHiveSortingDirection(direction);

    return orderBy(rows, handleField, direction);
  };

  const changeActiveHive = (hive) => {
    loginHelper.setCurrentHive(hive);
    window.location.href = '/pages';
  };

  const deleteHive = async () => {
    setIsLoading(true);

    await hiveService.deleteHive(hiveToDelete.id);

    setHiveToDelete(null);

    loadHives(true);
  };

  const closeHiveModuleEditor = () => {
    setHiveToEditModulesFor(null);
    setIsLoading(true);
    loadHives(true);
  };

  const columns = [
    {
      name: getLanguage('Title'),
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => (
        <Link
          color='inherit'
          href='#'
          onClick={(e) => {
            e.preventDefault();
            changeActiveHive(row);
          }}
        >
          <span className={classes.pageTitle}>{row.title}</span>
        </Link>
      ),
    },
    {
      name: getLanguage('Created'),
      selector: (row) => row.created,
      sortable: false,
      cell: (row) => (
        <span className={classes.pageTitle}>
          {moment(row.created).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      name: getLanguage('Options'),
      minWidth: '270px',
      cell: (row) => (
        <div>
          <Tooltip title={getLanguage('Delete')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setHiveToDelete(row)}
            >
              <Delete />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Import/Export')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setHiveToEditImportExportFor(row)}
            >
              <ImportExport />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('Modules')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => setHiveToEditModulesFor(row)}
            >
              <ViewModule />
            </Button>
          </Tooltip>{' '}
          <Tooltip title={getLanguage('LoginAs')}>
            <Button
              variant='contained'
              className={classes.button}
              onClick={() => changeActiveHive(row)}
            >
              <Forward />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='linear-gradient(180deg, #fff, #edf5fe)'
          loaderColor='#124238'
        />
      )}

      <Paper className={classes.paper}>
        {hives && (
          <DataTable
            noHeader={true}
            columns={columns}
            data={hives}
            pagination={true}
            paginationPerPage={100}
            selectableRows={false}
            sortFunction={customSort}
            defaultSortField={defaultSortColumn}
            defaultSortAsc={defaultSortDirection === 'asc'}
            subHeader={true}
            subHeaderComponent={
              <div>
                <Button
                  className={classes.button}
                  variant='contained'
                  onClick={() => createNewClicked()}
                >
                  <AddIcon /> {getLanguage('CreateNew')}
                </Button>
              </div>
            }
          />
        )}
      </Paper>

      <br />

      <GlobalDialog
        isDialogOpen={createNewIsOpen}
        handleDialogClose={() => requestedCloseCreate()}
        title={getLanguage('CreateNew')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          <EditHiveDetails onSave={(hiveObj) => onNewHiveSave(hiveObj)} />
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={hiveToEditModulesFor !== null}
        handleDialogClose={() => closeHiveModuleEditor()}
        title={getLanguage('Modules')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {hiveToEditModulesFor && (
            <EditHiveModules hiveId={hiveToEditModulesFor.id} />
          )}
        </Container>
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={hiveToDelete !== null}
        handleDialogClose={() => setHiveToDelete(null)}
        title={getLanguage('DeleteHive')}
        type='Drawer'
        sizeclass='L'
      >
        {hiveToDelete && (
          <Container>
            {getLanguage('DoYouReallyWantToDeleteTheHive?').replace(
              '{hiveTitle}',
              hiveToDelete.title
            )}
            <br />
            <br />
            <Button
              fullWidth={true}
              variant='contained'
              className={classes.button}
              onClick={() => deleteHive()}
            >
              {getLanguage('Delete')}
            </Button>
          </Container>
        )}
      </GlobalDialog>

      <GlobalDialog
        isDialogOpen={hiveToEditImportExportFor !== null}
        handleDialogClose={() => setHiveToEditImportExportFor(null)}
        title={getLanguage('Import/Export')}
        type='Drawer'
        sizeclass='L'
      >
        <Container>
          {hiveToEditImportExportFor && (
            <ImportExportHive
              hiveId={hiveToEditImportExportFor.id}
              requestClose={() => setHiveToEditImportExportFor(null)}
            />
          )}
        </Container>
      </GlobalDialog>
    </>
  );
};

export default Hives_Page;
