import React, { useEffect, useState } from 'react';
import { LoginService } from '../services/LoginService';
import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Link,
  FormControl,
} from '@material-ui/core';
import getLanguage from '../../../core/Language';
import {
  navigateToDashboard,
  navigateToLogin_Page,
} from '../../../core/Navigation';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useParams } from 'react-router-dom';
import { ToastsStore } from 'react-toasts';
import { LoadingIndicator } from '../../../core/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    backgroundColor: '#ffffff',
    paddingBottom: '40px',
    borderRadius: '12px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword_Page = (props) => {
  const classes = useStyles();

  const loginService = new LoginService();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [isDone, setIsDone] = useState(false);
  const [showInvalidTokenWarning, setShowInvalidTokenWarning] = useState(false);

  const { token } = useParams();

  const saveData = async () => {
    let validationError = false;

    // Validate password valid
    if (password && password.length < 6) {
      setPasswordError(getLanguage('PasswordIsTooShort'));
      validationError = true;
    } else {
      setPasswordError('');
    }

    // Validate repeat password
    if (!validationError && password && password !== repeatPassword) {
      setRepeatPasswordError(getLanguage('TheTwoPasswordsAreNotIdentical'));
      validationError = true;
    } else {
      setRepeatPasswordError('');
    }

    if (validationError) {
      return;
    }

    setIsLoading(true);

    let login = await loginService.consumeResetPasswordToken(token, password);
    if (login.isAxiosError) {
      if (
        login.response &&
        login.response.data &&
        login.response.data === 'Invalid_Reset_Password_Token'
      ) {
        setShowInvalidTokenWarning(true);
        return;
      }

      ToastsStore.error(`${getLanguage('SomethingWentWrong')}`);
      setIsLoading(false);
      return;
    }

    setIsDone(true);
    setIsLoading(false);
  };

  // const requestedCloseEdit = async
  return (
    <Container component='main' maxWidth='xs' className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          {getLanguage('ResetPassword')}
        </Typography>
        <br />
        {!showInvalidTokenWarning && (
          <>
            {isLoading && <LoadingIndicator />}
            {!isLoading && !isDone && (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveData();
                  }}
                >
                  <FormControl
                    fullWidth={true}
                    variant='outlined'
                    className={classes.formControl}
                  >
                    <TextField
                      variant='outlined'
                      type='password'
                      error={passwordError}
                      helperText={passwordError}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordError('');
                      }}
                      label={getLanguage('Password')}
                      className='textField'
                    />
                  </FormControl>
                  <FormControl
                    fullWidth={true}
                    variant='outlined'
                    className={classes.formControl}
                  >
                    <TextField
                      variant='outlined'
                      type='password'
                      error={repeatPasswordError}
                      helperText={repeatPasswordError}
                      value={repeatPassword}
                      onChange={(e) => {
                        setRepeatPassword(e.target.value);
                        setRepeatPasswordError('');
                      }}
                      label={getLanguage('RepeatPassword')}
                      className='textField'
                    />
                  </FormControl>

                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        type='submit'
                        fullWidth={true}
                        variant='contained'
                        color='primary'
                      >
                        {getLanguage('SaveChanges')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </>
            )}
            {!isLoading && isDone && (
              <>
                <p>{getLanguage('YourPassHasBeenUpdated')}</p>
                <Link color='inherit' href='/'>
                  {getLanguage('ClickHereToLoginNow')}
                </Link>
              </>
            )}
          </>
        )}

        {showInvalidTokenWarning && (
          <div>
            <p>
              {getLanguage('YouAlreadyCreatedANewPasswordOrTheLinkIsInvalid')}
            </p>
            <p>
              <Link color='inherit' href='/login'>
                {getLanguage('ClickHereToGoToTheLoginScreen')}
              </Link>
            </p>
            <p>
              <Link color='inherit' href='/login/forgot'>
                {getLanguage('ClickHereIfYouNeedANewLinkToResetYourPassword')}
              </Link>
            </p>
          </div>
        )}
      </div>
    </Container>
  );
};

export default ResetPassword_Page;
