import { getApiPath } from "../../../core/ServiceRoutes";
import Axios from "axios";
import { LoginHelper } from "../../../core/LoginHelper";

export class MeetingApi {
  loginHelper = new LoginHelper();

  async getMeeting(id) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.get(`${getApiPath()}/meetings/${id}`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getMeetingsForHive(hiveId, pagination, size, search) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          page: pagination ? pagination : 1,
          size: size ? size : 10,
          q: search ? search : "",
        },
      };
      let response = await Axios.get(`${getApiPath()}/api/meetings`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getMeetingsCsvForHive(hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
          responseType: "blob",
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.get(`${getApiPath()}/meetings/csv`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getMeetingsForLogin(loginId, hiveId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.get(
        `${getApiPath()}/logins/${loginId}/meeting`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getNumberForAllMeetings(hiveId, searchQuery) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
          responseType: "blob",
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          q: searchQuery,
        },
      };
      let response = await Axios.get(`${getApiPath()}/meetings/count`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async addMeeting(
    hiveId,
    title,
    startAt,
    endAt,
    startAtUTC,
    endAtUTC,
    pageId,
    description,
    type,
    startUrl,
    externalUrl,
    maxAttendees,
    isPrivate
  ) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
        },
      };
      let response = await Axios.post(
        `${getApiPath()}/meetings`,
        {
          title,
          startAt: startAtUTC,
          endAt: endAtUTC,
          pageId,
          description,
          type,
          startUrl,
          externalUrl,
          maxAttendees,
          isPrivate,
        },
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async updateMeeting(
    id,
    title,
    startAt,
    endAt,
    startAtUTC,
    endAtUTC,
    pageId,
    description,
    type,
    startUrl,
    externalUrl,
    maxAttendees,
    isPrivate
  ) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };
      let response = await Axios.put(
        `${getApiPath()}/meetings/${id}`,
        {
          title,
          startAt: startAtUTC,
          endAt: endAtUTC,
          pageId,
          description,
          type,
          startUrl,
          externalUrl,
          maxAttendees,
          isPrivate: isPrivate,
        },
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async deleteMeeting(id) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.delete(
        `${getApiPath()}/meetings/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async addMinutesToMeeting(id, minutes) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.put(
        `${getApiPath()}/meetings/${id}/addminutes/${minutes}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async callMeetingParticipant(hiveId, pageId, loginToken, postData) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginToken}`,
        },
      };

      let response = await Axios.post(
        `https://chat-service.jx.virtualhiveservices.io/videoconferences?hiveid=${hiveId}&pageid=${pageId}`,
        postData,
        config
      );

      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async addParticipantToMeeting(meetingGroupId, loginId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.post(
        `${getApiPath()}/meetinggroups/${meetingGroupId}?loginid=${loginId}`,
        null,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async getVisitorsOnSearch(
    hiveId,
    search,
    page,
    resultsPerPage,
    sortcolumn,
    sortDirection
  ) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
        params: {
          hiveid: hiveId ?? this.loginHelper.getCurrentHive().id,
          q: search,
          first: page * resultsPerPage,
          max: resultsPerPage,
          sortcolumn: sortcolumn,
          sortDirection: sortDirection,
        },
      };

      let response = await Axios.get(`${getApiPath()}/api/users`, config);
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }

      console.log({ ...error });
      return { ...error };
    }
  }

  async removeParticipantFromMeeting(meetingGroupId, loginId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.delete(
        `${getApiPath()}/meetinggroups/${meetingGroupId}?loginid=${loginId}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }

  async removeAllParticipantsFromMeeting(meetingGroupId) {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.loginHelper.getAuthToken()}`,
        },
      };

      let response = await Axios.delete(
        `${getApiPath()}/meetinggroups/all/${meetingGroupId}`,
        config
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }
}
