import { REACT_APP_TEMPLATE_SERVICE_URL } from '../../../config';
import Axios from 'axios';

export class HiveTemplatesApi {
  async getHiveTemplates() {
    try {
      let response = await Axios.get(
        `${REACT_APP_TEMPLATE_SERVICE_URL}/templates`
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        this.loginHelper.receivedUnauthorizedAnswerFromApi();
      }
      console.log({ ...error });
      return { ...error };
    }
  }
}
