function navigateToPages_Page(props) {
  props.history.push('/pages');
}

function navigateToStands_Page(props) {
  props.history.push('/stands');
}

function navigateToEditPage_Page(props, pageId) {
  props.history.push('/editpage/' + pageId);
}

function navigateToLogin_Page(props) {
  props.history.push('/login');
}

function navigateToNewLogin_Page(props) {
  props.history.push('/login/new');
}

function navigateToDashboard(props) {
  props.history.push('/');
}
function navigateToForgotPassword_Page(props) {
  props.history.push('/login/forgot');
}

function navigateToHives_Page(props) {
  props.history.push('/hives');
}

function navigateToEditHive_Page(props) {
  props.history.push('/hive');
}

function navigateToEditTemplate(props, templateId) {
  props.history.push('/template/' + templateId);
}

function navigateToEditEvents_Page(props) {
  props.history.push('/hive/events');
}

function navigateToPagesEvent(props) {
  props.history.push('/hive/events/pages/');
}

function navigateToEditPagesEvent(props, pageId) {
  props.history.push('/hive/events/pages/' + pageId);
}

function navigateToResourceArchive(props) {
  props.history.push('/resourceArchive');
}

function navigateToMeetings_Page(props) {
  props.history.push('/meetings');
}

export {
  navigateToPages_Page,
  navigateToStands_Page,
  navigateToEditPage_Page,
  navigateToLogin_Page,
  navigateToNewLogin_Page,
  navigateToDashboard,
  navigateToForgotPassword_Page,
  navigateToHives_Page,
  navigateToEditHive_Page,
  navigateToEditTemplate,
  navigateToEditEvents_Page,
  navigateToEditPagesEvent,
  navigateToPagesEvent,
  navigateToResourceArchive,
  navigateToMeetings_Page,
};
